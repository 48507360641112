import {MONTH} from '../constants';
import {ERRORS} from '../constants/errors';

export const uploadFile = (acceptedFiles: any, callback) => {
    for (const file of acceptedFiles) {
        const reader = new FileReader();
        reader.onload = () => {
            const data = new FormData();
            data.append('file', file);
            fetch(`${process.env.REACT_APP_BASE_URL}/upload-file`, {
                method: 'POST',
                body: data
            }).then((response) => response.json()).then((data) => {
                callback(data)
            });
        };
        reader.readAsArrayBuffer(file);
    }
}

export const uploadedFiles = (acceptedFiles: any) => {
    const newDate = [];
    for (const file of acceptedFiles) {
        newDate.push(new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const data = new FormData();
                data.append('file', file);
                fetch(`${process.env.REACT_APP_BASE_URL}/upload-file`, {
                    method: 'POST',
                    body: data
                }).then((response) => response.json()).then((data) => {
                    resolve(data.filePath)
                });
            };
            reader.onerror = () => {
                reject('Error')
            }
            reader.readAsArrayBuffer(file);
        }))
    }
    return newDate
}

export const checkEmptyArrays = (arrays) => {
    const newArray = [];
    for (let i = 0; i < arrays.length; i++) {
        if (Array.isArray(arrays[i]) && arrays[i] && arrays[i].length > 0) {
            newArray.push(...arrays[i])
        }
    }
    return newArray;
}

export const generateString = (): string => {
    const length = 8;
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

export const sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const findFieldError = (touched, errors) => {
    const required = Object.keys(touched)?.find(key => Object.keys(errors).filter(errorKey => errors[errorKey] === ERRORS.REQUIRED).includes(key));
    const incorrect = Object.keys(touched)?.find(key => Object.keys(errors).filter(errorKey => errors[errorKey] !== ERRORS.REQUIRED).includes(key))
    return {
        required,
        incorrect
    };
};

export const isSelected = (id, selected) => selected.map(item => item.id).indexOf(id) !== -1;

export function centeredEllipsis(str: string = '', start: number = 15, end: number = 10) {
    return `${str.substring(0, start)}...${str.substring(str.length - end)}`;
}
export const toMoneyFormat = (money) => parseFloat(money).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace('.', ',');

export const transformDate = (date: string, withHour: boolean = true): string => {
    const utcDifferent = -(new Date(date).getTimezoneOffset() / 60);
    const [newDate, time] = date.split('T')
    const [year, month, day] = newDate.split('-')
    const [hours, minutes] = time.split(':')
    const hoursWithUtc = Number(hours) + utcDifferent
    const nowDate = new Date().getDate();
    const nowYear = new Date().getFullYear();
    const timeConvert = `${hoursWithUtc > 12 ? `${((hoursWithUtc - 12) < 10 ? '0' : '')}${hoursWithUtc - 12}` : hoursWithUtc}:${minutes} ${parseInt(hours) > 12 ? 'PM' : 'AM'}`
    if (parseInt(year) === nowYear && parseInt(month) === nowDate) {
        return `Today at ${timeConvert}`
    } else {
        if (!withHour) {
            return `${day} ${MONTH[parseInt(month) - 1]}, ${year}`
        }
        return `${day} ${MONTH[parseInt(month) - 1]}, ${year} at ${timeConvert}`
    }
}

export const isOverflowing = (idElement: string) => {
    const element = document.getElementById(idElement);
    if (element === null){
        return;
    }
    return (element!.scrollHeight > element!.offsetHeight);
};

export const formattedDate = (dateValue: any) => {
    const date = new Date(dateValue);
    const month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const day = date.getDate();
    const monthName = date.getUTCMonth();
    const year = date.getFullYear();
    return `${day} ${month_names_short[monthName]} ${year}`
}
