import React, {useContext, useState} from 'react';

const INITIAL_VALUES = {
    email: '',
    setEmail: (newState: string) => undefined
}
const ForgotPasswordContext = React.createContext(INITIAL_VALUES);

const useForgotPasswordContext = () => {
    return useContext(ForgotPasswordContext);
};

function ForgotPasswordContextProvider({children}) {
    const [email, setEmail] = useState('');
    return (
        <ForgotPasswordContext.Provider value={{
            email,
            setEmail: (newState) => setEmail(newState)
        }}>
            {children}
        </ForgotPasswordContext.Provider>
    );
}
export {ForgotPasswordContextProvider, useForgotPasswordContext}
