import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import CustomButton from '../Buttons/Button';
import DialogLayout, {DialogLayoutProps} from '../../../layouts/DialogLayout';
import {COLORS} from '../../../constants/colors';

type DialogNegativeAction = DialogLayoutProps & {
    title?: string;
    subtitle?: string;
    buttonText: string;
    mainAction: any
}

const DialogNegativeAction: React.FC<DialogNegativeAction> = (props: DialogNegativeAction): JSX.Element => {
    const classes = useStyles();
    const {title, subtitle, buttonText, mainAction, ...rest} = props
    return (
        <DialogLayout
            hideIcon
            width={552}
            maxWidth={false}
            {...rest}
        >
            <Box className={classes.wrapper}>
                <Typography variant={'h4'} className={classes.title}>{title}</Typography>
                <Typography variant={'subtitle1'} className={classes.subtitle}>{subtitle}</Typography>
                <Box className={classes.buttonWrapper}>
                    <CustomButton
                        onClick={() => props.onClose('', 'escapeKeyDown')}
                    >
                        Cancel
                    </CustomButton>
                    <CustomButton
                        color={'secondary'}
                        onClick={mainAction}
                    >
                        {buttonText}
                    </CustomButton>
                </Box>
            </Box>
        </DialogLayout>
    );
};

export default DialogNegativeAction;

const useStyles = makeStyles<Theme>(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '40px 40px 32px 56px'
    },
    title: {
        lineHeight: 2,
        marginBottom: 8
    },
    subtitle: {
        color: COLORS.TEXT_SILVER,
        marginBottom: 24
    },
    buttonWrapper: {
        width: '60%',
        alignSelf: 'flex-end',
        display: 'flex',
        justifyContent: 'space-between'
    }
}));
