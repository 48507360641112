import React, {useState, useEffect} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {Box} from '@material-ui/core';
import BalanceBackground from '../../../assets/background/balance-background.svg';
import {useGlobalContext} from '../../../context/globalContext';
import {COLORS} from '../../../constants/colors';

const UserBalance = () => {
    const classes = useStyles();
    const {userData} = useGlobalContext();
    // const balance = userData?.balance && parseFloat(userData?.balance).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace('.', ',');
    return (
        <Box className={classes.UserBalance}>
            <Typography variant={'h3'} className={classes.FinancesTitle}>Finances</Typography>
            <Box className={classes.BalanceCardWrapper}>
                <img src={BalanceBackground} alt="" className={classes.BgImg}/>
                <Typography variant={'subtitle2'} className={classes.CardTitle}>Your current balance</Typography>
                <Typography variant={'h2'} className={classes.Money}>
                    ${userData?.billingBalance / 100}
                </Typography>
            </Box>
            <Box className={classes.BgCard}>
                <Box className={classes.Line}></Box>
            </Box>
        </Box>);
}

export default UserBalance;

const useStyles = makeStyles({
    UserBalance: {
        padding: '32px 40px 24px 40px',
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column'

    },
    FinancesTitle: {
        paddingBottom: 32
    },
    BgCard: {
        position: 'absolute',
        bottom: 0,
        right: 32,
        minHeight: 190,
        width: '75%',
        background: '#ebebeb',
        borderRadius: 17,
        zIndex: 5
    },
    Line: {
        width: '100%',
        height: 40,
        background: 'rgba(0, 0, 0, 0.04)',
        position: 'absolute',
        top: 45
    },
    BalanceCardWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        zIndex: 9,
        width: '90%',
        minHeight: 190,
        background: COLORS.PRIMARY,
        borderRadius: 17,
        color: 'white',
        padding: '45px 40px 40px 40px',
        boxShadow: '0px 18px 67px rgba(8, 42, 92, 0.29)'
    },
    BgImg: {
        width: '100%',
        height: '100%',
        zIndex: 2,
        objectFit: 'cover',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0
    },
    CardTitle: {
        fontWeight: 500,
        fontSize: 16,
        position: 'relative',
        zIndex: 2,
        color: 'white'
    },
    Money: {
        position: 'relative',
        zIndex: 2,
        fontWeight: 500,
        color: 'white',
        letterSpacing: -0.5,
        marginTop: 10
    }
});
