import React from 'react';
import {withStyles} from '@material-ui/core';
import Button from './Button';


export default withStyles(theme => ({
    root: {
        height: 62,
        background: '#276EF9',
        borderRadius: '4px',
        color: 'white',
        '&:hover': {
            background: '#276EF9'
        }
    },
    disabled: {
        background: 'rgba(0, 0, 0, 0.12)',
        color: 'rgba(0, 0, 0, 0.38)',
    }
}))(Button)

