import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, PaperProps, Typography} from '@material-ui/core';
import CustomBreadcrumbs from '../components/ui/BreadCrumbs';
import CustomPaper from '../components/ui/Paper';
import {COLORS} from '../constants/colors';

type FormLayoutProps = PaperProps & {
    crumbsDetails?: {message: string, link: string}[],
    title?: string;
    children: React.ReactNode;
}

const FormLayout: React.FC<FormLayoutProps> = (props: FormLayoutProps): JSX.Element => {
    const classes = useStyles();
    const {title, crumbsDetails, children, ...rest} = props;
    return (
        <CustomPaper className={classes.mainSection} {...rest}>
            {crumbsDetails &&
            <CustomBreadcrumbs
                details={crumbsDetails}
            />
            }
            {title && <Typography variant={'h3'} className={classes.title}>{title}</Typography>}
            {children}
        </CustomPaper>
    );
};

export default FormLayout;

const useStyles = makeStyles<Theme>(() => ({
    mainSection: {
        width: '100%',
        minHeight: '100%',
        background: COLORS.WHITE,
        padding: '32px 50px',
    },
    title: {
        colors: COLORS.BLACK,
        marginBottom: 32,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    }
}));

