import React, {useEffect, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import FormLayout from '../../layouts/FormLayout';
import {CREATE_CONSIGNMENT_SCHEMA} from '../../constants/validation_schema';
import {Formik, Form, Field, FieldProps, FieldArray} from 'formik';
import {useUIContext} from '../../context/UIContext';
import FormInput from '../../components/ui/FormInput';
import CustomDropzone from '../../components/ui/CustomDropzone';
import {CREATE_DROPZONE_STYLE} from '../products/helpers/constants';
import SelectConfirmFiles from '../brands/images/select-confirm-files.svg';
import FileChip from '../../components/ui/Chips/FileChip';
import CustomButton from '../../components/ui/Buttons/Button';
import {COLORS} from '../../constants/colors';
import SubmitButtonWithErrors from '../../components/ui/Buttons/SubmitButtonWithErrors';
import {uploadedFiles} from '../../helpers';
import {ProductAttributeTemplateType, useCreateProductsRangeMutation} from '../../generated/graphql';
import {useGlobalContext} from '../../context/globalContext';
import SuccessLayout from '../../layouts/SuccessLayout';
import OptionInputs from '../products/components/OptionInputs';
import DialogSelectOptionalField from '../../components/ui/Dialogs/DialogSelectOptionalField';
import {MAX_UPLOAD_FILES, UPLOAD_IMAGE_SIZE} from '../../constants';

const ConsignmentsCreate: React.FC = (props): JSX.Element => {
    const classes = useStyles();
    const {showEndAction} = useUIContext();
    const {userData} = useGlobalContext();
    const [createProducts, {data}] = useCreateProductsRangeMutation();
    const [open, setOpen] = useState<boolean>(false);
    if (data?.productsCreateRange) {
        return <SuccessLayout
            title={'Consignment successfully created'}
            push={'/consignments'}
            buttonText={'Back to consignments'}
        />
    }
    return (
        <FormLayout
            crumbsDetails={[
                {message: 'Consignments', link: ''},
                {message: 'Create', link: ''}
            ]}
            title={'Create consignment'}
        >
            <Formik
                initialValues={{
                    name: '',
                    quantity: '',
                    photoFilePaths: [],
                    attributes: []
                }}
                enableReinitialize={true}
                validationSchema={CREATE_CONSIGNMENT_SCHEMA}
                onSubmit={async (values) => {
                    try {
                        const {name, quantity, attributes, photoFilePaths} = values;
                        const files = await Promise.all(uploadedFiles(photoFilePaths));
                        const {data} = await createProducts({
                            variables: {
                                dataProducts: {
                                    name: name,
                                    serial: '',
                                    quantity: Number(quantity),
                                    photoFilePaths: files,
                                    attributes: attributes?.map(item => (
                                        item?.type === ProductAttributeTemplateType.Dropdown
                                            ? {templateId: item?.id, value: `${item?.value} ${item?.dimension}`}
                                            : {templateId: item?.id, value: item?.value}
                                    ))
                                },
                                // @ts-ignore
                                brandId: userData?.consignmentsBrand?.id,
                                rangeFrom: 1,
                                rangeTo: 1
                            }
                        })
                    } catch (e) {
                        showEndAction(e?.message)
                    }
                }}
            >
                {({handleSubmit, values, setFieldValue}) => {
                    return (
                        <Form onSubmit={handleSubmit} className={classes.container}>
                            <Box className={classes.inputsWrapper}>
                                <FormInput
                                    fullWidth
                                    name={'name'}
                                    label={'Consignment name'}
                                    labelWidth={145}
                                    formControlProps={{
                                        className: classes.input
                                    }}
                                />
                                <FormInput
                                    fullWidth
                                    name={'quantity'}
                                    label={'Quantity'}
                                    labelWidth={63}
                                    inputProps={{
                                        type: 'number'
                                    }}
                                    formControlProps={{
                                        className: classes.input
                                    }}
                                />
                            </Box>
                            <Field name={'photoFilePaths'}>
                                {({form, meta, field}: FieldProps) => (
                                    <CustomDropzone
                                        fieldProps={{form, meta, field}}
                                        style={CREATE_DROPZONE_STYLE}
                                        renderContent={(open: () => void) =>
                                            <>
                                                <Typography variant={'h5'} className={classes.uploadText}>
                                                    Consignment Pictures
                                                </Typography>
                                                <Box className={classes.uploadInfo}>
                                                    <img
                                                        src={SelectConfirmFiles}
                                                        alt="SelectConfirmFiles"
                                                        className={classes.uploadImg}
                                                    />
                                                    <Box className={classes.chipsWrapper}>
                                                        {values?.photoFilePaths?.length > 0 ?
                                                            values?.photoFilePaths?.map((item, index) => (
                                                                <FileChip
                                                                    key={index}
                                                                    label={item?.name}
                                                                    onDelete={() => {
                                                                        form.setFieldValue('photoFilePaths', values?.photoFilePaths?.filter((clickedItem, i) => i !== index))
                                                                    }}
                                                                />
                                                            ))
                                                            :
                                                            <Typography variant={'h5'} className={classes.uploadLimits}>
                                                                Format - JPG, PNG, TIFF or BMP. <br/>
                                                                Size - no more than 5 MB.
                                                            </Typography>
                                                        }
                                                    </Box>
                                                </Box>
                                                <CustomButton
                                                    variant={'text'}
                                                    color={'primary'}
                                                    onClick={open}
                                                    className={classes.uploadButton}
                                                >
                                                    Select files
                                                </CustomButton>
                                            </>
                                        }
                                        maxSize={UPLOAD_IMAGE_SIZE}
                                        maxFiles={MAX_UPLOAD_FILES}
                                        accept={['image/*']}
                                        onDrop={(acceptedFiles) => {
                                            form.setFieldValue('photoFilePaths', [...acceptedFiles, ...values.photoFilePaths])
                                        }}
                                    />
                                )}
                            </Field>
                            <Typography
                                className={classes.additionalInfo}
                                variant={'h4'}
                            >
                                Additional consignment information:
                            </Typography>
                            <FieldArray
                                name={'attributes'}
                            >
                                {(fieldArrayProps) => {
                                    const {push, remove, form} = fieldArrayProps;
                                    const {values} = form;
                                    const {attributes} = values;
                                    return attributes?.length > 0 && (
                                        <Box className={classes.attributesInput}>
                                            {attributes?.map((inputItem, index) => (
                                                <OptionInputs
                                                    onDeleteHandler={() => remove(index)}
                                                    key={index}
                                                    item={inputItem}
                                                    name={`attributes[${index}]`}
                                                />
                                            ))}
                                        </Box>
                                    )
                                }}
                            </FieldArray>
                            <CustomButton
                                color={'primary'}
                                variant={'text'}
                                className={classes.addFieldButton}
                                style={{}}
                                onClick={() => setOpen(true)}
                            >
                                Add field
                            </CustomButton>
                            <SubmitButtonWithErrors/>
                            <DialogSelectOptionalField
                                open={open}
                                onClose={() => setOpen(false)}
                                name={'attributes'}
                            />
                        </Form>
                    )
                }}
            </Formik>
        </FormLayout>
    );
};

export default ConsignmentsCreate;

const useStyles = makeStyles<Theme>(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginBottom: 33,
        marginTop: 16,
    },
    inputsWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 25
    },
    uploadText: {
        color: COLORS.TEXT_SILVER,
        height: '3rem'
    },
    uploadInfo: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        marginTop: 12,
    },
    uploadLimits: {
        fontSize: 12,
        color: COLORS.TEXT_SILVER,
        marginLeft: 36
    },
    uploadButton: {
        marginTop: 16
    },
    additionalInfo: {
        marginTop: 32,
        marginBottom: 16
    },
    input: {
        marginBottom: 12
    },
    addFieldButton: {
        alignSelf: 'flex-start',
        marginTop: 16
    },
    attributesInput: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        width: '100%',
        gridColumnGap: 25,
        gridRowGap: 30,
        marginBottom: 20,
    }
}));
