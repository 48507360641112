import React, {useState, useEffect} from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, Typography} from '@material-ui/core';
import {MuiAccordion, MuiAccordionDetails, MuiAccordionSummary} from './SendProductSection';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {ProductType} from '../../../types/enum';
import {COLORS} from '../../../constants/colors';
import clsx from 'clsx';
import {isOverflowing} from '../../../helpers';

type ProductInfoSectionProps = {
    data: any;
    type: ProductType;
}

const ProductInfoSection: React.FC<ProductInfoSectionProps> = (props: ProductInfoSectionProps): JSX.Element => {
    const classes = useStyles();
    const {data, type, ...rest} = props;
    const [showDescription, setShowDescription] = useState(false);
    const [itemIsOverflowing, setItemIsOverflowing] = useState(false);

    useEffect(() => {
        setItemIsOverflowing(isOverflowing('description'));
    }, [data?.description]);

    return (
        <MuiAccordion
            defaultExpanded
            square
            classes={{expanded: classes.AccordionExpanded}}
        >
            <MuiAccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel2a-content"
                id="panel2a-header"
                classes={{root: classes.AccordionSummary, expanded: classes.AccordionTopMargin}}
            >
                <Typography>INFO</Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails
                classes={{root: classes.AccordionDetailsFlex}}>
                <ul id="optionList" className={classes.optionList}>
                    {Boolean(data?.balance) &&
                    <li className={classes.descriptionOption}>
                        <span className={classes.descriptionName}>Quantity: </span>
                        <span>{data?.balance}</span>
                    </li>
                    }
                    {Boolean(data?.barcode) &&
                    <li className={classes.descriptionOption}>
                        <span className={classes.descriptionName}>Barcode: </span>
                        <span>{data?.barcode}</span>
                    </li>
                    }
                    {Boolean(data?.sku) &&
                    <li className={classes.descriptionOption}>
                        <span className={classes.descriptionName}>Article: </span>
                        <span>{data?.sku}</span>
                    </li>
                    }
                    {Boolean(data?.brand?.name) && type === ProductType.product &&
                    <li className={classes.descriptionOption}>
                        <span className={classes.descriptionName}>Brand: </span>
                        <span>{data?.brand?.name}</span>
                    </li>
                    }
                    {Boolean(data?.price) &&
                    <li className={classes.descriptionOption}>
                        <span className={classes.descriptionName}>Price: </span>
                        <span>{data?.price}</span>
                    </li>
                    }
                    {Boolean(data?.serial) &&
                    <li className={classes.descriptionOption}>
                        <span className={classes.descriptionName}>Serial number: </span>
                        <span>{data?.serial}</span>
                    </li>
                    }
                    {data?.attributes?.length > 0 && data?.attributes?.map((item, key) => (
                        <li key={key} className={classes.descriptionOption}>
                            <span className={classes.descriptionName}>{item?.template?.title}: </span>
                            <span>{item?.value}</span>
                        </li>
                    ))}
                    {data?.description &&
                    <li id="description" className={clsx(
                        classes.descriptionOption,
                        {[classes.description]: showDescription}
                    )}>
                        <span className={classes.descriptionName}>Description: </span>
                        <span onClick={() => setShowDescription(true)}>
                            {data?.description}
                            <span
                                className={classes.showTextReadMore}
                                style={{display: !showDescription && itemIsOverflowing ? 'block' : 'none'}}
                            >read more
                            </span>
                        </span>
                    </li>
                    }

                </ul>
            </MuiAccordionDetails>
        </MuiAccordion>
    );
};

export default ProductInfoSection;

const useStyles = makeStyles<Theme>(() => ({
    AccordionDetailsFlex: {
        padding: '0 0 10px 0',
        display: 'flex',
        height: '100%',
        overflowY: 'scroll',
        flexFlow: 'column wrap',
        borderBottom: 'none'
    },
    AccordionExpanded: {
        margin: '0 !important'
    },
    AccordionSummary: {
        padding: '12px 20px',
        margin: '0 !important',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    optionList: {
        position: 'relative',
        padding: '0 25px 10px 20px',
    },
    descriptionOption: {
        position: 'relative',
        marginBottom: 16,
        '-webkit-line-clamp': 2,
        wordBreak: 'break-all',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden'
    },
    description: {
        display: 'list-item',
        overflow: 'visible'
    },

    descriptionName: {
        fontSize: 14,
        textTransform: 'uppercase',
        color: COLORS.TEXT_SILVER
    },
    showTextReadMore: {
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '50%',
        textAlign: 'right',
        height: 20,
        color: '#276EF9',
        textDecoration: 'underline',
        background: 'linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 55%)',
        cursor: 'pointer'
    }
}));
