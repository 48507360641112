import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, TableCell, TableHeadProps, TableRow, TableHead} from '@material-ui/core';
import clsx from 'clsx';
import MuiTableCell from './TableCell';

export type CustomTableHeadProps = TableHeadProps & {
    headCells: Cell[];
    stickyHeader?: boolean;
    children?: React.ReactNode;
    isInvoicesTable?: boolean;
}

export type Cell = {
    align: 'inherit' | 'left' | 'right' | 'center' | 'justify' | undefined;
    label: string;
    width: string;
}

const CustomTableHead: React.FC<CustomTableHeadProps> = (props: CustomTableHeadProps): JSX.Element => {
    const {headCells, isInvoicesTable = false, stickyHeader = false, ...rest} = props;
    const classes = useStyles({isInvoicesTable});
    return (
        <TableHead classes={{root: classes.tableHead}} {...rest}>
            <TableRow  classes={{root: clsx(classes.tableRow, {[classes.stickyHeader]: stickyHeader})}}>
                {props.children}
                {headCells && headCells.map((item, _) => (
                    <MuiTableCell
                        key={_}
                        align={item.align}
                        padding={'none'}
                        classes={{
                            root: clsx(classes.tableCell, classes.headerCell),
                            stickyHeader: classes.stickyCell
                        }}
                        style={{
                            width: item.width
                        }}
                    >{item.label}</MuiTableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default CustomTableHead;

interface StyleProps {
    isInvoicesTable?: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>(() => ({
    tableHead: {
        backgroundColor: props => props.isInvoicesTable ? '#FCFCFE' : '#fff',
        position: 'sticky',
        top: 0,
        zIndex: 2,
        '& > tr > th:first-of-type': {
            paddingLeft: props => props.isInvoicesTable ? '24px !important' : '8px'
        },
        '& > tr > th:last-of-type': {
            paddingRight: props => props.isInvoicesTable ? '24px !important' : '8px'
        }
    },
    tableRow: {

    },
    stickyHeader: {

    },
    tableCell: {
        padding: '0 8px !important',
        height: 57
    },
    headerCell: {

    },
    stickyCell: {
        backgroundColor: props => props.isInvoicesTable ? '#FCFCFE' : '#fff'
    }
}));
