import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles} from '@material-ui/core';

type TwoSectionLayoutProps = {
    children: React.ReactNode,
    first: number,
    second: number
}

const TwoSectionLayout: React.FC<TwoSectionLayoutProps> = (props: TwoSectionLayoutProps): JSX.Element => {

    const {children, first, second, ...rest} = props;
    const classes = useStyles({first, second});
    return (
        <Box className={classes.wrapper} {...rest}>
            {children}
        </Box>
    );
};

export default TwoSectionLayout;

const useStyles = makeStyles<Theme, {second: number, first: number}>(() => ({
    wrapper: {
        width: '100%',
        height: '100%',
        columnGap: 30,
        display: 'grid',
        gridTemplateColumns: props => `${props.first}fr ${props.second}fr`

    }
}));
