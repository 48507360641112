import React, {useState, useEffect} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import DialogLayout, {DialogLayoutProps} from '../../../layouts/DialogLayout';
import {COLORS} from '../../../constants/colors';
import CustomButton from '../Buttons/Button';
import FormInput from '../FormInput';
import {Formik, Form, FormikProps} from 'formik';
import {CHECK_AS_CONFIRM_SCHEMA} from '../../../constants/validation_schema';
import {MESSAGES} from "../../../constants/messages";
import {useUIContext} from "../../../context/UIContext";

type DialogConfirmProps = DialogLayoutProps & {
    title?: string,
    action: () => void
    name?: any;
}
const DialogConfirm: React.FC<DialogConfirmProps> = (props: DialogConfirmProps): JSX.Element => {
    const classes = useStyles();
    const {title, action, name, ...rest} = props;
    const {showSnackBar} = useUIContext();

    const [counter, setCounter] = useState(10);

    useEffect(() => {
        if (counter > 0) {
            const timer = setTimeout(() => {
                setCounter(prevState => prevState - 1)
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [counter]);

    return (
        <DialogLayout
            width={552}
            {...rest}
            onClick={(event) => event.stopPropagation()}
        >
            <Box className={classes.wrapper}>
                <Typography variant={'h4'} style={{lineHeight: '48px'}}>{title}</Typography>
                <Typography
                    variant={'subtitle1'}
                    className={classes.subtitle}
                >Confirmed this action you agree that you get money for Invoice <span>#{name}</span>. This action cannot
                    be undone
                    and certificates will be send on customer account with address:
                </Typography>
                <Formik
                    initialValues={{
                        customerAddress: ''
                    }}
                    validationSchema={CHECK_AS_CONFIRM_SCHEMA}
                    onSubmit={(values, actions) => {
                        console.log({values, actions});
                        alert(JSON.stringify(values, null, 2));
                        actions.setSubmitting(false);
                    }}
                >
                    {(propsFormik: FormikProps<any>) => (
                        <Form>
                            <FormInput
                                name={'customerAddress'}
                                label={'Customer address'}
                                labelWidth={140}
                                formControlProps={{
                                    className: classes.customerAddress
                                }}
                            />
                            <Box className={classes.wrapperButton}>
                                <CustomButton
                                    disabled={Boolean(counter)}
                                    className={classes.cancelInvoice}
                                    onClick={() => {
                                        showSnackBar(MESSAGES.INVOICE_BURN_CERTIFICATES);
                                        props.onClose(event, 'escapeKeyDown')
                                    }}>
                                    Burn the certificates&nbsp;{counter ? <span>({counter})</span> : null}
                                </CustomButton>
                                <CustomButton
                                    onClick={(event) => props.onClose(event, 'escapeKeyDown')}>Cancel</CustomButton>
                                <CustomButton
                                    disabled={(!propsFormik.isValid || (Object.keys(propsFormik.touched).length === 0 && propsFormik.touched.constructor === Object))}
                                    onClick={() => {
                                        showSnackBar(MESSAGES.INVOICE_CONFIRM);
                                        action()
                                        props.onClose(event, 'escapeKeyDown')
                                    }}>Confirm</CustomButton>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </DialogLayout>
    );
};

export default DialogConfirm;

const useStyles = makeStyles<Theme>(() => ({
    wrapper: {
        padding: '40px 40px 32px 56px'
    },
    subtitle: {
        color: 'rgba(0, 0, 0, 0.87)',
        margin: '8px 0 24px 0',
        '& > span': {
            color: '#000000',
            fontWeight: 500,
            whiteSpace: 'nowrap'
        }
    },
    wrapperButton: {
        marginTop: 6,
        display: 'flex',
        '& > button': {
            padding: '8px 16px',
            maxHeight: 40
        },
        '& > button + button': {
            marginLeft: 18,
        },
        '& > button:last-of-type': {
            marginRight: -16,
        }
    },
    cancelInvoice: {
        whiteSpace: 'nowrap',
        marginRight: 'auto',
        marginLeft: '-16px',
        color: 'rgba(220, 48, 48, 1)',
        '&:hover': {
            backgroundColor: 'rgba(220, 48, 48, 0.04)'
        }
    },
    customerAddress: {
        width: '100%',
        minHeight: 73
    }
}));
