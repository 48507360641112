import React, {CSSProperties, FC, useState} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import clsx from 'clsx';
import {COLORS} from '../../../constants/colors';

type SelectPeriodProps = {
    plans: any
    setPlans: (newState: any) => void;
    style?: CSSProperties
}

const SelectPeriod:FC<SelectPeriodProps> = ({plans, setPlans, ...props}): JSX.Element => {
    const classes = useStyles();
    const [monthly, setMonthly] = useState(true);
    return (<div className={classes.Period} {...props}>
        <div className={clsx(classes.ActiveLeft, {[classes.ActiveRight]: monthly})}></div>
        <div
            className={classes.TextWrapper}
            onClick={() => {
                setMonthly(true);
                setPlans([
                    {...plans[0], price: 100, period: 'Month'},
                    {...plans[1], price: 300, period: 'Month'},
                    {...plans[2], price: 500, period: 'Month'},
                    {...plans[3]}]);
            }}
        >
            <Typography variant={'h5'} className={monthly ? classes.ActiveText : classes.Text}>Monthly</Typography>
        </div>
        <div
            className={classes.TextWrapper}
            onClick={() => {
                setMonthly(false);
                setPlans([
                    {...plans[0], price: 1200, period: 'Year'},
                    {...plans[1], price: 3600, period: 'Year'},
                    {...plans[2], price: 6000, period: 'Year'},
                    {...plans[3]}]);

            }}
        >
            <Typography variant={'h5'} className={!monthly ? classes.ActiveText : classes.Text}>Yearly</Typography>
        </div>
    </div>);
}

export default SelectPeriod;

const useStyles = makeStyles<Theme>((theme) => ({
    Period: {
        position: 'relative',
        width: 270,
        height: 50,
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        boxShadow: '0px 9px 34px rgba(8, 42, 92, 0.13)',
        borderRadius: 57,
        background: COLORS.WHITE
    },
    Text: {
        zIndex: 9,
        fontWeight: 600
    },
    ActiveText: {
        fontWeight: 600,
        zIndex: 9,
        color: COLORS.WHITE
    },
    TextWrapper: {
        width: '50%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    ActiveLeft: {
        position: 'absolute',
        left: 0,
        width: '50%',
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        borderRadius: 57,
        zIndex: 1,
        transform: 'translateX(135px)',
        transition: '.15s ease-in'
    },
    ActiveRight: {
        transition: '.15s ease-in',
        transform: 'translateX(0);',
        zIndex: 1
    }
}));
