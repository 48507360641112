
const LINKS = {
    SERVICE_RULES: 'https://brandprotect.world/service-rules-page/service-rules',
    LANDING: 'https://brandprotect.world/',
    LANDING_BLOG: 'https://brandprotect.world/blog',
    PLAY_MARKET_APP: 'https://play.google.com/store/apps/details?id=com.brandprotect.client',
    APP_STORE_APP: 'https://apps.apple.com/ua/app/brand-protect/id1566797618?l=uk',
    PRODUCT_URL_ADDRESS: 'https://goods.brandprotect.world/id',
    CONSULTING: 'https://calendly.com/support-brandprotect/30min',
    EXPLORER: 'https://explorer.brandprotocol.net'
}

const QUERY_PARAMS = ['utmSource', 'utmMedium', 'utmCampaign', 'utmContent', 'utmTerm'];

const CONTACT_MAIL = 'support@brandprotect.com'

const LOCAL_STORAGE_KEYS = {
    TOKEN: 'TOKEN',
    STORE_TOKEN: 'STORE_TOKEN',
    FIRST_ON_PLATFORM: 'FIRST_ON_PLATFORM',
    ROWS_PER_PAGE: 'ROWS_PER_PAGE'
}

const PLATFORM_TYPE = {
    DEMO: 'DEMO',
    PROD: 'PROD'
}

const ELEMENTS_HEIGHT = {
    HEADER: 70
}

const FILTERS = {
    STORES: {
        ALL: 'ALL',
        MY_STORES: 'MY_STORES',
        STORE_CONTACTS: 'STORE_CONTACTS'
    }
}

const TABLE_DEFAULT_TAKE = 25;

const UPLOAD_IMAGE_SIZE = 5e+6;

const CONTACTS_DATA = {
    TITLE: 'Brand Protect',
    STREET_ADDRESS: 'AN BUREN BLVD #F3-1028',
    CITY_STATE: 'RIVERSIDE, CALIFORNIA 92508',
    ZIP_CODE: '19510',
    PHONE: '+ 1 917 6755271',
    MAIL: 'info@brandprotect.world'
}
const MONTH = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const MAX_UPLOAD_FILES = 999;

export {
    LINKS,
    QUERY_PARAMS,
    LOCAL_STORAGE_KEYS,
    ELEMENTS_HEIGHT,
    CONTACT_MAIL,
    PLATFORM_TYPE,
    FILTERS,
    TABLE_DEFAULT_TAKE,
    CONTACTS_DATA,
    UPLOAD_IMAGE_SIZE,
    MONTH,
    MAX_UPLOAD_FILES
};
