import React from 'react';
import {makeStyles, TableRow, TableRowProps, TooltipProps, TypographyProps, withStyles} from '@material-ui/core';
import {INVOICE_PENDING} from "../helpers/enum";

type CustomTableRowProps = TableRowProps & {
    typeInvoice?: INVOICE_PENDING;
}

const CustomTableRow: React.FC<CustomTableRowProps> = (props: CustomTableRowProps): JSX.Element => {
    const {typeInvoice} = props;
    return (
        <MuiTableRow style={{background: typeInvoice === INVOICE_PENDING.PAID_AND_NOT_SENT ? '#FAE6E6' : '#fff' }} {...props}/>
    );
};

const MuiTableRow = withStyles(() => ({
    root: {
        '& > td:first-of-type': {
            paddingLeft: '24px !important'
        },
        '& > td:last-of-type': {
            paddingRight: '24px !important'
        }
    },
    hover: {
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0px 2px 15px rgba(60, 77, 121, 0.13)',
            // background: '#fff !important'
        }
    },
    selected: {
        boxShadow: '0px 2px 15px rgba(60, 77, 121, 0.13)',
        // background: '#fff !important'
    }
}))(TableRow)

export default CustomTableRow;

