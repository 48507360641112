import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, Button, makeStyles, TextField, Typography} from '@material-ui/core';
import {Form, Formik} from 'formik';
import {DEPOSIT_SCHEMA} from '../../constants/validation_schema';
import {
    useStripe,
    useElements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement
} from '@stripe/react-stripe-js';
import {useTopUpBalanceMutation} from '../../generated/graphql';
import {useUIContext} from '../../context/UIContext';
import {useGlobalContext} from '../../context/globalContext';
import FormInput from '../ui/FormInput';
import CustomHelperText from '../ui/FormHelperText';
import StripeInput from '../ui/Inputs/StripeInput';
import MuiCircularProgress from '../ui/CircularProgress';
import {toMoneyFormat} from '../../helpers';
import DialogDemoDeposit from '../ui/Dialogs/DialogDemoDeposit';
import {COLORS} from '../../constants/colors';
import {useFinanceContext} from '../../context/financeContext';

const CreditCardForm: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const stripe = useStripe();
    const elements = useElements();
    const [open, setOpen] = useState<boolean>(false);
    const [topUpBalance] = useTopUpBalanceMutation();
    const {showEndAction} = useUIContext();
    const {userData, setUserData} = useGlobalContext();
    const [disabled, setDisabled] = useState(true);
    const [errorMessages, setErrorMessages] = useState({
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null
    });
    const {financeQuery: {refetch}} = useFinanceContext();
    const handleElementChange = (event) => {
        if (!event.empty && event.complete) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
        if (event.error) {
            switch (event.elementType) {
                case 'cardNumber': {
                    setErrorMessages({...errorMessages, cardNumber: event.error.message});
                    break;
                }
                case 'cardExpiry': {
                    setErrorMessages({...errorMessages, cardExpiry: event.error.message});
                    break;
                }
                case 'cardCvc': {
                    setErrorMessages({...errorMessages, cardCvc: event.error.message});
                    break;
                }
            }
        } else {
            switch (event.elementType) {
                case 'cardNumber': {
                    setErrorMessages({...errorMessages, cardNumber: null});
                    break;
                }
                case 'cardExpiry': {
                    setErrorMessages({...errorMessages, cardExpiry: null});
                    break;
                }
                case 'cardCvc': {
                    setErrorMessages({...errorMessages, cardCvc: null});
                    break;
                }
            }
        }
    };
    return (
        <Formik
            initialValues={{
                amount: 1,
                cardholderName: '',
                paymentForUsa: false
            }}
            validationSchema={DEPOSIT_SCHEMA}
            onSubmit={async (values, {resetForm}) => {
                if (process.env.REACT_APP_MODE === 'DEMO') {
                    setOpen(true);
                } else {
                    try {
                        const {source} = await stripe.createSource(elements.getElement(CardNumberElement), {
                            type: 'card',
                            currency: 'usd',
                            owner: {
                                name: values.cardholderName,
                                email: userData?.email
                            }
                        });

                        const {data} = await topUpBalance({
                            variables: {
                                amount: Number(values.amount * 100),
                                paymentToken: source?.id,
                                paymentForUsa: false
                            }
                        })

                        if (data?.topUpBalance?.invoiceId) {
                            setUserData(prevState => ({
                                ...prevState,
                                billingBalance: prevState?.billingBalance + Number(values?.amount * 100)
                            }))
                            await refetch()
                        }
                    } catch (e) {

                        showEndAction(e?.message)
                    } finally {
                        resetForm();
                        elements.getElement(CardNumberElement).clear();
                        elements.getElement(CardExpiryElement).clear();
                        elements.getElement(CardCvcElement).clear();
                    }
                }
            }}
        >
            {({handleSubmit, isValid, values, isSubmitting}) => {
                return (
                    <Form onSubmit={handleSubmit} className={classes.container}>
                        <Box className={classes.inputWrapper}>
                            <FormInput
                                fullWidth
                                name={'amount'}
                                labelWidth={100}
                                label="Amount, USD"
                                placeholder={'Amount, USD'}
                                inputProps={{
                                    type: 'number'
                                }}
                            />
                            <FormInput
                                fullWidth
                                name={'cardholderName'}
                                labelWidth={125}
                                label="Cardholder name"
                                placeholder={'Cardholder name'}
                            />
                            <TextField
                                fullWidth
                                label="Credit Card Number"
                                variant={'outlined'}
                                error={errorMessages.cardNumber}
                                className={classes.stripeInput}
                                helperText={errorMessages.cardNumber ? <Typography className={classes.errorMessage}>
                                    <i className={'material-icons'}>info_outline</i>
                                    {errorMessages.cardNumber}
                                </Typography> || 'Invalid' : ''}
                                onChange={(e) => handleElementChange(e)}
                                InputLabelProps={{shrink: true}}
                                InputProps={{
                                    inputProps: {
                                        component: CardNumberElement
                                    },
                                    inputComponent: StripeInput
                                }}
                            />
                            <Box className={classes.rowWrapper}>
                                <TextField
                                    fullWidth
                                    variant={'outlined'}
                                    label={'Expiration date'}
                                    error={errorMessages.cardExpiry}
                                    className={classes.stripeInput}
                                    helperText={errorMessages.cardExpiry ? <Typography className={classes.errorMessage}>
                                        <i className={'material-icons'}>info_outline</i>
                                        {errorMessages.cardExpiry}
                                    </Typography> || 'Invalid' : ''}
                                    onChange={(e) => handleElementChange(e)}
                                    InputLabelProps={{shrink: true}}
                                    InputProps={{
                                        inputProps: {
                                            component: CardExpiryElement
                                        },
                                        inputComponent: StripeInput
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    variant={'outlined'}
                                    label={'Code CVV/CVC'}
                                    placeholder={'lol'}
                                    error={errorMessages.cardCvc}
                                    className={classes.stripeInput}
                                    helperText={errorMessages.cardCvc ? <Typography className={classes.errorMessage}>
                                        <i className={'material-icons'}>info_outline</i>
                                        {errorMessages.cardCvc}
                                    </Typography> || 'Invalid' : ''}
                                    onChange={(e) => handleElementChange(e)}
                                    InputLabelProps={{shrink: true}}
                                    InputProps={{
                                        inputProps: {
                                            component: CardCvcElement,
                                            type: 'password'
                                        },
                                        inputComponent: StripeInput,
                                        type: 'password'
                                    }}
                                    type={'password'}
                                />
                            </Box>
                        </Box>
                        <Button
                            type={'submit'}
                            className={classes.button}
                            disabled={isSubmitting || !isValid}
                        >
                            {isSubmitting ?
                                <MuiCircularProgress/> : `Pay ${values?.amount && toMoneyFormat(values.amount)}`}
                        </Button>
                        {open && <DialogDemoDeposit
                            open={open}
                            onClose={() => setOpen(false)}
                        />}
                    </Form>
                )
            }}
        </Formik>
    );
};

export default CreditCardForm;

const useStyles = makeStyles<Theme>(() => ({
    container: {
        padding: '50px 16px 16px 50px',
        maxWidth: 500,
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    inputWrapper: {
        display: 'flex',
        flexDirection: 'column',
        '&>*': {
            marginBottom: 12
        }
    },
    rowWrapper: {
        display: 'grid',
        gridGap: 25,
        gridTemplateColumns: '1fr .8fr',
    },
    stripeInput: {
        marginBottom: 32
    },
    button: {
        boxShadow: '0px 4px 16px rgba(1, 49, 172, 0.25)',
        borderRadius: '12px',
        backgroundColor: '#276EF9',
        color: 'white',
        padding: '16px 32px',
        textTransform: 'none',
        fontHeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        justifySelf: 'start',
        marginTop: 25,
        gridRow: '6/7',
        alignSelf: 'flex-start',
        '&:hover': {
            background: '#4584FF',
            boxShadow: '0px 4px 16px rgba(1, 49, 172, 0.25)'
        },
        '&:disabled': {
            background: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.38)'
        }
    },
    errorMessage: {
        fontSize: 12,
        color: COLORS.ERROR_PRIMARY,
        position: 'absolute',
        textTransform: 'none',
        lineHeight: 1.4,
        marginLeft: 4,
        letterSpacing: 0,
        display: 'flex',
        alignItems: 'center',
        '& i': {
            color: COLORS.ERROR_PRIMARY,
            fontSize: 14,
        }
    }
}));
