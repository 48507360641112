import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import SelectedDetails from './SelectedDetails';
import {Skeleton} from '@material-ui/lab';
import {ProductType} from '../../../types/enum';
import {useGetProductQuery} from '../../../generated/graphql';
import {useGlobalContext} from '../../../context/globalContext';
import FailedToLoad from '../../../components/FailedToLoad';
import QrCodeSection from './QrCodeSection';
import CustomPaper from '../../../components/ui/Paper';
import SendProductSection from './SendProductSection';
import {checkEmptyArrays} from '../../../helpers';
import ProductInfoSection from './ProductInfoSection';

type ProductDetails = {
    type: ProductType;
    selected: any;
    setSelected: any;
    selectedItem: number | null;
    dataRefetch: () => void
    deleteProducts: any
}

const ProductDetails: React.FC<ProductDetails> = (props: ProductDetails): JSX.Element => {
    const classes = useStyles();
    const {type = ProductType.product, selected, setSelected, selectedItem, dataRefetch, deleteProducts} = props;
    const {userData} = useGlobalContext();
    const {data, error, refetch} = useGetProductQuery({
        variables: {
            id: selectedItem,
            blockchainAddress: userData?.blockchainAddress
        },
        skip: !selectedItem
    });
    if (error) {
        return <FailedToLoad refetch={refetch}/>
    }

    return (
        <CustomPaper className={classes.container}>
            {selected?.length >= 2 ?
                <SelectedDetails
                    selected={selected}
                    setSelected={setSelected}
                    type={type}
                    deleteProducts={deleteProducts}
                />
                : <>
                    <Box className={classes.header}>
                        <Typography variant={'h4'} className={classes.title}>
                            {data?.product?.name ? data?.product?.name : <Skeleton/>}
                        </Typography>
                        <QrCodeSection
                            type={type}
                            data={data?.product}
                        />
                    </Box>
                    <Box className={'ProductDetails'}>
                        <SendProductSection
                            type={type}
                            options={checkEmptyArrays([data?.whoami?.stores?.data, data?.whoami?.contacts?.data])}
                            selectedItem={selectedItem}
                            dataRefetch={dataRefetch}
                            brand={data?.product?.brand}
                        />
                        <ProductInfoSection
                            data={data?.product}
                            type={type}
                        />
                        {/*{product?.attributes?.length > 0 && <ProductDetailsAccordion product={product}/>}*/}
                    </Box>
                </>
            }
        </CustomPaper>
    );
};
export default ProductDetails;

const useStyles = makeStyles<Theme>(() => ({
    container: {
        minWidth: 350,
        overflowY: 'scroll',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    },
    header: {
        padding: 40
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        wordBreak: 'break-word'
    }
}));


