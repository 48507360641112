
const changeFormatDate = (date) => {
    const arr = date.split('-', 3);
    const day = arr[2].split('T');
    return `${arr[1]}/${day[0]}/${arr[0]}`;
};

const dateWithHours = (time) => {
    const [date, hours] = time.split('.')[0].split('T');
    return `${date} ${hours}`
}

export {
    changeFormatDate,
    dateWithHours
}
