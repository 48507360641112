import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useUIContext} from '../context/UIContext';

function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

function useToggleBoolean(value: boolean) {
    const [state, setState] = useState<boolean>(value);
    const toggleState = () => setState(prevState => !prevState)
    return [state, toggleState];
}

function useMergeState(initialState: any) {
    const [state, setState] = useState<any>(initialState);

    const mergedState = (newState: any) => {
        setState((prevState: any) => ({
            ...prevState,
            ...newState
        }))
    }
    return [state, mergedState]
}

function useLocalStorage(key: string, initialValue: any) {
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? item : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = (value: any) => {
        try {
            const valueToStore =
                value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            if (typeof value === 'string') {
                window.localStorage.setItem(key, valueToStore);
            } else {
                window.localStorage.setItem(key, JSON.stringify(valueToStore));
            }
        } catch (error) {
            console.log(error);
        }
    };
    const removeValue = (): void => {
        window.localStorage.removeItem(key);
    }
    return [storedValue, setValue, removeValue];
}

function useCopy() {
    const {showSnackBar} = useUIContext();
    return (value: string, message = 'Copy to Clipboard') => {
        navigator.clipboard.writeText(value).then(() => {
            showSnackBar(message);
        }).catch(error => {
            showSnackBar(error?.message);
        });
    };
}

function useSelectRow(initialState = []): any {
    const [selected, setSelected] = useState<any[]>(initialState);
    const handleClick = useCallback((event, item): void => {
        const ids = selected.map(item => item.id);
        const selectedIndex = ids.indexOf(item.id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, item);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    }, [selected]);
    const selectAllClick = (event: React.ChangeEvent<HTMLInputElement>, data: any[]) => {
        const dataIds = data?.map(item => item.id);
        const isIncludes = selected.some(r => dataIds.includes(r?.id))
        if (!isIncludes && selected.length === 0) {
            setSelected(data);
            return;
        }
        if (!isIncludes && selected.length > 0) {
            setSelected(prevState => [...data, ...prevState])
            return;
        }
        if (isIncludes) {
            setSelected(prevState => prevState.filter(item => !dataIds.includes(item.id)));
            return;
        }
        // if (event.target.checked && data?.length > 0) {
        //     setSelected(data);
        //     return;
        // }
        // setSelected([]);
    };
    return [selected, handleClick, selectAllClick, setSelected]
}

const useMountedRef = () => {
    const mountedRef = useRef<boolean>(true);
    useEffect(() => {
        return () => {
            mountedRef.current = false;
        }
    }, []);
    return mountedRef;
}

const useAnimatedText = (text, delayMs) => {
    const [currentPos, setCurrentPos] = useState(0);
    useEffect(() => {
        const imperativeHandler = setInterval(() => {
            setCurrentPos(pos => {
                const isLast = pos === text.length - 1;
                return isLast ? 0 : pos + 1;
            })
        }, delayMs)
        return () => {
            clearInterval(imperativeHandler)
        };
    }, [text, delayMs]);
    return text.substring(0, currentPos)
}

function useColoringValue(primaryColor: string, secondaryColor: string, value: number) {
    if (!value) {
        return
    }
    const valueToString = value.toString()
    const arrayOfString = valueToString.split('.');

    return <span style={{color: primaryColor}}>{arrayOfString[0]}<span
        style={{color: secondaryColor}}>{value % 1 == 0 ? '.00' : '.'}{arrayOfString[1]}</span></span>
}

export {
    usePrevious,
    useCopy,
    useLocalStorage,
    useToggleBoolean,
    useMergeState,
    useSelectRow,
    useAnimatedText,
    useColoringValue
}
