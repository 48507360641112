import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, Typography} from '@material-ui/core';
import AuthLayout from '../../layouts/AuthLayout';
import LogInForm from '../../components/forms/LogInForm';
import AlertsError from './components/AlertsError';
import LoginLeftImg from '../../assets/background/loginLeftImg.svg';

const LoginPage: React.FC = (props): JSX.Element => {
    const classes = useStyles();
    const [serverError, setServerError] = useState(null);
    return (
        <AuthLayout
            icon={LoginLeftImg}
        >
            {serverError && <AlertsError title={'Server Error'} subTitle={'Log In'} severity={'error'} />}
            <Typography variant={'h2'}>Sign In to Brand Protect</Typography>
            <LogInForm
                serverError={serverError}
                setServerError={setServerError}
            />
        </AuthLayout>
    );
};

export default LoginPage;

const useStyles = makeStyles<Theme>(() => ({}));
