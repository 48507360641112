const ERRORS = {
    INVALID_TYPE: 'Write valid data',
    REQUIRED: 'This field is required',
    SHORT_REQUIRED: 'Required',
    EMAIL: 'Invalid email address',
    AGREEMENT: 'Must Accept Terms and Conditions',
    INTEGER: 'Allowed positive integer numbers',
    FLOAT: 'Float value. Use no more than two characters after the dot',
    POSITIVE: 'Must be greater than zero',
    TYPE_NUMBER: `Must be a number`,
    ETHEREUM_ADDRESS: 'Enter valid ethereum address',
    ZIP_CODE: 'Enter valid ZIP code',
    PRICE: 'Price must be a valid dollar amount',
    PHONE_NUMBER: 'Phone number is not valid',
    DIAPASON_FROM: '',
    DIAPASON_TO: '',
    MAX: (number: number) => `Must be ${number} characters or less`,
    MIN: (number: number) => `Must be more that ${number} characters`,
    MATCH: (str: string) => `${str} must match`,
    MORE_THAN: (number: number) => `Minimal amount is ${number}`,
    LESS_THAN: (number: number) => `Maximum amount is ${number}`,
    MIN_AMOUNT: (number: number) => `Minimal amount is ${number}$`,
    MAX_AMOUNT: (number: number) => `Maximum amount is ${number}$`,
}

export {ERRORS}
