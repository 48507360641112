import React, {CSSProperties} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {DropzoneProps, useDropzone} from 'react-dropzone';
import {FieldProps} from 'formik';
import {MuiFormHelperText} from './FormHelperText';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {COLORS} from '../../constants/colors';

interface CustomDropzoneProps extends DropzoneProps {
    renderContent: any;
    style: any;
    fieldProps: FieldProps;
    label?: string;
    wrapperStyle?: any
}

const CustomDropzone: React.FC<CustomDropzoneProps> = (props: CustomDropzoneProps): JSX.Element => {
    const classes = useStyles();
    const {fieldProps: {meta: {error, touched}}, renderContent, label, style, wrapperStyle, ...rest} = props;

    const {getRootProps, getInputProps, acceptedFiles, isDragActive, isDragAccept, isDragReject, open} = useDropzone({
        noClick: true,
        accept: ['.png', '.jpg', '.jpeg', '.gif', '.webp', '.mp4', '.mp3'],
        maxFiles: 1,
        maxSize: 3e+7,
        onDrop: acceptedFiles => {
            acceptedFiles.forEach((file) => {

                // const reader = new FileReader();
                // reader.onload = async () => {
                //     const data = new FormData();
                //     data.append('file', file);
                //     fetch(`${backandApiUrl}/upload-file`, {
                //         method: 'POST',
                //         body: data
                //     }).then((response) => {
                //          ('response', response);
                //         return response.json();
                //     }).then((data) => {
                //     });
                // };
                // reader.readAsArrayBuffer(file);
            });
        },
        ...rest
    });
    return (
        <Box className={wrapperStyle}>
            <Box {...getRootProps({style})}>
                <input {...getInputProps()} />
                {renderContent(open)}
            </Box>
            {(error && touched) &&
            <MuiFormHelperText className={classes.errorText}>
                <ErrorOutlineIcon className={classes.ErrorOutlineIcon}/>
                {error}
            </MuiFormHelperText>
            }
        </Box>
    );
};

export default CustomDropzone;

const useStyles = makeStyles<Theme>(theme => ({
    FormLabel: {
        marginBottom: 9
    },
    errorText: {
        color: theme.palette.error.main,
        marginTop: 5
    },
    ErrorOutlineIcon: {
        fontSize: 14,
        marginRight: 2
    }
}));
