import React, {FC, ReactNode} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {LINKS} from '../constants';
import {IconButton} from '@material-ui/core';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AuthLayoutPattern from '../assets/background/authLayoutPattern.svg';
import AuthLayoutPattern2 from '../assets/background/authLayoutPattern2.svg';
type SelectLayoutProps = {
    children?: ReactNode;
}

const SelectLayout:FC<SelectLayoutProps> = ({children}: SelectLayoutProps) => {
    const classes = useStyles();
    const handlerClose = () => window.location.href = LINKS.LANDING;

    return (<div className={classes.backgroundWrapper}>
        <img src={AuthLayoutPattern} alt="back" className={classes.topPattern}/>
        <img src={AuthLayoutPattern2} alt="back2" className={classes.bottomPattern} />
        <IconButton
            size={'medium'}
            className={classes.closeButton}
            onClick={handlerClose}
        >
            <CloseRoundedIcon />
        </IconButton>
        {children}
    </div>);
}

export default SelectLayout;

const useStyles = makeStyles({
    backgroundWrapper: {
        position: 'relative',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden'
    },
    topPattern: {
        position: 'absolute',
        top: 0,
        left: '18%',
        zIndex: 1
    },
    closeButton: {
        position: 'absolute',
        right: '3%',
        top: '3%'
    },
    bottomPattern: {
        position: 'absolute',
        right: 10,
        bottom: 20,
        zIndex: 1
    }
});
