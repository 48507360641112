import React, {useCallback} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, IconButton, InputAdornment, makeStyles, MenuItem, Select, TextField, Typography} from '@material-ui/core';
import {ProductAttributeTemplateType} from '../../../generated/graphql';
import {Field, useFormikContext} from 'formik';
import FormInput from '../../../components/ui/FormInput';
import CloseIcon from '@material-ui/icons/Close';
import CustomHelperText from '../../../components/ui/FormHelperText';

type OptionInputsProps = {
    item: InputItemType;
    name: string;
    onDeleteHandler: any
}

type InputItemType = {
    id: number,
    placeholder?: string
    placeholders: any[] | null
    title?: string
    type: ProductAttributeTemplateType
    value: string
    __typename: string
}

const OptionInputs: React.FC<OptionInputsProps> = (props: OptionInputsProps): JSX.Element => {
    const classes = useStyles();
    const {item, name, onDeleteHandler} = props;
    switch (item.type) {
        case ProductAttributeTemplateType.MultiString: {
            return <Field name={`${name}.value`}>
                {({meta, field}) => (
                    <Box className={classes.largeInput}>
                        <TextField
                            fullWidth
                            variant={'outlined'}
                            label={item?.title}
                            placeholder={item?.title}
                            error={meta?.error && meta?.touched}
                            helperText={(meta?.error && meta?.touched) && <CustomHelperText meta={meta}/>}

                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end">
                                        <IconButton
                                            onClick={onDeleteHandler}
                                        >
                                            <CloseIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            {...field}
                        />
                    </Box>
                )}
            </Field>
        }
        case ProductAttributeTemplateType.Dropdown: {
            return <Box className={classes.dropdownInput}>
                <Field name={`${name}.value`}>
                    {({meta, field}) => (
                        <TextField
                            variant={'outlined'}
                            label={item?.title}
                            placeholder={item?.title}
                            error={meta?.error && meta?.touched}
                            classes={{
                                root: classes.selectTextField
                            }}
                            helperText={(meta?.error && meta?.touched) && <CustomHelperText meta={meta}/>}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment
                                        position="end">
                                        <IconButton
                                            onClick={onDeleteHandler}
                                        >
                                            <CloseIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            {...field}
                        />
                    )}
                </Field>
                {item?.placeholders?.length > 0 &&
                <Field name={`${name}.dimension`}>
                    {({field}) => (
                        <Select
                            className={classes.select}
                            variant={'outlined'}
                            {...field}
                        >
                            {item?.placeholders?.map((item, _) => (
                                <MenuItem key={_} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </Field>
                }
            </Box>
        }
        default: {
            return <Field name={`${name}.value`}>
                {({meta, field}) => (
                    <TextField
                        variant={'outlined'}
                        label={item?.title}
                        placeholder={item?.title}
                        error={meta?.error && meta?.touched}
                        helperText={(meta?.error && meta?.touched) && <CustomHelperText meta={meta}/>}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment
                                    position="end">
                                    <IconButton
                                        onClick={onDeleteHandler}
                                    >
                                        <CloseIcon/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        {...field}
                    />
                )}
            </Field>
        }
    }
};

export default OptionInputs;

const useStyles = makeStyles<Theme>(() => ({
    largeInput: {
        gridColumn: '1/4'
    },
    dropdownInput: {
        display: 'grid',
        gridTemplateColumns: '1fr 0.7fr',
        gridColumnGap: 8
    },
    select: {
        height: 56
    },
    selectTextField: {
        '&>.MuiFormHelperText-contained': {
            margin: 0
        }
    }
}));
