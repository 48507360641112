import React from 'react';
import {Doughnut} from 'react-chartjs-2';
import {makeStyles, Typography} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';


const StatisticsChart: React.FC = (): JSX.Element => {
    const classes = useStyles();

    const getData = () => {
        return {
            labels: [],
            datasets: [{
                data: [500, 120, 48],
                backgroundColor: ['#276EF9', '#7DB7FF', '#F3A9A9'],
                borderWidth: 0,
                cutout: '70%'
            }]
        };
    };


    return (
        <div
            className={classes.statisticsChart}
        >
            <div className={classes.chart}>
                <Doughnut data={getData} options={{
                    animation: {
                        duration: 0
                    },
                    plugins: {
                        title: {
                            display: false
                        },
                        legend: {
                            display: false
                        },
                        tooltip: {
                            enabled: false
                        }
                    }
                }}/>
            </div>
            <div className={classes.chartItems}>
                <div className={classes.chartItem}>
                    <Typography variant={'subtitle2'} className={classes.title}><span className={classes.point}></span>Received</Typography>
                    <Typography variant={'body2'} className={classes.amount}>500 invoices</Typography>
                </div>
                <div className={classes.chartItem}>
                    <Typography variant={'subtitle2'} className={classes.title}><span style={{background: '#7DB7FF'}} className={classes.point}></span>Pending</Typography>
                    <Typography variant={'body2'} className={classes.amount}>120 invoices</Typography>
                </div>
                <div className={classes.chartItem}>
                    <Typography variant={'subtitle2'} className={classes.title}><span style={{background: '#F3A9A9'}} className={classes.point}></span>Canceled</Typography>
                    <Typography variant={'body2'} className={classes.amount}>48 invoices</Typography>
                </div>
            </div>
        </div>
    );
};

export default StatisticsChart;

const useStyles = makeStyles<Theme>((theme) => ({
    statisticsChart: {
        overflow: 'hidden',
        padding: 24,
        background: '#FFFFFF',
        boxShadow: '0px 5px 28px -12px rgba(73, 105, 219, 0.28)',
        borderRadius: 16,
        display: 'flex'
    },
    chart: {
        margin: 'auto 0',
        maxWidth: 134,
        width: '35%'
    },
    chartItems: {
        marginLeft: 30,
        width: '100%',
        maxWidth: 300,
        [theme.breakpoints.down(1700)]: {
            marginLeft: 5
        }
    },
    chartItem: {
        cursor: 'default',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '4px 16px',
        width: '100%',
        borderRadius: 8,
        minWidth: 215,
        '&:hover': {
            background: '#EEF4FF'
        }
    },
    title: {
        position: 'relative',
        marginLeft: 20,
        fontSize: 14,
        lineHeight: '32px',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: 500
    },
    amount: {
        fontSize: 14,
        lineHeight: '24px',
        color: 'rgba(0, 0, 0, 0.6)',
        marginLeft: 5
    },
    point: {
        position: 'absolute',
        top: 12,
        left: -20,
        background: '#276EF9',
        borderRadius: 2,
        minWidth: 8,
        minHeight: 8
    }
}));
