import React, {useState} from 'react';
import {Box, Checkbox, IconButton, makeStyles, Table, TableBody, TableContainer, Typography} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import clsx from "clsx";
import CustomTableHead from "../../../components/ui/Tables/TableHead";
import MuiTableCell from "../../../components/ui/Tables/TableCell";
import CustomPaper from "../../../components/ui/Paper";
import {INVOICES_COMPLETED_HEAD} from "../../../constants/tableColumns";
import {useSelectRow} from "../../../helpers/hooks";
import TableSkeleton from "../../../components/ui/Tables/TableSkeleton";
import {isSelected} from "../../../helpers";
import CustomTableRow from "../../../components/ui/Tables/TableRow";
import TooltipCell from "../../../components/ui/Tables/TooltipCell";
import {DeleteOutline, LocationOnOutlined} from "@material-ui/icons";
import CustomButton from "../../../components/ui/Buttons/Button";
import CustomTableRowInvoices from "./CustomTableRowInvoices";
import StatusInvoice from "./StatusInvoice";
import {INVOICE_COMPLETED_STATUS} from "../helpers/enum";

const CompletedTable: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const [selected, handleClick, selectAllClick, setSelected] = useSelectRow([]);
    // const includesSelected = selected.some(r => data?.map(item => item.id).includes(r?.id))
    const loading = false;

    return (
        <div className={classes.completedTableWrapper}>
            <div className={classes.tableHeader}>
                <Typography variant={'h5'} className={classes.tableTitle}>Completed</Typography>
            </div>
            <div className={classes.completedTable}>
                <CustomPaper className={
                    clsx(
                        classes.paperContainer
                    )
                }>
                    <TableContainer className={clsx(
                        classes.tableContainer
                    )}>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            stickyHeader
                        >
                            <CustomTableHead className={classes.tableHead} isInvoicesTable headCells={INVOICES_COMPLETED_HEAD}>
                                {/*<MuiTableCell width={'auto'} padding="checkbox">*/}
                                {/*    <Checkbox*/}
                                {/*        color={'primary'}*/}
                                {/*        onChange={(event) => selectAllClick(event, data)}*/}
                                {/*        indeterminate={includesSelected}*/}
                                {/*        checked={data.length === selected.length}*/}
                                {/*    />*/}
                                {/*</MuiTableCell>*/}
                            </CustomTableHead>
                            <TableBody classes={{root: classes.tableBody}}>
                                {loading ? <TableSkeleton cell={6} rows={5}/>
                                    : data.map((item, index) => {
                                        const isItemSelected = isSelected(item.id, selected);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        // @ts-ignore
                                        return (
                                            <CustomTableRowInvoices
                                                hover
                                                key={item?.id}
                                                // @ts-ignore
                                                onClick={(event) => handleClick(event, item)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                selected={isItemSelected}
                                            >
                                                {/*<MuiTableCell padding="checkbox">*/}
                                                {/*    <Checkbox*/}
                                                {/*        checked={isItemSelected}*/}
                                                {/*        inputProps={{'aria-labelledby': labelId}}*/}
                                                {/*        color={'primary'}*/}
                                                {/*    />*/}
                                                {/*</MuiTableCell>*/}
                                                <TooltipCell
                                                    label={item?.name}
                                                    value={item?.name}
                                                />
                                                <TooltipCell
                                                    label={item?.clientAddress === '' ? 'Waiting address...' : item?.clientAddress}
                                                    value={item?.clientAddress === '' ? 'Waiting address...' : item?.clientAddress}
                                                />
                                                <TooltipCell
                                                    label={item?.amount}
                                                    value={item?.amount}
                                                    align={'right'}
                                                />
                                                <TooltipCell
                                                    label={item?.productsPcs}
                                                    value={item?.productsPcs}
                                                    align={'right'}
                                                />
                                                <TooltipCell
                                                    label={item?.issueDate}
                                                    value={item?.issueDate}
                                                />
                                                <MuiTableCell
                                                    className={classes.status}
                                                >
                                                    <StatusInvoice invoiceStatus={item?.status} statusDate={item?.statusDate} />
                                                </MuiTableCell>
                                                {/*<TooltipCell*/}
                                                {/*    label={item?.status}*/}
                                                {/*    value={item?.status}*/}
                                                {/*    className={classes.status}*/}
                                                {/*/>*/}
                                            </CustomTableRowInvoices>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CustomPaper>
            </div>
        </div>
    );
};

export default CompletedTable;


const useStyles = makeStyles<Theme>(() => ({
    completedTableWrapper: {
        marginTop: 40
    },
    tableHeader: {
        marginBottom: 16
    },
    tableTitle: {
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '24px'
    },
    completedTable: {},
    TableBody: {
        '& > tr:last-of-type > td': {
            borderBottom: 'none'
        }
    },
    table: {
        // '& td > h6': {
        //     fontSize: '16px'
        // }
    },
    paperContainer: {
        filter: 'drop-shadow(0px 6px 17px rgba(73, 105, 219, 0.08))',
        borderRadius: 16
    },
    status: {
        minWidth: 230
    },
    tableBody: {
        '& > tr > td:nth-child(4)': {
            paddingRight: 32
        }
    },
    tableHead: {
        '& > tr > th:nth-child(4)': {
            paddingRight: '32px !important'
        }
    }
}));

const data = [
    {
        id: 1,
        name: ' #548-66AB',
        clientAddress: '0xc54sa5d458654556sd48654556sd48654556sd4',
        amount: '5 408.22',
        productsPcs: 5,
        issueDate: '08 Jan 2022',
        status: INVOICE_COMPLETED_STATUS.PAID,
        statusDate: '09 Jan 2022'
    },
    {
        id: 2,
        name: ' #548-66AB',
        clientAddress: '0xc54sa5d458654556sd48654556sd48654556sd4',
        amount: '5 408.22',
        productsPcs: 5,
        issueDate: '08 Jan 2022',
        status: INVOICE_COMPLETED_STATUS.CHECKED,
        statusDate: '09 Jan 2022'
    },
    {
        id: 3,
        name: ' #548-66AB',
        clientAddress: '0xc54sa5d458654556sd48654556sd48654556sd4',
        amount: '5 408.22',
        productsPcs: 5,
        issueDate: '08 Jan 2022',
        status: INVOICE_COMPLETED_STATUS.CANCELED,
        statusDate: ''
    },
    {
        id: 4,
        name: ' #548-66AB',
        clientAddress: '0xc54sa5d458654556sd48654556sd48654556sd4',
        amount: '5 408.22',
        productsPcs: 5,
        issueDate: '08 Jan 2022',
        status: INVOICE_COMPLETED_STATUS.CHECKED,
        statusDate: '09 Jan 2022'
    },
    {
        id: 5,
        name: ' #548-66AB',
        clientAddress: '0xc54sa5d458654556sd48654556sd48654556sd4',
        amount: '5 408.22',
        productsPcs: 5,
        issueDate: '08 Jan 2022',
        status: INVOICE_COMPLETED_STATUS.PAID,
        statusDate: '09 Jan 2022'
    }
]
