import * as Yup from 'yup';
import {ERRORS} from './errors';
import {BRAND_TYPE} from '../pages/brands/helpers/constants';
import {REGEX} from './regex';
import {ProductAttributeTemplateType} from '../generated/graphql';

const ATTRIBUTES_SCHEMA = Yup.array().of(Yup.object().shape({
    type: Yup.string(),
    value: Yup.string().when('type', {
        is: (type: ProductAttributeTemplateType) => type === ProductAttributeTemplateType.MultiString,
        then: Yup.string().trim().required(ERRORS.REQUIRED),
        otherwise: Yup.string().trim().required(ERRORS.REQUIRED)
    }).when('type', {
        is: (type: ProductAttributeTemplateType) => type === ProductAttributeTemplateType.Integer,
        then: Yup.string().required(ERRORS.REQUIRED).typeError(ERRORS.TYPE_NUMBER),
        otherwise: Yup.string().trim().required(ERRORS.REQUIRED)
    })
}))

const SIGN_UP_SCHEMA = Yup.object({
    email: Yup.string().trim().email(ERRORS.EMAIL).required(ERRORS.REQUIRED),
    password: Yup.string().trim().min(8, ERRORS.MIN(8)).max(256, ERRORS.MAX(256)).required(ERRORS.REQUIRED),
    password_confirmation: Yup.string().trim().required(ERRORS.REQUIRED).oneOf([Yup.ref('password'), null], ERRORS.MATCH('Passwords')),
    agreement: Yup.boolean().oneOf([true], ERRORS.AGREEMENT)
})

const LOGIN_SCHEMA = Yup.object({
    email: Yup.string().trim().email(ERRORS.EMAIL).required(ERRORS.REQUIRED),
    // username: Yup.string().trim().required(ERRORS.REQUIRED).max(100, ERRORS.MAX(100)).min(6, ERRORS.MIN(6)),
    password: Yup.string().trim().required(ERRORS.REQUIRED)
})

const CONTACT_US_SCHEMA = Yup.object({
    fullName: Yup.string().trim().required(ERRORS.REQUIRED),
    company: Yup.string().trim().required(ERRORS.REQUIRED),
    position: Yup.string().trim().required(ERRORS.REQUIRED),
    email: Yup.string().trim().email(ERRORS.EMAIL).required(ERRORS.REQUIRED)
})

const FORGOT_PASS_EMAIL_SCHEMA = Yup.object({
    email: Yup.string().trim().email(ERRORS.EMAIL).required(ERRORS.REQUIRED)
})

const FORGOT_PASS_CODE_SCHEMA = Yup.object({
    emailCode: Yup.string().trim().required(ERRORS.REQUIRED),
    password: Yup.string().trim().min(8, ERRORS.MIN(8)).max(256, ERRORS.MAX(256)).required(ERRORS.REQUIRED),
    passwordConfirm: Yup.string().trim().required(ERRORS.REQUIRED).oneOf([Yup.ref('password'), null], ERRORS.MATCH('Passwords'))
})

const CREATE_BRAND_SCHEMA = Yup.object({
    name: Yup.string().trim().required(ERRORS.REQUIRED).max(100, ERRORS.MAX(100)),
    // contactInfo: Yup.string().required(ERRORS.REQUIRED),
    parentBrandId: Yup.string().when('type', (type, schema) => {
        if (type === BRAND_TYPE.SUB_BRAND) {
            return Yup.string().trim().required(ERRORS.REQUIRED);
        }
        return Yup.string().trim();
    }),
    brandConfirmFiles: Yup.array().min(1, ERRORS.REQUIRED)
    // .of(Yup.object().shape({name: Yup.string()}))
})

const UPLOAD_FEED_SCHEMA = Yup.object({
    file: Yup.object().shape({
        filepath: Yup.string().trim(),
        preview: Yup.string().trim()
    })
})


const CREATE_PRODUCT_SCHEMA = Yup.object({
    showSerialNumber: Yup.boolean(),
    name: Yup.string().trim().required(ERRORS.REQUIRED).max(125, ERRORS.MAX(125)),
    brandId: Yup.object({
        id: Yup.number(),
        name: Yup.string().required(ERRORS.REQUIRED).trim()
    }),
    quantity: Yup.number().when('showSerialNumber', {
        is: false,
        then: Yup.number()
            .moreThan(0, ERRORS.MORE_THAN(0))
            .lessThan(10000, ERRORS.LESS_THAN(9999))
            .typeError(ERRORS.TYPE_NUMBER)
            .positive(ERRORS.POSITIVE)
            .required(ERRORS.REQUIRED)
            .integer(ERRORS.INTEGER),
        otherwise: Yup.number()
    }),
    // price: Yup
    //     .mixed()
    //     .required(ERRORS.REQUIRED)
    //     .typeError(ERRORS.TYPE_NUMBER)
    //     .test('type', ERRORS.TYPE_NUMBER, value => !isNaN(value))
    //     .test('validDollar', ERRORS.PRICE, value => REGEX.PRICE.test(value)),

    parentBrandId: Yup.string().when('type', (type, schema) => {
        if (type === BRAND_TYPE.SUB_BRAND) {
            return Yup.string().trim().required(ERRORS.REQUIRED);
        }
        return Yup.string().trim();
    }),
    serial: Yup.object().when('showSerialNumber', {
        is: true,
        then: Yup.object().shape({
            to: Yup.number().positive(ERRORS.POSITIVE).min(Yup.ref('from'), "Less than from field"),
            from: Yup.number().positive(ERRORS.POSITIVE).max(Yup.ref('to'), "More than to field"),
        })
    }),
    attributes: ATTRIBUTES_SCHEMA
})

const CREATE_CONSIGNMENT_SCHEMA = Yup.object({
    name: Yup.string().trim().required(ERRORS.REQUIRED).max(125, ERRORS.MAX(125)),
    quantity: Yup.number()
        .moreThan(0, ERRORS.MORE_THAN(0))
        .lessThan(10000, ERRORS.LESS_THAN(9999))
        .typeError(ERRORS.TYPE_NUMBER)
        .positive(ERRORS.POSITIVE)
        .required(ERRORS.REQUIRED)
        .integer(ERRORS.INTEGER),
    attributes: ATTRIBUTES_SCHEMA
})

const ADD_STORE_SCHEMA = Yup.object({
    name: Yup.string().trim().max(100, ERRORS.MAX(100)).min(3, ERRORS.MIN(3)).required(ERRORS.REQUIRED),
    comment: Yup.string().trim().max(500, ERRORS.MAX(500)),
    blockchainAddress: Yup.string().trim().matches(REGEX.ETHEREUM_ADDRESS, ERRORS.ETHEREUM_ADDRESS).required(ERRORS.REQUIRED)
});

const CREATE_STORE_SCHEMA = Yup.object({
    email: Yup.string().trim().email(ERRORS.EMAIL).required(ERRORS.REQUIRED),
    password: Yup.string().trim().max(100, ERRORS.MAX(100)).min(8, ERRORS.MIN(8)).required(ERRORS.REQUIRED),
    name: Yup.string().trim().max(500, ERRORS.MAX(100)).required(ERRORS.REQUIRED),
    comment: Yup.string().trim().max(256, ERRORS.MAX(256))
})

const SEND_SINGLE_PRODUCT_SCHEMA = Yup.object({
    quantity:
        Yup.number()
            .moreThan(0, ERRORS.MORE_THAN(0))
            .typeError(ERRORS.TYPE_NUMBER)
            .positive(ERRORS.POSITIVE)
            .required(ERRORS.REQUIRED)
            .integer(ERRORS.INTEGER),
    receiverAddress: Yup.string().required(ERRORS.SHORT_REQUIRED).typeError(ERRORS.INVALID_TYPE).matches(REGEX.ETHEREUM_ADDRESS, ERRORS.ETHEREUM_ADDRESS)
})

const SEND_MULTI_PRODUCT_SCHEMA = Yup.object({
    receiverAddress: Yup.string().required(ERRORS.REQUIRED).matches(REGEX.ETHEREUM_ADDRESS, ERRORS.ETHEREUM_ADDRESS),
    items: Yup.array().of(
        Yup.object().shape({
            quantity: Yup.number().required(ERRORS.REQUIRED)
        })
    )
})

const CHANGE_PASSWORD_SCHEMA = Yup.object({
    password: Yup.string().trim().required(ERRORS.REQUIRED),
    newPassword: Yup.string().trim().max(100, ERRORS.MAX(100)).min(8, ERRORS.MIN(8)).required(ERRORS.REQUIRED),
    confirmPassword: Yup.string().trim().required(ERRORS.REQUIRED).oneOf([Yup.ref('newPassword'), null], ERRORS.MATCH('Passwords'))
})

const UPDATE_INVOICE_INFO_SCHEMA = Yup.object({
    fullName: Yup.string().trim().max(100, ERRORS.MAX(100)).min(2, ERRORS.MIN(2)).required(ERRORS.REQUIRED),
    streetAddress: Yup.string().trim().max(100, ERRORS.MAX(100)).min(2, ERRORS.MIN(2)).required(ERRORS.REQUIRED),
    cityState: Yup.string().trim().max(100, ERRORS.MAX(100)).min(2, ERRORS.MIN(2)).required(ERRORS.REQUIRED),
    zipCode: Yup.string().trim().matches(REGEX.ZIP_CODE, ERRORS.ZIP_CODE).required(ERRORS.REQUIRED),
    // phoneNumber: Yup.string().trim().matches(REGEX.PHONE_NUMBER, ERRORS.PHONE_NUMBER).required(ERRORS.REQUIRED),
    // email: Yup.string().trim().email(ERRORS.EMAIL).required(ERRORS.REQUIRED).required(ERRORS.REQUIRED),
})

const DEPOSIT_SCHEMA = Yup.object({
    amount: Yup.number().moreThan(0.99, ERRORS.MIN_AMOUNT(1)).lessThan(1000000, ERRORS.MAX_AMOUNT(999999)).integer(ERRORS.INTEGER).required(ERRORS.REQUIRED),
    cardholderName: Yup.string().trim().max(100, ERRORS.MAX(3)).min(3, ERRORS.MIN(3)).required(ERRORS.REQUIRED)
})

const INVOICE_GENERATE_SCHEMA = Yup.object().shape({
    amount: Yup.number().moreThan(0.99, ERRORS.MIN_AMOUNT(1)).lessThan(1000000, ERRORS.MAX_AMOUNT(999999)).integer(ERRORS.INTEGER).required(ERRORS.REQUIRED),
    comment: Yup.string().trim().max(500, ERRORS.MAX(500))
})

const CREATE_PRODUCT_BY_FEED_SCHEMA = Yup.object().shape({
    brandId: Yup.object({
        id: Yup.number(),
        name: Yup.string().trim().required(ERRORS.REQUIRED)
    }),
    filepath: Yup.array().of(
        Yup.object().shape({
            quantity: Yup.number().required(ERRORS.REQUIRED)
        })
    )
})

const CHECK_AS_CONFIRM_SCHEMA = Yup.object().shape({
    customerAddress: Yup.string().required(ERRORS.SHORT_REQUIRED).typeError(ERRORS.INVALID_TYPE).matches(REGEX.ETHEREUM_ADDRESS, ERRORS.ETHEREUM_ADDRESS)
})

const CREATE_NEW_INVOICE_SCHEMA = Yup.object().shape({
    totalAmount: Yup.number().required(ERRORS.REQUIRED).typeError(ERRORS.TYPE_NUMBER),
    dueOnDate: Yup.string().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
    itemsInvoice: Yup.array().of(
        Yup.object().shape({
            quantity: Yup.number().required(ERRORS.REQUIRED)
        })
    )
})

const RECIPIENT_EMAIL_SCHEMA = Yup.object({
    email: Yup.string().trim().email(ERRORS.EMAIL).required(ERRORS.REQUIRED)
})


export {
    SIGN_UP_SCHEMA,
    LOGIN_SCHEMA,
    CONTACT_US_SCHEMA,
    FORGOT_PASS_EMAIL_SCHEMA,
    FORGOT_PASS_CODE_SCHEMA,
    CREATE_BRAND_SCHEMA,
    UPLOAD_FEED_SCHEMA,
    CREATE_PRODUCT_SCHEMA,
    CREATE_CONSIGNMENT_SCHEMA,
    ADD_STORE_SCHEMA,
    CREATE_STORE_SCHEMA,
    SEND_SINGLE_PRODUCT_SCHEMA,
    CHANGE_PASSWORD_SCHEMA,
    SEND_MULTI_PRODUCT_SCHEMA,
    UPDATE_INVOICE_INFO_SCHEMA,
    DEPOSIT_SCHEMA,
    INVOICE_GENERATE_SCHEMA,
    CREATE_PRODUCT_BY_FEED_SCHEMA,
    CHECK_AS_CONFIRM_SCHEMA,
    CREATE_NEW_INVOICE_SCHEMA,
    RECIPIENT_EMAIL_SCHEMA
}
