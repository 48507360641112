import React from 'react';
import {makeStyles, TableRowProps, Typography} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import CreateNewInvoice from './CreateNewInvoice';
import NewInvoicePreview from './NewInvoicePreview';
import {useUIContext} from '../../../../context/UIContext';
import {MESSAGES} from '../../../../constants/messages';
import QRInvoicePreview from '../QRInvoicePreview';

type CustomCreateInvoiceProps = {

}

const CreateInvoice: React.FC<CustomCreateInvoiceProps> = (props: CustomCreateInvoiceProps): JSX.Element => {
    // const {} = props;
    const classes = useStyles();
    const [openNewInvoicePreview, setOpenNewInvoicePreview] = React.useState(false);
    const [openQRInvoicePreview, setOpenQRInvoicePreview] = React.useState(false);
    const [dataNewInvoice, setDataNewInvoice] = React.useState([]);
    const {showSnackBar, setOpenCreateInvoice} = useUIContext();


    const onSubmitInvoice = (values: any) => {
        values ? console.log(values, 'onSubmitInvoice') : console.log(dataNewInvoice, 'onSubmitInvoice');
        showSnackBar(MESSAGES.INVOICE_CREATED);
        setOpenNewInvoicePreview(false);
        setOpenCreateInvoice(false);
        setOpenQRInvoicePreview(true);
    }

    return (
        <div className={classes.createInvoice}>
            <CreateNewInvoice
                setOpenNewInvoicePreview={setOpenNewInvoicePreview}
                dataNewInvoice={dataNewInvoice}
                setDataNewInvoice={setDataNewInvoice}
                onSubmitInvoice={onSubmitInvoice}
            />
            <NewInvoicePreview
                openNewInvoicePreview={openNewInvoicePreview}
                setOpenNewInvoicePreview={setOpenNewInvoicePreview}
                dataNewInvoice={dataNewInvoice}
                onSubmitInvoice={onSubmitInvoice}
            />
            <QRInvoicePreview
                openQRInvoicePreview={openQRInvoicePreview}
                setOpenQRInvoicePreview={setOpenQRInvoicePreview}
                dataNewInvoice={dataNewInvoice}
                setDataNewInvoice={setDataNewInvoice}
            />
        </div>
    );
};

export default CreateInvoice;

const useStyles = makeStyles<Theme>(() => ({
    CreateInvoice: {}
}));
