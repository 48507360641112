import React, {useState, useEffect, useRef} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles} from '@material-ui/core';
import CustomButton from '../../../components/ui/Buttons/Button';
import OpenInNew from '@material-ui/icons/OpenInNew';
import GetApp from '@material-ui/icons/GetApp';
import QRCode from 'qrcode.react';
import Base64Downloader from 'react-base64-downloader';
import {LINKS} from '../../../constants';
import {ProductType} from '../../../types/enum';
import {useHistory} from 'react-router-dom';
import {Skeleton} from '@material-ui/lab';

type QrCodeSection = {
    type: ProductType,
    data: any
}

const QrCodeSection: React.FC<QrCodeSection> = (props: QrCodeSection): JSX.Element => {
    const classes = useStyles();
    const {type, data} = props;
    const qrRef = useRef();
    const [base64, setBase64] = useState(null);
    const [qrProduct, setQrProduct] = useState(0);
    const history = useHistory();
    useEffect(() => {
        const canvas = document.querySelector('#qr_id>canvas');
        if (canvas) {
            // @ts-ignore
            const image = canvas.toDataURL();
            setBase64(image);
        }
    }, [qrProduct]);
    useEffect(() => {
        if (data?.id) {
            setQrProduct(data?.id);
        }
    }, [data?.id]);
    const goToDetailsPage = () => {
        if (data) {
            history.push(`/${type === ProductType.product ? 'products': 'consignments'}/info`, {
                type,
                data
            })
        }
    }

    return (
        <Box className={classes.qrCodeWrapper} id={'qr_id'}>
            {data?.id ?
                <QRCode
                    value={`${LINKS.PRODUCT_URL_ADDRESS}/${data?.id}`}
                    ref={qrRef}
                    size={130}
                />
                :
                <Box style={{width: 130, height: 130}}>
                    <Skeleton variant={'rect'} width={130} height={130}/>
                </Box>
            }
            <Box className={classes.buttonWrapper} id={'header'}>
                <CustomButton
                    startIcon={<OpenInNew/>}
                    className={classes.buttonsAlign}
                    onClick={goToDetailsPage}
                >Open page</CustomButton>
                {/*// @ts-ignore*/}
                <Base64Downloader
                    className={classes.downloadButton}
                    base64={base64}
                    downloadName={`${data?.name}QrCode`}
                >
                    <CustomButton
                        variant="text"
                        color="primary"
                        startIcon={<GetApp/>}
                        className={classes.buttonsAlign}
                    >
                        Click to download
                    </CustomButton>
                </Base64Downloader>
            </Box>
        </Box>
    );
};

export default QrCodeSection;

const useStyles = makeStyles<Theme>(() => ({
    qrCodeWrapper: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'center',
    },
    buttonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        marginLeft: 10
    },
    buttonsAlign: {},
    downloadButton: {
        border: 'none',
        backgroundColor: 'white',
        outline: 'none',
        alignSelf: 'center',
    },
}));
