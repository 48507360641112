import React, {useDebugValue, useState, useMemo, useEffect} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, InputAdornment, makeStyles, TextField, Typography} from '@material-ui/core';
import DialogLayout, {DialogLayoutProps} from '../../../layouts/DialogLayout';
import SearchIcon from '@material-ui/icons/Search';
import {useDebounce} from 'use-debounce';
import {ProductAttributeTemplateType, useGetProductAttributeTemplatesQuery} from '../../../generated/graphql';
import {NavLink} from 'react-router-dom';
import MuiCircularProgress from '../CircularProgress';
import {COLORS} from '../../../constants/colors';
import _ from 'lodash';
import clsx from 'clsx';
import {useFormikContext} from 'formik';
import LoadingLayout from '../../../layouts/LoadingLayout';

type DialogSelectOptionalFieldProps = DialogLayoutProps & {
    name: string;
}

const DialogSelectOptionalField: React.FC<DialogSelectOptionalFieldProps> = (props: DialogSelectOptionalFieldProps): JSX.Element => {
    const classes = dialogSelectOptionalStyles();
    const {name, ...rest} = props
    const [value, setValue] = useState('');
    const [debounceValue] = useDebounce(value, 500)
    const {data: dataQuery, loading, error, previousData} = useGetProductAttributeTemplatesQuery({
        variables: {
            searchQuery: debounceValue
        }
    })
    const data = useMemo(() => {
        if (dataQuery?.productAttributeTemplates?.length > 0) {
            return _.groupBy(dataQuery?.productAttributeTemplates, item => {
                return item?.title[0].toUpperCase();
            })
        } else {
            return []
        }
    }, [dataQuery]);
    const {setFieldValue, touched, setTouched, values} = useFormikContext();
    const handlePushOption = (item): void => {
        const isSelected = values[name]?.find(i => i?.id === item?.id);
        if (!isSelected) {
            setTouched(({...touched, [name]: null}))
            setFieldValue(name, [
                item?.type === ProductAttributeTemplateType.Dropdown ?
                    {...item, value: '', dimension: item?.placeholders[0]}
                    : {...item, value: ''},
                ...values[name]
            ])
            props.onClose('', 'escapeKeyDown');
        }
    }
    return (
        <DialogLayout
            width={950}
            maxWidth={false}
            {...rest}
        >
            <Box className={classes.wrapper}>
                <Typography variant={'h4'}>Fields</Typography>
                <TextField
                    fullWidth
                    label={'Search'}
                    placeholder={'Search'}
                    variant={'outlined'}
                    className={classes.input}
                    value={value}
                    onChange={event => setValue(event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {loading && previousData ? <MuiCircularProgress/> : <SearchIcon/>}
                            </InputAdornment>
                        )
                    }}
                />
                {loading && previousData ? <LoadingLayout size={40}/> :
                    data && Object.keys(data)?.length > 0 ?
                        <Box className={classes.gridWrapper}>
                            {
                                Object.keys(data)?.map((key, _) => (
                                    <Box className={classes.list} key={_}>
                                        <Typography className={classes.listKey}>{key}</Typography>
                                        <ul>
                                            {
                                                data[key]?.map((item, index) => {
                                                    return (
                                                        <li
                                                            key={item?.id}
                                                            className={clsx(
                                                                classes.listItem,
                                                                {[classes.selectedItem]: values[name]?.find(i => i?.id === item?.id)}
                                                            )}
                                                            onClick={() => handlePushOption(item)}
                                                        >
                                                            {item?.title}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </Box>
                                ))
                            }
                        </Box>
                        :
                        <Box className={classes.notFoundWrapper}>
                            <Typography variant={'h4'} className={classes.notFoundTitle}>Nothing not found</Typography>
                            <Typography variant={'subtitle1'} className={classes.notFoundInfo}>
                                Check the spelling of the request if there are
                                any typos, such as &quot;color&quot; instead of &quot;color&quot;
                            </Typography>
                            <Typography variant={'subtitle1'} className={classes.notFoundInfo}>
                                Try using a more general query like &quot;Material&quot;
                                instead of &quot;Fabric&quot;
                            </Typography>
                            <Typography variant={'subtitle1'} className={classes.notFoundInfo}>
                                If your problem has not been resolved, please{' '}
                                <NavLink to={'/contact/support'}>
                                    contact support
                                </NavLink>
                            </Typography>
                        </Box>
                }
            </Box>
        </DialogLayout>
    );
};

export default DialogSelectOptionalField;

export const dialogSelectOptionalStyles = makeStyles<Theme>(() => ({
    wrapper: {
        padding: '24px 56px',
        height: 540,
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll'
    },
    input: {
        marginTop: 35
    },
    notFoundWrapper: {
        marginBottom: 20
    },
    notFoundTitle: {
        color: COLORS.TEXT_SILVER,
        marginTop: 40
    },
    notFoundInfo: {
        color: COLORS.TEXT_LIGHT_SILVER,
        lineHeight: 2.4
    },
    list: {
        display: 'flex',
        '& ul': {
            padding: '20px 0'
        }
    },
    listKey: {
        color: '#1A59ED',
        margin: '1.1em 1em 0 0'
    },
    gridWrapper: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        marginTop: 15,
    },
    listItem: {
        marginBottom: 10,
        cursor: 'pointer',
    },
    selectedItem: {
        textDecoration: 'line-through'
    }
}));
