import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {CircularProgress, makeStyles, Typography} from '@material-ui/core';
import {Formik, Form} from 'formik';
import {FORGOT_PASS_EMAIL_SCHEMA} from '../../constants/validation_schema';
import FormBoxInput from '../ui/FormBoxInput';
import AlternateEmailRoundedIcon from '@material-ui/icons/AlternateEmailRounded';
import CustomButton from '../ui/Buttons/Button';
import {COLORS} from '../../constants/colors';
import {useHistory} from 'react-router-dom';
import {useGenerateEmailCodeMutation} from '../../generated/graphql';
import {useUIContext} from '../../context/UIContext';
import MuiCircularProgress from '../ui/CircularProgress';


const SendEmailForm: React.FC = (props): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const [generateEmailCode] = useGenerateEmailCodeMutation();
    const {showSnackBar} = useUIContext();
    return (
        <Formik
            initialValues={{
                email: ''
            }}
            validationSchema={FORGOT_PASS_EMAIL_SCHEMA}
            onSubmit={async (values) => {
                try {
                    const {data} = await generateEmailCode({
                        variables: values
                    })
                    if (data?.generateEmailCode?.result) {
                        history.push('/forgot-password/send-code', values);
                    }
                } catch (e) {
                    showSnackBar(e?.message)
                }
            }}
        >
            {({handleSubmit, isSubmitting, isValid}) => (
                <Form onSubmit={handleSubmit}>
                    <Typography variant={'h2'} className={classes.title}>Forgot password?</Typography>
                    <Typography variant={'subtitle1'} className={classes.subTitle}>Send a link to your e-mail to reset
                        your password</Typography>
                    <FormBoxInput
                        fullWidth
                        name={'email'}
                        icon={<AlternateEmailRoundedIcon/>}
                        label={'Email'}
                        labelWidth={42}
                    />
                    <CustomButton
                        type={'submit'}
                        className={classes.submitBtn}
                        disabled={!isValid || isSubmitting}
                    >{(isSubmitting) ? <MuiCircularProgress /> : 'Send reset code'}</CustomButton>
                </Form>
            )}
        </Formik>
    );
};

export default SendEmailForm;

const useStyles = makeStyles<Theme>(() => ({
    title: {
        marginBottom: 21,
        textAlign: 'start'
    },
    subTitle: {
        marginBottom: 25,
        textAlign: 'start'
    },
    submitBtn: {
        height: 62,
        width: '100%',
        background: '#276EF9',
        borderRadius: '4px',
        color: 'white',
        marginTop: 40,
        '&:hover': {
            background: '#276EF9'
        },
        '&:disabled': {
            background: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.38)'
        }
    }
}));
