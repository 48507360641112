import React, {ReactChildren, ReactNode} from 'react';
import {Theme} from '@material-ui/core/styles';
import {IconButton, makeStyles, Paper} from '@material-ui/core';
import {Grid} from '@material-ui/core';
import {useGlobalContext} from '../context/globalContext';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AuthLayoutPattern from '../assets/background/authLayoutPattern.svg';
import AuthLayoutPattern2 from '../assets/background/authLayoutPattern2.svg';
import WhiteLogo from '../assets/icons/white-logo.svg';

type AuthLayoutProps = {
    children: ReactNode | ReactChildren;
    icon: string;
}

const AuthLayout: React.FC<AuthLayoutProps> = (props: AuthLayoutProps): JSX.Element => {
    const {children, icon} = props;
    const {darkTheme} = useGlobalContext();
    const classes = useStyles({darkTheme});
    const handlerClose = () => window.location.href = 'https://brandprotect.world/';

    return (
        <Grid
            container
            classes={{
                root: classes.grid
            }}
        >
            <Paper
                elevation={0}
                square={true}
                classes={{
                    root: classes.firstPaper
                }}
            >
                <img src={WhiteLogo} className={classes.logoImg} alt="logo"/>
                <img src={icon} className={classes.bgImg} alt="SignUp"/>
            </Paper>
            <Paper
                elevation={0}
                square={true}
                classes={{
                    root: classes.secondPaper
                }}
            >
                <img src={AuthLayoutPattern} alt="back" className={classes.firstPattern}/>
                <img src={AuthLayoutPattern2} alt="back2" className={classes.secondPattern} />
                <IconButton
                    size={'medium'}
                    className={classes.closeButton}
                    onClick={handlerClose}
                >
                    <CloseRoundedIcon />
                </IconButton>
                <Paper
                    elevation={0}
                    square={true}
                    classes={{
                        root: classes.paperContainer
                    }}
                >
                    {children}
                </Paper>
            </Paper>
        </Grid>
    );
};

export default AuthLayout;

const useStyles = makeStyles<Theme , {darkTheme: boolean}>(theme => ({
    grid: {
        position: 'relative',
        width: '100vw',
        height: '100vh'
    },
    firstPaper: {
        flex: 0.4125,
        backgroundColor: props => !props?.darkTheme ? theme.palette.primary.main : '#313131',
        display: 'grid',
        placeItems: 'center',
    },
    bgImg: {
        width: '100%',
        height: 'auto'
    },
    logoImg: {
        position: 'absolute',
        top: 16,
        left: 26
    },
    secondPaper: {
        position: 'relative',
        flex: 0.5875,
        overflowY: 'scroll',
        zIndex: 0
    },
    paperContainer: {
        height: '100%',
        maxWidth: 582,
        padding: '0 16px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',

    },
    firstPattern: {
        position: 'absolute',
        top: '-5%',
        left: '-15%',
        zIndex: 1
    },
    secondPattern: {
        position: 'absolute',
        bottom: 0,
        right: 0,
        zIndex: 1
    },
    closeButton: {
        position: 'absolute',
        right: '3%',
        top: '3%'
    }
}));
