import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import DialogLayout, {DialogLayoutProps} from '../../../layouts/DialogLayout';
import {DialogIconWrapper} from './DialogEndActionType';
import Hello from '../../../assets/icons/hello.svg';
import CustomButton from '../Buttons/Button';
import {COLORS} from '../../../constants/colors';

const DialogDemoDeposit: React.FC<DialogLayoutProps> = (props: DialogLayoutProps): JSX.Element => {
    const classes = useStyles();
    return (
        <DialogLayout
            width={525}
            hideIcon
            {...props}
        >
            <Box className={classes.container}>
                <DialogIconWrapper
                    className={'SuccessIconWrap'}
                    icon={Hello}
                />
                <Typography align={'center'} variant={'h4'} >Hey!</Typography>
                <Typography align={'center'} variant={'h5'} className={classes.DialogText}>
                    You have just landed on our demo platform.
                    In this version, you can familiarize yourself
                    with the basic functions of our platform
                </Typography>
                <CustomButton
                    variant={'contained'}
                    onClick={() => props.onClose('', 'escapeKeyDown')}
                >Confirm</CustomButton>
            </Box>
        </DialogLayout>
    );
};

export default DialogDemoDeposit;

const useStyles = makeStyles<Theme>(() => ({
    container: {
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 40,
    },
    DialogText: {
        margin: '18px 0 32px 0'
    },
    iconWrapper: {
        border: '10px solid rgba(39, 110, 249, 0.04)',
        backgroundColor: COLORS.PRIMARY_BACKGROUND
    }
}));
