import React, {useContext} from 'react';
import {useSelectRow} from '../helpers/hooks';

type initialValuesType = {

}

const initialValues: initialValuesType = {

}

const ProductContext = React.createContext(initialValues);

export const useProductContext = () => {
    return useContext(ProductContext);
};

const ProductContextProvider:React.FC = ({children}) => {
    const [selected, handleClick, selectAllClick, setSelected] = useSelectRow([]);

    return (
        <ProductContext.Provider
            value={{
                selected: selected,
                handleClick: handleClick,
                selectAllClick: selectAllClick,
                setSelected: setSelected
            }}
        >
            {children}
        </ProductContext.Provider>
    );
}

export default ProductContextProvider;
