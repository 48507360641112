import {ProductType} from '../types/enum';

const MESSAGES = {
    PASS_CHANGE: 'Password successfully changed',
    ADD_STORE: 'Store successfully added',
    BRAND_DELETE: 'Brand deleted successfully',
    PRODUCT_DELETE: 'Product deleted successfully',
    PRODUCTS_DELETE: 'Products deleted successfully',
    CONSIGNMENT_DELETE: 'Consignment deleted successfully',
    CONSIGNMENTS_DELETE: 'Consignments deleted successfully',
    ADD_CONTACT: 'Contact successfully added',
    CONTACT_DELETE: 'Contact deleted successfully',
    STORE_DELETE: 'Store deleted successfully',
    PRODUCT_SEND: 'Product send successfully',
    PRODUCTS_SEND: 'Products send successfully',
    CONSIGNMENT_SEND: 'Consignment send successfully',
    CONSIGNMENTS_SEND: 'Consignments send successfully',
    CHANGE_INVOICE_INFO: 'Invoice info successfully changed',
    DELETE_SUBTITLE: 'This record will be permanently deleted. You can’t undo this action',
    LOGIN_SUBTITLE: (name) => `Please confirm your login to the ${name} account.`,
    EMPTY_BRANDS_TITLE: 'You have no brands yet',
    EMPTY_BRANDS_SUBTITLE: 'Create your first brand for product creation opportunities',
    EMPTY_PRODUCTS_TITLE: 'You have no products yet',
    EMPTY_PRODUCTS_SUBTITLE_NO_STORE: 'Create your first product',
    EMPTY_PRODUCTS_SUBTITLE: 'Received products will be displayed here',
    EMPTY_CONS_SUBTITLE_NO_STORE: 'Create your first consignment',
    EMPTY_CONS_SUBTITLE: 'Received consignment will be displayed here',
    SUCCESS_CHANGE_PASSWORD: 'Password changed successfully',
    SUCCESS_CHANGE_AVATAR: 'Avatar changed successfully',
    SUCCESS_TERMINATED_SESSION: 'Session successfully terminated',
    SUCCESS_TERMINATED_SESSIONS: 'Sessions successfully terminated',
    INVOICE_CREATED: 'Invoice was successfully created',
    INVOICE_CANCEL: 'Invoice was canceled',
    INVOICE_CONFIRM: 'Invoice was confirmed',
    INVOICE_BURN_CERTIFICATES: 'The Certificates was burned',
    INVOICE_SEND_TO_EMAIL: 'Invoice was sent to email',
    EMPTY_PRODUCTS_SEND_TITLE: (type: ProductType) => {
        return `You don't have any ${type === ProductType.product ? 'products' : 'consignments'} to send. You can select products right now`
    },

}

export {MESSAGES}
