import React, {useState, useEffect} from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, Paper} from '@material-ui/core';
import FormInput, {FormInputType} from './FormInput';
import clsx from 'clsx';
import {useFormikContext} from 'formik';
import {COLORS} from '../../constants/colors';

type FormBoxInputProps = FormInputType & {
    icon: any;
    wrapperContainer?: any
}

const FormBoxInput: React.FC<FormBoxInputProps> = (props: FormBoxInputProps): JSX.Element => {
    const classes = useStyles();
    const [focus, setFocus] = useState(false);
    const [error, setError] = useState(false);
    const {icon, wrapperContainer, ...rest} = props;
    const {errors, touched}: any = useFormikContext();
    useEffect(() => {
        setError(false)
        if (Object.prototype.hasOwnProperty.call(errors, props.name) && touched[props.name]) {
            setError(true)
        }
    }, [errors[props.name], touched[props.name]]);
    const focusInput = () => setFocus(true)
    const blurInput = () => setFocus(false)
    return (
        <Paper elevation={0} className={clsx(
            classes.inputContainer,
            wrapperContainer
        )}>
            <Paper
                elevation={0}
                className={clsx(
                    classes.box,
                    {[classes.boxFocused]: focus},
                    {[classes.boxError]: error}
                )}>
                {icon}
            </Paper>
            <FormInput
                blur={blurInput}
                onFocus={focusInput}
                {...rest}
            />
        </Paper>
    );
};

export default FormBoxInput;

const useStyles = makeStyles<Theme>(theme => ({
    inputContainer: {
        display: 'flex',
        width: '100%'
    },
    box: {
        width: 56,
        height: 56,
        background: 'rgba(0, 0, 0, 0.08)',
        borderRadius: '15px 5px',
        display: 'grid',
        placeItems: 'center',
        marginRight: 16,
        '& .MuiSvgIcon-root': {
            fill: COLORS.TEXT_SILVER
        },
        '& i': {
            color: COLORS.TEXT_SILVER
        }
    },
    boxFocused: {
        background: theme.palette.primary.main,
        '& .MuiSvgIcon-root': {
            fill: 'white'
        },
        '& i': {
            color: 'white'
        }
    },
    boxError: {
        background: theme.palette.secondary.main,
        '& .MuiSvgIcon-root': {
            fill: 'white'
        },
        '& i': {
            color: 'white'
        }
    }
}));
