import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, BoxProps, makeStyles} from '@material-ui/core';
import {TransactionType} from '../../../types/enum';
import Income from '../../../assets/icons/income.svg';
import Outcome from '../../../assets/icons/outcome.svg';
import {COLORS} from '../../../constants/colors';

type TransactionStatusProps = BoxProps & {
    incoming: boolean
}

const TransactionStatus: React.FC<TransactionStatusProps> = (props: TransactionStatusProps): JSX.Element => {
    const {incoming, ...rest} = props;
    const classes = useStyles({incoming});
    return (
        <Box className={classes.container} {...rest}>
            <img src={incoming ? Income : Outcome} alt="type-img"/>
        </Box>
    );
};

export default TransactionStatus;

const useStyles = makeStyles<Theme, {incoming: boolean}>(() => ({
    container: {
        width: 56,
        height: 56,
        borderRadius: '15px 5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 16,
        backgroundColor: props => props.incoming ? COLORS.SUCCESS_LIGHT : COLORS.ERROR_BACKGROUND
    }
}));
