import React, {useRef} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import QRCode from 'qrcode.react';
import DialogLayout, {DialogLayoutProps} from '../../../layouts/DialogLayout';
import {COLORS} from '../../../constants/colors';
import PDFUpload from '../../../assets/pdf-upload.svg';
import CustomButton from '../Buttons/Button';

type DownloadInvoiceProps = DialogLayoutProps & {
    open: boolean,
    file: string
    invoiceId: string
}

const ModalDownloadInvoice: React.FC<DownloadInvoiceProps> = (props: DownloadInvoiceProps): JSX.Element => {
    const classes = useStyles();
    const {open, file, invoiceId, ...rest} = props;

    const downloadQR = () => {
        const canvas = document.getElementById("QRCodePDF") as HTMLCanvasElement;
        if (canvas) {
            const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            const downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = `BP-invoice-${invoiceId}.png`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };
    return (
        <DialogLayout
            open={open}
            width={750}
            title={'Download invoice'}
            {...rest}
        >
            <Box className={classes.container}>
                <Typography variant={'h4'}>Download invoice</Typography>
                <Typography variant={'subtitle1'} className={classes.subTitle}>You can choose the type of invoice upload</Typography>
                <Box className={classes.buttonWrapper}>
                    <Box className={classes.section}>
                        <img src={PDFUpload} alt="PDF"/>
                        <a href={`${process.env.REACT_APP_BASE_URL}${file}`} target="_blank" download rel="noreferrer">
                            <CustomButton>
                                Download PDF
                            </CustomButton>
                        </a>
                    </Box>
                    <Box className={classes.section}>
                        <Box className={classes.qrCodeWrapper}>
                            <QRCode
                                id={'QRCodePDF'}
                                fgColor={COLORS.PRIMARY}
                                value={`${process.env.REACT_APP_BASE_URL}${file}`}
                                size={70}
                                level={"H"}
                            />
                        </Box>
                        <CustomButton onClick={downloadQR}>
                            Download QR-code
                        </CustomButton>
                    </Box>
                </Box>
            </Box>

        </DialogLayout>
    );
};

export default ModalDownloadInvoice;

const useStyles = makeStyles<Theme>(theme => ({
    subTitle: {
        margin: '8px 0px 36px'
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    section: {
        position: 'relative',
        padding: 16,
        flex: 0.48,
        height: 190,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        textAlign: 'center',
        background: '#EEF3FF',
        borderRadius: 4,
        '& > *': {
            alignSelf: 'center'
        },
        '& img': {
            width: 90,
            height: 90,
        },
        '& a': {
            textDecoration: 'none'
        }
    },
    qrCodeWrapper: {
        width: 90,
        height: 90,
        display: 'grid',
        placeItems: 'center',
        border: '2px solid #9ECFFF',
        borderRadius: 9,
        backgroundColor: 'white'
    },
    container: {
        padding: '24px 56px'
    }
}));
