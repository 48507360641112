import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, CircularProgress, makeStyles, Typography} from '@material-ui/core';
import {COLORS} from '../../constants/colors';
import FormLayout from '../../layouts/FormLayout';
import {Formik, Form, Field, FieldProps} from 'formik';
import {CREATE_BRAND_SCHEMA} from '../../constants/validation_schema';
import FormInput from '../../components/ui/FormInput';
import {BRAND_TYPE, DROPZONE_STYLE} from './helpers/constants';
import CustomDropzone from '../../components/ui/CustomDropzone';
import CustomButton from '../../components/ui/Buttons/Button';
import {useCreateBrandMutation} from '../../generated/graphql';
import {useHistory} from 'react-router-dom';
import MuiCircularProgress from '../../components/ui/CircularProgress';
import {uploadedFiles} from '../../helpers';
import RenderUploadLogo from './components/RenderUploadLogo';
import RenderUploadFiles from './components/RenderUploadFiles';
import {useUIContext} from '../../context/UIContext';
import {MAX_UPLOAD_FILES, UPLOAD_IMAGE_SIZE} from '../../constants';

const BrandsCreate: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const [createBrand,] = useCreateBrandMutation();
    // const {userData} = useGlobalContext();
    const {showSnackBar} = useUIContext();
    const [loading, setLoading] = useState(false);
    // const [getBrands, {loading, data}] = useGetBrandsForCreateLazyQuery();
    const [open, setOpen] = useState(false);
    const [files, setFiles] = useState([]);
    // const fetchData = async (value: string) => {
    //     await getBrands({
    //         variables: {
    //             querySearch: value,
    //             ownerId: userData?.id
    //         }
    //     })
    // }
    // useEffect(() => {
    //     (async () => {
    //         await fetchData('')
    //     })()
    // }, []);

    return (
        <FormLayout
            crumbsDetails={[
                {message: 'Brand', link: ''},
                {message: 'Create', link: ''}
            ]}
            title={'Create brand'}
        >
            <Formik
                initialValues={{
                    name: '',
                    type: BRAND_TYPE.BRAND,
                    parentBrandId: undefined,
                    logo: [],
                    brandConfirmFiles: [],
                    additionalFileIds: '',
                    contactInfo: ''
                }}
                validationSchema={CREATE_BRAND_SCHEMA}
                onSubmit={async (values) => {
                    try {
                        const files = await Promise.all(uploadedFiles(values?.brandConfirmFiles));
                        const logo = values?.logo?.length === 1 ? await Promise.all(uploadedFiles(values?.logo)) : ['']
                        await createBrand({
                            variables: {
                                name: values.name,
                                logoPath: logo[0],
                                contactsInfo: values.contactInfo,
                                parentBrandId: values?.parentBrandId && Number(values.parentBrandId),
                                documentPaths: files
                            }
                        })
                        history.push('/brands/success-create');
                    } catch (e) {
                        showSnackBar(e?.message)
                    }
                }}
            >
                {({
                    handleSubmit,
                    values,
                    isSubmitting,
                    errors,
                    touched, setFieldValue
                }) => {
                    // console.log('values', values);
                    return (
                        <Form onSubmit={handleSubmit} className={classes.formWrapper}>
                            <FormInput
                                name={'name'}
                                label={values.type === BRAND_TYPE.SUB_BRAND ? 'Sub-brand name' : 'Brand name'}
                                labelWidth={values.type === BRAND_TYPE.SUB_BRAND ? 125 : 90}
                                formControlProps={{
                                    className: classes.nameInput
                                }}
                            />
                            {/*<Field name={'type'}>*/}
                            {/*    {({field, form, meta}) => {*/}
                            {/*        return (*/}
                            {/*            <FormikRadioGroup form={form} field={field}>*/}
                            {/*                <FormControlLabel*/}
                            {/*                    value={BRAND_TYPE.BRAND}*/}
                            {/*                    control={<Radio color={'primary'}/>}*/}
                            {/*                    label={'Brand'}*/}
                            {/*                />*/}
                            {/*                <FormControlLabel*/}
                            {/*                    value={BRAND_TYPE.SUB_BRAND}*/}
                            {/*                    control={<Radio color={'primary'}/>}*/}
                            {/*                    label={'Sub-brand'}*/}
                            {/*                    style={{marginLeft: 20}}*/}
                            {/*                />*/}
                            {/*            </FormikRadioGroup>*/}
                            {/*        );*/}
                            {/*    }}*/}
                            {/*</Field>*/}
                            {/*{(values.type === BRAND_TYPE.SUB_BRAND) &&*/}
                            {/*    <Field name={'parentBrandId'}>*/}
                            {/*        {({meta, form}) => (*/}
                            {/*            <Autocomplete*/}
                            {/*                autoComplete*/}
                            {/*                autoSelect*/}
                            {/*                blurOnSelect={'touch'}*/}
                            {/*                className={classes.parentNameInput}*/}
                            {/*                open={open}*/}
                            {/*                loading={loading}*/}
                            {/*                onOpen={() => setOpen(true)}*/}
                            {/*                onClose={() => setOpen(false)}*/}
                            {/*                options={data?.brands?.data || []}*/}
                            {/*                getOptionSelected={(option, value) => {*/}
                            {/*                    return option.name === value.name*/}
                            {/*                }}*/}
                            {/*                onChange={(event, value) => {*/}
                            {/*                    // @ts-ignore*/}
                            {/*                    form.setFieldValue('parentBrandId', Number(value?.id));*/}
                            {/*                }}*/}
                            {/*                getOptionLabel={(option) => option?.name}*/}
                            {/*                renderInput={(params) => {*/}
                            {/*                    return (*/}
                            {/*                        <TextField*/}
                            {/*                            {...params}*/}
                            {/*                            label="Brand name"*/}
                            {/*                            variant="outlined"*/}
                            {/*                            onChange={debounced}*/}
                            {/*                            InputProps={{*/}
                            {/*                                ...params.InputProps,*/}
                            {/*                                endAdornment: (*/}
                            {/*                                    <React.Fragment>*/}
                            {/*                                        {loading ? <CircularProgress color="inherit" size={20}/> : null}*/}
                            {/*                                        {params.InputProps.endAdornment}*/}
                            {/*                                    </React.Fragment>*/}
                            {/*                                )*/}
                            {/*                            }}*/}
                            {/*                        />*/}
                            {/*                    )*/}
                            {/*                }*/}
                            {/*                }*/}
                            {/*            />*/}
                            {/*        )}*/}
                            {/*    </Field>*/}
                            {/*}*/}
                            <Box className={classes.dropzoneWrapper}>
                                <Field name={'logo'}>
                                    {({form, meta, field}: FieldProps) => (
                                        <CustomDropzone
                                            fieldProps={{form, meta, field}}
                                            style={DROPZONE_STYLE(Boolean(meta.error && meta.touched))}
                                            wrapperStyle={classes.dropzoneMargin}
                                            accept={['image/*']}
                                            multiple={false}
                                            renderContent={RenderUploadLogo}
                                            maxSize={UPLOAD_IMAGE_SIZE}
                                            maxFiles={1}
                                            onDrop={(acceptedFiles) => {
                                                if (acceptedFiles?.length === 1) {
                                                    form.setFieldValue('logo', acceptedFiles)
                                                }
                                            }}
                                        />
                                    )}
                                </Field>
                                {loading && <CircularProgress />}
                                <Field name={'brandConfirmFiles'}>
                                    {({form, meta, field}: FieldProps) => (
                                        <CustomDropzone
                                            fieldProps={{form, meta, field}}
                                            style={DROPZONE_STYLE(Boolean(meta.error && meta.touched))}
                                            wrapperStyle={classes.dropzoneMargin}
                                            renderContent={RenderUploadFiles}
                                            accept={['image/*', '.pdf', '.doc']}
                                            maxFiles={MAX_UPLOAD_FILES}
                                            onDrop={(acceptedFiles) => {
                                                console.log('acceptedFiles', acceptedFiles);
                                                setFieldValue('brandConfirmFiles', [...acceptedFiles, ...values.brandConfirmFiles])
                                            }}
                                        />
                                    )}
                                </Field>
                            </Box>
                            {/*<Typography variant={'h4'} className={classes.ownershipTitle}>Сonfirm brand*/}
                            {/*    ownership</Typography>*/}
                            {/*<Typography variant={'subtitle1'} className={classes.ownershipSubTitle}>Enter your Skype*/}
                            {/*    username or phone number. Our employee will <br/> contact you to confirm brand ownership*/}
                            {/*</Typography>*/}
                            {/*<FormInput*/}
                            {/*    name={'contactInfo'}*/}
                            {/*    label={'Skype / Phone '}*/}
                            {/*    labelWidth={120}*/}
                            {/*    formControlProps={{*/}
                            {/*        className: classes.parentNameInput*/}
                            {/*    }}*/}
                            {/*/>*/}
                            <Box className={classes.submitButtonWrapper}>
                                <CustomButton
                                    variant={'contained'}
                                    color={'primary'}
                                    type={'submit'}
                                    disabled={isSubmitting}
                                >{isSubmitting ? <MuiCircularProgress color={'primary'}/> : 'Submit'}</CustomButton>
                                <div className={classes.errorWrapper}>
                                    {errors.name && touched.name || errors.parentBrandId && touched.parentBrandId ?
                                        <div className={classes.error}>
                                            <i className={'material-icons'}>info_outline</i>
                                            <Typography
                                                variant={'subtitle1'}
                                            >{'Enter the name of the parent brand'}</Typography>
                                        </div> : null}
                                    {
                                        errors.brandConfirmFiles && touched.brandConfirmFiles ?
                                            <div className={classes.error}>
                                                <i className={'material-icons'}>info_outline</i>
                                                <Typography
                                                    variant={'subtitle1'}>{'Attach files that confirm that you are a holder of this brand '}</Typography>
                                            </div> : null
                                    }
                                </div>
                            </Box>
                        </Form>
                    )
                }}
            </Formik>
        </FormLayout>
    );
};

export default BrandsCreate;

const useStyles = makeStyles<Theme>(theme => ({
    mainSection: {
        width: '100%',
        height: '100%',
        background: COLORS.WHITE,
        padding: '32px 50px'
    },
    formWrapper: {
        display: 'flex',
        width: '100%',
        flexFlow: 'column wrap',
        alignContent: 'stretch',
    },
    nameInput: {
        maxWidth: 320,
        marginBottom: 32
    },
    parentNameInput: {
        maxWidth: 320,
        marginTop: 32
    },
    dropzoneMargin: {
        margin: '32px 0'
    },
    uploadText: {
        color: COLORS.TEXT_SILVER,
        height: '3rem'
    },
    uploadInfo: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        marginTop: 12,
    },
    chipsWrapper: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: 81,
        flexWrap: 'wrap',
        overflowY: 'scroll'
    },
    uploadImg: {
        height: 81,
        width: 'auto',
        marginRight: 8
    },
    uploadLimits: {
        fontSize: 12,
        color: COLORS.TEXT_SILVER
    },
    uploadButton: {
        alignSelf: 'flex-start',
        marginTop: 12
    },
    ownershipTitle: {
        marginBottom: 8,
        lineHeight: 1.8
    },
    ownershipSubTitle: {
        color: COLORS.TEXT_SILVER
    },
    dropzoneWrapper: {
        position: 'relative',
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: 30,
        minHeight: 200,
    },
    submitButtonWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 40
    },
    errorWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginRight: 'auto',
    },
    error: {
        marginLeft: 20,
        display: 'flex',
        flexDirection: 'row',
        '&>i': {
            fontSize: 18,
            color: COLORS.TEXT_SILVER,
            alignSelf: 'center',
            marginRight: 5,
        }
    },
    previewLogo: {
        height: 81,
        width: 81,
        borderRadius: 100,
        marginRight: 8
    }

}));
