import React, {useCallback, useEffect, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Checkbox, IconButton, makeStyles} from '@material-ui/core';
import TwoSectionLayout from '../../layouts/TwoSectionLayout';
import {ProductsSearchType, useDeleteProductMutation, useGetProductsQuery} from '../../generated/graphql';
import {useGlobalContext} from '../../context/globalContext';
import FailedToLoad from '../../components/FailedToLoad';
import {DeleteOutline, LocationOnOutlined} from '@material-ui/icons';
import EmptyPage, {EmptyPageType} from '../../components/EmptyPage';
import CheckBoxTable from '../../components/ui/Tables/CheckBoxTable';
import {PRODUCTS_HEAD} from '../../constants/tableColumns';
import {useSelectRow} from '../../helpers/hooks';
import TableSkeleton from '../../components/ui/Tables/TableSkeleton';
import CustomTableRow from '../../components/ui/Tables/TableRow';
import MuiTableCell from '../../components/ui/Tables/TableCell';
import {isSelected} from '../../helpers';
import {useHistory} from 'react-router-dom';
import DeleteDialog from '../../components/ui/Dialogs/DialogDelete';
import {useUIContext} from '../../context/UIContext';
import {MESSAGES} from '../../constants/messages';
import ProductDetails from './components/ProductDetails';
import {ProductType} from '../../types/enum';
import {array} from 'yup';
import TooltipCell from '../../components/ui/Tables/TooltipCell';

const ProductPage: React.FC = (props): JSX.Element => {
    const classes = useStyles();
    const {rowsPerPage, userData} = useGlobalContext();
    const {showSnackBar} = useUIContext();
    const [skip, setSkip] = useState<number>(0);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const history = useHistory();
    const [id, setId] = useState(null);

    const {data, loading, error, refetch, fetchMore, previousData} = useGetProductsQuery({
        variables: {
            ownerId: userData?.id,
            take: +rowsPerPage,
            skip: skip,
            searchType: ProductsSearchType.ProductsOnly,
            blockchainAddress: userData?.blockchainAddress
        }
    })
    const [selected, handleClick, selectAllClick, setSelected] = useSelectRow([]);
    const [deleteProducts] = useDeleteProductMutation();
    useEffect(() => {
        if (data?.products?.data?.length > 0) {
            setId(data?.products?.data[0]?.id)
        }
    }, [data]);
    // const [deleteProduct] = useDeleteProductMutation();
    const goToLocation = (id: number): void => history.push(`/products/location/${id}`)
    const showDeleteDialog = useCallback((event, id) => {
        event.stopPropagation();
        setDeleteId(id);
    }, [deleteId]);
    const deleteProductHandler = async () => {
        try {
            const {data} = await deleteProducts({
                variables: {
                    // @ts-ignore
                    ids: deleteId ? [deleteId] : selected?.map(item => item?.id)
                }
            })
            if (data?.productsDelete) {
                setSelected((prevState) => deleteId ? prevState?.filter(item => item?.id !== deleteId) : [])
                showSnackBar(selected?.length >= 2 ? MESSAGES.PRODUCTS_DELETE : MESSAGES.PRODUCT_DELETE)
            }
        } catch (e) {
            showSnackBar(e?.message)
        } finally {
            setDeleteId(null)
            await refetch()
        }
    }
    if (error) {
        return <FailedToLoad refetch={refetch}/>
    }
    if (data?.products?.data?.length === 0 && !loading) {
        return <EmptyPage type={EmptyPageType.Products}/>
    }
    return (
        <TwoSectionLayout
            first={2}
            second={1}
        >
            <CheckBoxTable
                headCells={PRODUCTS_HEAD}
                data={data?.products?.data}
                selected={selected}
                totalCount={data?.products?.total}
                setSkip={setSkip}
                selectAllClick={selectAllClick}
                tablePaginationProps={{
                    rowsPerPageOptions: [10, 25, 50],
                    fetchMore: fetchMore,
                    labelDisplayedRows: () => {
                        return `${skip}-${(data?.products?.data?.length || 0) + skip} of ${data?.products?.total || 0}`;
                    }
                }}
            >
                {loading && !previousData ? <TableSkeleton cell={6}/>
                    : data?.products?.data?.map((item, index) => {
                        const isItemSelected = isSelected(item.id, selected);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                            <CustomTableRow
                                hover
                                key={item?.id}
                                // @ts-ignore
                                onClick={(event) => handleClick(event, item)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                selected={isItemSelected}

                            >
                                <MuiTableCell padding="checkbox">
                                    <Checkbox
                                        checked={isItemSelected}
                                        inputProps={{'aria-labelledby': labelId}}
                                        color={'primary'}
                                    />
                                </MuiTableCell>
                                <TooltipCell
                                    label={item?.name}
                                    value={item?.name}
                                />
                                <TooltipCell
                                    label={item?.brand?.name}
                                    value={item?.brand?.name}
                                />
                                <TooltipCell
                                    label={item?.balance}
                                    value={item?.balance}
                                    align={'right'}
                                />
                                <MuiTableCell align="center" width={'auto'}>
                                    <IconButton
                                        disabled={true}
                                        onClick={() => goToLocation(item?.id)}
                                    >
                                        <LocationOnOutlined fontSize={'small'}/>
                                    </IconButton>
                                    <IconButton
                                        onClick={event => showDeleteDialog(event, item?.id)}
                                    >
                                        <DeleteOutline fontSize={'small'}/>
                                    </IconButton>
                                </MuiTableCell>
                            </CustomTableRow>
                        )
                    })
                }
            </CheckBoxTable>
            <ProductDetails
                selectedItem={selected?.length > 0 ? selected[0]?.id : id}
                selected={selected}
                setSelected={setSelected}
                type={ProductType.product}
                dataRefetch={refetch}
                deleteProducts={deleteProductHandler}
            />
            {deleteId &&
            <DeleteDialog
                open={Boolean(deleteId)}
                title={'Delete?'}
                subtitle={'This record will be permanently deleted. You can’t undo this action'}
                onClose={() => setDeleteId(null)}
                action={deleteProductHandler}
            />
            }
        </TwoSectionLayout>
    );
};

export default ProductPage;

const useStyles = makeStyles<Theme>(() => ({
    tableContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    iconButton: {
        zIndex: 2
    },
}));
