import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {IconButton, InputAdornment, makeStyles, Typography} from '@material-ui/core';
import {Form, Formik} from 'formik';
import FormBoxInput from '../ui/FormBoxInput';
import CustomButton from '../ui/Buttons/Button';
import {FORGOT_PASS_CODE_SCHEMA} from '../../constants/validation_schema';
import {useLocation} from 'react-router-dom';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import {useUIContext} from '../../context/UIContext';
import {MESSAGES} from '../../constants/messages';
import {useLocalStorage} from '../../helpers/hooks';
import {LOCAL_STORAGE_KEYS} from '../../constants';
import {useResetPasswordMutation} from '../../generated/graphql';
import MuiCircularProgress from '../ui/CircularProgress';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

const SendCodeForm: React.FC = (props): JSX.Element => {
    const classes = useStyles();
    const location = useLocation();
    // @ts-ignore
    const {email} = location?.state;
    const {showSnackBar} = useUIContext();
    const [, setToken] = useLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, null);
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword(prevState => !prevState);
    const [resetPassword] = useResetPasswordMutation();
    return (
        <Formik
            initialValues={{
                emailCode: '',
                password: '',
                passwordConfirm: ''
            }}
            validationSchema={FORGOT_PASS_CODE_SCHEMA}
            onSubmit={async (values) => {
                try {
                    const {data} = await resetPassword({
                        variables: {
                            email: email,
                            emailCode: values.emailCode,
                            newPassword: values.passwordConfirm
                        }
                    })
                    if (data?.resetPassword?.token) {
                        setToken(data?.resetPassword?.token);
                        window.location.reload()
                    }
                    showSnackBar(MESSAGES.PASS_CHANGE)
                } catch (e) {
                    showSnackBar(e?.message)
                }
            }}
        >
            {({handleSubmit, isSubmitting, isValid, values, setFieldValue}) => (
                <Form onSubmit={handleSubmit}>
                    <Typography variant={'h2'} className={classes.title}>Reset Password?</Typography>
                    <Typography variant={'subtitle1'} className={classes.subTitle}>
                        We pass code to your email.
                        Please check email and enter code and choose your new password.
                    </Typography>
                    <FormBoxInput
                        fullWidth
                        name={'emailCode'}
                        icon={<VpnKeyOutlinedIcon/>}
                        label={'Code'}
                        labelWidth={42}
                        formControlProps={{
                            className: classes.input
                        }}
                    />
                    <FormBoxInput
                        fullWidth
                        name={'password'}
                        icon={<VpnKeyOutlinedIcon/>}
                        label={'Password'}
                        labelWidth={70}
                        formControlProps={{
                            className: classes.input
                        }}
                        type={!showPassword ? 'password' : 'text'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={toggleShowPassword}
                                >
                                    {!showPassword ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormBoxInput
                        fullWidth
                        name={'passwordConfirm'}
                        icon={<VpnKeyOutlinedIcon/>}
                        label={'Confirm password'}
                        labelWidth={135}
                        formControlProps={{
                            className: classes.input
                        }}
                        type={!showPassword ? 'password' : 'text'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={toggleShowPassword}
                                >
                                    {!showPassword ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <CustomButton
                        type={'submit'}
                        className={classes.submitBtn}
                        disabled={!isValid || isSubmitting}
                    >{(isSubmitting) ? <MuiCircularProgress /> : 'Save new password'}</CustomButton>
                </Form>
            )}
        </Formik>
    );
};

export default SendCodeForm;

const useStyles = makeStyles<Theme>(() => ({
    title: {
        marginBottom: 21,
        textAlign: 'start'
    },
    subTitle: {
        marginBottom: 25,
        textAlign: 'start'
    },
    submitBtn: {
        height: 62,
        width: '100%',
        background: '#276EF9',
        borderRadius: '4px',
        color: 'white',
        marginTop: 40,
        '&:hover': {
            background: '#276EF9'
        },
        '&:disabled': {
            background: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.38)'
        }
    },
    input: {
        marginBottom: 16
    }
}));
