import React, {useMemo, useState, useEffect} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import CustomPaper from '../../components/ui/Paper';
import CustomButton from "../../components/ui/Buttons/Button";
import EmptyInvoicePageImage from '../../assets/empty-invoice-page.svg'
import {COLORS} from "../../constants/colors";
import CreateInvoice from "./components/CreateInvoice/CreateInvoice";
import {useUIContext} from "../../context/UIContext";
import {ReactSVG} from "react-svg";
import AddInvoice from "../../assets/icons/addInvoice.svg";
import {useGlobalContext} from "../../context/globalContext";
import DialogInvoiceData from "../../components/ui/Dialogs/DialogInvoiceData";


const EmptyInvoicePage: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const {setOpenCreateInvoice} = useUIContext();
    const {userData} = useGlobalContext();
    const [openDialogInvoiceData, setOpenDialogInvoiceData] = useState<boolean>(false);

    return (
        <CustomPaper className={classes.emptyPage}>
            <img src={EmptyInvoicePageImage} alt={'empty-img'} className={classes.img}/>
            <Typography variant={'subtitle2'} className={classes.title}>You have no invoices yet</Typography>
            <Typography variant={'subtitle1'} className={classes.subtitle}>Create your first invoice</Typography>
            <Box className={classes.buttonWrapper}>
                <CustomButton
                    variant={'contained'}
                    className={classes.button}
                    onClick={() => {
                        if (
                            userData?.fullName &&
                            userData?.streetAddress &&
                            userData?.cityState &&
                            userData?.zipCode
                            // userData?.phoneNumber &&
                            // userData?.email
                        ) {
                            setOpenCreateInvoice(true);
                            return;
                        }
                        setOpenDialogInvoiceData(true);
                    }}
                ><ReactSVG src={AddInvoice}/><span>Create invoice</span>
                </CustomButton>
            </Box>
            <CreateInvoice/>
            {openDialogInvoiceData && <DialogInvoiceData
                open={openDialogInvoiceData}
                setOpenDialogInvoiceData={setOpenDialogInvoiceData}
                title={'Fill invoice data'}
                onClose={() => setOpenDialogInvoiceData(false)}
            />}
        </CustomPaper>
    );
};

export default EmptyInvoicePage;

const useStyles = makeStyles<Theme>((theme) => ({
    emptyPage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column',
        width: '100%',
        height: '100%',
        background: 'white',
        '&>*': {
            margin: '0 7.5px'
        }
    },
    buttonWrapper: {
        display: 'flex'
    },
    title: {
        textAlign: 'center',
        lineHeight: '24px',
        margin: '16px 0 8px'
    },
    subtitle: {
        color: COLORS.TEXT_SILVER,
        textAlign: 'center',
        marginBottom: 24,
        maxWidth: 360,
        margin: '0 auto',
        lineHeight: '24px'
    },
    img: {
        alignSelf: 'center'
    },
    button: {
        height: 56,
        margin: '0 8px',
        padding: '16px 32px',
        '& > span > div > div > svg': {
            display: 'block',
            marginRight: 8
        }
    }
}));
