import React, {ChangeEvent, useEffect, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {
    Box,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
    TextField,
    Typography
} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import TransactionStatus from './TransactionStatus';
import {useFinanceContext} from '../../../context/financeContext';
import _ from 'lodash'
import {transformDate} from '../../../helpers';

const TransactionHistory: React.FC = (props): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const {financeQuery: {data}} = useFinanceContext();
    const [value, setValue] = useState('');
    const [activeInput, setActiveInput] = useState(false);
    const navigateTransactionInfo = (data: any) => {
        history.push(`/finances/transaction`, data);
    };
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => setValue(event?.target?.value);
    const toggleShowInput = () => setActiveInput(prevState => !prevState);
    return (
        <Box className={classes.TransactionHistory}>
            {/*<Box className={classes.TransactionsSearch}>*/}
            {/*    {activeInput*/}
            {/*        ? <TextField*/}
            {/*            autoFocus*/}
            {/*            value={value}*/}
            {/*            onChange={handleChange}*/}
            {/*            fullWidth*/}
            {/*            placeholder={'Search'}*/}
            {/*        />*/}
            {/*        : <Typography variant={'h4'} className={classes.Title}>Recent transactions</Typography>}*/}
            {/*    <IconButton*/}
            {/*        className={classes.SearchIcon}*/}
            {/*        onClick={toggleShowInput}*/}
            {/*    >*/}
            {/*        <SearchIcon/>*/}
            {/*    </IconButton>*/}
            {/*    /!*<IconButton*!/*/}
            {/*    /!*    size={'small'}*!/*/}
            {/*    /!*    disabled*!/*/}
            {/*    /!*>*!/*/}
            {/*    /!*    <FilterListIcon />*!/*/}
            {/*    /!*</IconButton>*!/*/}
            {/*</Box>*/}
            <Box className={classes.ListWrapper}>
                {data?.whoami?.billingTransactions && data?.whoami?.billingTransactions?.length > 0 &&
                Object.keys(_.groupBy(data.whoami.billingTransactions?.slice()?.reverse(), 'createdAt')).map(item =>
                    (
                        <List
                            key={item}
                            subheader={<Typography
                                className={classes.SubheaderText}>{transformDate(item, false)}</Typography>}
                            classes={{
                                root: classes.TransactionList,
                                subheader: classes.Subheader
                            }}
                        >
                            {_.groupBy(data?.whoami?.billingTransactions, 'createdAt')[item]?.map(transaction =>
                                (
                                    <ListItem
                                        key={transaction.id}
                                        classes={{button: classes.ListItemButton}}
                                        // button
                                        disableGutters
                                        // onClick={() => navigateTransactionInfo(transaction.id)}
                                    >
                                        <ListItemIcon>
                                            <TransactionStatus
                                                incoming={transaction?.incoming}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={transaction?.incoming ? 'incoming' : 'outcoming'}
                                            secondary={transaction?.incoming ? 'Payment' : 'Service'}
                                        />
                                        <ListItemSecondaryAction>
                                            <Typography
                                                variant={'h5'}
                                                align={'right'}
                                                style={{color: transaction?.incoming ? '#259D58' : '#DC3030'}}
                                            >
                                                {`${transaction?.incoming ? '+' : '-'}$${transaction.amount / 100}`}
                                            </Typography>
                                            <Typography variant={'subtitle1'} align={'right'}>
                                                {transformDate(item)}
                                            </Typography>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                ))}
                        </List>
                    ))
                }
            </Box>
            <Box className={classes.Transparent}></Box>
        </Box>
    );
};

export default TransactionHistory;

const useStyles = makeStyles<Theme>((theme) => ({
    TransactionHistory: {
        overflow: 'auto',
        height: 'calc(100vh - 450px)'
    },
    TransactionsSearch: {
        padding: '32px 32px 5px 32px',
        display: 'flex',
        flexDirection: 'row'
    },
    Title: {
        alignSelf: 'center'
    },
    SearchIcon: {
        marginLeft: 'auto'
    },
    ListWrapper: {
    },
    TransactionList: {
        padding: '0 33px 8px 50px'
    },
    SubheaderText: {
        fontWeight: 'normal',
        fontSize: 14,
        color: 'rgba(0, 0, 0, 0.6)',
        paddingBottom: theme.spacing(1)
    },
    Subheader: {
        paddingBottom: theme.spacing(2),
        paddingTop: theme.spacing(2)
    },
    ListItemButton: {
        borderRadius: 8,
        zIndex: 2,
        '&:hover': {
            backgroundColor: 'rgba(39, 110, 249, 0.08)'
        }
    }
}));
