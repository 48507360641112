import React, {useContext, useState} from 'react';
import {IconButton, Snackbar, SnackbarProps} from '@material-ui/core';
import {useMergeState} from '../helpers/hooks';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {useLocation} from 'react-router-dom';
import DialogEndActionType from '../components/ui/Dialogs/DialogEndActionType';
import {CONTACT_MAIL} from '../constants';
import {END_ACTION_TYPE} from '../types/type';
import DialogUploadFeed from '../components/ui/Dialogs/DialogUploadFeed';
import MuiSnackbar from '../components/ui/Snackbar';
import DialogUnconfirmedBrand from '../components/ui/Dialogs/DialogUnconfirmedBrand';

const INITIAL_VALUES = {
    snackBar: {
        open: false,
        message: 'Copy to clipboard',
    },
    endAction: {
        open: false,
        message: `Something went wrong... Please try again or \n contact ${CONTACT_MAIL}`,
    },
    mergeSnackBar: null,
    showSnackBar: null,
    hideSnackBar: null,
    showHeader: true,
    setShowHeader: null,
    endActionType: null,
    showEndAction: null,
    setUploadFeed: null,
    unconfirmedWarning: null,
    setUnconfirmedWarning: null,
    openCreateInvoice: false,
    setOpenCreateInvoice: null
}
type UIContextType = {
    snackBar: SnackbarProps,
    mergeSnackBar: React.Dispatch<React.SetStateAction<SnackbarProps>>,
    showSnackBar: (message: string) => void
    hideSnackBar: () => void,
    showHeader: boolean,
    setShowHeader: React.Dispatch<React.SetStateAction<boolean>>,
    openCreateInvoice: boolean,
    setOpenCreateInvoice: React.Dispatch<React.SetStateAction<boolean>>,
    showEndAction: (message: string, type?: END_ACTION_TYPE) => void,
    setUploadFeed: (newState: boolean) => void,
    setUnconfirmedWarning: React.Dispatch<React.SetStateAction<unconfirmedBrandModalType>>
}
type unconfirmedBrandModalType = {
    open: boolean,
    brandNames: null | string[]
}
const UIContext = React.createContext<UIContextType>(INITIAL_VALUES);

const useUIContext = () => {
    return useContext(UIContext);
};

function UIContextProvider({children}) {
    const [openCreateInvoice, setOpenCreateInvoice] = useState<boolean>(INITIAL_VALUES.openCreateInvoice);
    const [showHeader, setShowHeader] = useState<boolean>(INITIAL_VALUES.showHeader);
    const [snackBar, mergeSnackBar] = useMergeState(INITIAL_VALUES.snackBar);
    const [endAction, setEndAction] = useMergeState({
        ...INITIAL_VALUES.endAction,
        onClose: () => setEndAction({...INITIAL_VALUES.endAction, open: false})
    });
    const [unconfirmedWarning, setUnconfirmedWarning] = useState(INITIAL_VALUES.setUnconfirmedWarning);
    const [uploadFeed, setUploadFeed] = useState(false);
    const closeUploadFeed = () => setUploadFeed(false);
    const showSnackBar = (message: string): void => {
        mergeSnackBar({
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
            },
            autoHideDuration: 4000,
            open: true,
            message
        })
    }
    const showEndAction = (message: string, type: END_ACTION_TYPE = END_ACTION_TYPE.ERROR) => {
        setEndAction({
            open: true,
            message,
            type
        })
    }
    const hideSnackBar = () => mergeSnackBar(INITIAL_VALUES.snackBar)

    return (
        <UIContext.Provider value={{
            snackBar,
            mergeSnackBar,
            showSnackBar,
            hideSnackBar,
            showHeader,
            setShowHeader,
            showEndAction,
            setUploadFeed,
            setUnconfirmedWarning,
            openCreateInvoice,
            setOpenCreateInvoice
        }}>
            {children}
            {snackBar?.open &&
            <MuiSnackbar
                onClose={hideSnackBar}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        // className={classes.close}
                        size={'small'}
                        onClick={hideSnackBar}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                }
                {...snackBar}
            />
            }
            {endAction.open && <DialogEndActionType {...endAction}/>}
            {uploadFeed &&
                <DialogUploadFeed
                    maxWidth={false}
                    open={uploadFeed}
                    onClose={closeUploadFeed}
                />
            }
            {unconfirmedWarning?.open &&
            <DialogUnconfirmedBrand
                open={unconfirmedWarning?.open}
                width={773}
                brandNames={unconfirmedWarning?.brandNames}
                maxWidth={false}
                onClose={() => setUnconfirmedWarning(INITIAL_VALUES.setUnconfirmedWarning)}
            />
            }
        </UIContext.Provider>
    );
}

export {UIContextProvider, useUIContext}

