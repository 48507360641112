import {
    CONSIGNMENT_SELECT_HEAD,
    CONSIGNMENT_SEND_HEAD,
    PRODUCT_SELECT_HEAD,
    PRODUCT_SEND_HEAD
} from '../../../constants/tableColumns';
import {MESSAGES} from '../../../constants/messages';

const CREATE_DROPZONE_STYLE = {
    padding: '32px 40px',
    border: '2px dashed rgba(0, 0, 0, 0.12)',
    width: '100%',
    borderRadius: 4,
    margin: '16px 0 32px 0',
}

const PRODUCT_MULTI_SEND_STATE = {
    title: 'Products',
    link: '/products',
    listTitle: 'Product list',
    selectTableHead: PRODUCT_SELECT_HEAD,
    sendTableHead: PRODUCT_SEND_HEAD,
    message: MESSAGES.PRODUCTS_SEND
}

const CONSIGNMENT_MULTI_SEND_STATE = {
    title: 'Consignments',
    link: '/consignments',
    listTitle: 'Consignment list',
    selectTableHead: CONSIGNMENT_SELECT_HEAD,
    sendTableHead: CONSIGNMENT_SEND_HEAD,
    message: MESSAGES.CONSIGNMENTS_SEND
}
export {
    CREATE_DROPZONE_STYLE,
    PRODUCT_MULTI_SEND_STATE,
    CONSIGNMENT_MULTI_SEND_STATE
}
