import React, {useEffect, useMemo} from 'react'
import {createTheme, CssBaseline, ThemeProvider} from '@material-ui/core';
import {useGlobalContext} from '../context/globalContext';

const MyThemeProvider: React.FC = (props): JSX.Element => {
    const {children} = props;
    const {darkTheme, setDarkTheme} = useGlobalContext();

    useEffect(() => {
        const darkMode = localStorage.getItem("darkMode");
        if (darkMode) {
            setDarkTheme(true)
        } else {
            setDarkTheme(false)
        }
    }, []);
    const themeColors = useMemo(() => {
        return ({
            primaryMainColor: !darkTheme ? '#276EF9' : '#276EF9',
            typographyColor: !darkTheme ? 'rgba(0, 0, 0, 0.87)' : '#FFFFFF',
        })
    }, [darkTheme]);

    const theme = React.useMemo(
        () =>
            createTheme({
                palette: {
                    type: darkTheme ? 'dark' : 'light',
                    primary: {
                        main: !darkTheme ? '#276EF9' : 'rgb(49, 49, 49)',
                    },
                    secondary: {
                        main: '#DC3030'
                    },
                    // error: {
                    //     main: '#DC3030'
                    // },
                    text: {
                        secondary: 'rgba(0, 0, 0, 0.6)'
                    },
                },
                typography: {
                    fontFamily: '"Inter", \'sans-serif\'',
                    fontSize: 14,
                    fontWeightLight: 300,
                    fontWeightRegular: 400,
                    fontWeightMedium: 500,
                    allVariants: {
                        color: themeColors.typographyColor
                    },
                    h1: {
                        fontWeight: 500,
                        fontSize: 64,
                        letterSpacing: -0.5
                    },
                    h2: {
                        fontSize: 40,
                        fontWeight: 500,
                    },
                    h3: {
                        fontSize: 32,
                        fontWeight: 'normal'
                    },
                    h4: {
                        fontWeight: 400,
                        fontSize: 24
                    },
                    h5: {
                        fontWeight: 400,
                        fontSize: 16
                    },
                    subtitle1: {
                        fontSize: 14,
                    },
                    subtitle2: {
                        fontWeight: 500,
                        fontSize: 16
                    },
                    caption: {
                        fontSize: 12,
                        letterSpacing: 2,
                        textTransform: 'uppercase',
                    }
                },
                overrides: {
                    MuiButton: {
                        label: {
                            textTransform: 'none',
                            fontFamily: 'Inter',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: 16,
                        },
                    }
                }
            }),
        [darkTheme],
    );

    return (
        <ThemeProvider theme={theme}>
            {children}
            <CssBaseline/>
        </ThemeProvider>
    )
};

export default MyThemeProvider

