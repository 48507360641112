import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box, CircularProgress,
    LinearProgress,
    makeStyles,
    Typography
} from '@material-ui/core';
import {COLORS} from '../../../constants/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {ActionsType} from '../../../types/enum';
import RenderActionIcon from '../components/RenderActionIcon';
import ActionChip from './ActionChip';
import SuccessSVG from '../images/success.svg';

type DropdownProgressProps = {
    type: ActionsType,
    title: string,
    totalCount: number,
    completeCount: number
}

const DropdownProgress: React.FC<DropdownProgressProps> = (props: DropdownProgressProps): JSX.Element => {
    const [expanded, setExpanded] = useState(false);
    const classes = useStyles({expanded});
    const {type, title, totalCount, completeCount} = props;
    const [progress, setProgress] = React.useState(0);
    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    return 0;
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, 100);
            });
        }, 500);
        return () => {
            clearInterval(timer);
        };
    }, []);
    const pastAction = () => {
        switch (type) {
            case ActionsType.creating: {
                return 'created'
            }
            case ActionsType.sending: {
                return 'sended'
            }
            case ActionsType.deleting: {
                return 'deleted'
            }
        }
    }

    return (
        <Accordion
            square
            expanded={expanded}
            onChange={() => setExpanded(prevState => !prevState)}
            classes={{
                root: classes.Accordion,
                expanded: classes.expandedAccordion
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel2a-content"
                id="panel2a-header"
                classes={{
                    root: classes.AccordionSummary,
                    expanded: classes.AccordionTopMargin,
                    expandIcon: classes.expandIconWrapper,
                    content: classes.AccordionSummaryContent
                }}
            >
                <Box className={classes.content}>
                    <RenderActionIcon type={type}/>
                    <Box className={classes.actionInfoWrapper}>
                        <Box className={classes.header}>
                            <ActionChip type={type} />
                            <Typography variant={'subtitle2'} className={classes.title}>
                                {title}
                            </Typography>
                        </Box>
                        <Typography className={classes.progressTitle}>{totalCount} products • {completeCount} {pastAction()}</Typography>
                        <LinearProgress
                            variant={'buffer'}
                            value={progress}
                            classes={{
                                root: classes.bottomProgress,
                                colorPrimary: classes.bottomColorPrimary,
                                bar1Buffer: classes.bottomBar1Buffer
                            }}
                        />
                    </Box>
                </Box>
                <LinearProgress
                    variant={'buffer'}
                    value={progress}
                    classes={{
                        root: classes.linearProgress,
                        colorPrimary: classes.colorPrimary,
                        bar1Buffer: classes.bar1Buffer
                    }}
                />
                <div className={classes.rightSection}/>
            </AccordionSummary>
            <AccordionDetails
                classes={{
                    root: classes.AccordionDetails
                }}
            >
                <Box className={classes.productListWrapper}>
                    {Array.from({length: 10}, () => Math.random()).map((item, _) => {
                        return <Box className={classes.productItem} key={_}>
                            {progress === 100 ? <img src={SuccessSVG} alt="SuccessSVG"/> : <CircularProgress variant="determinate" size={24} value={progress} />}

                            <Typography variant={'subtitle1'} className={classes.productName}>Product name or feed</Typography>
                        </Box>
                    })}
                </Box>

            </AccordionDetails>
        </Accordion>
    );
};

export default DropdownProgress;

const useStyles = makeStyles<Theme, {expanded: boolean}>(() => ({
    Accordion: {
        backgroundColor: COLORS.PRIMARY_BACKGROUND,
        maxWidth: 850,
        marginBottom: 32,
        minHeight: 100,
        border: 0,
        boxShadow: 'none',
        borderRadius: 16,
        paddingBottom: 0,
        '&:before': {
            backgroundColor: 'transparent'
        }
    },
    AccordionSummary: {
        height: 100,
        width: '100%',
        backgroundColor: 'transparent',
        padding: '16px 16px 24px 16px',
        borderRadius: 16,
        position: 'relative'
    },
    AccordionDetails: {
        backgroundColor: COLORS.WHITE,
        borderBottomLeftRadius: 16,
        borderBottomRightRadius: 16,
        padding: 0,
        display: 'flex',
        flexDirection: 'column'
    },
    linearProgress: {
        position: 'absolute',
        left: 56,
        right: 52,
        top: 0,
        bottom: 0,
        height: '100%'
    },
    colorPrimary: {
        backgroundColor: COLORS.ROW_BACKGROUND
    },
    AccordionSummaryContent: {
        margin: 0,
        height: '100%',

    },
    bar1Buffer: {
        backgroundColor: COLORS.PRIMARY_BACKGROUND,
        zIndex: 1
    },
    expandIconWrapper: {
        transition: 'none',
        zIndex: 3,
    },
    content: {
        zIndex: 3,
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
    },
    actionInfoWrapper: {
        marginLeft: 16,
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
    },
    header: {
        display: 'flex'
    },
    title: {
        marginLeft: 16
    },
    progressTitle: {
        fontSize: 12,
        color: COLORS.TEXT_SILVER
    },
    bottomProgress: {
        zIndex: 3
    },
    bottomColorPrimary: {

    },
    bottomBar1Buffer: {

    },
    rightSection: {
        position: 'absolute',
        height: '100%',
        width: 52,
        backgroundColor: COLORS.ROW_BACKGROUND,
        top: 0,
        right: 0,
        bottom: 0,
        borderTopRightRadius: 16,
        borderBottomRightRadius: props => props.expanded ? 0 : 16,
    },
    expandedAccordion: {
        boxShadow: '0px 10px 75px rgba(4, 59, 140, 0.19)',
    },
    AccordionTopMargin: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    productListWrapper: {
        padding: '16px 16px 24px 56px'
    },
    productItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 16
    },
    productName: {
        marginLeft: 16
    }
}));
