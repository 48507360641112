import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Button, ButtonProps, makeStyles} from '@material-ui/core';
import clsx from 'clsx';

type SquareButton = ButtonProps & {
    materialIcon: string
}

const SquareButton: React.FC<SquareButton> = (props: SquareButton): JSX.Element => {
    const classes = useStyles();
    const {materialIcon, className,  ...rest} = props;
    return (
        <Button
            className={clsx(
                classes.headerBtn,
                className
            )}
            {...rest}
        >
            <i className={'material-icons'}>{materialIcon}</i>
        </Button>
    );
};

export default SquareButton;

const useStyles = makeStyles<Theme>(() => ({
    headerBtn: {
        height: 56,
        width: 56,
        background: 'rgba(39, 110, 249, 0.12)',
        borderRadius: '15px 5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        outline: 'none',
        border: 'none',
        marginLeft: 15,
        '&:hover': {
            background: 'rgba(39, 110, 249, 0.12)'
        },
        '& i': {
            color: '#276EF9'
        },
        '&:disabled': {
            background: 'rgba(0, 0, 0, 0.04)',
            '& i': {
                color: 'rgba(0, 0, 0, 0.38)'
            }
        }
    },
}));

