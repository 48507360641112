import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, Table, TableBody, TableBodyProps, TableContainer, TableProps} from '@material-ui/core';
import CustomTableHead, {CustomTableHeadProps} from './TableHead';
import {useGlobalContext} from '../../../context/globalContext';
import CustomTablePagination, {CustomTablePaginationProps} from './TablePagination';

import clsx from 'clsx';

type MainTable = TableProps & {
    fetchMore: any;
    skip: number;
    setSkip: React.Dispatch<React.SetStateAction<number>>;
    tableHeadProps: CustomTableHeadProps;
    totalCount: number;
    tableBodyProps?: TableBodyProps;
    tablePaginationProps?: CustomTablePaginationProps;
}

const MainTable: React.FC<MainTable> = (props: MainTable): JSX.Element => {
    const classes = useStyles();
    const {fetchMore, skip, setSkip, tableHeadProps, tableBodyProps, totalCount, tablePaginationProps, ...rest} = props;

    return (
        <TableContainer
            className={clsx(
                classes.tableContainer
            )}
        >
            <Table
                size={'small'}
                stickyHeader
                classes={{
                    root: classes.table
                }}
                {...rest}
            >
                <CustomTableHead {...tableHeadProps}/>
                <TableBody
                    classes={{root: classes.body}}
                    {...tableBodyProps}
                >
                    {props.children}
                </TableBody>
            </Table>
            <CustomTablePagination
                className={classes.TablePagination}
                setSkip={setSkip}
                fetchMore={fetchMore}
                totalCount={totalCount}
                {...tablePaginationProps}
            />
        </TableContainer>
    );
};

export default MainTable;

const useStyles = makeStyles<Theme>(() => ({
    tableContainer: {
        flex: '1 1 auto',
        overflowY: 'scroll',
        height: 0,
        borderRadius: 8
    },
    body: {
        overflowY: 'scroll'
    },
    paginationWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 24px',
        width: '100%',
        '& span': {
            // color: theme.palette.secondary.contrastText
        }
    },
    TablePagination: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8
    },
    table: {
        paddingBottom: 52,
    }
}));
