import React, {FC} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {ListItem, ListItemIcon, ListItemText, Typography} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import List from '@material-ui/core/List';
import Button from '../../../components/ui/Buttons/Button';
import {COLORS} from '../../../constants/colors';
import clsx from 'clsx';

type PlanCardProps = {
    item: PlanItem,
    setShowForm: (newState) => void
}
export type PlanItem = {
    type: 'Small' | 'Popular' | 'Pro' | 'Essential';
    price: number | 'Individual';
    period?: 'Month' | 'Year';
    description?: string;
    offer?: string[];
    isPopular?: boolean;
}

const PlanCard:FC<PlanCardProps> = ({item, setShowForm}: PlanCardProps): JSX.Element => {
    const classes = useStyles();
    const {type, price, period, description, offer, isPopular} = item;
    const showFormHandler = () => {
        if (price === 'Individual') {
            setShowForm(true);
        }
    };
    return (
        <div className={clsx(classes.PlanCard,{[classes.PopularPlanCard]: isPopular})}>
            {isPopular && <div className={classes.Tape}><Typography className={classes.PopularText}>Popular</Typography></div>}
            <Typography
                variant={'h4'}
                className={isPopular && classes.ActiveText}
            >{type}</Typography>
            <div className={classes.Price}>
                <Typography
                    variant={'h2'}
                    className={isPopular && classes.ActiveText}
                >{typeof price === 'number' ? `$${price}` : price}</Typography>

                {period && <Typography
                    variant={'subtitle2'}
                    className={clsx(classes.Period, {[classes.ActiveDescText]: isPopular})}

                > / {period}</Typography>}
            </div>
            <Typography className={clsx(classes.Description,{[classes.ActiveLightText]: isPopular})}>{description}</Typography>
            {price === 'Individual'
                ? <Typography className={clsx(classes.Description,{[classes.ActiveLightText]: isPopular})}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
                    purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor
                    rhoncus dolor purus non enim
                </Typography> : <Typography className={clsx(classes.Description,{[classes.ActiveLightText]: isPopular})}>We propose for you:</Typography>}
            {offer?.map((item, index) => <List key={index} disablePadding>
                <ListItem
                    alignItems={'flex-start'}
                    disableGutters
                    className={classes.ListItem}
                >
                    <ListItemIcon
                        className={classes.ListItemIcon}
                    >
                        <CheckCircleIcon
                            className={isPopular && classes.CheckCircleActiveIcon}
                            fontSize={'small'}
                        />
                    </ListItemIcon>
                    <ListItemText
                        classes={{
                            primary: isPopular ? classes.ListActiveItemText : classes.ListItemText
                        }}
                        primary={item}
                    />
                </ListItem>
            </List>)}
            <Button
                variant={price === 'Individual' ? 'contained' : 'outlined'}
                color={'primary'}
                myiColor={item?.isPopular && COLORS.WHITE}
                onClick={() => showFormHandler()}
                style={{marginTop: 'auto'}}
            >{price === 'Individual' ? 'Contact us' : 'Choose plan'}</Button>
        </div>
    );
}

export default PlanCard;

const useStyles = makeStyles<Theme>(theme => ({
    PlanCard: {
        position: 'relative',
        width: '100%',
        height: '100%',
        padding: theme.spacing(5),
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 20,
        transition: '.15s ease-in',
        overflow: 'hidden',
        '&:hover': {
            boxShadow: '0px 18px 50px rgba(8, 42, 92, 0.21)',
            transition: '.15s ease-in'
        }
    },
    PopularPlanCard: {
        background: theme.palette.primary.main,
        color: 'white'
    },

    Price: {
        width: '100%',
        height: 60,
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        margin: '8px 0'
    },
    Period: {
        color: 'rgba(0,0,0,0.5)',
        marginLeft: theme.spacing(0) * 2
    },
    ActiveText: {
        color: 'white'
    },
    ActiveDescText: {
        color: 'white',
        fontSize: 16,
        opacity: 0.8
    },
    ActiveLightText: {
        color: 'white',
        opacity: 0.8,
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 2,
        marginBottom: 8,

    },
    Description: {
        fontWeight: 500,
        fontSize: 14,
        opacity: 0.6,
        marginBottom: 8,
        lineHeight: 2
    },
    ListActiveItemText: {
        color: COLORS.WHITE,
        fontWeight: 500,
        fontSize: 14
    },
    ListItem: {
        paddingTop: theme.spacing(0),
        paddingBottom: theme.spacing(0)
    },
    ListItemIcon: {
        alignSelf: 'center',
        marginTop: 0,
        minWidth: 30
    },
    ListItemText: {
        fontWeight: 500,
        fontSize: 14
    },
    CheckCircleActiveIcon: {
        color: 'white',
        opacity: 0.6
    },
    PopularText: {
        fontWeight: 500,
        fontSize: 12,
        lineHeight: 18,
        letterSpacing: 1,
        textTransform: 'uppercase',
        color: '#000000'
    },
    Tape: {
        position: 'absolute',
        right: '-12%',
        top: '4%',
        background: 'white',
        height: 22,
        width: 155,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
        transform: 'rotate(40.42deg)'
    }


}));
