import {ApolloClient, ApolloLink, from, HttpLink, InMemoryCache} from '@apollo/client';
import {LOCAL_STORAGE_KEYS} from '../constants';
import {onError} from '@apollo/client/link/error';
import StatusCodes from '../StatusCodes';
import {setContext} from '@apollo/client/link/context';

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
        // graphQLErrors.map(({ message, locations, path }) =>
        graphQLErrors.map((e) => {
            if (e?.extensions?.code === StatusCodes.UNAUTHORIZED.toString()) {
                localStorage.clear();
                window.location.reload();
            }
        }
        );
    if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URL,
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    const storeToken = localStorage.getItem(LOCAL_STORAGE_KEYS.STORE_TOKEN);
    if (storeToken || token) {
        return {
            headers: {
                authorization: storeToken || token,
                ...headers,
            }
        }
    } else {
        return {
            ...headers
        }
    }
});

export const client = new ApolloClient({
    connectToDevTools: true,
    cache: new InMemoryCache(),
    link: from([authLink, errorLink, httpLink]),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only'
        }
    }
});
