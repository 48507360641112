import React, {ReactChildren, ReactNode} from 'react';
import {Button, ButtonProps, makeStyles, withStyles} from '@material-ui/core';
import {Color} from '@material-ui/lab';
import {Theme} from '@material-ui/core/styles';
import {COLORS} from '../../../constants/colors';

type CustomButtonProps = ButtonProps & {
    children: ReactNode | ReactChildren;
    myiColor?: string | undefined
}

const CustomButton: React.FC<CustomButtonProps> = (props: CustomButtonProps): JSX.Element => {
    const {children, myiColor,  ...rest} = props;
    const classes = useStyles({myiColor});
    return (
        <MuiButton
            color={'primary'}
            classes={
                myiColor &&
                {
                    text: classes.text,
                    outlined: classes.outlined,
                    contained: classes.contained
                }
            }
            {...rest}
        >
            {children}
        </MuiButton>
    );
};

type useStylesProps = {
    myiColor: string
}

const useStyles = makeStyles<Theme, useStylesProps>(theme => ({
    text: {
        color: props => props.myiColor
    },
    outlined: {
        color: COLORS.WHITE,
        border: props => `1px solid ${props.myiColor}`,
        '&:hover': {
            border: props => `1px solid ${props.myiColor}`
        }
    },
    contained: {
        color: props => props.myiColor,
        backgroundColor: COLORS.WHITE,
        border: props => `1px solid ${props.myiColor}`
    }
}))

const MuiButton = withStyles(theme => ({
    root: {
        height: 44,
        padding: '0 24px',
        borderRadius: 12,
        outline: 'none',
        lineHeight: 1,
        '&:disabled': {
            cursor: 'not-allowed',
            // pointerEvents: 'all !important'
        },
    },
    text: {
        // background: 'inherit',
    },
    textPrimary: {

    },
    textSecondary: {

    },
    outlined: {
        boxShadow: 'none'
    },
    outlinedPrimary: {
        // border: `2px solid #9ECFFF`,
        boxShadow: 'none'
    },
    outlinedSecondary: {

    },

    contained: {

    },
    containedPrimary: {
        '&:active': {
            background: '#1A59ED',
        },
        '&:hover': {
            background: '#4584FF'
        },
    },
    containedSecondary: {
    },
    focusVisible: {

    },
    disabled: {

    },
    colorInherit: {

    }
}))(Button)

export default CustomButton;
