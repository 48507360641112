import React, {FC, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import DialogLayout, {DialogLayoutProps} from '../../../layouts/DialogLayout';
import CustomButton from '../Buttons/Button';
import {END_ACTION_TYPE} from '../../../types/type';
import clsx from 'clsx';
import ErrorIcon from '../../../assets/icons/error.svg';
import WarningIcon from '../../../assets/icons/warning.svg';
import SuccessIcon from '../../../assets/icons/upload-one.svg';

export type DialogEndActionTypeProps = DialogLayoutProps & {
    open: boolean
    message: string
    action: any
    type: END_ACTION_TYPE
}

const DialogEndActionType: React.FC<DialogEndActionTypeProps> = (props: DialogEndActionTypeProps): JSX.Element => {
    const classes = useStyles();

    // @ts-ignore
    const {type = END_ACTION_TYPE.ERROR, open, message, onClose, ...rest} = props;
    const [data,] = useState(() => defineData(type));
    return (
        <DialogLayout
            onClose={onClose}
            open={open}
            {...rest}
        >
            <Box className={classes.wrapper}>
                <DialogIconWrapper
                    className={data.className}
                    icon={data.icon}
                />
                <Typography variant="h4" className={classes.title}>{data.title}</Typography>
                <Typography variant="subtitle2" className={classes.subTitle}>{message}</Typography>
                <CustomButton
                    variant={'contained'}
                    color={type === END_ACTION_TYPE.ERROR ? 'secondary' : 'primary'}
                    onClick={(event) => onClose(event, 'escapeKeyDown')}
                >{data.button}</CustomButton>
            </Box>
        </DialogLayout>
    );
};

type DialogIconWrapperProps = {
    className?: string,
    icon: any
}

export const DialogIconWrapper: FC<DialogIconWrapperProps> = (props: DialogIconWrapperProps) => {
    const classes = useStyles();
    const {className, icon} = props;
    return (
        <div className={clsx(
            classes.IconWrap,
            classes[className]
        )}>
            <img src={icon} alt="type"/>
        </div>
    );
}
const defineData = (type: END_ACTION_TYPE) => {
    switch (type) {
        case END_ACTION_TYPE.ERROR:
            return {
                icon: ErrorIcon,
                className: 'ErrorIconWrap',
                button: 'Try again',
                title: 'Error'
            }
        case END_ACTION_TYPE.WARNING:
            return {
                icon: WarningIcon,
                className: 'WarningIconWrap',
                button: 'Continue',
                title: 'Warning'
            }
        case END_ACTION_TYPE.SUCCESS:
            return {
                icon: SuccessIcon,
                className: 'SuccessIconWrap',
                button: 'Continue',
                title: 'Success'
            }
        default: {
            return {
                icon: ErrorIcon,
                className: 'ErrorIconWrap',
                button: 'Continue',
                title: 'Success'
            }
        }
    }

}


export default DialogEndActionType;

const useStyles = makeStyles<Theme>(() => ({
    closeIcon: {
        position: 'absolute',

    },
    IconWrap: {
        height: 85,
        width: 85,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        borderWidth: 9,
        borderStyle: 'solid',
        marginBottom: 18
    },
    SuccessIconWrap: {
        background: '#E6EEFF',
        borderColor: '#F6F9FF'
    },
    WarningIconWrap: {
        background: '#FDF3D7',
        borderColor: '#FFFCF4'
    },
    ErrorIconWrap: {
        background: '#F3A9A9',
        borderColor: '#FAE6E6'
    },
    wrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '50px 32px'
    },
    title: {
        textAlign: 'center',
        marginBottom: 22
    },
    subTitle: {
        textAlign: 'center',
        marginBottom: 22
    }
}));
