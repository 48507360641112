import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, IconButton, makeStyles, Typography} from '@material-ui/core';
import {useLocation, useParams} from 'react-router-dom';
import FormLayout from '../../layouts/FormLayout';
import {useGetStoreQuery, useLoginAsStoreMutation} from '../../generated/graphql';
import FailedToLoad from '../../components/FailedToLoad';
import {StoreType} from '../../types/enum';
import {COLORS} from '../../constants/colors';
import SquareButton from '../../components/ui/Buttons/SquareButton';
import {useCopy, useLocalStorage} from '../../helpers/hooks';
import StoreTransactionTable from './components/StoreTransactionTable';
import LoadingLayout from '../../layouts/LoadingLayout';
import DialogLayout from '../../layouts/DialogLayout';
import {MESSAGES} from '../../constants/messages';
import CustomButton from '../../components/ui/Buttons/Button';
import {LOCAL_STORAGE_KEYS} from '../../constants';
import {useUIContext} from '../../context/UIContext';
import {useGlobalContext} from '../../context/globalContext';

const StoreInfoPage: React.FC = (props): JSX.Element => {
    const classes = useStyles();
    // @ts-ignore
    const {id} = useParams();
    const [open, setOpen] = useState(false);
    const {showSnackBar} = useUIContext();
    const {userData} = useGlobalContext();
    // @ts-ignore
    const {state: {type}} = useLocation();
    const [storeToken, setStoreToken] = useLocalStorage(LOCAL_STORAGE_KEYS.STORE_TOKEN, null)
    const copy = useCopy();
    const {data, error, loading, refetch} = useGetStoreQuery({
        variables: {
            id: Number(id),
            filter: {
                address: userData?.blockchainAddress
            }
        }
    })
    const [loginAsStore] = useLoginAsStoreMutation();
    const openDialog = () => setOpen(true)
    const loginStore = async () => {
        try {
            const {data: dataLogin} = await loginAsStore({
                variables: {
                    storeAccountId: data?.contact?.target?.id
                }})
            if (dataLogin?.loginAsStore?.token) {
                setStoreToken(dataLogin?.loginAsStore?.token)
                window.location.reload()
            }
        } catch (e) {
            showSnackBar(e?.message)
        }
    }
    if (error) {
        return <FailedToLoad refetch={refetch} />
    }
    if (loading) {
        return <LoadingLayout
            size={30}
            wrapperStyle={classes.loading}
        />
    }
    return (
        <FormLayout
            crumbsDetails={[
                {message: 'Stores', link: ''},
                {message: data?.contact?.name, link: ''},
            ]}
        >
            <Box className={classes.header}>
                <Typography variant={'h3'} className={classes.title}>{data?.contact?.name}</Typography>
                {type === StoreType.store &&
                <SquareButton
                    onClick={openDialog}
                    materialIcon={'login'}
                    className={classes.loginButton}
                />
                }
            </Box>
            {data?.contact?.target?.blockchainAddress && <Box className={classes.addressWrapper}>
                <Box>
                    <Typography variant={'caption'}>address</Typography>
                    <Typography variant={'h5'} className={classes.value}>{data?.contact?.target?.blockchainAddress}</Typography>
                </Box>
                <IconButton className={classes.copy} aria-label="delete" onClick={() => copy(data?.contact?.target?.blockchainAddress, 'Copy address')}>
                    <i className={'material-icons'} style={{color: 'black'}}>content_copy</i>
                </IconButton>
            </Box>}
            {type === StoreType.store && <Box className={classes.addressWrapper}>
                <Box>
                    <Typography variant={'caption'}>name</Typography>
                    <Typography variant={'h5'} className={classes.value}>{data?.contact?.name}</Typography>
                </Box>
                <IconButton onClick={() => copy(data?.contact?.name, 'Copy name')} className={classes.copy}>
                    <i className={'material-icons'} style={{color: 'black'}}>content_copy</i>
                </IconButton>
            </Box>}
            <Typography variant="h4" gutterBottom className={classes.transactionList}>Transaction list</Typography>
            {data?.contact?.target?.blockchainTransactions?.data && data?.contact?.target?.blockchainTransactions?.data?.length > 0 ?
                <StoreTransactionTable data={data?.contact?.target?.blockchainTransactions?.data}/>
                : <Typography variant={'subtitle1'} className={classes.emptyText}>You don`t have any transactions with {data?.contact?.name} yet. You can send products right now</Typography>
            }
            {open &&
            <DialogLayout
                open={open}
                width={552}
                onClose={() => setOpen(false)}
                hideIcon
            >
                <Box className={classes.wrapper}>
                    <Typography variant={'h4'}>
                        Go to account?
                    </Typography>
                    <Typography variant={'subtitle1'} className={classes.subtitle}>
                        {MESSAGES.LOGIN_SUBTITLE(data?.contact?.name)}
                    </Typography>
                    <Box className={classes.wrapperButton}>
                        <CustomButton onClick={() => setOpen(false)}>Cancel</CustomButton>
                        <CustomButton onClick={loginStore}>
                            Confirm
                        </CustomButton>
                    </Box>
                </Box>
            </DialogLayout>
            }
        </FormLayout>
    );
};

export default StoreInfoPage;

const useStyles = makeStyles<Theme>(() => ({
    loading: {
        background: COLORS.WHITE,
        borderRadius: 8,
    },
    loaderWrapper: {

    },
    header: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexFlow: 'row nowrap',
        marginBottom: 32
    },
    title: {
        colors: COLORS.BLACK,
        alignSelf: 'center',
        lineHeight: 2
    },
    loginButton: {
        alignSelf: 'center'
    },
    addressWrapper: {
        marginBottom: '1rem',
        display: 'flex',
        justifyContent: 'flex-start',
        flexFlow: 'row wrap',
    },
    copy: {
        marginLeft: 25,
        marginTop: 6
    },
    value: {
        marginTop: 8
    },
    emptyText: {
        color: COLORS.TEXT_SILVER,
        marginTop: 24
    },
    wrapper: {
        padding: '40px 56px'
    },
    subtitle: {
        color: COLORS.TEXT_SILVER,
        margin: '8px 0 24px 0'
    },
    wrapperButton: {
        width: '50%',
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'space-around'
    },

}));
