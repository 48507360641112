import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import CustomButton from '../../../components/ui/Buttons/Button';
import {useHistory} from 'react-router-dom';
import NoBrandsImg from '../../../assets/empty-products-page.svg';
import {COLORS} from '../../../constants/colors';


const EmptyProductsSection: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <Box className={classes.container}>
            <img src={NoBrandsImg} alt="No brands"/>
            <Typography className={classes.title} variant={'subtitle2'}>You have no products yet</Typography>
            <Typography className={classes.subtitle} variant={'subtitle1'}>There will be products that you create</Typography>
            <CustomButton
                variant={'contained'}
                onClick={() => history.push('/products/create')}
            >
                Create product
            </CustomButton>
        </Box>
    );
};

export default EmptyProductsSection;

const useStyles = makeStyles<Theme>(() => ({
    container: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column',
    },
    title: {
        margin: '32px 0 8px 0'
    },
    subtitle: {
        margin: '0 0 24px 0',
        color: COLORS.TEXT_SILVER
    }
}));
