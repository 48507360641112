import React from 'react';
import {makeStyles, TableRowProps, Typography} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import StatusChecked from '../../../assets/icons/statusChecked.svg';
import StatusPaid from '../../../assets/icons/statusPaid.svg';
import StatusCanceled from '../../../assets/icons/stausCanceled.svg';
import {ReactSVG} from 'react-svg'
import {INVOICE_COMPLETED_STATUS} from "../helpers/enum";
import clsx from "clsx";

type CustomStatusInvoiceProps = {
    invoiceStatus: INVOICE_COMPLETED_STATUS;
    statusDate?: string;
}

const StatusInvoice: React.FC<CustomStatusInvoiceProps> = (props: CustomStatusInvoiceProps): JSX.Element => {
    const {invoiceStatus, statusDate} = props;
    const classes = useStyles();

    let statusIcon;

    switch (invoiceStatus) {
        case INVOICE_COMPLETED_STATUS.PAID:
            statusIcon = StatusPaid
            break;
        case INVOICE_COMPLETED_STATUS.CHECKED:
            statusIcon = StatusChecked
            break;
        case INVOICE_COMPLETED_STATUS.CANCELED:
            statusIcon = StatusCanceled
            break;
    }

    return (
        <div
            className={clsx(
                classes.statusInvoice,
                {[classes.paid]: invoiceStatus === INVOICE_COMPLETED_STATUS.PAID},
                {[classes.checked]: invoiceStatus === INVOICE_COMPLETED_STATUS.CHECKED},
                {[classes.canceled]: invoiceStatus === INVOICE_COMPLETED_STATUS.CANCELED}
            )}
        >
            <ReactSVG src={statusIcon}/>
            <Typography
                variant={'body1'}
            >
                {invoiceStatus === INVOICE_COMPLETED_STATUS.CANCELED
                    ? 'Canceled'
                    : <span>{invoiceStatus === INVOICE_COMPLETED_STATUS.PAID ? 'Paid' : 'Checked'} {statusDate}</span>
                }
            </Typography>
        </div>
    );
};

export default StatusInvoice;

const useStyles = makeStyles<Theme>(() => ({
    statusInvoice: {
        width: 'min-content',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 24,
        padding: '8px 16px',
        '& > div > div > svg': {
            display: 'block'
        },
        '& > p': {
            fontSize: 14,
            marginLeft: 4,
        }
    },
    paid: {
        background: '#E3FCEC',
        '& > p': {
            color: '#197741'
        }
    },
    checked: {
        background: '#E4EEFF',
        '& > p': {
            color: '#276EF9'
        }
    },
    canceled: {
        background: '#FAE6E6',
        '& > p': {
            color: '#DC3030'
        }
    }
}));
