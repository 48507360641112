import React, {FC} from 'react';
import {useHistory, withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import CustomButton from '../../components/ui/Buttons/Button';
import {Box, Typography} from '@material-ui/core';
import ProductSuccess from './images/product-success-create.svg';

const ProductSuccessPage:FC = ():JSX.Element => {
    const classes = useStyle();
    const history = useHistory();
    return (
        <Box className={classes.createSuccessPage}>
            <img src={ProductSuccess} alt="failed" className={classes.img}/>
            <Typography className={classes.title}>Product successfully created</Typography>
            <CustomButton
                variant={'contained'}
                color={'primary'}
                onClick={() => history.push('/products')}>Back to product</CustomButton>
        </Box>
    );
}

export default ProductSuccessPage;

const useStyle = makeStyles({
    createSuccessPage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'white',
        height: '100%',
        width: '100%'
    },
    img: {
        alignSelf: 'center',
        marginBottom: 10

    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.87)',
        marginBottom: 8
    },
    subtitle: {
        fontSize: 14,
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.6)',
        fontWeight: 'normal',
        marginBottom: 20,
        '&>p': {
            marginBottom: 5
        }
    }
});
