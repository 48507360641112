import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Chip, ChipProps, makeStyles} from '@material-ui/core';
import {COLORS} from '../../../constants/colors';


const FileChip: React.FC<ChipProps> = (props: ChipProps): JSX.Element => {
    const classes = useStyles();

    return (
        <Chip
            variant="outlined"
            classes={{root: classes.chipRoot, deleteIcon: classes.chipDeleteIcon}}
            style={{marginBottom: 10, marginRight: 5}}
            {...props}
        />
    );
};

export default FileChip;

const useStyles = makeStyles<Theme>(() => ({
    chipRoot: {
        background: 'rgba(33, 33, 33, 0.08)',
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 16,
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.87)'
    },
    chipDeleteIcon: {
        color: COLORS.TEXT_SILVER
    }
}));
