import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/core';
import FormLayout from '../../layouts/FormLayout';
import DropdownProgress from './components/DropdownProgress';
import {ActionsType} from '../../types/enum';

const ActionsPage: React.FC = (): JSX.Element => {
    const classes = useStyles();

    return (
        <FormLayout
            title={'Wait list'}
        >
            <DropdownProgress totalCount={10} completeCount={5} type={ActionsType.creating} title={'Creating'}/>
            <DropdownProgress totalCount={10} completeCount={5} type={ActionsType.deleting} title={'deleting'}/>
            <DropdownProgress totalCount={10} completeCount={5} type={ActionsType.sending} title={'sending'}/>
        </FormLayout>
    );
};

export default ActionsPage;

const useStyles = makeStyles<Theme>(() => ({}));
