import React, {useEffect} from 'react';
import {Chart, defaults, Line} from 'react-chartjs-2';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from '@material-ui/core/Button';
import SuccessHrobak from '../../../pages/home/images/SuccessHrobak.svg'
import ErrorsHrobak from '../../../pages/home/images/ErrorsHrobak.svg'
import UnloadHrobak from '../../../pages/home/images/UnloadHrobak.svg'
import clsx from 'clsx';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
defaults.font.family = '"Inter", "sans-serif"';


const LineChart = () => {
    const classes = useStyles();

    const [date, setDate] = React.useState<string | number>('year');

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDate(event.target.value as number);
    };

    // Ticks Date now start ****************************************
    const monthTick = 6;
    const dayTick = 3;
    const hourTick = 17;

    const colorTick = Array(30).fill('#bcbec9')

    if (date === 'year') {
        colorTick[monthTick] = '#21264C';
    }

    if (date === 'month') {
        colorTick[dayTick] = '#21264C';
    }

    if (date === 'day') {
        colorTick[hourTick] = '#21264C';
    }
    // Ticks Date now end ****************************************

    let dataSuccess = [4025, 4998, 3536, 5122, 3430, 3211, 4235, 4231, 10000, 5365, 5747, 8345]
    let dataUnloaded = [0, 585, 1314, 0, 621, 2561, 1101, 5532, 2631, 2212, 99, 452]
    let dataErrors = [1000, 1500, 300, 3000, 1500, 200, 1500, 300, 1600, 100, 200, 1300]

    let labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jan', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    if (date === 'month') {
        dataSuccess = [115, 20, 143, 100, 110, 130, 178, 190, 96, 80, 250, 135, 115, 60, 143, 100, 110, 130, 178, 190, 96, 80, 250, 135, 178, 190, 96, 93, 250, 135]
        dataUnloaded = [10, 20, 33, 50, 60, 75, 43, 18, 80, 32, 25, 95, 30, 87, 63, 45, 110, 12, 27, 85, 17, 45, 64, 13, 28, 44, 98, 12, 2, 56]
        dataErrors = [0, 1, 10, 15, 3, 8, 0, 4, 50, 36, 32, 25, 12, 8, 33, 2, 1, 0, 0, 0, 5, 1, 0, 0, 0, 16, 8, 9, 22, 3]
        labels = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30']
    }
    if (date === 'day') {
        dataSuccess = [0, 0, 0, 0, 0, 0, 30, 15, 44, 50, 0, 0, 13, 15, 22, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        dataUnloaded = [0, 0, 0, 0, 0, 0, 5, 3, 0, 1, 0, 0, 1, 0, 0, 2, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        dataErrors = [0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        labels = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
    }

    let chart;

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const ctx = document.getElementById("myChart").getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, 0, 1000);
        gradient.addColorStop(0, '#C1D3FF');
        gradient.addColorStop(0.35, 'rgba(170, 195, 255, 0)');

        // @ts-ignore
        chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'Success',
                        // @ts-ignore
                        lineTension: 0.5,
                        pointRadius: 0,
                        pointHoverRadius: 6,
                        hoverBackgroundColor: '#276EF9',
                        hoverBorderColor: '#fff',
                        hoverBorderWidth: 5,
                        pointBackgroundColor: '#fff',
                        pointBorderColor: '#276EF9',
                        fill: true,
                        data: dataSuccess,
                        backgroundColor: gradient,
                        borderColor: '#276EF9',
                        borderWidth: 3
                    },
                    {
                        label: 'Unloaded',
                        pointRadius: 0,
                        // @ts-ignore
                        lineTension: 0.5,
                        pointHoverRadius: 6,
                        hoverBackgroundColor: '#F3A9A9',
                        hoverBorderColor: '#fff',
                        hoverBorderWidth: 5,
                        pointBackgroundColor: '#fff',
                        pointBorderColor: '#F3A9A9',
                        fill: true,
                        data: dataUnloaded,
                        backgroundColor: 'rgba(250, 230, 230, 0.3)',
                        borderColor: '#F3A9A9',
                        borderWidth: 3
                    },
                    {
                        label: 'Errors',
                        pointRadius: 0,
                        // @ts-ignore
                        lineTension: 0.5,
                        pointHoverRadius: 6,
                        hoverBackgroundColor: '#9ecfff',
                        hoverBorderColor: '#fff',
                        hoverBorderWidth: 5,
                        pointBackgroundColor: '#fff',
                        pointBorderColor: '#9ecfff',
                        fill: true,
                        data: dataErrors,
                        backgroundColor: 'rgba(39, 110, 249, 0.01)',
                        borderColor: '#9ecfff',
                        borderWidth: 3
                    }]
            },
            options: {
                plugins: {
                    title: {
                        display: false
                    },
                    legend: {
                        display: false
                    },
                    tooltip: {
                        titleAlign: 'center',
                        bodyColor: '#ffffff',
                        backgroundColor: ' #21264C',
                        cornerRadius: 10,
                        padding: {left: 20, right: 20, top: 10, bottom: 10},
                        caretPadding: 15,
                        caretSize: 6,
                        xAlign: 'center',
                        yAlign: 'bottom',
                        titleFont: {size: 12, weight: 'bold'},
                        bodyFont: {size: 12, weight: 'lighter'},
                        displayColors: false,
                        titleMarginBottom: 4,
                        bodyAlign: 'left',
                        callbacks: {
                            title: function (tooltipItem) {
                                if (date === 'month') {
                                    return `${tooltipItem[0].label} Jul`;
                                }
                                if (date === 'day') {
                                    return `${tooltipItem[0].label} ${Number(tooltipItem[0].label) < 13 ? 'am' : 'pm'}`;
                                }
                                return `${tooltipItem[0].label}`;
                            }
                        }
                    }
                },
                layout: {
                    padding: {top: 80}
                },
                scales: {
                    y: {
                        suggestedMin: 0.00,
                        suggestedMax: 1.00,
                        grid: {
                            borderWidth: 0,
                            color: 'rgba(0, 0, 0, 0.05)',
                            lineWidth: 2,
                            borderDash: [9, 9],
                            drawTicks: false
                        },
                        ticks: {
                            callback: function (label) {
                                if (date === 'month' || date === 'day' || label === 0) {
                                    return `${label} `;
                                }
                                // @ts-ignore
                                return `${label / 1000}k`;
                            },
                            padding: 14,
                            color: '#bcbec9',
                            font: {size: 14},
                        }
                    },
                    x: {
                        grid: {
                            display: false
                        },
                        ticks: {
                            padding: 10,
                            // @ts-ignore
                            color: colorTick,
                            font: {size: 14}
                        }
                    }
                },
                maintainAspectRatio: false,
                responsive: true,
                interaction: {
                    mode: 'index',
                    intersect: false
                }
            }
        });
        return () => chart.destroy();
    });

    const handleHideChart = (index) => {
        if (chart._metasets[index].hidden) {
            chart.show(index);
            chart.update();
            return;
        }
        chart.hide(index);
        chart.update();
    };


    return (
        <div className={classes.Paper}>
            <canvas id="myChart"/>
            <div className={classes.selectChart}>
                <FormControl variant="standard" className={classes.formControl}>
                    <Select
                        disableUnderline
                        value={date}
                        onChange={handleChange}
                        name="age"
                        classes={{
                            root: classes.root,
                            select: classes.select
                        }}
                        inputProps={{'aria-label': 'date'}}
                        MenuProps={{
                            getContentAnchorEl: null,
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'center'
                            },
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'center'
                            },
                            classes: {
                                list: classes.list,
                                paper: classes.paper
                            }
                        }}
                    >
                        <MenuItem value={'year'}>One year</MenuItem>
                        <MenuItem value={'month'}>One month</MenuItem>
                        <MenuItem value={'day'}>One day</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className={classes.chipsContainer}>
                <Button
                    classes={{
                        root: clsx(
                            classes.btn,
                            classes.btnSuccess,
                            // {[classes.btnOff]: }
                        ),
                        label: classes.btnLabel
                    }}
                    startIcon={<img src={SuccessHrobak}/>}
                    onClick={() => handleHideChart(0)}
                    variant="contained">
                    Success
                </Button>
                <Button
                    classes={{
                        root: clsx(classes.btn, classes.btnErrors),
                        label: classes.btnLabel
                    }}
                    startIcon={<img src={ErrorsHrobak}/>}
                    onClick={() => handleHideChart(2)}
                    variant="contained">
                    Errors
                </Button>
                <Button
                    classes={{
                        root: clsx(classes.btn, classes.btnUnloaded),
                        label: classes.btnLabel
                    }}
                    startIcon={<img src={UnloadHrobak}/>}
                    onClick={() => handleHideChart(1)}
                    variant="contained">
                    Unloaded
                </Button>
            </div>
        </div>
    );
};
export default LineChart;


const useStyles = makeStyles((theme) => {
    return ({
        Paper: {
            marginLeft: -10,
            position: 'relative',
            backgroundColor: 'white',
            height: '100%',
            minHeight: 450,
            // padding: '20px 20px 40px 20px',
            textAlign: 'center',
        },
        chipsContainer: {
            position: 'absolute',
            top: 6,
            left: 14,
            '& > button + button': {
                marginLeft: 16
            }
        },
        btn: {
            background: ' #FFFFFF',
            boxShadow: '0px 4px 9px rgba(8, 42, 92, 0.08)',
            borderRadius: 65,
            padding: '2px 16px 2px 21px',
            '&:hover': {
                backgroundColor: 'rgba(26, 89, 237, 0)'
            }
        },
        btnOff: {
            color: 'rgba(0, 0, 0, 0.38)',
            border: '1px solid rgba(0, 0, 0, 0.12)',
            boxShadow: 'none'
        },
        btnSuccess: {
            color: '#1A59ED'
        },
        btnErrors: {
            color: '#6BA9FF'
        },
        btnUnloaded: {
            color: '#E36464'
        },
        btnLabel: {
            fontWeight: 500,
            fontSize: 14,
            lineHeight: '32px'
        },
        selectChart: {
            position: 'absolute',
            top: 6,
            right: 27
        },
        formControl: {
            fontSize: 5
        },
        root: {
            fontSize: 14,
            color: 'rgba(0, 0, 0, 0.7)',
            fontWeight: 500,
            lineHeight: '24px',
            '&:focus': {
                backgroundColor: 'inherit'
            }
        },
        select: {
            paddingRight: '30px !important'
        },
        paper: {
            boxShadow: '0px 6px 29px rgba(0, 4, 38, 0.16)',
            borderRadius: '16px',
            padding: '3px 0',
            marginTop: '8px'
        },
        list: {
            'li:hover, &:focus': {
                borderRadius: '8px',
                backgroundColor: 'rgba(39, 110, 249, 0.04)'
            },
            'li:active': {
                color: 'rgba(39, 110, 249, 0.1)',
                backgroundColor: 'rgba(39, 110, 249, 0.1)'
            },
            '& .Mui-selected': {
                paddingLeft: '16px',
                borderRadius: '8px',
                backgroundColor: 'rgba(39, 110, 249, 0.08)',
                '&:hover, &:focus': {
                    backgroundColor: 'rgba(39, 110, 249, 0.08)'
                },
                '&:active': {
                    backgroundColor: 'rgba(39, 110, 249, 0.1)'
                }
            },
            '& .MuiTouchRipple-root': {
                color: 'rgba(39, 110, 249, 0.1)'
            }
        }
    });
});
