import React from 'react';
import {Chip, ChipProps, makeStyles} from '@material-ui/core'
import {ActionsType} from '../../../types/enum';
import {Theme} from '@material-ui/core/styles';
import {COLORS} from '../../../constants/colors';

type ActionChipsProps = ChipProps & {
    type: ActionsType
}

const ActionChip = (props: ActionChipsProps) => {
    const classes = useStyles();
    const {type, ...rest} = props;

    return <Chip
        label={type}
        size={'small'}
        classes={{
            root: classes[type]
        }}
        {...rest}
    />
}

export default ActionChip;


const useStyles = makeStyles<Theme>(() => ({
    creating: {
        background: COLORS.SUCCESS_ACTION,
        color: COLORS.WHITE,
        height: 26,
        borderRadius: 8
    },
    deleting: {
        background: COLORS.ERROR_PRIMARY,
        color: COLORS.WHITE,
        height: 26,
        borderRadius: 8
    },
    sending: {
        background: COLORS.PRIMARY,
        color: COLORS.WHITE,
        height: 26,
        borderRadius: 8
    }
}));
