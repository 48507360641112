import React, {useCallback, useRef} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, IconButton, makeStyles, Paper, Typography} from '@material-ui/core';
import {DialogType} from '../helpers/type';
import CustomButton from '../../../components/ui/Buttons/Button';
import {COLORS} from '../../../constants/colors';
import {useUIContext} from '../../../context/UIContext';
import {useHistory} from 'react-router-dom';
import {StoreType} from '../../../types/enum';
import SquareButton from '../../../components/ui/Buttons/SquareButton';
import {centeredEllipsis} from '../../../helpers';

type StoreCard = {
    item: StoreData,
    showDialog: (item: StoreData, type: DialogType) => void

}

type StoreData = {
    id: number,
    name: string,
    type?: StoreType,
    target: {blockchainAddress: string},
    comment?: string
}

const StoreCard: React.FC<StoreCard> = (props: StoreCard): JSX.Element => {
    const classes = useStyles();
    const {item, showDialog} = props;
    const history = useHistory();

    const {showSnackBar} = useUIContext();
    const copyAddress = useCallback(
        (wallet) => {
            navigator.clipboard.writeText(wallet).then(() => {
                showSnackBar('Wallet copied to clipboard')
            });
        },
        [item?.target?.blockchainAddress]
    );
    const navigateStoreInfo = useCallback(
        (id: number, type: StoreType) => {
            history.push(`/stores/info/${id}`, {
                type
            });
        }, [item?.id]);
    return (
        <Paper elevation={3} className={classes.item}>
            <Box className={classes.headerItem}>
                <Box className={classes.nameWrapper}>
                    <Typography variant={'caption'} className={classes.section}>name</Typography>
                    <Typography
                        variant={'h4'}
                        className={classes.name}
                    >{item?.name}</Typography>
                </Box>
                <Box className={classes.headerAction}>
                    {item?.type === StoreType.store &&
                        <SquareButton
                            materialIcon={'login'}
                            onClick={() => showDialog(item, DialogType.loginStore)}
                        />
                    }
                </Box>
            </Box>
            <Box className={classes.address}>
                <Box className={classes.addressWrapper}>
                    <Typography variant={'caption'} className={classes.section}>address</Typography>
                    <Typography variant={'h5'} className={classes.address}>{centeredEllipsis(item?.target?.blockchainAddress)}</Typography>
                </Box>
                <Box className={classes.copyButton}>
                    <IconButton onClick={() => copyAddress(item?.target?.blockchainAddress)}>
                        <i className={'material-icons'}>content_copy</i>
                    </IconButton>
                </Box>
            </Box>
            {item?.comment &&
            <Box className={classes.comment}>
                <Box>
                    <Typography variant={'caption'} className={classes.section}>comment</Typography>
                    <Typography className={classes.commentText}>{item?.comment}</Typography>
                </Box>
            </Box>
            }
            <Box className={classes.actionBtn}>
                <CustomButton onClick={() => navigateStoreInfo(item?.id, item?.type)}>
                    MORE INFO
                </CustomButton>
                <CustomButton
                    color={'secondary'}
                    // disabled={item?.type === StoreType.store}
                    onClick={() => showDialog(item, DialogType.deleteStore)}
                >DELETE</CustomButton>
            </Box>
        </Paper>
    );
};

export default StoreCard;

const useStyles = makeStyles<Theme>(() => ({
    item: {
        position: 'relative',
        display: 'flex',
        flexFlow: 'column',
        padding: 24,
        minWidth: 350,
        height: 310,
        '& button': {
            cursor: 'pointer'
        },
        '& button:disabled': {
            cursor: 'default',
            '& i': {
                cursor: 'default'
            }
        }
    },
    headerItem: {
        marginBottom: '1rem',
        display: 'flex',
        justifyContent: 'space-between',
        flexFlow: 'row nowrap',
    },
    nameWrapper: {
        maxWidth: 450,
        overflow: 'hidden'
    },
    section: {
        marginBottom: 0
    },
    name: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    headerAction: {
        display: 'flex'
    },
    address: {
        marginBottom: '1rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'flex',
        justifyContent: 'space-between',
        flexFlow: 'row',
        whiteSpace: 'nowrap',
    },
    addressWrapper: {
        overflow: 'hidden',
        flex: 1
    },
    copyButton: {
        height: 56,
        width: 56,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    comment: {
        marginBottom: '1rem',
    },
    commentText: {
        color: COLORS.TEXT_SILVER,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        height: 48
    },
    actionBtn: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'flex-end',
        marginTop: 'auto',
        '&>*': {
            marginLeft: 15
        }
    },

}));
