
const KNOWLEDGE_BASE = [
    'Inspect',
    'Brands',
    'Products',
    'Consignments',
    'Apps',
    'Shops',
    'Finances',
    'Implementation'
]
const TOP_QUESTION = [
    'How to download the product?',
    'Learn about your privacy settings',
    'Two-factor authentication',
    'About Shops'
]
const SUPPORT_LINKS = [
    {title: 'Сhange password'},
    {title: 'Upload product feed'}
]
const ISSUES = ['technical', 'money', 'other', 'bug report'];

const QUESTIONS = [
    {
        title: 'How to download the product?',
        subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim ...'
    },
    {
        title: 'How to download the product?',
        subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim ...'
    },
    {
        title: 'How to download the product?',
        subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim ...'
    },
    {
        title: 'How to download the product?',
        subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim ...'
    },
    {
        title: 'How to download the product?',
        subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim ...'
    },
    {
        title: 'How to download the product?',
        subTitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor rhoncus dolor purus non enim ...'
    }
];

export {
    KNOWLEDGE_BASE,
    TOP_QUESTION,
    SUPPORT_LINKS,
    ISSUES,
    QUESTIONS
}
