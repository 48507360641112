
export type AddStoreInitData = {
    name: string;
    blockchainAddress: string;
    comment: string;
}
export type CreateStoreInitData = {
    email: string;
    password: string;
    name: string;
    comment: string;
}

export enum DialogType {
    deleteStore = 'deleteType',
    loginStore = 'loginStore',


}

export type CREATE_STORE = {
    ADD: 'ADD',
    CREATE: 'CREATE'
}
