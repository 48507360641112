import React, {useMemo, useState, useEffect} from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, Typography} from '@material-ui/core';
import CustomPaper from '../../components/ui/Paper';
import StatisticsChart from './components/StatisticsChart';
import bgBtnCreateInvoice from '../../assets/background/bgBtnCreateInvoice.png';
import PendingTable from './components/PendingTable';
import CompletedTable from './components/CompletedTable';
import CreateInvoice from './components/CreateInvoice/CreateInvoice';
import {useUIContext} from '../../context/UIContext';
import EmptyInvoicePage from "./EmptyInvoicePage";
import {useGlobalContext} from "../../context/globalContext";
import DialogInvoiceData from "../../components/ui/Dialogs/DialogInvoiceData";

const InvoicePage: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const {setOpenCreateInvoice} = useUIContext();
    const [openDialogInvoiceData, setOpenDialogInvoiceData] = useState<boolean>(false);

    const {userData} = useGlobalContext();

    const data = true;


    if (!data) {
        return <EmptyInvoicePage />
    }

    return (
        <CustomPaper className={classes.container}>
            <Typography variant={'h3'} className={classes.title}>Invoices</Typography>
            <div className={classes.statisticsContainer}>
                <div className={classes.totalReceived}>
                    <div className={classes.totalReceivedContainer}>
                        <Typography variant={'caption'} className={classes.subTitle}>total received</Typography>
                        <Typography
                            variant={'h2'}
                            className={classes.totalPrice}
                        >54,597<span style={{color: 'rgba(0, 0, 0, 0.38)'}}>.80</span>
                            <span className={classes.currency}> USD</span>
                        </Typography>
                        <div className={classes.pendingCanceledContainer}>
                            <div className={classes.pendingContainer}>
                                <Typography variant={'subtitle2'} className={classes.pending}>Pending</Typography>
                                <Typography variant={'body1'} className={classes.pendingPrice}>$4,525<span
                                    style={{color: 'rgba(0, 0, 0, 0.38)'}}>.17</span></Typography>
                            </div>
                            <div className={classes.canceledContainer}>
                                <Typography variant={'subtitle2'} className={classes.canceled}>Canceled</Typography>
                                <Typography variant={'body1'} className={classes.canceledPrice}>$5,000<span
                                    style={{color: 'rgba(0, 0, 0, 0.38)'}}>.00</span></Typography>
                            </div>
                        </div>
                    </div>
                </div>
                <StatisticsChart />
                <div
                    className={classes.btnCreateInvoice}
                    onClick={() => {
                        if (
                            userData?.fullName &&
                            userData?.streetAddress &&
                            userData?.cityState &&
                            userData?.zipCode
                            // userData?.phoneNumber &&
                            // userData?.email
                        ) {
                            setOpenCreateInvoice(true);
                            return;
                        }
                        setOpenDialogInvoiceData(true);
                    }}
                >
                    <Typography variant={'h2'} className={classes.btnTitle}>Create Invoice</Typography>
                    <img className={classes.bgBtnCreateInvoice} src={bgBtnCreateInvoice} />
                </div>
            </div>
            <PendingTable />
            <CompletedTable />
            <CreateInvoice />
            {openDialogInvoiceData && <DialogInvoiceData
                open={openDialogInvoiceData}
                setOpenDialogInvoiceData={setOpenDialogInvoiceData}
                title={'Fill invoice data'}
                onClose={() => setOpenDialogInvoiceData(false)}
            />}
        </CustomPaper>
    );
};

export default InvoicePage;

const useStyles = makeStyles<Theme>((theme) => ({
    container: {
        width: '100%',
        minHeight: '100%',
        overflowY: 'auto',
        padding: '35px 40px 60px 40px'
    },
    title: {
        lineHeight: '48px'
    },
    subTitle: {
        display: 'block',
        letterSpacing: 2,
        color: ' rgba(0, 0, 0, 0.6)',
        fontSize: 12,
        lineHeight: '18px'
    },
    totalPrice: {
        display: 'inline',
        fontSize: 40,
        lineHeight: '60px'
    },
    currency: {
        color: 'rgba(0, 0, 0, 0.38)',
        fontSize: 24,
        lineHeight: '48px'
    },
    statisticsContainer: {
        margin: '36px 0 40px',
        display: 'flex',
        '& > div': {
            width: '33%',
            maxHeight: 182
        },
        '& > div + div': {
            marginLeft: 24
        },
    },
    totalReceived: {
        padding: '20px 24px 26px',
        background: '#FFFFFF',
        boxShadow: '0px 5px 28px -12px rgba(73, 105, 219, 0.28)',
        borderRadius: 16,
        display: 'flex'
    },
    totalReceivedContainer: {
        marginRight: 'auto'
    },
    pendingCanceledContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 6,
    },
    pendingContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    canceledContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    pending: {
        color: theme.palette.primary.main,
        fontSize: 14,
        lineHeight: '28px',
        '&:before': {
            content: `"●"`,
            color: theme.palette.primary.main,
            margin: '0 7px 0 0'
        }
    },
    pendingPrice: {
        lineHeight: '24px'
    },
    canceled: {
        color: 'rgba(220, 48, 48, 1)',
        fontSize: 14,
        lineHeight: '28px',
        '&:before': {
            content: `"●"`,
            color: 'rgba(220, 48, 48, 1)',
            margin: '0 7px 0 0'
        }
    },
    canceledPrice: {
        lineHeight: '24px'
    },
    btnCreateInvoice: {
        cursor: 'pointer',
        position: 'relative',
        borderRadius: 16,
        background: '#276EF9',
        display: 'flex',
        alignItems: 'center'
    },
    btnTitle: {
        color: '#FFFFFF',
        width: 234,
        lineHeight: '60px',
        margin: '31px 48px'
    },
    bgBtnCreateInvoice: {
        position: 'absolute',
        top: 0,
        right: 0
    }
}));
