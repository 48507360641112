import React, {useContext, useEffect, useState} from 'react';
import {useLocalStorage} from '../helpers/hooks';
import {FILTERS_TYPE, userDataType} from '../types/type';
import {useGetUserDataQuery} from '../generated/graphql';
import {LOCAL_STORAGE_KEYS} from '../constants';
import FailedToLoad from '../components/FailedToLoad';

type initialValuesType = {
    darkTheme: boolean;
    setDarkTheme: React.Dispatch<React.SetStateAction<boolean>>;
    toggleThemeMode: () => void;
    userData: userDataType | null,
    setUserData: React.Dispatch<React.SetStateAction<userDataType | null>>,
    filter: FILTERS_TYPE,
    setFilter: React.Dispatch<React.SetStateAction<string>>
    rowsPerPage: number;
    changeRowsPerPage: any
}

const initialValues: initialValuesType = {
    darkTheme: false,
    setDarkTheme: () => undefined,
    toggleThemeMode: () => undefined,
    setUserData: null,
    userData: null,
    filter: 'ALL',
    setFilter: () => undefined,
    rowsPerPage: 25,
    changeRowsPerPage: (value: any) => value
}

const GloBalContext = React.createContext(initialValues);

export const useGlobalContext = () => {
    return useContext(GloBalContext);
};

const GloBalContextProvider:React.FC = ({children}) => {
    const [token] = useLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, null);
    const [rowsPerPageStorage, setRowsPerPageStorage] = useLocalStorage(LOCAL_STORAGE_KEYS.ROWS_PER_PAGE, 25);
    const [darkTheme, setDarkTheme] = useState(initialValues.darkTheme);
    const [userData, setUserData] = useState<userDataType | null>(token);
    const [filter, setFilter] = useState(initialValues.filter);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageStorage);
    const {data, error, refetch} = useGetUserDataQuery({
        skip: !token
    });
    useEffect(() => {
        if(data?.whoami) {
            // @ts-ignore
            setUserData(
                {
                    ...data?.whoami,
                    currentSession: data?.currentSession
                }
            )
        }
    }, [data]);
    const changeRowsPerPage = (value: number) => {
        setRowsPerPageStorage(value);
        setRowsPerPage(value);
    };
    const toggleTheme = (): void => {
        setDarkTheme(prevState => !prevState)
    }
    const toggleThemeMode = (): void => {
        if (darkTheme) {
            localStorage.removeItem('darkMode')
        } else {
            localStorage.setItem('darkMode', 'true')
        }
        toggleTheme()
    }

    if (error) {
        return <FailedToLoad refetch={refetch} />
    }
    return (
        <GloBalContext.Provider
            value={{
                darkTheme,
                setDarkTheme,
                toggleThemeMode,
                userData,
                setUserData,
                filter,
                setFilter,
                rowsPerPage,
                changeRowsPerPage
            }}
        >
            {children}
        </GloBalContext.Provider>
    );
}

export default GloBalContextProvider;
