import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Button, makeStyles} from '@material-ui/core';
import {Form, Formik } from 'formik';
import FormBoxInput from '../ui/FormBoxInput';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
import {INVOICE_GENERATE_SCHEMA} from '../../constants/validation_schema';
import MuiCircularProgress from '../ui/CircularProgress';
import {useHistory} from 'react-router-dom';
// @ts-ignore
import {useTopUpBalanceByInvoiceMutation} from '../../generated/graphql';
import {useUIContext} from '../../context/UIContext';
import {useFinanceContext} from '../../context/financeContext';


const InvoiceForm: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();

    const {showEndAction} = useUIContext();
    const [topUpBalanceByInvoice] = useTopUpBalanceByInvoiceMutation();
    return (
        <Formik
            initialValues={{
                amount: 1,
                comment: ''
            }}
            validationSchema={INVOICE_GENERATE_SCHEMA}
            onSubmit={async (values) => {
                const {amount, comment} = values;
                try {
                    const {data} = await topUpBalanceByInvoice({
                        variables: {
                            amount: Number(amount) * 100,
                            comment,
                            paymentForUsa: false
                        }
                    });
                    if (data?.topUpBalanceByInvoice?.invoiceId) {
                        history.push({
                            pathname: '/finances/invoice',
                            state: {
                                data: data?.topUpBalanceByInvoice,
                                comment
                            }
                        });
                    }
                } catch (e) {
                    showEndAction(e?.message)
                }
            }}
        >
            {({isValid, isSubmitting}) => (
                <Form className={classes.container}>
                    <FormBoxInput
                        fullWidth
                        icon={<AttachMoneyIcon />}
                        name={'amount'}
                        label={'Amount, USD'}
                        labelWidth={100}
                        formControlProps={{
                            style: {marginBottom: 8}
                        }}
                        inputProps={{
                            type: 'number'
                        }}
                    />
                    <FormBoxInput
                        fullWidth
                        icon={<CommentOutlinedIcon />}
                        name={'comment'}
                        label={'Comment (optional)'}
                        labelWidth={145}
                        multiline={true}
                        rows={6}
                    />
                    {/*<FormControlLabel*/}
                    {/*    classes={{*/}
                    {/*        root: classes.FormControlLabel,*/}
                    {/*        label: classes.FormLabel*/}
                    {/*    }}*/}
                    {/*    control={<Checkbox*/}
                    {/*        color={'primary'}*/}
                    {/*    />}*/}
                    {/*    label={'Payment will be made from the territory of the USA'}*/}
                    {/*/>*/}
                    <Button
                        className={classes.button}
                        type={'submit'}
                        disabled={!isValid || isSubmitting}
                    >
                        {isSubmitting ? <MuiCircularProgress /> : 'Send'}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default InvoiceForm;

const useStyles = makeStyles<Theme>(() => ({
    container: {
        padding: '50px 16px 16px 50px',
        maxWidth: 650,
        height: '100%',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    button: {
        boxShadow: '0px 4px 16px rgba(1, 49, 172, 0.25)',
        borderRadius: '12px',
        backgroundColor: '#276EF9',
        color: 'white',
        padding: '16px 32px',
        textTransform: 'none',
        fontHeight: 600,
        fontSize: '16px',
        lineHeight: '24px',
        justifySelf: 'start',
        marginTop: 32,
        gridRow: '6/7',
        alignSelf: 'flex-start',
        '&:hover': {
            background: '#4584FF',
            boxShadow: '0px 4px 16px rgba(1, 49, 172, 0.25)'
        },
        '&:disabled': {
            background: 'rgba(0, 0, 0, 0.12)',
            color: 'rgba(0, 0, 0, 0.38)'
        }
    },
}));
