import {COLORS} from '../../../constants/colors';

const BRAND_TYPE = {
    BRAND: 'BRAND',
    SUB_BRAND: 'SUB_BRAND'
}

const DROPZONE_STYLE = (error: boolean) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 40px',
    border: `2px dashed ${error ? COLORS.ERROR_PRIMARY : 'rgba(0, 0, 0, 0.12)'}`,
    width: '100%',
    minHeight: 250,
    borderRadius: 4,
})

const BRAND_PRODUCTS_TAKE = 25


export {BRAND_TYPE, DROPZONE_STYLE, BRAND_PRODUCTS_TAKE}
