import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, DialogActions, makeStyles, Typography} from '@material-ui/core';
import {COLORS} from '../constants/colors';
import {NavLink, useHistory} from 'react-router-dom';
import LogoDemo from '../assets/logo-demo.svg';
import Logo from '../assets/logo.svg';
import {ELEMENTS_HEIGHT, LINKS, LOCAL_STORAGE_KEYS, PLATFORM_TYPE} from '../constants';
import {useGlobalContext} from '../context/globalContext';
import {useLogOutMutation, UserType} from '../generated/graphql';
import {useLocalStorage} from '../helpers/hooks';
import DialogLogout from '../components/ui/Dialogs/DialogLogout';
import DialogNegativeAction from '../components/ui/Dialogs/DialogNegativeAction';
import LoadingSVG from '../assets/icons/loader.svg';

const MainNavigation: React.FC = (props): JSX.Element => {
    const classes = useStyles();
    const [storeToken,, removeStoreToken] = useLocalStorage(LOCAL_STORAGE_KEYS.STORE_TOKEN, null);
    const [token, , removeToken] = useLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, null);
    const [logOutOpen, setLogOutOpen] = useState<boolean>(false);
    const [logout] = useLogOutMutation();
    const {userData, setUserData} = useGlobalContext();
    const logoutFromShop = async () => {
        try {
            const {data} = await logout({
                variables: {
                    sessionTokens: [storeToken]
                }
            })
            if (data?.logout) {
                removeStoreToken();
                window.location.reload();
            }
        } catch (e) {
            console.log(e)
        }
    }
    const logOut = async () => {
        try {
            const {data} = await logout({
                variables: {
                    sessionTokens: storeToken ? [token, storeToken] : [token]
                }
            })
            if (data?.logout) {
                removeStoreToken();
                removeToken()
                setUserData(null)
                // history.push('/');
                window.location.reload();
            }
        } catch (e) {
            console.log(e)
        } finally {
            closeLogOutDialog()
        }
    };
    const closeLogOutDialog = () => setLogOutOpen(false);

    return (
        <Box className={classes.mainNavigation}>
            <NavLink to={'/'} className={classes.logo}>
                {(process.env.REACT_APP_MODE === PLATFORM_TYPE.DEMO)
                    ? <img className={classes.logoImg} src={LogoDemo} alt={'logo-demo'}/>
                    : <img className={classes.logoImg} src={Logo} alt={'logo'}/>
                }
            </NavLink>
            <ul className={classes.mainList}>
                {storeToken && userData?.type === UserType.Store &&
                <li onClick={() => logoutFromShop()} className={classes.logOutButton}>
                    <i className={'material-icons-outlined'}>logout</i>
                    <Typography variant={'subtitle2'}>Back to parent</Typography>
                </li>
                }
                <NavLink
                    to={'/'}
                    exact
                    activeClassName={classes.active}
                    isActive={(match, location) => (location.pathname === '/' || location.pathname === '/log-in')}>
                    <li><i className={'material-icons-outlined'}>home</i><Typography variant={'subtitle2'}>Home</Typography></li>
                </NavLink>
                {userData?.type !== UserType.Store &&
                <NavLink
                    to={'/brands'}
                    exact
                    activeClassName={classes.active}
                    isActive={(match, location) => !location.pathname.indexOf('/brands')}>
                    <li><i className={'material-icons-outlined'}>local_offer</i><Typography variant={'subtitle2'}>Brands</Typography></li>
                </NavLink>
                }
                <NavLink
                    to={'/products'}
                    exact activeClassName={classes.active}
                    isActive={(match, location) => !location.pathname.indexOf('/products')}>
                    <li><i className={'material-icons-outlined'}>shopping_bag</i><Typography variant={'subtitle2'}>Products</Typography></li>
                </NavLink>
                <NavLink
                    to={'/consignments'}
                    exact
                    activeClassName={classes.active}
                    isActive={(match, location) => !location.pathname.indexOf('/consignments')}>
                    <li><i className={'material-icons-outlined'}>sticky_note_2</i><Typography variant={'subtitle2'}>Consignments</Typography></li>
                </NavLink>
                {userData?.type !== UserType.Store &&
                    <>
                        <NavLink
                            to={'/stores'}
                            exact
                            activeClassName={classes.active}
                            isActive={(match, location) => !location.pathname.indexOf('/stores')}>
                            <li><i className={'material-icons-outlined'}>store</i><Typography variant={'subtitle2'}>Stores</Typography></li>
                        </NavLink>
                        <NavLink
                            to={'/invoices'}
                            exact
                            activeClassName={classes.active}
                            isActive={(match, location) => !location.pathname.indexOf('/invoices')}>
                            <li><i className={'material-icons-outlined'}>receipt_long</i><Typography variant={'subtitle2'}>Invoices</Typography></li>
                        </NavLink>
                        <NavLink
                            to={'/finances'}
                            exact
                            activeClassName={classes.active}
                            isActive={(match, location) => !location.pathname.indexOf('/finances')}>
                            <li><i className={'material-icons-outlined'}>payment</i><Typography variant={'subtitle2'}>Finances</Typography></li>
                        </NavLink>
                    </>
                }
                <NavLink
                    to={'/settings'}
                    exact
                    activeClassName={classes.active}
                    isActive={(match, location) => !location.pathname.indexOf('/settings')}>
                    <li><i className={'material-icons-outlined'}>settings</i><Typography variant={'subtitle2'}>Settings</Typography></li>
                </NavLink>
            </ul>
            <ul className={classes.list}>
                <NavLink
                    to={'/actions'}
                    exact
                    strict
                    activeClassName={classes.active}
                    isActive={(match, location) => !location.pathname.indexOf('/actions')}
                >
                    <li>
                        <img src={LoadingSVG} alt="actions"/>
                        <Typography variant={'subtitle2'}>Wait list</Typography>
                    </li>
                </NavLink>
                <NavLink
                    to={'/support'}
                    exact
                    strict
                    activeClassName={classes.active}
                    isActive={(match, location) => !location.pathname.indexOf('/support')}
                >
                    <li>
                        <i className={'material-icons-outlined'}>
                            contact_support
                        </i>
                        <Typography variant={'subtitle2'}>Support</Typography>
                    </li>
                </NavLink>
                <li
                    onClick={() => window.location.replace(LINKS.LANDING_BLOG)}>
                    <i className={'material-icons-outlined'}>
                        article
                    </i>
                    <Typography variant={'subtitle2'}>Blog</Typography>
                </li>
            </ul>
            <ul>
                <hr/>
                <li onClick={() => setLogOutOpen(true)}>
                    <i className={'material-icons-outlined'}>logout</i>
                    <Typography variant={'subtitle2'}>Log out</Typography>
                </li>
            </ul>
            {logOutOpen &&
                <DialogNegativeAction
                    open={logOutOpen}
                    onClose={closeLogOutDialog}
                    mainAction={logOut}
                    title={'Log out?'}
                    subtitle={'Are you sure you want to log out?'}
                    buttonText={'Yes, log out'}
                />
            }
        </Box>
    );
};

export default MainNavigation;

const useStyles = makeStyles<Theme>(() => ({
    mainNavigation: {
        gridArea: 'side',
        backgroundColor: COLORS.WHITE,
        display: 'flex',
        justifyContent: 'space-between',
        flexFlow: 'column',
        '& ul': {
            padding: 10
        },
        '& li': {
            cursor: 'pointer',
            width: '100%',
            height: 50,
            marginBottom: 5,
            display: 'flex',
            flexFlow: 'wrap',
            alignItems: 'center',
            borderRadius: 16,
            padding: '0px 25px',
            transition: 'all .3s ease',
        },
        '& h5': {
            fontWeight: '500 !important'
        },
        '& li:hover': {
            '& i, h6': {
                color: COLORS.PRIMARY,
            },
            background: COLORS.PRIMARY_ACTIVE_BACK,
        },
        '& li:focus': {
            '& i, h6': {
                color: COLORS.PRIMARY,
            },
            background: COLORS.PRIMARY_ACTIVE_BACK,
        },
        '& i': {
            color: 'rgba(0, 0, 0, 0.87)',
            marginRight: 25
        },
        '& img': {
            color: 'rgba(0, 0, 0, 0.87)',
            marginRight: 25
        }
    },
    logo: {
        height: ELEMENTS_HEIGHT.HEADER,
        display: 'flex',
        justifyContent: 'center',
    },
    logOutButton: {
        cursor: 'pointer',
        '& i, h6': {
            color: `${COLORS.ERROR_PRIMARY} !important`,
        },
        '&:hover': {
            '& i, h6': {
                color: COLORS.ERROR_PRIMARY,
            },
            background: `${COLORS.ERROR_LIGHT} !important`
        },
    },
    active: {
        '& li': {
            background: COLORS.PRIMARY_ACTIVE_BACK,
        },
        '& h6, i': {
            color: COLORS.PRIMARY
        }
    },
    mainList: {
        marginBottom: 'auto'
    },
    logoImg: {
        height: 56,
        width: 'auto',
        alignSelf: 'center'
    }
}));
