import React from 'react';
import './home.scss';
import Upload from './images/upload.svg';
import CityStatistic from './images/CityStatistic.svg';
import CustomSelect from '../../components/ui/Select/Select';
import LineChart from '../../components/ui/LineChart/LineChart';

export default function HomePage() {
    return (
        <div className={'Home'}>
            <div className="comingSoon">
                <div className="Text">Page coming soon</div>
            </div>
            <div className="title">Product Info</div>
            <div className="productInfoSection">
                <div className="total">
                    <div className="subtitle">
                        <div className="imgWrapper total">
                            <i className={'material-icons iconShop'}>shopping_bag</i>
                        </div>
                        <div className="subTitleText">Total</div>
                    </div>
                    <div className="statistics">
                        <span className="Number">100,820</span>
                        <span className="greenText">+12% </span>
                        <span className="period">vs last 7 days</span>
                    </div>
                </div>
                <div className="success">
                    <div className="subtitle">
                        <div className="imgWrapper success"><img src={Upload}/></div>
                        <div className="subTitleText">Successfully loaded</div>
                    </div>
                    <div className="statistics">
                        <span className="Number">90,782</span>
                        <span className="greenText">+5% </span>
                        <span className="period">vs last 7 days</span>
                    </div>
                </div>
                <div className="errors">
                    <div className="subtitle">
                        <div className="imgWrapper errors">
                            <i className={'material-icons iconError'}>report_problem</i>
                        </div>
                        <div className="subTitleText">With errors</div>
                    </div>
                    <div className="statistics">
                        <span className="Number">904</span>
                        <span className="greenText">+14% </span>
                        <span className="period">vs last 7 days</span>
                    </div>
                </div>
                <div className="unload">
                    <div className="subtitle">
                        <div className="imgWrapper unloaded">
                            <i className={'material-icons iconsUnloaded'}>close</i>
                        </div>
                        <div className="subTitleText">Unloaded</div>
                    </div>
                    <div className="statistics">
                        <span className="Number">134</span>
                        <span className="redText">-5% </span>
                        <span className="period">vs last 7 days</span>
                    </div>
                </div>
            </div>
            <div className="Statistic"  >
                <div className="statistic-title title">
                    <p>Statistic</p>
                    <CustomSelect />
                </div>
                <div className="city-statistics title">
                    City Purchases Statistics
                </div>
                <div className="wrapperContent">
                    <LineChart />
                </div>
                <div className="wrapperCityContent">
                    <img src={CityStatistic} className={'CityStatistic'}/>
                </div>
            </div>
        </div>
    );
}
