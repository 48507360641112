import React, {ReactChildren, ReactNode} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';

type CustomDrawerProps = {
    children: ReactNode | ReactChildren;
    open: boolean;
    onClose: any;
}

const CustomDrawer: React.FC<CustomDrawerProps> = (props: CustomDrawerProps): JSX.Element => {
    const {children, open, onClose} = props;
    return (
        <Drawer
            onClick={(event) => event.stopPropagation()}
            BackdropProps={{style: {opacity: 0}}}
            anchor={'right'}
            open={open}
            onClose={onClose}
        >
            {children}
        </Drawer>
    );
}

export default CustomDrawer;

const useStyles = makeStyles<Theme>(() => ({}));
