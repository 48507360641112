import React from 'react';
import {ActionsType} from '../../../types/enum';
import Creating from '../images/creating.svg';
import Deleting from '../images/delete.svg';
import Sending from '../images/sending.svg';

const RenderActionIcon: React.FC<{type: ActionsType}> = ({type}): any => {
    switch (type) {
        case ActionsType.creating: {
            return <img src={Creating} alt={type}/>
        }
        case ActionsType.deleting: {
            return <img src={Deleting} alt={type}/>
        }
        case ActionsType.sending: {
            return <img src={Sending} alt={type}/>
        }
    }
};

export default RenderActionIcon;

