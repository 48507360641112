import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {
    FormControl, FormControlProps, FormHelperText, FormHelperTextProps, InputLabel, InputLabelProps,
    makeStyles,
    OutlinedInput,
    OutlinedInputProps, withStyles,
} from '@material-ui/core';
import {Field, FieldProps} from 'formik';
import clsx from 'clsx';

import {useGlobalContext} from '../../context/globalContext';
import CustomHelperText from './FormHelperText';
import {REGEX} from '../../constants/regex';

export type FormInputType = OutlinedInputProps & {
    name: string;
    helperText?: any;
    label?: string;
    formControlProps?: FormControlProps;
    inputLabelProps?: InputLabelProps;
    helperTextProps?: FormHelperTextProps;
    blur?: () => void;
}

const FormInput: React.FC<FormInputType> = (props: FormInputType): JSX.Element => {
    const classes = useStyles();
    const {darkTheme} = useGlobalContext();
    const {name, helperText, label, formControlProps, inputLabelProps, helperTextProps, blur, ...rest} = props;
    const preventNonNumericalInput = (event) => {
        if (props?.inputProps?.type && props.inputProps.type === 'number') {
            event = event || window.event;
            const charCode = (typeof event.which == "undefined") ? event.keyCode : event.which;
            const charStr = String.fromCharCode(charCode);
            if (!charStr.match(/^[0-9.]*$/))
                event.preventDefault();
        }
    }
    return <Field name={name}>
        {({field, meta, form}: FieldProps) => {
            const {onBlur, ...fieldRest} = field;
            return <MuiFormControl
                className={clsx()}
                variant="outlined"
                error={!!(meta.error && meta.touched)}
                {...formControlProps}
            >
                {label &&
                <InputLabel
                    classes={{
                        root: clsx(classes.InputLabel, {[classes.darkLabel]: darkTheme})
                    }}
                    {...inputLabelProps}
                >{label}</InputLabel>
                }
                <OutlinedInput
                    classes={{
                        input: clsx(
                            {[classes.darkAutoInput]: darkTheme},
                            {[classes.lightAutoInput]: !darkTheme},
                        ),
                        adornedEnd: classes.adornedEnd
                    }}
                    onBlur={(event) => {
                        if(blur) {
                            blur()
                        }
                        onBlur(event);
                    }}
                    {...fieldRest}
                    onKeyPress={preventNonNumericalInput}
                    {...rest}
                />
                <CustomHelperText
                    meta={meta}
                    message={helperText}
                    {...helperTextProps}
                />
            </MuiFormControl>
        }}
    </Field>;
};

export default FormInput;

const MuiFormControl = withStyles({
    root: {
        minHeight: 73,
        flex: 1
    }
})(FormControl)

const MuiFormHelperText = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 12,
        textTransform: 'none',
        letterSpacing: 0,
        lineHeight: 1.4,
        marginLeft: 16,
        marginTop: 0
    }
})(FormHelperText)

const useStyles = makeStyles<Theme>(theme => ({

    darkAutoInput: {
        '&:-webkit-autofill': {
            boxShadow: '0 0 0 100px #424242 inset',
            color: 'white',
            '-webkit-text-fill-color': 'white'
        }
    },
    lightAutoInput: {
        '&:-webkit-autofill': {
            boxShadow: '0 0 0 100px white inset',
            color: 'rgba(0, 0, 0, 0.87)',
            '-webkit-text-fill-color': 'rgba(0, 0, 0, 0.87)'
        }
    },
    InputLabel: {
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 16
    },
    darkLabel: {
        color: 'white'
    },
    adornedEnd: {
        paddingRight: 12
    }
}))


