import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, Tooltip, TooltipProps} from '@material-ui/core';
import {COLORS} from '../../constants/colors';

const MuiTooltip: React.FC<TooltipProps> = (props: TooltipProps): JSX.Element => {
    const classes = useStyles();
    const {children, ...rest} = props;
    return (
        <Tooltip
            arrow
            classes={classes}
            placement="top"
            {...rest}
        >
            {children}
        </Tooltip>
    );
};


const useStyles = makeStyles<Theme>(() => ({
    arrow: {
        color: COLORS.TOOLTIP_BACKGROUND
    },
    tooltip: {
        textAlign: 'center',
        padding: '8px 16px',
        backgroundColor: COLORS.TOOLTIP_BACKGROUND
    }
}));

export default MuiTooltip;

