import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, Typography} from '@material-ui/core';
import DialogLayout, {DialogLayoutProps} from '../../../layouts/DialogLayout';
import {DialogIconWrapper} from './DialogEndActionType';
import Hello from '../../../assets/icons/hello.svg';
import CustomButton from '../Buttons/Button';
import {LINKS} from '../../../constants';

const DialogGreetings: React.FC<DialogLayoutProps> = (props: DialogLayoutProps): JSX.Element => {
    const classes = useStyles();
    const getConsultation = () => {
        window.open(LINKS.CONSULTING, '_blank');
        props.onClose('', 'escapeKeyDown');
    }
    return (
        <DialogLayout
            width={525}
            {...props}
        >
            <div style={{
                position: 'relative',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '56px 27px'
            }}>
                <DialogIconWrapper
                    icon={Hello}
                    className={'SuccessIconWrap'}
                />
                <Typography align={'center'} variant={'h4'} >Hey!</Typography>
                <Typography align={'center'} variant={'h5'} style={{margin: '18px 0 0 0'}}>
                    You have just landed on our demo platform.
                    In this version, you can familiarize yourself
                    with the basic functions of our platform
                </Typography>
                <Typography align={'center'} variant={'h5'} style={{margin: '18px 0 32px 0'}}>
                    If you need an individual consultation, you can make a booking through special service <br/>
                </Typography>
                <CustomButton
                    variant={'outlined'}
                    onClick={getConsultation}
                    style={{
                        width: '100%'
                    }}
                >Get consultation</CustomButton>
                <CustomButton
                    variant={'contained'}
                    onClick={() => props.onClose('', 'escapeKeyDown')}
                    style={{
                        marginTop: 16,
                        width: '100%'
                    }}

                >Confirm</CustomButton>
            </div>
        </DialogLayout>
    );
};

export default DialogGreetings;

const useStyles = makeStyles<Theme>(() => ({}));
