import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {useGlobalContext} from '../context/globalContext';
import {UserType} from '../generated/graphql';
import EmptyBrandsPage from '../assets/empty-brands-page.svg';
import EmptyProductPage from '../assets/empty-products-page.svg';
import EmptyStorePage from '../assets/no-stores.svg';
import EmptyBrandsManufacturerPage from '../assets/empty-brands-manufacturer.svg';
import UploadFeed from '../assets/icons/upload-feed.svg';
import {MESSAGES} from '../constants/messages';
import {COLORS} from '../constants/colors';
import CustomButton from './ui/Buttons/Button';
import {useHistory} from 'react-router-dom';
import CustomPaper from './ui/Paper';
import {useUIContext} from '../context/UIContext';

type EmptyPageProps = {
    type: EmptyPageType
}

const EmptyPage: React.FC<EmptyPageProps> = (props: EmptyPageProps): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const {type} = props;
    const {userData} = useGlobalContext();
    const {setUploadFeed} = useUIContext();
    const [data, setData] = useState(configureData(type, userData?.type));
    const handleClick = ():void => {
        history.push(data?.button?.link);
    }
    return (
        <CustomPaper className={classes.emptyPage}>
            <img src={data?.img} alt={'empty-img'} className={classes.img}/>
            <Typography variant={'subtitle2'} className={classes.title}>{data?.title}</Typography>
            <Typography variant={'subtitle1'} className={classes.subtitle}>{data?.subtitle}</Typography>
            <Box className={classes.buttonWrapper}>
                {data?.uploadFeed &&
                <CustomButton
                    variant={'outlined'}
                    startIcon={<img src={UploadFeed} style={{marginRight: 5, marginBottom: 0}}/>}
                    onClick={() => setUploadFeed(true)}
                    className={classes.button}
                >Upload feed</CustomButton>
                }
                {data?.button &&
                <CustomButton
                    variant={'contained'}
                    className={classes.button}
                    onClick={handleClick}
                >
                    {data?.button?.text}
                </CustomButton>
                }
            </Box>
        </CustomPaper>
    );
};

export enum EmptyPageType {
    Brands = 'Brands',
    Products = 'Products',
    Store = 'Store',
    Consignments = 'Consignments'
}

const configureData = (type: EmptyPageType, userData: UserType) => {
    switch (type) {
        case EmptyPageType.Brands: {
            return {
                title: MESSAGES.EMPTY_BRANDS_TITLE,
                subtitle: MESSAGES.EMPTY_BRANDS_SUBTITLE,
                img: userData !== UserType.Manufacturer ? EmptyBrandsManufacturerPage : EmptyBrandsPage,
                button: userData !== UserType.Store ? {
                    text: 'Create brand',
                    link: '/brands/create'
                } :  null
            }
        }
        case EmptyPageType.Products: {
            return {
                title: 'You have no products yet',
                subtitle: userData !== UserType.Store ? MESSAGES.EMPTY_PRODUCTS_SUBTITLE_NO_STORE : MESSAGES.EMPTY_PRODUCTS_SUBTITLE,
                img: userData !== UserType.Store ? EmptyBrandsManufacturerPage : EmptyProductPage,
                button: userData !== UserType.Store ? {
                    text: 'Create product',
                    link: '/products/create'
                } : null,
                uploadFeed: userData !== UserType.Store
            }
        }
        case EmptyPageType.Consignments: {
            return {
                title: 'You have no consignments yet',
                subtitle: userData !== UserType.Store ? MESSAGES.EMPTY_CONS_SUBTITLE_NO_STORE : MESSAGES.EMPTY_CONS_SUBTITLE,
                img: userData !== UserType.Store ? EmptyBrandsManufacturerPage : EmptyProductPage,
                button: userData !== UserType.Store ? {
                    text: 'Create consignment',
                    link: '/consignments/create'
                } : null,
                uploadFeed: userData !== UserType.Store
            }
        }
        case EmptyPageType.Store: {
            return {
                title: 'You have no stores yet',
                subtitle: 'There will be stores that you create',
                img: EmptyStorePage,
                button: userData !== UserType.Store ? {
                    text: 'Add store',
                    link: '/stores/create'
                } : null
            }
        }
    }
}

export default EmptyPage;

const useStyles = makeStyles<Theme>(() => ({
    emptyPage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column',
        width: '100%',
        height: '100%',
        background: 'white',
        '&>*': {
            margin: '0 7.5px'
        }
    },
    buttonWrapper: {
        display: 'flex'
    },
    title: {
        textAlign: 'center',
        lineHeight: 3
    },
    subtitle: {
        color: COLORS.TEXT_SILVER,
        textAlign: 'center',
        marginBottom: 16,
        maxWidth: 360,
        margin: '0 auto',
        lineHeight: 1.6
    },
    img: {
        alignSelf: 'center'
    },
    button: {
        margin: '0 8px'
    }

}));
