import React, {useCallback, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, IconButton, makeStyles, Typography} from '@material-ui/core';
import CustomButton from '../../../components/ui/Buttons/Button';
import DeleteDialog from '../../../components/ui/Dialogs/DialogDelete';
import {COLORS} from '../../../constants/colors';
import {useHistory} from 'react-router-dom';
import {BrandStatus} from '../../../generated/graphql';
import {ProductType} from '../../../types/enum';
import {useUIContext} from '../../../context/UIContext';

type SelectedDetails = {
    selected: any
    setSelected: any,
    type: ProductType,
    deleteProducts: any
}

const SelectedDetails: React.FC<SelectedDetails> = (props: SelectedDetails): JSX.Element => {
    const classes = useStyles();
    const {selected, setSelected, type, deleteProducts} = props;
    const history = useHistory();
    const {setUnconfirmedWarning, showSnackBar} = useUIContext();
    const [deleteDialog, setDeleteDialog] = useState(false);
    const unSelect = useCallback((item) => {
        setSelected(prevState => prevState.filter(i => i.id !== item?.id));
    }, [selected]);
    const sendProducts = () => {
        const brandNames = selected.filter(item => item?.brand?.status === BrandStatus.AutoConfirmed).map(item => item?.brand?.name)
        if (brandNames?.length === 0 || type !== ProductType.product) {
            history.push(type === ProductType.product ? '/products/send-products' : '/consignments/send-consignments', {
                selected,
                type
            })
        } else {
            setUnconfirmedWarning({
                open: true,
                // @ts-ignore
                brandNames: [...new Set(brandNames)]
            })
        }
    }
    return (
        <Box className={classes.selectedItems}>
            <Typography variant={'h4'} className={classes.selectedCount}>
                <span>Selected:</span> {selected.length} {type === ProductType.product ? 'products' : 'consignments'}
            </Typography>
            <ul className={classes.selectedList}>
                {selected.map((item, index) => (
                    <li key={index} className={classes.selectedItem}>
                        <Typography variant={'subtitle1'} className={classes.selectedText}>{item.name}</Typography>
                        <IconButton
                            onClick={() => unSelect(item)}
                            size={'small'}>
                            <i className={`material-icons hide`}>close</i>
                        </IconButton>
                    </li>
                ))}
            </ul>
            <Box className={classes.selectedActionsWrapper}>
                <CustomButton
                    color={'secondary'}
                    onClick={() => setDeleteDialog(true)}
                >DELETE</CustomButton>
                <CustomButton
                    variant={'outlined'}
                    onClick={sendProducts}
                    style={{marginLeft: '24px', minWidth: 94}}
                >SEND</CustomButton>
            </Box>
            {deleteDialog &&
            <DeleteDialog
                onClose={() => setDeleteDialog(false)}
                open={deleteDialog}
                action={deleteProducts}
                title={'Delete?'}
                subtitle={'This record will be permanently deleted. You can’t undo this action'}
            />
            }
        </Box>
    );
};

export default SelectedDetails;

const useStyles = makeStyles<Theme>(() => ({
    selectedItems: {
        position: 'relative',
        background: COLORS.WHITE,
        borderRadius: 8,
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '24px 0 0 0',
    },
    selectedText: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: COLORS.TEXT_SILVER
    },
    selectedCount: {
        padding: '24px 34px',
        '& span': {
            color: 'rgba(0, 0, 0, 0.47)',
        }
    },
    selectedList: {
        color: 'rgba(0, 0, 0, 0.47)',
        flex: '1 1 auto',
        overflowY: 'scroll',
        height: 0,
        '&::-webkit-scrollbar': {
            display: 'none',
            '-ms-overflow-style': 'none',
            scrollbarWidth: 'none',
        }
    },
    selectedItem: {
        padding: '8px 36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&:hover': {
            background: 'rgba(0, 0, 0, 0.04)'
        },
        '& .hide': {
            visibility: 'hidden'
        },
        '&:hover .hide': {
            visibility: 'visible'
        }
    },
    selectedActionsWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingRight: 24,
        paddingBottom: 24,
    },
}));
