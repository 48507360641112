import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {
    Box,
    CircularProgress,
    IconButton,
    InputAdornment,
    makeStyles,
    Typography
} from '@material-ui/core';
import {Form, Formik, FormikValues} from 'formik';
import {useParams, Link} from 'react-router-dom';
import FormBoxInput from '../ui/FormBoxInput';
import AlternateEmailRoundedIcon from '@material-ui/icons/AlternateEmailRounded';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import FormCheckBox from '../ui/FormCheckBox';
import SubmitButton from '../ui/Buttons/SubmitButton';
import {sleep} from '../../helpers';
import {QUERY_PARAMS, LINKS, LOCAL_STORAGE_KEYS} from '../../constants';
import {RegisterMutationVariables, useRegisterMutation, UserType} from '../../generated/graphql';
import {SIGN_UP_SCHEMA} from '../../constants/validation_schema';
import {useLocalStorage} from '../../helpers/hooks';
import {useUIContext} from '../../context/UIContext';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

const SignUpForm: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const {showSnackBar} = useUIContext();
    const [register] = useRegisterMutation();
    const [, setToken] = useLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, null);
    const [firstOnPlatform, setFirstOnPlatform] = useLocalStorage(LOCAL_STORAGE_KEYS.FIRST_ON_PLATFORM, null);
    const {type}: any = useParams();
    const [showPassword, setShowPassword] = useState(false);
    const toggleShowPassword = () => setShowPassword(prevState => !prevState);
    const navigateServiceRules = () => window.open(LINKS.SERVICE_RULES);

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
                password_confirmation: '',
                agreement: false
            }}
            validationSchema={SIGN_UP_SCHEMA}
            onSubmit={async (values: FormikValues) => {
                try {
                    await sleep(1000)
                    const url = new URL(window.location.href);
                    const arr = QUERY_PARAMS.filter((item: string) => url.searchParams.has(item));
                    const variables: RegisterMutationVariables = {
                        email: values.email,
                        password: values.password,
                        userType: type as UserType,
                    };
                    arr.forEach((item: string) => {
                        variables[item] = url.searchParams.get(item);
                    });
                    const {data} = await register({variables: variables});
                    if (data?.register) {
                        setToken(data?.register?.token);
                        if (!firstOnPlatform) {
                            setFirstOnPlatform(true);
                        }
                        window.location.reload()
                    }
                } catch (e) {
                    showSnackBar(e?.message);
                }
            }}
        >
            {({isValid, isSubmitting, setFieldValue, values, handleSubmit, errors, touched}) => {
                return (
                    <Form className={classes.formContainer} onSubmit={handleSubmit}>
                        <FormBoxInput
                            fullWidth
                            name={'email'}
                            icon={<AlternateEmailRoundedIcon/>}
                            label={'Email'}
                            labelWidth={42}
                        />
                        <FormBoxInput
                            fullWidth
                            name={'password'}
                            icon={<VpnKeyOutlinedIcon/>}
                            label={'Password'}
                            labelWidth={73}
                            type={!showPassword ? 'password' : 'text'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleShowPassword}
                                    >
                                        {!showPassword ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <FormBoxInput
                            fullWidth
                            name={'password_confirmation'}
                            icon={<VpnKeyOutlinedIcon/>}
                            label={'Confirm password'}
                            labelWidth={135}
                            type={!showPassword ? 'password' : 'text'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleShowPassword}
                                    >
                                        {!showPassword ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <Box className={classes.checkBoxWrapper}>
                            <FormCheckBox
                                color={'primary'}
                                name={'agreement'}
                                label={<Typography variant="h5" style={{color: 'rgba(0,0,0,0.6)'}}>
                                    Accept <a onClick={navigateServiceRules} href={'#'}>User Agreement</a>
                                </Typography>}
                            />
                        </Box>
                        <SubmitButton
                            type={'submit'}
                            disabled={!isValid || isSubmitting}
                        >
                            {isSubmitting ? <CircularProgress color={'primary'} size={24}/> : 'Sign Up'}
                        </SubmitButton>
                        <Typography className={classes.haveAcc}>Already have an account? <Link to={'/log-in'}>Log
                            in!</Link></Typography>
                    </Form>
                )
            }}
        </Formik>
    );
};

export default SignUpForm;

const useStyles = makeStyles<Theme>(theme => ({
    formContainer: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 2,
        width: '100%',
        '&>.MuiPaper-root': {
            marginTop: 16
        }
    },
    checkBoxWrapper: {
        height: 65,
        width: '100%'
    },
    haveAcc: {
        color: '#636465',
        marginTop: 24,
    }
}));
