import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, DialogTitle, makeStyles, Typography} from '@material-ui/core';
import DialogLayout, {DialogLayoutProps} from '../../../layouts/DialogLayout';
import {Form, Formik, Field, FieldProps} from 'formik';
import {useUIContext} from '../../../context/UIContext';
import {UPLOAD_FEED_SCHEMA} from '../../../constants/validation_schema';
import {useHistory} from 'react-router-dom';
import CustomDropzone from '../CustomDropzone';
import UploadFeed from '../../../assets/upload-files.svg';
import {DROPZONE_UPLOAD_FEED} from '../../../styles';
import {COLORS} from '../../../constants/colors';
import CustomButton from '../Buttons/Button';
import {useParseCsvFeedFileMutation} from '../../../generated/graphql';
import {uploadFile} from '../../../helpers';

type DialogUploadFeedProps = DialogLayoutProps & {

}

const DialogUploadFeed: React.FC<DialogUploadFeedProps> = (props: DialogUploadFeedProps): JSX.Element => {
    const classes = useStyles();
    const {open, ...rest} = props;
    const {showSnackBar} = useUIContext();
    const [parseCSVFeedFile] = useParseCsvFeedFileMutation();
    const history = useHistory();
    return (
        <DialogLayout
            open={open}
            maxWidth={false}
            width={750}
            {...rest}
        >
            <Box className={classes.wrapper}>
                <Typography variant={'h4'}>Upload feed</Typography>
                <Formik
                    initialValues={{
                        file: {
                            filepath: '',
                            preview: ''
                        }
                    }}
                    validationSchema={UPLOAD_FEED_SCHEMA}
                    onSubmit={async (values) => {
                        try {
                            const result = await parseCSVFeedFile({
                                variables: {
                                    filepath: values?.file?.filepath
                                }
                            })

                            if (result?.data?.parseCSVFeedFile?.length > 0) {
                                history.push('/upload-feed', {
                                    filepath: result?.data?.parseCSVFeedFile,
                                    fileName: values?.file?.preview
                                })
                            }
                        } catch (e) {
                            showSnackBar(e.message);
                        } finally {
                            props.onClose('', 'escapeKeyDown')
                        }
                    }}
                >
                    {({handleSubmit, setFieldValue}) => {
                        return (<Form onSubmit={handleSubmit}>
                            <Field name={'file'}>
                                {({form, meta, field}: FieldProps) => (
                                    <CustomDropzone
                                        accept={['.csv']}
                                        fieldProps={{form, meta, field}}
                                        style={DROPZONE_UPLOAD_FEED}
                                        renderContent={(open: () => void) =>
                                            <>
                                                <img src={UploadFeed} alt="UploadFeed"/>
                                                <Typography className={classes.formats}>required formats: csv</Typography>
                                                <Typography variant={'h5'} style={{color: 'rgba(0, 0, 0, 0.6)'}}>
                                                    Drop your file or click to Browse
                                                </Typography>
                                                <CustomButton
                                                    className={classes.selectFilesButton}
                                                    variant={'text'}
                                                    color={'primary'}
                                                    onClick={open}
                                                >
                                                    Select files
                                                </CustomButton>
                                            </>
                                        }
                                        multiple={false}
                                        onDrop={(acceptedFiles) => {
                                            uploadFile(acceptedFiles, (data) => {
                                                const parseFileObj = {
                                                    filepath: data.filePath,
                                                    preview: data.originalName
                                                };
                                                setFieldValue('file', parseFileObj)
                                                handleSubmit()
                                            })
                                        }}
                                    />
                                )}
                            </Field>
                        </Form>);
                    }}
                </Formik>
            </Box>
        </DialogLayout>
    );
};

export default DialogUploadFeed;

const useStyles = makeStyles<Theme>(() => ({
    wrapper: {
        padding: '24px 56px 40px 56px',
        '& img': {
            height: 80,
            width: 80,
            alignSelf: 'center'
        }
    },
    dialogTitle: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 0 20px 0  ',
        fontWeight: 'normal'
    },
    formats: {
        color: COLORS.TEXT_LIGHT_SILVER,
        textTransform: 'none',
        fontSize: 12,
        margin: '16px 0'
    },
    selectFilesButton: {
        marginTop: 16,
        alignSelf: 'center'
    }

}));
