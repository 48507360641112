import React from 'react';
import {
    Switch,
    Route,
    Redirect, useLocation,
} from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import {useGlobalContext} from './context/globalContext';
import SignUpPage from './pages/auth/SignUpPage';
import SelectUserTypePage from './pages/auth/SelectUserTypePage';
import SelectPlanPage from './pages/auth/SelectPlanPage';
import ForgotPasswordPage from './pages/auth/ForgotPasswordPage';
import LoginPage from './pages/auth/LoginPage';
import {REGEX} from './constants/regex';

const App: React.FC = (): JSX.Element => {
    const {userData} = useGlobalContext();
    const {pathname} = useLocation();
    return (
        <Switch>
            {userData ?
                <Route component={MainLayout}/>
                :
                <>
                    <Route exact path={'/sign-up/:type'} component={SignUpPage}/>
                    <Route exact path={'/select-type'} component={SelectUserTypePage} />
                    <Route exact path={'/select-plan'} component={SelectPlanPage} />
                    <Route exact path={'/forgot-password/send-code'} component={ForgotPasswordPage} />
                    <Route exact path={'/forgot-password/send-email'} component={ForgotPasswordPage} />
                    <Route exact path={'/log-in'} component={LoginPage} />
                    <Route exact={Boolean(pathname.match(REGEX.NOT_AUTHORIZED_URLS))} path="/">
                        <Redirect to="/log-in"></Redirect>
                    </Route>
                    {/*<Redirect from="/" to="/log-in" />*/}
                </>
            }
        </Switch>
    );
}

export default App;
