import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import FormLayout from '../../layouts/FormLayout';
import {useLocation, useParams} from 'react-router-dom';
import Question from './components/Question';
import {QUESTIONS} from './helpers/constants';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ContactWithUsSection from './components/ContactWithUsSection';

const QuestionPage: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const type = useParams<string>();
    const {state} = useLocation();
    // @ts-ignore
    const {title} = state;
    return (
        <FormLayout
            crumbsDetails={[
                {message: 'Support', link: ''},
                {message: title, link: ''}
            ]}
        >
            <Box className={classes.QuestionPage}>
                <Box>
                    <Typography variant={'h3'} className={classes.Title}>{title}</Typography>
                    <Question questions={QUESTIONS}/>
                </Box>
                <Box>
                    <Typography variant={'h3'} className={classes.Title}>Can`t find what you are looking for?</Typography>
                    <ContactWithUsSection
                        icon={<ChatBubbleOutlineIcon />}
                        title={'Chat with us'}
                        subTitle={'Status: Not available.'}
                        push={'/support/send-request'}
                        style={{marginBottom: 32}}
                        disabled
                    />
                    <ContactWithUsSection
                        icon={<MailOutlineIcon />}
                        title={'Email us'}
                        subTitle={'We\'ll get back to you within 24 hrs.'}
                        push={'/support/send-request'}
                    />
                </Box>
            </Box>
        </FormLayout>
    );
};

export default QuestionPage;

const useStyles = makeStyles<Theme>(() => ({
    QuestionPage: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: 48
    },
    Title: {
        marginBottom: 32
    }
}));
