/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};


export type Account = Node & {
  __typename?: 'Account';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  status: AccountStatus;
  walletStorage: AccountWalletStorageType;
  sessions?: Maybe<Array<AccountSession>>;
  billingBalance: Scalars['Float'];
  billingTransactions?: Maybe<Array<BillingTransaction>>;
  consignmentsBrand: Brand;
  contacts: ContactNodes;
  stores: ContactNodes;
  email: Scalars['String'];
  blockchainAddress: Scalars['String'];
  blockchainTransactions: ProductTransactionNodes;
  type: UserType;
  avatarFilePath?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  cityState?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};


export type AccountContactsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type AccountStoresArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type AccountBlockchainTransactionsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  filter?: Maybe<BlockchainTransactionsFilter>;
};

export type AccountSession = {
  __typename?: 'AccountSession';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  account: Account;
  ipAddr: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  userAgent?: Maybe<UserAgent>;
  expiresAt: Scalars['Date'];
};

export enum AccountStatus {
  Active = 'ACTIVE',
  Disabled = 'DISABLED'
}

export enum AccountWalletStorageType {
  Cloud = 'CLOUD',
  Device = 'DEVICE'
}

export type AuthResult = {
  __typename?: 'AuthResult';
  account: Account;
  token: Scalars['String'];
};

export type BillingTransaction = Node & {
  __typename?: 'BillingTransaction';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  amount: Scalars['Int'];
  incoming: Scalars['Boolean'];
  comment: Scalars['String'];
};

export type BlockchainTransactionsFilter = {
  address?: Maybe<Scalars['String']>;
};

export type Brand = Node & {
  __typename?: 'Brand';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  owner: Account;
  status: BrandStatus;
  name: Scalars['String'];
  logoPath?: Maybe<Scalars['String']>;
  parentBrand?: Maybe<Brand>;
  childrenBrands?: Maybe<Array<Maybe<Brand>>>;
  isConsignmentsBrand: Scalars['Boolean'];
  certificateBlockchainId: Scalars['String'];
  products: ProductNodes;
};


export type BrandProductsArgs = {
  querySearch?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};

export type BrandNodes = {
  __typename?: 'BrandNodes';
  data?: Maybe<Array<Brand>>;
  total: Scalars['Int'];
};

export enum BrandStatus {
  AutoConfirmed = 'AUTO_CONFIRMED',
  Confirmed = 'CONFIRMED',
  Processing = 'PROCESSING',
  Blocked = 'BLOCKED'
}

export type Contact = Node & {
  __typename?: 'Contact';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  name: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  target: Account;
};

export type ContactNodes = {
  __typename?: 'ContactNodes';
  data?: Maybe<Array<Contact>>;
  total: Scalars['Int'];
};

export type CostComplexity = {
  min?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
};


export type GenerateEmailCodeResult = {
  __typename?: 'GenerateEmailCodeResult';
  result: Scalars['Boolean'];
  expiresAt: Scalars['Date'];
};

export type Mutation = {
  __typename?: 'Mutation';
  echo: Scalars['String'];
  register: AuthResult;
  registerOrLoginClient?: Maybe<AuthResult>;
  login: AuthResult;
  logout: Scalars['Boolean'];
  sessionCreate: AuthResult;
  changePassword: AuthResult;
  generateEmailCode: GenerateEmailCodeResult;
  resetPassword: AuthResult;
  updateAccount: AuthResult;
  notifyTransaction: Scalars['Boolean'];
  brandCreate: Brand;
  brandDelete: Scalars['Boolean'];
  productsCreateRange: Scalars['Boolean'];
  productsDelete: Scalars['Boolean'];
  productsSend: Array<Scalars['Boolean']>;
  contactAdd: Contact;
  contactDelete: Scalars['Boolean'];
  storeCreate: Contact;
  storeDelete: Scalars['Boolean'];
  loginAsStore: AuthResult;
  topUpBalance: TopUpBalanceResult;
  topUpBalanceByInvoice: TopUpBalanceResult;
  /** Parse CSV feed file and return all column names */
  parseCSVFeedFile?: Maybe<Array<Scalars['String']>>;
  productsCreateByUploadedCSVFeed?: Maybe<Scalars['Boolean']>;
  generateWallet: WalletKeys;
};


export type MutationEchoArgs = {
  text: Scalars['String'];
};


export type MutationRegisterArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  userType: UserType;
};


export type MutationRegisterOrLoginClientArgs = {
  email: Scalars['String'];
  step: RegisterOrLoginClientStep;
  oneTimeCode?: Maybe<Scalars['String']>;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLogoutArgs = {
  sessionTokens?: Maybe<Array<Scalars['String']>>;
  sessionIds?: Maybe<Array<Scalars['Int']>>;
};


export type MutationSessionCreateArgs = {
  isApiKey: Scalars['Boolean'];
};


export type MutationChangePasswordArgs = {
  password: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationGenerateEmailCodeArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
  emailCode: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationUpdateAccountArgs = {
  data: UpdateAccountInput;
};


export type MutationNotifyTransactionArgs = {
  txHash: Scalars['String'];
};


export type MutationBrandCreateArgs = {
  name: Scalars['String'];
  logoPath?: Maybe<Scalars['String']>;
  contactsInfo: Scalars['String'];
  parentBrandId?: Maybe<Scalars['Int']>;
  documentPaths: Array<Scalars['String']>;
};


export type MutationBrandDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationProductsCreateRangeArgs = {
  data: ProductsCreateInput;
  brandId: Scalars['Int'];
  rangeFrom?: Scalars['Int'];
  rangeTo?: Scalars['Int'];
};


export type MutationProductsDeleteArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationProductsSendArgs = {
  input: Array<ProductSendManyItemsInput>;
  receiverAddress?: Maybe<Scalars['String']>;
};


export type MutationContactAddArgs = {
  blockchainAddress: Scalars['String'];
  name: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};


export type MutationContactDeleteArgs = {
  contactId: Scalars['Int'];
};


export type MutationStoreCreateArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  name: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};


export type MutationStoreDeleteArgs = {
  storeAccountId: Scalars['Int'];
};


export type MutationLoginAsStoreArgs = {
  storeAccountId: Scalars['Int'];
};


export type MutationTopUpBalanceArgs = {
  amount: Scalars['Int'];
  paymentToken: Scalars['String'];
  paymentForUsa: Scalars['Boolean'];
};


export type MutationTopUpBalanceByInvoiceArgs = {
  amount: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  paymentForUsa: Scalars['Boolean'];
};


export type MutationParseCsvFeedFileArgs = {
  filepath: Scalars['String'];
};


export type MutationProductsCreateByUploadedCsvFeedArgs = {
  fields: Array<ProductsCreateByUploadedCsvFeedInput>;
  filepath: Scalars['String'];
  brandId: Scalars['Int'];
};

export type Node = {
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
};

export type Product = Node & {
  __typename?: 'Product';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  name: Scalars['String'];
  brand: Brand;
  photoFilePaths?: Maybe<Array<Scalars['String']>>;
  balance: Scalars['Int'];
  tokenBlockchainId: Scalars['String'];
  serial?: Maybe<Scalars['String']>;
  attributes?: Maybe<Array<ProductAttribute>>;
};


export type ProductBalanceArgs = {
  blockchainAddress: Scalars['String'];
};

export type ProductAttribute = {
  __typename?: 'ProductAttribute';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  template: ProductAttributeTemplate;
  value: Scalars['String'];
};

export type ProductAttributeInput = {
  templateId: Scalars['Int'];
  value: Scalars['String'];
};

export type ProductAttributeTemplate = {
  __typename?: 'ProductAttributeTemplate';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  type: ProductAttributeTemplateType;
  title?: Maybe<Scalars['String']>;
  placeholder?: Maybe<Scalars['String']>;
  placeholders?: Maybe<Array<Scalars['String']>>;
};

export enum ProductAttributeTemplateType {
  String = 'STRING',
  MultiString = 'MULTI_STRING',
  Integer = 'INTEGER',
  Float = 'FLOAT',
  Radio = 'RADIO',
  Checkbox = 'CHECKBOX',
  Dropdown = 'DROPDOWN'
}

export type ProductNodes = {
  __typename?: 'ProductNodes';
  data?: Maybe<Array<Product>>;
  total: Scalars['Int'];
};

export type ProductSendManyItemsInput = {
  productId: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type ProductTransaction = {
  __typename?: 'ProductTransaction';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  product: Product;
  quantity?: Maybe<Scalars['Int']>;
  blockchainHash: Scalars['String'];
  addressFrom: Scalars['String'];
  addressTo: Scalars['String'];
};

export type ProductTransactionNodes = {
  __typename?: 'ProductTransactionNodes';
  data?: Maybe<Array<ProductTransaction>>;
  total: Scalars['Int'];
};

export type ProductsCreateByUploadedCsvFeedInput = {
  columnName: Scalars['String'];
  templateId: Scalars['Int'];
};

export type ProductsCreateInput = {
  name: Scalars['String'];
  serial?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  photoFilePaths?: Maybe<Array<Scalars['String']>>;
  attributes?: Maybe<Array<ProductAttributeInput>>;
};

export enum ProductsSearchType {
  ConsignmentsOnly = 'CONSIGNMENTS_ONLY',
  ProductsOnly = 'PRODUCTS_ONLY',
  All = 'ALL'
}

export type Query = {
  __typename?: 'Query';
  echo: Scalars['String'];
  error?: Maybe<Scalars['Int']>;
  whoami: Account;
  currentSession: AccountSession;
  account?: Maybe<Account>;
  contact?: Maybe<Contact>;
  brands: BrandNodes;
  brand: Brand;
  products: ProductNodes;
  product: Product;
  productAttributeTemplates?: Maybe<Array<ProductAttributeTemplate>>;
};


export type QueryEchoArgs = {
  text: Scalars['String'];
};


export type QueryAccountArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryContactArgs = {
  id?: Maybe<Scalars['Int']>;
};


export type QueryBrandsArgs = {
  querySearch?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['Int']>;
  confirmedOnly?: Maybe<Scalars['Boolean']>;
  includeConsignments?: Maybe<Scalars['Boolean']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBrandArgs = {
  id: Scalars['Int'];
};


export type QueryProductsArgs = {
  ownerAddress?: Maybe<Scalars['String']>;
  excludeIds?: Maybe<Array<Scalars['Int']>>;
  querySearch?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  searchType?: ProductsSearchType;
};


export type QueryProductArgs = {
  id: Scalars['Int'];
};


export type QueryProductAttributeTemplatesArgs = {
  searchQuery?: Maybe<Scalars['String']>;
};

export enum RegisterOrLoginClientStep {
  SendCode = 'SEND_CODE',
  VerifyCode = 'VERIFY_CODE'
}

export type Task = Node & {
  __typename?: 'Task';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  runAfter: Scalars['Date'];
  running: Scalars['Boolean'];
  done: Scalars['Boolean'];
  data: Scalars['String'];
  priority: Scalars['Int'];
  package?: Maybe<TaskPackage>;
};

export type TaskPackage = Node & {
  __typename?: 'TaskPackage';
  id: Scalars['Int'];
  createdAt: Scalars['Date'];
  updatedAt: Scalars['Date'];
  name: Scalars['String'];
  type: Scalars['String'];
  priority: Scalars['Int'];
  tasks?: Maybe<Array<Task>>;
};

export enum TaskPackageType {
  Creating = 'CREATING',
  Sending = 'SENDING',
  Other = 'OTHER'
}

export type TopUpBalanceResult = {
  __typename?: 'TopUpBalanceResult';
  invoiceId: Scalars['String'];
  createdAt: Scalars['String'];
  pdfFilePath: Scalars['String'];
  clientName: Scalars['String'];
  clientAddress: Scalars['String'];
  clientEmail: Scalars['String'];
  subtotal: Scalars['String'];
  vat: Scalars['String'];
  total: Scalars['String'];
};

export type UpdateAccountInput = {
  avatarFilePath?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  streetAddress?: Maybe<Scalars['String']>;
  cityState?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  offlineWalletAddress?: Maybe<Scalars['String']>;
};

export type UserAgent = {
  __typename?: 'UserAgent';
  ua?: Maybe<Scalars['String']>;
  cpu?: Maybe<UserAgentCpu>;
  os?: Maybe<UserAgentOs>;
  engine?: Maybe<UserAgentEngine>;
  browser?: Maybe<UserAgentBrowser>;
};

export type UserAgentBrowser = {
  __typename?: 'UserAgentBrowser';
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  major?: Maybe<Scalars['String']>;
};

export type UserAgentCpu = {
  __typename?: 'UserAgentCpu';
  architecture?: Maybe<Scalars['String']>;
};

export type UserAgentEngine = {
  __typename?: 'UserAgentEngine';
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type UserAgentOs = {
  __typename?: 'UserAgentOs';
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export enum UserType {
  Manufacturer = 'MANUFACTURER',
  Distributor = 'DISTRIBUTOR',
  Store = 'STORE'
}

export type WalletKeys = {
  __typename?: 'WalletKeys';
  mnemonic: Scalars['String'];
  firstAccountPrivateKey: Scalars['String'];
  firstAccountAddress: Scalars['String'];
};

export type StoreFragment = { __typename?: 'ContactNodes', total: number, data?: Maybe<Array<{ __typename?: 'Contact', id: number, name: string, comment?: Maybe<string>, createdAt: any, target: { __typename?: 'Account', id: number, blockchainAddress: string } }>> };

export type SessionFragment = { __typename?: 'AccountSession', id: number, createdAt: any, updatedAt: any, ipAddr: string, address?: Maybe<string>, expiresAt: any, userAgent?: Maybe<{ __typename?: 'UserAgent', ua?: Maybe<string>, cpu?: Maybe<{ __typename?: 'UserAgentCpu', architecture?: Maybe<string> }>, os?: Maybe<{ __typename?: 'UserAgentOs', name?: Maybe<string>, version?: Maybe<string> }>, engine?: Maybe<{ __typename?: 'UserAgentEngine', name?: Maybe<string>, version?: Maybe<string> }>, browser?: Maybe<{ __typename?: 'UserAgentBrowser', name?: Maybe<string>, version?: Maybe<string>, major?: Maybe<string> }> }> };

export type ContactFragment = { __typename?: 'Account', id: number, email: string, type: UserType, blockchainAddress: string, billingBalance: number, fullName?: Maybe<string>, streetAddress?: Maybe<string>, cityState?: Maybe<string>, zipCode?: Maybe<string>, avatarFilePath?: Maybe<string>, consignmentsBrand: { __typename?: 'Brand', id: number }, sessions?: Maybe<Array<{ __typename?: 'AccountSession', id: number, createdAt: any, updatedAt: any, ipAddr: string, address?: Maybe<string>, expiresAt: any, userAgent?: Maybe<{ __typename?: 'UserAgent', ua?: Maybe<string>, cpu?: Maybe<{ __typename?: 'UserAgentCpu', architecture?: Maybe<string> }>, os?: Maybe<{ __typename?: 'UserAgentOs', name?: Maybe<string>, version?: Maybe<string> }>, engine?: Maybe<{ __typename?: 'UserAgentEngine', name?: Maybe<string>, version?: Maybe<string> }>, browser?: Maybe<{ __typename?: 'UserAgentBrowser', name?: Maybe<string>, version?: Maybe<string>, major?: Maybe<string> }> }> }>> };

export type AuthDataFragment = { __typename?: 'AuthResult', token: string, account: { __typename?: 'Account', id: number, email: string, type: UserType, blockchainAddress: string, billingBalance: number, fullName?: Maybe<string>, streetAddress?: Maybe<string>, cityState?: Maybe<string>, zipCode?: Maybe<string>, avatarFilePath?: Maybe<string>, consignmentsBrand: { __typename?: 'Brand', id: number }, sessions?: Maybe<Array<{ __typename?: 'AccountSession', id: number, createdAt: any, updatedAt: any, ipAddr: string, address?: Maybe<string>, expiresAt: any, userAgent?: Maybe<{ __typename?: 'UserAgent', ua?: Maybe<string>, cpu?: Maybe<{ __typename?: 'UserAgentCpu', architecture?: Maybe<string> }>, os?: Maybe<{ __typename?: 'UserAgentOs', name?: Maybe<string>, version?: Maybe<string> }>, engine?: Maybe<{ __typename?: 'UserAgentEngine', name?: Maybe<string>, version?: Maybe<string> }>, browser?: Maybe<{ __typename?: 'UserAgentBrowser', name?: Maybe<string>, version?: Maybe<string>, major?: Maybe<string> }> }> }>> } };

export type RegisterMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  userType: UserType;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'AuthResult', token: string, account: { __typename?: 'Account', id: number, email: string, type: UserType, blockchainAddress: string, billingBalance: number, fullName?: Maybe<string>, streetAddress?: Maybe<string>, cityState?: Maybe<string>, zipCode?: Maybe<string>, avatarFilePath?: Maybe<string>, consignmentsBrand: { __typename?: 'Brand', id: number }, sessions?: Maybe<Array<{ __typename?: 'AccountSession', id: number, createdAt: any, updatedAt: any, ipAddr: string, address?: Maybe<string>, expiresAt: any, userAgent?: Maybe<{ __typename?: 'UserAgent', ua?: Maybe<string>, cpu?: Maybe<{ __typename?: 'UserAgentCpu', architecture?: Maybe<string> }>, os?: Maybe<{ __typename?: 'UserAgentOs', name?: Maybe<string>, version?: Maybe<string> }>, engine?: Maybe<{ __typename?: 'UserAgentEngine', name?: Maybe<string>, version?: Maybe<string> }>, browser?: Maybe<{ __typename?: 'UserAgentBrowser', name?: Maybe<string>, version?: Maybe<string>, major?: Maybe<string> }> }> }>> } } };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthResult', token: string, account: { __typename?: 'Account', id: number, email: string, type: UserType, blockchainAddress: string, billingBalance: number, fullName?: Maybe<string>, streetAddress?: Maybe<string>, cityState?: Maybe<string>, zipCode?: Maybe<string>, avatarFilePath?: Maybe<string>, consignmentsBrand: { __typename?: 'Brand', id: number }, sessions?: Maybe<Array<{ __typename?: 'AccountSession', id: number, createdAt: any, updatedAt: any, ipAddr: string, address?: Maybe<string>, expiresAt: any, userAgent?: Maybe<{ __typename?: 'UserAgent', ua?: Maybe<string>, cpu?: Maybe<{ __typename?: 'UserAgentCpu', architecture?: Maybe<string> }>, os?: Maybe<{ __typename?: 'UserAgentOs', name?: Maybe<string>, version?: Maybe<string> }>, engine?: Maybe<{ __typename?: 'UserAgentEngine', name?: Maybe<string>, version?: Maybe<string> }>, browser?: Maybe<{ __typename?: 'UserAgentBrowser', name?: Maybe<string>, version?: Maybe<string>, major?: Maybe<string> }> }> }>> } } };

export type GenerateEmailCodeMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type GenerateEmailCodeMutation = { __typename?: 'Mutation', generateEmailCode: { __typename?: 'GenerateEmailCodeResult', result: boolean, expiresAt: any } };

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  emailCode: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'AuthResult', token: string, account: { __typename?: 'Account', id: number, email: string, type: UserType, blockchainAddress: string, billingBalance: number, fullName?: Maybe<string>, streetAddress?: Maybe<string>, cityState?: Maybe<string>, zipCode?: Maybe<string>, avatarFilePath?: Maybe<string>, consignmentsBrand: { __typename?: 'Brand', id: number }, sessions?: Maybe<Array<{ __typename?: 'AccountSession', id: number, createdAt: any, updatedAt: any, ipAddr: string, address?: Maybe<string>, expiresAt: any, userAgent?: Maybe<{ __typename?: 'UserAgent', ua?: Maybe<string>, cpu?: Maybe<{ __typename?: 'UserAgentCpu', architecture?: Maybe<string> }>, os?: Maybe<{ __typename?: 'UserAgentOs', name?: Maybe<string>, version?: Maybe<string> }>, engine?: Maybe<{ __typename?: 'UserAgentEngine', name?: Maybe<string>, version?: Maybe<string> }>, browser?: Maybe<{ __typename?: 'UserAgentBrowser', name?: Maybe<string>, version?: Maybe<string>, major?: Maybe<string> }> }> }>> } } };

export type ChangePasswordMutationVariables = Exact<{
  password: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword: { __typename?: 'AuthResult', token: string, account: { __typename?: 'Account', id: number, email: string, type: UserType, blockchainAddress: string, billingBalance: number, fullName?: Maybe<string>, streetAddress?: Maybe<string>, cityState?: Maybe<string>, zipCode?: Maybe<string>, avatarFilePath?: Maybe<string>, consignmentsBrand: { __typename?: 'Brand', id: number }, sessions?: Maybe<Array<{ __typename?: 'AccountSession', id: number, createdAt: any, updatedAt: any, ipAddr: string, address?: Maybe<string>, expiresAt: any, userAgent?: Maybe<{ __typename?: 'UserAgent', ua?: Maybe<string>, cpu?: Maybe<{ __typename?: 'UserAgentCpu', architecture?: Maybe<string> }>, os?: Maybe<{ __typename?: 'UserAgentOs', name?: Maybe<string>, version?: Maybe<string> }>, engine?: Maybe<{ __typename?: 'UserAgentEngine', name?: Maybe<string>, version?: Maybe<string> }>, browser?: Maybe<{ __typename?: 'UserAgentBrowser', name?: Maybe<string>, version?: Maybe<string>, major?: Maybe<string> }> }> }>> } } };

export type UpdateAccountMutationVariables = Exact<{
  data: UpdateAccountInput;
}>;


export type UpdateAccountMutation = { __typename?: 'Mutation', updateAccount: { __typename?: 'AuthResult', token: string, account: { __typename?: 'Account', id: number, email: string, type: UserType, blockchainAddress: string, billingBalance: number, fullName?: Maybe<string>, streetAddress?: Maybe<string>, cityState?: Maybe<string>, zipCode?: Maybe<string>, avatarFilePath?: Maybe<string>, consignmentsBrand: { __typename?: 'Brand', id: number }, sessions?: Maybe<Array<{ __typename?: 'AccountSession', id: number, createdAt: any, updatedAt: any, ipAddr: string, address?: Maybe<string>, expiresAt: any, userAgent?: Maybe<{ __typename?: 'UserAgent', ua?: Maybe<string>, cpu?: Maybe<{ __typename?: 'UserAgentCpu', architecture?: Maybe<string> }>, os?: Maybe<{ __typename?: 'UserAgentOs', name?: Maybe<string>, version?: Maybe<string> }>, engine?: Maybe<{ __typename?: 'UserAgentEngine', name?: Maybe<string>, version?: Maybe<string> }>, browser?: Maybe<{ __typename?: 'UserAgentBrowser', name?: Maybe<string>, version?: Maybe<string>, major?: Maybe<string> }> }> }>> } } };

export type CreateBrandMutationVariables = Exact<{
  name: Scalars['String'];
  logoPath?: Maybe<Scalars['String']>;
  contactsInfo: Scalars['String'];
  parentBrandId?: Maybe<Scalars['Int']>;
  documentPaths: Array<Scalars['String']> | Scalars['String'];
}>;


export type CreateBrandMutation = { __typename?: 'Mutation', brandCreate: { __typename?: 'Brand', id: number } };

export type CreateProductsRangeMutationVariables = Exact<{
  dataProducts: ProductsCreateInput;
  brandId: Scalars['Int'];
  rangeFrom: Scalars['Int'];
  rangeTo: Scalars['Int'];
}>;


export type CreateProductsRangeMutation = { __typename?: 'Mutation', productsCreateRange: boolean };

export type SendProductsMutationVariables = Exact<{
  input: Array<ProductSendManyItemsInput> | ProductSendManyItemsInput;
  receiverAddress?: Maybe<Scalars['String']>;
}>;


export type SendProductsMutation = { __typename?: 'Mutation', productsSend: Array<boolean> };

export type AddStoreMutationVariables = Exact<{
  blockchainAddress: Scalars['String'];
  name: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
}>;


export type AddStoreMutation = { __typename?: 'Mutation', contactAdd: { __typename?: 'Contact', id: number, name: string, comment?: Maybe<string>, createdAt: any, target: { __typename?: 'Account', blockchainAddress: string } } };

export type CreateStoreMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  name: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
}>;


export type CreateStoreMutation = { __typename?: 'Mutation', storeCreate: { __typename?: 'Contact', id: number, name: string, comment?: Maybe<string>, createdAt: any, target: { __typename?: 'Account', blockchainAddress: string } } };

export type LoginAsStoreMutationVariables = Exact<{
  storeAccountId: Scalars['Int'];
}>;


export type LoginAsStoreMutation = { __typename?: 'Mutation', loginAsStore: { __typename?: 'AuthResult', token: string, account: { __typename?: 'Account', id: number, email: string, type: UserType, blockchainAddress: string, billingBalance: number, fullName?: Maybe<string>, streetAddress?: Maybe<string>, cityState?: Maybe<string>, zipCode?: Maybe<string>, avatarFilePath?: Maybe<string>, consignmentsBrand: { __typename?: 'Brand', id: number }, sessions?: Maybe<Array<{ __typename?: 'AccountSession', id: number, createdAt: any, updatedAt: any, ipAddr: string, address?: Maybe<string>, expiresAt: any, userAgent?: Maybe<{ __typename?: 'UserAgent', ua?: Maybe<string>, cpu?: Maybe<{ __typename?: 'UserAgentCpu', architecture?: Maybe<string> }>, os?: Maybe<{ __typename?: 'UserAgentOs', name?: Maybe<string>, version?: Maybe<string> }>, engine?: Maybe<{ __typename?: 'UserAgentEngine', name?: Maybe<string>, version?: Maybe<string> }>, browser?: Maybe<{ __typename?: 'UserAgentBrowser', name?: Maybe<string>, version?: Maybe<string>, major?: Maybe<string> }> }> }>> } } };

export type TopUpBalanceMutationVariables = Exact<{
  amount: Scalars['Int'];
  paymentToken: Scalars['String'];
  paymentForUsa: Scalars['Boolean'];
}>;


export type TopUpBalanceMutation = { __typename?: 'Mutation', topUpBalance: { __typename?: 'TopUpBalanceResult', invoiceId: string } };

export type TopUpBalanceByInvoiceMutationVariables = Exact<{
  amount: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  paymentForUsa: Scalars['Boolean'];
}>;


export type TopUpBalanceByInvoiceMutation = { __typename?: 'Mutation', topUpBalanceByInvoice: { __typename?: 'TopUpBalanceResult', invoiceId: string, createdAt: string, pdfFilePath: string, clientName: string, clientAddress: string, clientEmail: string, subtotal: string, vat: string, total: string } };

export type LogOutMutationVariables = Exact<{
  sessionTokens?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  sessionIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type LogOutMutation = { __typename?: 'Mutation', logout: boolean };

export type ParseCsvFeedFileMutationVariables = Exact<{
  filepath: Scalars['String'];
}>;


export type ParseCsvFeedFileMutation = { __typename?: 'Mutation', parseCSVFeedFile?: Maybe<Array<string>> };

export type DeleteBrandMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteBrandMutation = { __typename?: 'Mutation', brandDelete: boolean };

export type DeleteProductMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteProductMutation = { __typename?: 'Mutation', productsDelete: boolean };

export type DeleteContactMutationVariables = Exact<{
  contactId: Scalars['Int'];
}>;


export type DeleteContactMutation = { __typename?: 'Mutation', contactDelete: boolean };

export type DeleteStoreMutationVariables = Exact<{
  storeAccountId: Scalars['Int'];
}>;


export type DeleteStoreMutation = { __typename?: 'Mutation', storeDelete: boolean };

export type GetUserDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserDataQuery = { __typename?: 'Query', whoami: { __typename?: 'Account', id: number, email: string, type: UserType, blockchainAddress: string, billingBalance: number, fullName?: Maybe<string>, streetAddress?: Maybe<string>, cityState?: Maybe<string>, zipCode?: Maybe<string>, avatarFilePath?: Maybe<string>, sessions?: Maybe<Array<{ __typename?: 'AccountSession', id: number, createdAt: any, updatedAt: any, ipAddr: string, address?: Maybe<string>, expiresAt: any, userAgent?: Maybe<{ __typename?: 'UserAgent', ua?: Maybe<string>, cpu?: Maybe<{ __typename?: 'UserAgentCpu', architecture?: Maybe<string> }>, os?: Maybe<{ __typename?: 'UserAgentOs', name?: Maybe<string>, version?: Maybe<string> }>, engine?: Maybe<{ __typename?: 'UserAgentEngine', name?: Maybe<string>, version?: Maybe<string> }>, browser?: Maybe<{ __typename?: 'UserAgentBrowser', name?: Maybe<string>, version?: Maybe<string>, major?: Maybe<string> }> }> }>>, consignmentsBrand: { __typename?: 'Brand', id: number } }, currentSession: { __typename?: 'AccountSession', id: number, createdAt: any, updatedAt: any, ipAddr: string, address?: Maybe<string>, expiresAt: any, userAgent?: Maybe<{ __typename?: 'UserAgent', ua?: Maybe<string>, cpu?: Maybe<{ __typename?: 'UserAgentCpu', architecture?: Maybe<string> }>, os?: Maybe<{ __typename?: 'UserAgentOs', name?: Maybe<string>, version?: Maybe<string> }>, engine?: Maybe<{ __typename?: 'UserAgentEngine', name?: Maybe<string>, version?: Maybe<string> }>, browser?: Maybe<{ __typename?: 'UserAgentBrowser', name?: Maybe<string>, version?: Maybe<string>, major?: Maybe<string> }> }> } };

export type GetBrandsForCreateQueryVariables = Exact<{
  querySearch?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['Int']>;
  confirmedOnly?: Maybe<Scalars['Boolean']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetBrandsForCreateQuery = { __typename?: 'Query', brands: { __typename?: 'BrandNodes', data?: Maybe<Array<{ __typename?: 'Brand', id: number, name: string }>> } };

export type GetBrandsQueryVariables = Exact<{
  ownerId?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetBrandsQuery = { __typename?: 'Query', brands: { __typename?: 'BrandNodes', total: number, data?: Maybe<Array<{ __typename?: 'Brand', id: number, status: BrandStatus, name: string, logoPath?: Maybe<string>, createdAt: any, parentBrand?: Maybe<{ __typename?: 'Brand', id: number }>, childrenBrands?: Maybe<Array<Maybe<{ __typename?: 'Brand', id: number }>>> }>> } };

export type GetBrandQueryVariables = Exact<{
  id: Scalars['Int'];
  querySearch?: Maybe<Scalars['String']>;
  blockchainAddress: Scalars['String'];
  ownerId?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetBrandQuery = { __typename?: 'Query', brand: { __typename?: 'Brand', id: number, name: string, products: { __typename?: 'ProductNodes', total: number, data?: Maybe<Array<{ __typename?: 'Product', id: number, name: string, balance: number, brand: { __typename?: 'Brand', status: BrandStatus, name: string } }>> } } };

export type GetProductsQueryVariables = Exact<{
  ownerId?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  searchType: ProductsSearchType;
  blockchainAddress: Scalars['String'];
}>;


export type GetProductsQuery = { __typename?: 'Query', products: { __typename?: 'ProductNodes', total: number, data?: Maybe<Array<{ __typename?: 'Product', id: number, name: string, balance: number, brand: { __typename?: 'Brand', name: string, status: BrandStatus } }>> } };

export type GetProductsForSelectQueryVariables = Exact<{
  ownerId?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  searchType: ProductsSearchType;
  blockchainAddress: Scalars['String'];
  querySearch?: Maybe<Scalars['String']>;
  excludeIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type GetProductsForSelectQuery = { __typename?: 'Query', products: { __typename?: 'ProductNodes', total: number, data?: Maybe<Array<{ __typename?: 'Product', id: number, name: string, balance: number, brand: { __typename?: 'Brand', name: string } }>> } };

export type GetProductQueryVariables = Exact<{
  id: Scalars['Int'];
  blockchainAddress: Scalars['String'];
}>;


export type GetProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: number, name: string, balance: number, tokenBlockchainId: string, serial?: Maybe<string>, photoFilePaths?: Maybe<Array<string>>, brand: { __typename?: 'Brand', name: string, status: BrandStatus }, attributes?: Maybe<Array<{ __typename?: 'ProductAttribute', value: string, template: { __typename?: 'ProductAttributeTemplate', title?: Maybe<string> } }>> }, whoami: { __typename?: 'Account', contacts: { __typename?: 'ContactNodes', data?: Maybe<Array<{ __typename?: 'Contact', id: number, name: string, target: { __typename?: 'Account', blockchainAddress: string } }>> }, stores: { __typename?: 'ContactNodes', data?: Maybe<Array<{ __typename?: 'Contact', id: number, name: string, target: { __typename?: 'Account', blockchainAddress: string } }>> } } };

export type GetOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOptionsQuery = { __typename?: 'Query', whoami: { __typename?: 'Account', stores: { __typename?: 'ContactNodes', total: number, data?: Maybe<Array<{ __typename?: 'Contact', id: number, name: string, comment?: Maybe<string>, createdAt: any, target: { __typename?: 'Account', id: number, blockchainAddress: string } }>> }, contacts: { __typename?: 'ContactNodes', total: number, data?: Maybe<Array<{ __typename?: 'Contact', id: number, name: string, comment?: Maybe<string>, createdAt: any, target: { __typename?: 'Account', id: number, blockchainAddress: string } }>> } } };

export type GetStoresQueryVariables = Exact<{
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetStoresQuery = { __typename?: 'Query', whoami: { __typename?: 'Account', stores: { __typename?: 'ContactNodes', total: number, data?: Maybe<Array<{ __typename?: 'Contact', id: number, name: string, comment?: Maybe<string>, createdAt: any, target: { __typename?: 'Account', id: number, blockchainAddress: string } }>> }, contacts: { __typename?: 'ContactNodes', total: number, data?: Maybe<Array<{ __typename?: 'Contact', id: number, name: string, comment?: Maybe<string>, createdAt: any, target: { __typename?: 'Account', id: number, blockchainAddress: string } }>> } } };

export type GetStoreQueryVariables = Exact<{
  id?: Maybe<Scalars['Int']>;
  filter?: Maybe<BlockchainTransactionsFilter>;
}>;


export type GetStoreQuery = { __typename?: 'Query', contact?: Maybe<{ __typename?: 'Contact', id: number, name: string, comment?: Maybe<string>, target: { __typename?: 'Account', id: number, blockchainAddress: string, blockchainTransactions: { __typename?: 'ProductTransactionNodes', total: number, data?: Maybe<Array<{ __typename?: 'ProductTransaction', id: number, createdAt: any, quantity?: Maybe<number>, blockchainHash: string, addressFrom: string, addressTo: string, product: { __typename?: 'Product', id: number } }>> } } }> };

export type GetConsignmentsQueryVariables = Exact<{
  ownerId?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  searchType: ProductsSearchType;
  blockchainAddress: Scalars['String'];
}>;


export type GetConsignmentsQuery = { __typename?: 'Query', products: { __typename?: 'ProductNodes', total: number, data?: Maybe<Array<{ __typename?: 'Product', id: number, name: string, balance: number }>> } };

export type GetFinancesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFinancesQuery = { __typename?: 'Query', whoami: { __typename?: 'Account', billingTransactions?: Maybe<Array<{ __typename?: 'BillingTransaction', id: number, amount: number, incoming: boolean, comment: string, createdAt: any }>> } };

export type GetProductAttributeTemplatesQueryVariables = Exact<{
  searchQuery?: Maybe<Scalars['String']>;
}>;


export type GetProductAttributeTemplatesQuery = { __typename?: 'Query', productAttributeTemplates?: Maybe<Array<{ __typename?: 'ProductAttributeTemplate', id: number, type: ProductAttributeTemplateType, title?: Maybe<string>, placeholder?: Maybe<string>, placeholders?: Maybe<Array<string>> }>> };

export const StoreFragmentDoc = gql`
    fragment Store on ContactNodes {
  data {
    id
    name
    comment
    createdAt
    target {
      id
      blockchainAddress
    }
  }
  total
}
    `;
export const SessionFragmentDoc = gql`
    fragment Session on AccountSession {
  id
  createdAt
  updatedAt
  ipAddr
  address
  userAgent {
    ua
    cpu {
      architecture
    }
    os {
      name
      version
    }
    engine {
      name
      version
    }
    browser {
      name
      version
      major
    }
  }
  expiresAt
}
    `;
export const ContactFragmentDoc = gql`
    fragment Contact on Account {
  id
  email
  type
  blockchainAddress
  billingBalance
  consignmentsBrand {
    id
  }
  fullName
  streetAddress
  cityState
  zipCode
  avatarFilePath
  sessions {
    ...Session
  }
}
    ${SessionFragmentDoc}`;
export const AuthDataFragmentDoc = gql`
    fragment AuthData on AuthResult {
  token
  account {
    ...Contact
  }
}
    ${ContactFragmentDoc}`;
export const RegisterDocument = gql`
    mutation register($email: String!, $password: String!, $userType: UserType!) {
  register(email: $email, password: $password, userType: $userType) {
    ...AuthData
  }
}
    ${AuthDataFragmentDoc}`;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      userType: // value for 'userType'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    ...AuthData
  }
}
    ${AuthDataFragmentDoc}`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const GenerateEmailCodeDocument = gql`
    mutation generateEmailCode($email: String!) {
  generateEmailCode(email: $email) {
    result
    expiresAt
  }
}
    `;
export type GenerateEmailCodeMutationFn = Apollo.MutationFunction<GenerateEmailCodeMutation, GenerateEmailCodeMutationVariables>;

/**
 * __useGenerateEmailCodeMutation__
 *
 * To run a mutation, you first call `useGenerateEmailCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateEmailCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateEmailCodeMutation, { data, loading, error }] = useGenerateEmailCodeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useGenerateEmailCodeMutation(baseOptions?: Apollo.MutationHookOptions<GenerateEmailCodeMutation, GenerateEmailCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateEmailCodeMutation, GenerateEmailCodeMutationVariables>(GenerateEmailCodeDocument, options);
      }
export type GenerateEmailCodeMutationHookResult = ReturnType<typeof useGenerateEmailCodeMutation>;
export type GenerateEmailCodeMutationResult = Apollo.MutationResult<GenerateEmailCodeMutation>;
export type GenerateEmailCodeMutationOptions = Apollo.BaseMutationOptions<GenerateEmailCodeMutation, GenerateEmailCodeMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!, $emailCode: String!, $newPassword: String!) {
  resetPassword(email: $email, emailCode: $emailCode, newPassword: $newPassword) {
    ...AuthData
  }
}
    ${AuthDataFragmentDoc}`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *      emailCode: // value for 'emailCode'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($password: String!, $newPassword: String!) {
  changePassword(password: $password, newPassword: $newPassword) {
    ...AuthData
  }
}
    ${AuthDataFragmentDoc}`;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const UpdateAccountDocument = gql`
    mutation updateAccount($data: UpdateAccountInput!) {
  updateAccount(data: $data) {
    ...AuthData
  }
}
    ${AuthDataFragmentDoc}`;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;

/**
 * __useUpdateAccountMutation__
 *
 * To run a mutation, you first call `useUpdateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountMutation, { data, loading, error }] = useUpdateAccountMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export type UpdateAccountMutationOptions = Apollo.BaseMutationOptions<UpdateAccountMutation, UpdateAccountMutationVariables>;
export const CreateBrandDocument = gql`
    mutation createBrand($name: String!, $logoPath: String, $contactsInfo: String!, $parentBrandId: Int, $documentPaths: [String!]!) {
  brandCreate(
    name: $name
    logoPath: $logoPath
    contactsInfo: $contactsInfo
    parentBrandId: $parentBrandId
    documentPaths: $documentPaths
  ) {
    id
  }
}
    `;
export type CreateBrandMutationFn = Apollo.MutationFunction<CreateBrandMutation, CreateBrandMutationVariables>;

/**
 * __useCreateBrandMutation__
 *
 * To run a mutation, you first call `useCreateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandMutation, { data, loading, error }] = useCreateBrandMutation({
 *   variables: {
 *      name: // value for 'name'
 *      logoPath: // value for 'logoPath'
 *      contactsInfo: // value for 'contactsInfo'
 *      parentBrandId: // value for 'parentBrandId'
 *      documentPaths: // value for 'documentPaths'
 *   },
 * });
 */
export function useCreateBrandMutation(baseOptions?: Apollo.MutationHookOptions<CreateBrandMutation, CreateBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBrandMutation, CreateBrandMutationVariables>(CreateBrandDocument, options);
      }
export type CreateBrandMutationHookResult = ReturnType<typeof useCreateBrandMutation>;
export type CreateBrandMutationResult = Apollo.MutationResult<CreateBrandMutation>;
export type CreateBrandMutationOptions = Apollo.BaseMutationOptions<CreateBrandMutation, CreateBrandMutationVariables>;
export const CreateProductsRangeDocument = gql`
    mutation createProductsRange($dataProducts: ProductsCreateInput!, $brandId: Int!, $rangeFrom: Int!, $rangeTo: Int!) {
  productsCreateRange(
    brandId: $brandId
    data: $dataProducts
    rangeFrom: $rangeFrom
    rangeTo: $rangeTo
  )
}
    `;
export type CreateProductsRangeMutationFn = Apollo.MutationFunction<CreateProductsRangeMutation, CreateProductsRangeMutationVariables>;

/**
 * __useCreateProductsRangeMutation__
 *
 * To run a mutation, you first call `useCreateProductsRangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductsRangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductsRangeMutation, { data, loading, error }] = useCreateProductsRangeMutation({
 *   variables: {
 *      dataProducts: // value for 'dataProducts'
 *      brandId: // value for 'brandId'
 *      rangeFrom: // value for 'rangeFrom'
 *      rangeTo: // value for 'rangeTo'
 *   },
 * });
 */
export function useCreateProductsRangeMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductsRangeMutation, CreateProductsRangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductsRangeMutation, CreateProductsRangeMutationVariables>(CreateProductsRangeDocument, options);
      }
export type CreateProductsRangeMutationHookResult = ReturnType<typeof useCreateProductsRangeMutation>;
export type CreateProductsRangeMutationResult = Apollo.MutationResult<CreateProductsRangeMutation>;
export type CreateProductsRangeMutationOptions = Apollo.BaseMutationOptions<CreateProductsRangeMutation, CreateProductsRangeMutationVariables>;
export const SendProductsDocument = gql`
    mutation sendProducts($input: [ProductSendManyItemsInput!]!, $receiverAddress: String) {
  productsSend(input: $input, receiverAddress: $receiverAddress)
}
    `;
export type SendProductsMutationFn = Apollo.MutationFunction<SendProductsMutation, SendProductsMutationVariables>;

/**
 * __useSendProductsMutation__
 *
 * To run a mutation, you first call `useSendProductsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendProductsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendProductsMutation, { data, loading, error }] = useSendProductsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      receiverAddress: // value for 'receiverAddress'
 *   },
 * });
 */
export function useSendProductsMutation(baseOptions?: Apollo.MutationHookOptions<SendProductsMutation, SendProductsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendProductsMutation, SendProductsMutationVariables>(SendProductsDocument, options);
      }
export type SendProductsMutationHookResult = ReturnType<typeof useSendProductsMutation>;
export type SendProductsMutationResult = Apollo.MutationResult<SendProductsMutation>;
export type SendProductsMutationOptions = Apollo.BaseMutationOptions<SendProductsMutation, SendProductsMutationVariables>;
export const AddStoreDocument = gql`
    mutation addStore($blockchainAddress: String!, $name: String!, $comment: String) {
  contactAdd(
    blockchainAddress: $blockchainAddress
    name: $name
    comment: $comment
  ) {
    id
    name
    comment
    createdAt
    target {
      blockchainAddress
    }
  }
}
    `;
export type AddStoreMutationFn = Apollo.MutationFunction<AddStoreMutation, AddStoreMutationVariables>;

/**
 * __useAddStoreMutation__
 *
 * To run a mutation, you first call `useAddStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addStoreMutation, { data, loading, error }] = useAddStoreMutation({
 *   variables: {
 *      blockchainAddress: // value for 'blockchainAddress'
 *      name: // value for 'name'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useAddStoreMutation(baseOptions?: Apollo.MutationHookOptions<AddStoreMutation, AddStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddStoreMutation, AddStoreMutationVariables>(AddStoreDocument, options);
      }
export type AddStoreMutationHookResult = ReturnType<typeof useAddStoreMutation>;
export type AddStoreMutationResult = Apollo.MutationResult<AddStoreMutation>;
export type AddStoreMutationOptions = Apollo.BaseMutationOptions<AddStoreMutation, AddStoreMutationVariables>;
export const CreateStoreDocument = gql`
    mutation createStore($email: String!, $password: String!, $name: String!, $comment: String) {
  storeCreate(email: $email, password: $password, name: $name, comment: $comment) {
    id
    name
    comment
    createdAt
    target {
      blockchainAddress
    }
  }
}
    `;
export type CreateStoreMutationFn = Apollo.MutationFunction<CreateStoreMutation, CreateStoreMutationVariables>;

/**
 * __useCreateStoreMutation__
 *
 * To run a mutation, you first call `useCreateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoreMutation, { data, loading, error }] = useCreateStoreMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      name: // value for 'name'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateStoreMutation(baseOptions?: Apollo.MutationHookOptions<CreateStoreMutation, CreateStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStoreMutation, CreateStoreMutationVariables>(CreateStoreDocument, options);
      }
export type CreateStoreMutationHookResult = ReturnType<typeof useCreateStoreMutation>;
export type CreateStoreMutationResult = Apollo.MutationResult<CreateStoreMutation>;
export type CreateStoreMutationOptions = Apollo.BaseMutationOptions<CreateStoreMutation, CreateStoreMutationVariables>;
export const LoginAsStoreDocument = gql`
    mutation loginAsStore($storeAccountId: Int!) {
  loginAsStore(storeAccountId: $storeAccountId) {
    ...AuthData
  }
}
    ${AuthDataFragmentDoc}`;
export type LoginAsStoreMutationFn = Apollo.MutationFunction<LoginAsStoreMutation, LoginAsStoreMutationVariables>;

/**
 * __useLoginAsStoreMutation__
 *
 * To run a mutation, you first call `useLoginAsStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAsStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAsStoreMutation, { data, loading, error }] = useLoginAsStoreMutation({
 *   variables: {
 *      storeAccountId: // value for 'storeAccountId'
 *   },
 * });
 */
export function useLoginAsStoreMutation(baseOptions?: Apollo.MutationHookOptions<LoginAsStoreMutation, LoginAsStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginAsStoreMutation, LoginAsStoreMutationVariables>(LoginAsStoreDocument, options);
      }
export type LoginAsStoreMutationHookResult = ReturnType<typeof useLoginAsStoreMutation>;
export type LoginAsStoreMutationResult = Apollo.MutationResult<LoginAsStoreMutation>;
export type LoginAsStoreMutationOptions = Apollo.BaseMutationOptions<LoginAsStoreMutation, LoginAsStoreMutationVariables>;
export const TopUpBalanceDocument = gql`
    mutation topUpBalance($amount: Int!, $paymentToken: String!, $paymentForUsa: Boolean!) {
  topUpBalance(
    amount: $amount
    paymentToken: $paymentToken
    paymentForUsa: $paymentForUsa
  ) {
    invoiceId
  }
}
    `;
export type TopUpBalanceMutationFn = Apollo.MutationFunction<TopUpBalanceMutation, TopUpBalanceMutationVariables>;

/**
 * __useTopUpBalanceMutation__
 *
 * To run a mutation, you first call `useTopUpBalanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTopUpBalanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [topUpBalanceMutation, { data, loading, error }] = useTopUpBalanceMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      paymentToken: // value for 'paymentToken'
 *      paymentForUsa: // value for 'paymentForUsa'
 *   },
 * });
 */
export function useTopUpBalanceMutation(baseOptions?: Apollo.MutationHookOptions<TopUpBalanceMutation, TopUpBalanceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TopUpBalanceMutation, TopUpBalanceMutationVariables>(TopUpBalanceDocument, options);
      }
export type TopUpBalanceMutationHookResult = ReturnType<typeof useTopUpBalanceMutation>;
export type TopUpBalanceMutationResult = Apollo.MutationResult<TopUpBalanceMutation>;
export type TopUpBalanceMutationOptions = Apollo.BaseMutationOptions<TopUpBalanceMutation, TopUpBalanceMutationVariables>;
export const TopUpBalanceByInvoiceDocument = gql`
    mutation topUpBalanceByInvoice($amount: Int!, $comment: String, $paymentForUsa: Boolean!) {
  topUpBalanceByInvoice(
    amount: $amount
    comment: $comment
    paymentForUsa: $paymentForUsa
  ) {
    invoiceId
    createdAt
    pdfFilePath
    clientName
    clientAddress
    clientEmail
    subtotal
    vat
    total
  }
}
    `;
export type TopUpBalanceByInvoiceMutationFn = Apollo.MutationFunction<TopUpBalanceByInvoiceMutation, TopUpBalanceByInvoiceMutationVariables>;

/**
 * __useTopUpBalanceByInvoiceMutation__
 *
 * To run a mutation, you first call `useTopUpBalanceByInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTopUpBalanceByInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [topUpBalanceByInvoiceMutation, { data, loading, error }] = useTopUpBalanceByInvoiceMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      comment: // value for 'comment'
 *      paymentForUsa: // value for 'paymentForUsa'
 *   },
 * });
 */
export function useTopUpBalanceByInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<TopUpBalanceByInvoiceMutation, TopUpBalanceByInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TopUpBalanceByInvoiceMutation, TopUpBalanceByInvoiceMutationVariables>(TopUpBalanceByInvoiceDocument, options);
      }
export type TopUpBalanceByInvoiceMutationHookResult = ReturnType<typeof useTopUpBalanceByInvoiceMutation>;
export type TopUpBalanceByInvoiceMutationResult = Apollo.MutationResult<TopUpBalanceByInvoiceMutation>;
export type TopUpBalanceByInvoiceMutationOptions = Apollo.BaseMutationOptions<TopUpBalanceByInvoiceMutation, TopUpBalanceByInvoiceMutationVariables>;
export const LogOutDocument = gql`
    mutation logOut($sessionTokens: [String!], $sessionIds: [Int!]) {
  logout(sessionTokens: $sessionTokens, sessionIds: $sessionIds)
}
    `;
export type LogOutMutationFn = Apollo.MutationFunction<LogOutMutation, LogOutMutationVariables>;

/**
 * __useLogOutMutation__
 *
 * To run a mutation, you first call `useLogOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logOutMutation, { data, loading, error }] = useLogOutMutation({
 *   variables: {
 *      sessionTokens: // value for 'sessionTokens'
 *      sessionIds: // value for 'sessionIds'
 *   },
 * });
 */
export function useLogOutMutation(baseOptions?: Apollo.MutationHookOptions<LogOutMutation, LogOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogOutMutation, LogOutMutationVariables>(LogOutDocument, options);
      }
export type LogOutMutationHookResult = ReturnType<typeof useLogOutMutation>;
export type LogOutMutationResult = Apollo.MutationResult<LogOutMutation>;
export type LogOutMutationOptions = Apollo.BaseMutationOptions<LogOutMutation, LogOutMutationVariables>;
export const ParseCsvFeedFileDocument = gql`
    mutation parseCSVFeedFile($filepath: String!) {
  parseCSVFeedFile(filepath: $filepath)
}
    `;
export type ParseCsvFeedFileMutationFn = Apollo.MutationFunction<ParseCsvFeedFileMutation, ParseCsvFeedFileMutationVariables>;

/**
 * __useParseCsvFeedFileMutation__
 *
 * To run a mutation, you first call `useParseCsvFeedFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParseCsvFeedFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parseCsvFeedFileMutation, { data, loading, error }] = useParseCsvFeedFileMutation({
 *   variables: {
 *      filepath: // value for 'filepath'
 *   },
 * });
 */
export function useParseCsvFeedFileMutation(baseOptions?: Apollo.MutationHookOptions<ParseCsvFeedFileMutation, ParseCsvFeedFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ParseCsvFeedFileMutation, ParseCsvFeedFileMutationVariables>(ParseCsvFeedFileDocument, options);
      }
export type ParseCsvFeedFileMutationHookResult = ReturnType<typeof useParseCsvFeedFileMutation>;
export type ParseCsvFeedFileMutationResult = Apollo.MutationResult<ParseCsvFeedFileMutation>;
export type ParseCsvFeedFileMutationOptions = Apollo.BaseMutationOptions<ParseCsvFeedFileMutation, ParseCsvFeedFileMutationVariables>;
export const DeleteBrandDocument = gql`
    mutation deleteBrand($id: Int!) {
  brandDelete(id: $id)
}
    `;
export type DeleteBrandMutationFn = Apollo.MutationFunction<DeleteBrandMutation, DeleteBrandMutationVariables>;

/**
 * __useDeleteBrandMutation__
 *
 * To run a mutation, you first call `useDeleteBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBrandMutation, { data, loading, error }] = useDeleteBrandMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBrandMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBrandMutation, DeleteBrandMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBrandMutation, DeleteBrandMutationVariables>(DeleteBrandDocument, options);
      }
export type DeleteBrandMutationHookResult = ReturnType<typeof useDeleteBrandMutation>;
export type DeleteBrandMutationResult = Apollo.MutationResult<DeleteBrandMutation>;
export type DeleteBrandMutationOptions = Apollo.BaseMutationOptions<DeleteBrandMutation, DeleteBrandMutationVariables>;
export const DeleteProductDocument = gql`
    mutation deleteProduct($ids: [Int!]!) {
  productsDelete(ids: $ids)
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>;
export const DeleteContactDocument = gql`
    mutation deleteContact($contactId: Int!) {
  contactDelete(contactId: $contactId)
}
    `;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      contactId: // value for 'contactId'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const DeleteStoreDocument = gql`
    mutation deleteStore($storeAccountId: Int!) {
  storeDelete(storeAccountId: $storeAccountId)
}
    `;
export type DeleteStoreMutationFn = Apollo.MutationFunction<DeleteStoreMutation, DeleteStoreMutationVariables>;

/**
 * __useDeleteStoreMutation__
 *
 * To run a mutation, you first call `useDeleteStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteStoreMutation, { data, loading, error }] = useDeleteStoreMutation({
 *   variables: {
 *      storeAccountId: // value for 'storeAccountId'
 *   },
 * });
 */
export function useDeleteStoreMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStoreMutation, DeleteStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStoreMutation, DeleteStoreMutationVariables>(DeleteStoreDocument, options);
      }
export type DeleteStoreMutationHookResult = ReturnType<typeof useDeleteStoreMutation>;
export type DeleteStoreMutationResult = Apollo.MutationResult<DeleteStoreMutation>;
export type DeleteStoreMutationOptions = Apollo.BaseMutationOptions<DeleteStoreMutation, DeleteStoreMutationVariables>;
export const GetUserDataDocument = gql`
    query getUserData {
  whoami {
    ...Contact
    sessions {
      ...Session
    }
  }
  currentSession {
    ...Session
  }
}
    ${ContactFragmentDoc}
${SessionFragmentDoc}`;

/**
 * __useGetUserDataQuery__
 *
 * To run a query within a React component, call `useGetUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserDataQuery(baseOptions?: Apollo.QueryHookOptions<GetUserDataQuery, GetUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDataQuery, GetUserDataQueryVariables>(GetUserDataDocument, options);
      }
export function useGetUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDataQuery, GetUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDataQuery, GetUserDataQueryVariables>(GetUserDataDocument, options);
        }
export type GetUserDataQueryHookResult = ReturnType<typeof useGetUserDataQuery>;
export type GetUserDataLazyQueryHookResult = ReturnType<typeof useGetUserDataLazyQuery>;
export type GetUserDataQueryResult = Apollo.QueryResult<GetUserDataQuery, GetUserDataQueryVariables>;
export const GetBrandsForCreateDocument = gql`
    query getBrandsForCreate($querySearch: String, $ownerId: Int, $confirmedOnly: Boolean, $take: Int, $skip: Int) {
  brands(
    querySearch: $querySearch
    ownerId: $ownerId
    confirmedOnly: $confirmedOnly
    take: $take
    skip: $skip
  ) {
    data {
      id
      name
    }
  }
}
    `;

/**
 * __useGetBrandsForCreateQuery__
 *
 * To run a query within a React component, call `useGetBrandsForCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsForCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsForCreateQuery({
 *   variables: {
 *      querySearch: // value for 'querySearch'
 *      ownerId: // value for 'ownerId'
 *      confirmedOnly: // value for 'confirmedOnly'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetBrandsForCreateQuery(baseOptions?: Apollo.QueryHookOptions<GetBrandsForCreateQuery, GetBrandsForCreateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandsForCreateQuery, GetBrandsForCreateQueryVariables>(GetBrandsForCreateDocument, options);
      }
export function useGetBrandsForCreateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandsForCreateQuery, GetBrandsForCreateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandsForCreateQuery, GetBrandsForCreateQueryVariables>(GetBrandsForCreateDocument, options);
        }
export type GetBrandsForCreateQueryHookResult = ReturnType<typeof useGetBrandsForCreateQuery>;
export type GetBrandsForCreateLazyQueryHookResult = ReturnType<typeof useGetBrandsForCreateLazyQuery>;
export type GetBrandsForCreateQueryResult = Apollo.QueryResult<GetBrandsForCreateQuery, GetBrandsForCreateQueryVariables>;
export const GetBrandsDocument = gql`
    query getBrands($ownerId: Int, $take: Int, $skip: Int) {
  brands(ownerId: $ownerId, take: $take, skip: $skip) {
    data {
      id
      status
      name
      logoPath
      createdAt
      parentBrand {
        id
      }
      childrenBrands {
        id
      }
    }
    total
  }
}
    `;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
      }
export function useGetBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<typeof useGetBrandsLazyQuery>;
export type GetBrandsQueryResult = Apollo.QueryResult<GetBrandsQuery, GetBrandsQueryVariables>;
export const GetBrandDocument = gql`
    query getBrand($id: Int!, $querySearch: String, $blockchainAddress: String!, $ownerId: Int, $take: Int, $skip: Int) {
  brand(id: $id) {
    id
    name
    products(querySearch: $querySearch, ownerId: $ownerId, take: $take, skip: $skip) {
      data {
        id
        name
        brand {
          status
          name
        }
        balance(blockchainAddress: $blockchainAddress)
      }
      total
    }
  }
}
    `;

/**
 * __useGetBrandQuery__
 *
 * To run a query within a React component, call `useGetBrandQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandQuery({
 *   variables: {
 *      id: // value for 'id'
 *      querySearch: // value for 'querySearch'
 *      blockchainAddress: // value for 'blockchainAddress'
 *      ownerId: // value for 'ownerId'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetBrandQuery(baseOptions: Apollo.QueryHookOptions<GetBrandQuery, GetBrandQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandQuery, GetBrandQueryVariables>(GetBrandDocument, options);
      }
export function useGetBrandLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandQuery, GetBrandQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandQuery, GetBrandQueryVariables>(GetBrandDocument, options);
        }
export type GetBrandQueryHookResult = ReturnType<typeof useGetBrandQuery>;
export type GetBrandLazyQueryHookResult = ReturnType<typeof useGetBrandLazyQuery>;
export type GetBrandQueryResult = Apollo.QueryResult<GetBrandQuery, GetBrandQueryVariables>;
export const GetProductsDocument = gql`
    query getProducts($ownerId: Int, $take: Int, $skip: Int, $searchType: ProductsSearchType!, $blockchainAddress: String!) {
  products(ownerId: $ownerId, take: $take, skip: $skip, searchType: $searchType) {
    data {
      id
      name
      brand {
        name
        status
      }
      balance(blockchainAddress: $blockchainAddress)
    }
    total
  }
}
    `;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchType: // value for 'searchType'
 *      blockchainAddress: // value for 'blockchainAddress'
 *   },
 * });
 */
export function useGetProductsQuery(baseOptions: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
      }
export function useGetProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
        }
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<GetProductsQuery, GetProductsQueryVariables>;
export const GetProductsForSelectDocument = gql`
    query getProductsForSelect($ownerId: Int, $take: Int, $skip: Int, $searchType: ProductsSearchType!, $blockchainAddress: String!, $querySearch: String, $excludeIds: [Int!]) {
  products(
    ownerId: $ownerId
    take: $take
    skip: $skip
    searchType: $searchType
    querySearch: $querySearch
    excludeIds: $excludeIds
  ) {
    data {
      id
      name
      brand {
        name
      }
      balance(blockchainAddress: $blockchainAddress)
    }
    total
  }
}
    `;

/**
 * __useGetProductsForSelectQuery__
 *
 * To run a query within a React component, call `useGetProductsForSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsForSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsForSelectQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchType: // value for 'searchType'
 *      blockchainAddress: // value for 'blockchainAddress'
 *      querySearch: // value for 'querySearch'
 *      excludeIds: // value for 'excludeIds'
 *   },
 * });
 */
export function useGetProductsForSelectQuery(baseOptions: Apollo.QueryHookOptions<GetProductsForSelectQuery, GetProductsForSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductsForSelectQuery, GetProductsForSelectQueryVariables>(GetProductsForSelectDocument, options);
      }
export function useGetProductsForSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductsForSelectQuery, GetProductsForSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductsForSelectQuery, GetProductsForSelectQueryVariables>(GetProductsForSelectDocument, options);
        }
export type GetProductsForSelectQueryHookResult = ReturnType<typeof useGetProductsForSelectQuery>;
export type GetProductsForSelectLazyQueryHookResult = ReturnType<typeof useGetProductsForSelectLazyQuery>;
export type GetProductsForSelectQueryResult = Apollo.QueryResult<GetProductsForSelectQuery, GetProductsForSelectQueryVariables>;
export const GetProductDocument = gql`
    query getProduct($id: Int!, $blockchainAddress: String!) {
  product(id: $id) {
    id
    name
    brand {
      name
      status
    }
    balance(blockchainAddress: $blockchainAddress)
    tokenBlockchainId
    serial
    photoFilePaths
    attributes {
      template {
        title
      }
      value
    }
  }
  whoami {
    contacts {
      data {
        id
        name
        target {
          blockchainAddress
        }
      }
    }
    stores {
      data {
        id
        name
        target {
          blockchainAddress
        }
      }
    }
  }
}
    `;

/**
 * __useGetProductQuery__
 *
 * To run a query within a React component, call `useGetProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductQuery({
 *   variables: {
 *      id: // value for 'id'
 *      blockchainAddress: // value for 'blockchainAddress'
 *   },
 * });
 */
export function useGetProductQuery(baseOptions: Apollo.QueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
      }
export function useGetProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductQuery, GetProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductQuery, GetProductQueryVariables>(GetProductDocument, options);
        }
export type GetProductQueryHookResult = ReturnType<typeof useGetProductQuery>;
export type GetProductLazyQueryHookResult = ReturnType<typeof useGetProductLazyQuery>;
export type GetProductQueryResult = Apollo.QueryResult<GetProductQuery, GetProductQueryVariables>;
export const GetOptionsDocument = gql`
    query getOptions {
  whoami {
    stores {
      ...Store
    }
    contacts {
      ...Store
    }
  }
}
    ${StoreFragmentDoc}`;

/**
 * __useGetOptionsQuery__
 *
 * To run a query within a React component, call `useGetOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetOptionsQuery, GetOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOptionsQuery, GetOptionsQueryVariables>(GetOptionsDocument, options);
      }
export function useGetOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOptionsQuery, GetOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOptionsQuery, GetOptionsQueryVariables>(GetOptionsDocument, options);
        }
export type GetOptionsQueryHookResult = ReturnType<typeof useGetOptionsQuery>;
export type GetOptionsLazyQueryHookResult = ReturnType<typeof useGetOptionsLazyQuery>;
export type GetOptionsQueryResult = Apollo.QueryResult<GetOptionsQuery, GetOptionsQueryVariables>;
export const GetStoresDocument = gql`
    query getStores($take: Int, $skip: Int) {
  whoami {
    stores(take: $take, skip: $skip) {
      ...Store
    }
    contacts(take: $take, skip: $skip) {
      ...Store
    }
  }
}
    ${StoreFragmentDoc}`;

/**
 * __useGetStoresQuery__
 *
 * To run a query within a React component, call `useGetStoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoresQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetStoresQuery(baseOptions?: Apollo.QueryHookOptions<GetStoresQuery, GetStoresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoresQuery, GetStoresQueryVariables>(GetStoresDocument, options);
      }
export function useGetStoresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoresQuery, GetStoresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoresQuery, GetStoresQueryVariables>(GetStoresDocument, options);
        }
export type GetStoresQueryHookResult = ReturnType<typeof useGetStoresQuery>;
export type GetStoresLazyQueryHookResult = ReturnType<typeof useGetStoresLazyQuery>;
export type GetStoresQueryResult = Apollo.QueryResult<GetStoresQuery, GetStoresQueryVariables>;
export const GetStoreDocument = gql`
    query getStore($id: Int, $filter: BlockchainTransactionsFilter) {
  contact(id: $id) {
    id
    name
    comment
    target {
      id
      blockchainAddress
      blockchainTransactions(filter: $filter) {
        data {
          id
          createdAt
          product {
            id
          }
          quantity
          blockchainHash
          addressFrom
          addressTo
        }
        total
      }
    }
  }
}
    `;

/**
 * __useGetStoreQuery__
 *
 * To run a query within a React component, call `useGetStoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetStoreQuery(baseOptions?: Apollo.QueryHookOptions<GetStoreQuery, GetStoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreQuery, GetStoreQueryVariables>(GetStoreDocument, options);
      }
export function useGetStoreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreQuery, GetStoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreQuery, GetStoreQueryVariables>(GetStoreDocument, options);
        }
export type GetStoreQueryHookResult = ReturnType<typeof useGetStoreQuery>;
export type GetStoreLazyQueryHookResult = ReturnType<typeof useGetStoreLazyQuery>;
export type GetStoreQueryResult = Apollo.QueryResult<GetStoreQuery, GetStoreQueryVariables>;
export const GetConsignmentsDocument = gql`
    query getConsignments($ownerId: Int, $take: Int, $skip: Int, $searchType: ProductsSearchType!, $blockchainAddress: String!) {
  products(ownerId: $ownerId, take: $take, skip: $skip, searchType: $searchType) {
    data {
      id
      name
      balance(blockchainAddress: $blockchainAddress)
    }
    total
  }
}
    `;

/**
 * __useGetConsignmentsQuery__
 *
 * To run a query within a React component, call `useGetConsignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConsignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConsignmentsQuery({
 *   variables: {
 *      ownerId: // value for 'ownerId'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      searchType: // value for 'searchType'
 *      blockchainAddress: // value for 'blockchainAddress'
 *   },
 * });
 */
export function useGetConsignmentsQuery(baseOptions: Apollo.QueryHookOptions<GetConsignmentsQuery, GetConsignmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConsignmentsQuery, GetConsignmentsQueryVariables>(GetConsignmentsDocument, options);
      }
export function useGetConsignmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConsignmentsQuery, GetConsignmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConsignmentsQuery, GetConsignmentsQueryVariables>(GetConsignmentsDocument, options);
        }
export type GetConsignmentsQueryHookResult = ReturnType<typeof useGetConsignmentsQuery>;
export type GetConsignmentsLazyQueryHookResult = ReturnType<typeof useGetConsignmentsLazyQuery>;
export type GetConsignmentsQueryResult = Apollo.QueryResult<GetConsignmentsQuery, GetConsignmentsQueryVariables>;
export const GetFinancesDocument = gql`
    query getFinances {
  whoami {
    billingTransactions {
      id
      amount
      incoming
      comment
      createdAt
    }
  }
}
    `;

/**
 * __useGetFinancesQuery__
 *
 * To run a query within a React component, call `useGetFinancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFinancesQuery(baseOptions?: Apollo.QueryHookOptions<GetFinancesQuery, GetFinancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFinancesQuery, GetFinancesQueryVariables>(GetFinancesDocument, options);
      }
export function useGetFinancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFinancesQuery, GetFinancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFinancesQuery, GetFinancesQueryVariables>(GetFinancesDocument, options);
        }
export type GetFinancesQueryHookResult = ReturnType<typeof useGetFinancesQuery>;
export type GetFinancesLazyQueryHookResult = ReturnType<typeof useGetFinancesLazyQuery>;
export type GetFinancesQueryResult = Apollo.QueryResult<GetFinancesQuery, GetFinancesQueryVariables>;
export const GetProductAttributeTemplatesDocument = gql`
    query getProductAttributeTemplates($searchQuery: String) {
  productAttributeTemplates(searchQuery: $searchQuery) {
    id
    type
    title
    placeholder
    placeholders
  }
}
    `;

/**
 * __useGetProductAttributeTemplatesQuery__
 *
 * To run a query within a React component, call `useGetProductAttributeTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductAttributeTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductAttributeTemplatesQuery({
 *   variables: {
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useGetProductAttributeTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetProductAttributeTemplatesQuery, GetProductAttributeTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProductAttributeTemplatesQuery, GetProductAttributeTemplatesQueryVariables>(GetProductAttributeTemplatesDocument, options);
      }
export function useGetProductAttributeTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProductAttributeTemplatesQuery, GetProductAttributeTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProductAttributeTemplatesQuery, GetProductAttributeTemplatesQueryVariables>(GetProductAttributeTemplatesDocument, options);
        }
export type GetProductAttributeTemplatesQueryHookResult = ReturnType<typeof useGetProductAttributeTemplatesQuery>;
export type GetProductAttributeTemplatesLazyQueryHookResult = ReturnType<typeof useGetProductAttributeTemplatesLazyQuery>;
export type GetProductAttributeTemplatesQueryResult = Apollo.QueryResult<GetProductAttributeTemplatesQuery, GetProductAttributeTemplatesQueryVariables>;