import React from 'react';
import './styles/support-page.scss';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import FormLayout from '../../layouts/FormLayout';
import {Formik, Form, Field, FieldProps} from 'formik';
import FormInput from '../../components/ui/FormInput';
import CustomDropzone from '../../components/ui/CustomDropzone';
import {CREATE_DROPZONE_STYLE} from '../products/helpers/constants';
import SelectConfirmFiles from '../brands/images/select-confirm-files.svg';
import FileChip from '../../components/ui/Chips/FileChip';
import CustomButton from '../../components/ui/Buttons/Button';
import SubmitButtonWithErrors from '../../components/ui/Buttons/SubmitButtonWithErrors';
import {COLORS} from '../../constants/colors';

const SubmitRequestPage: React.FC = (): JSX.Element => {
    const classes = useStyles();
    return (
        <FormLayout
            crumbsDetails={[
                {message: 'Support', link: ''},
                {message: 'Submit a request ', link: ''}
            ]}
            title={'Submit a request'}
        >
            <Formik
                initialValues={{
                    issue: '',
                    email: '',
                    subject: '',
                    description: '',
                    pictures: []
                }}
                // validationSchema={}
                onSubmit={async (values) => {
                    try {
                        console.log('values', values);
                    } catch (e) {
                        console.log('e', e);
                    }
                }}
            >{({values}) => (
                    <Form className={classes.Form}>
                        {/*<Field*/}
                        {/*    name='issue'*/}
                        {/*    component={FormikAutocomplete}*/}
                        {/*    label="Issue"*/}
                        {/*    style={{*/}
                        {/*        width: '50%',*/}
                        {/*        minWidth: 300,*/}
                        {/*        marginBottom: theme.spacing(5) - 8*/}
                        {/*    }}*/}
                        {/*    options={issue.map(item => item)}*/}
                        {/*    formikError={props.errors.issue}*/}
                        {/*    bottomArrow*/}
                        {/*    textFieldProps={{*/}
                        {/*        margin: 'normal',*/}
                        {/*        variant: 'outlined',*/}
                        {/*        placeholder: "Issue",*/}
                        {/*    }}*/}
                        {/*/>*/}
                        <FormInput
                            name={'email'}
                            label={'Email address'}
                            labelWidth={105}
                            formControlProps={{
                                classes: {root: classes.TextField}
                            }}
                        />
                        <FormInput
                            name={'subject'}
                            label={'Subject'}
                            placeholder={'Subject'}
                            labelWidth={55}
                            formControlProps={{
                                classes: {root: classes.TextField}
                            }}
                        />
                        <FormInput
                            name={'description'}
                            label={'Description'}
                            placeholder={'Description'}
                            labelWidth={85}
                            multiline
                            rows={7}
                            formControlProps={{
                                classes: {root: classes.TextField}
                            }}
                            helperTextProps={{
                                className: classes.helperText
                            }}
                            helperText={'Please enter the details of your request. A member of our support staff will respond as soon as possible.'}
                        />
                        <Typography variant={'h4'} style={{marginBottom: 12}}>Attachment (Optional)</Typography>
                        <Field name={'photoFilePaths'}>
                            {({form, meta, field}: FieldProps) => (
                                <CustomDropzone
                                    fieldProps={{form, meta, field}}
                                    style={CREATE_DROPZONE_STYLE}
                                    renderContent={(open: () => void) =>
                                        <>
                                            <Typography variant={'h5'} className={classes.uploadText}>
                                            Product Pictures
                                            </Typography>
                                            <Box className={classes.uploadInfo}>
                                                <img src={SelectConfirmFiles} alt="SelectConfirmFiles"
                                                    className={classes.uploadImg}/>
                                                <Box className={classes.chipsWrapper}>
                                                    {values?.pictures?.length > 0 ?
                                                        values?.pictures?.map((item, index) => (
                                                            <FileChip
                                                                key={index}
                                                                label={item?.preview}
                                                                onDelete={() => {
                                                                    const changedArray = values?.pictures?.filter((clickedItem) => item?.preview !== clickedItem?.preview)
                                                                    form.setFieldValue('pictures', changedArray)
                                                                }}
                                                            />
                                                        ))
                                                        :
                                                        <Typography variant={'h5'}
                                                            className={classes.uploadLimits}>
                                                        Format - JPG, PNG, TIFF or BMP.
                                                        </Typography>
                                                    }
                                                </Box>
                                            </Box>
                                            <CustomButton
                                                variant={'text'}
                                                color={'primary'}
                                                onClick={open}
                                                className={classes.uploadButton}
                                            >
                                            Select files
                                            </CustomButton>
                                        </>
                                    }
                                    maxFiles={10}
                                    accept={['image/*']}
                                    onDrop={(acceptedFiles) => {
                                        acceptedFiles.forEach((file) => {
                                            const reader = new FileReader();
                                            reader.onload = () => {
                                                const data = new FormData();
                                                data.append('file', file);
                                                fetch(process.env.REACT_APP_API_FILE_URL, {
                                                    method: 'POST',
                                                    body: data
                                                }).then((response) => {
                                                    return response.json();
                                                }).then((data) => {
                                                    const parseFileObj = {
                                                        filePath: data.filePath,
                                                        preview: data.originalName
                                                    };
                                                    const fileObject = JSON.parse(JSON.stringify(parseFileObj, null, 2));
                                                    form.setFieldValue('photoFilePaths', [...values?.pictures, {...fileObject}]);
                                                });
                                            };
                                            reader.readAsArrayBuffer(file);
                                        });
                                    }}
                                />
                            )}
                        </Field>
                        <SubmitButtonWithErrors/>
                    </Form>
                )}
            </Formik>
        </FormLayout>
    );
};

export default SubmitRequestPage;

const useStyles = makeStyles<Theme>(() => ({
    Form: {
        display: 'flex',
        flexDirection: 'column'
    },
    TextField: {
        width: '50%',
        minWidth: 300,
        marginBottom: 12
    },
    Title: {
        marginBottom: 24
    },
    helperText: {
        letterSpacing: 0,
        textTransform: 'none',
        color: COLORS.TEXT_SILVER
    }
}));
