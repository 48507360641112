import React, {useCallback} from 'react';
import {Theme} from '@material-ui/core/styles';
import {
    IconButton,
    List,
    ListItem,
    ListItemAvatar, ListItemSecondaryAction,
    ListItemText,
    ListProps,
    makeStyles
} from '@material-ui/core';
import {AccountSession} from '../../../generated/graphql';
import {transformDate} from '../../../helpers';
import Desktop from '../images/desktop.svg';
import Smartphone from '../images/smartphone.svg';
import ActiveSmartphone from '../images/smartphone-active.svg';
import DesktopDisabled from '../images/desktop-disabled.svg';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

type SessionItem = ListProps & {
    session: AccountSession,
    currentSection?: boolean
    deleteSession?: any;
}

const SessionItem: React.FC<SessionItem> = (props: SessionItem): JSX.Element => {
    const classes = useStyles();
    const {session: {userAgent: {os, browser}, createdAt, ipAddr, address, id}, currentSection = false, deleteSession, ...rest} = props;
    console.log('ipAddr', ipAddr);
    return (
        <List className={classes.root} {...rest}>
            <ListItem
                classes={{
                    root: classes.ListItem
                }}
                disableGutters
            >
                <ListItemAvatar
                    classes={{
                        root: classes.ListItemAvatar
                    }}
                >
                    {os?.name === 'Android' || os?.name === 'iOS'
                        ? <img src={currentSection ? ActiveSmartphone : Smartphone} alt="mobile"/>
                        : <img src={currentSection ? Desktop : DesktopDisabled} alt="desktop"/>
                    }
                </ListItemAvatar>
                <ListItemText
                    classes={{
                        root: classes.ListItemText,
                        primary: classes.primaryText,
                        secondary: classes.secondaryText
                    }}
                    primary={`${os?.name} ${os?.version}${address ? ` ∙ ${address}` : ''}`}
                    secondary={`${transformDate(createdAt)} ∙ ${ipAddr} ∙ ${browser?.name} browser`}
                />
                {!currentSection &&
                <ListItemSecondaryAction>
                    <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => deleteSession(id)}
                    >
                        <CloseRoundedIcon />
                    </IconButton>
                </ListItemSecondaryAction>
                }
            </ListItem>
        </List>
    );
};

export default React.memo(SessionItem);

const useStyles = makeStyles<Theme>(() => ({
    ListItem: {

    },
    ListItemAvatar: {
        minWidth: 40,
        height: '100%',
        marginBottom: 12
    },
    ListItemText: {

    },
    primaryText: {

    },
    secondaryText: {
        fontSize: 16
    },
}));
