import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Chip, ChipProps, makeStyles} from '@material-ui/core';
import {BrandStatus} from '../../../generated/graphql';
import {COLORS} from '../../../constants/colors';

type StatusChipProps = ChipProps & {
    status: BrandStatus | 'SUCCESS'
}

const StatusChip: React.FC<StatusChipProps> = React.memo((props: StatusChipProps): JSX.Element => {
    const {status, ...rest} = props;
    const [data, setData] = useState(calcData(status));
    const classes = useStyles({color: data?.color, status: status});
    return (
        <Chip
            classes={{
                root: classes.chipRoot,
                label: classes.label
            }}
            clickable={false}
            label={
                <>
                    <i className={`material-icons ${classes.iconStyle}`}>{data?.icon}</i>
                    {data?.label}
                </>
            }
            {...rest}
        />
    );
});

export default StatusChip;

const calcData = (status: BrandStatus | 'SUCCESS') => {
    switch (status) {
        case BrandStatus.Blocked: {
            return {
                color: COLORS.ERROR_PRIMARY,
                label: 'rejected',
                icon: 'close'
            }
        }
        case BrandStatus.Processing: {
            return {
                color: COLORS.WARNING,
                label: 'processing',
                icon: 'lens'
            }
        }
        case BrandStatus.Confirmed: {
            return {
                color: COLORS.SUCCESS,
                label: 'confirmed',
                icon: 'done'
            }
        }
        case BrandStatus.AutoConfirmed: {
            return {
                color: COLORS.SUCCESS,
                label: 'auto-confirmed',
                icon: 'done'
            }
        }
        case 'SUCCESS': {
            return {
                color: COLORS.SUCCESS,
                label: 'success',
                icon: 'done'

            }
        }
    }
}

const useStyles = makeStyles<Theme, {color: string, status: BrandStatus | 'SUCCESS'}>(() => ({
    chipRoot: {
        color: props => props.color,
        background: 'transparent',
    },
    iconStyle: {
        color: props => props.color,
        fontSize: props => props.status === BrandStatus.Processing ? 10 : 14
    },
    label: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 0,
    }
}));
