import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    makeStyles,
    Typography,
    withStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Form, Formik} from 'formik';
import {SEND_SINGLE_PRODUCT_SCHEMA} from '../../../constants/validation_schema';
import CustomButton from '../../../components/ui/Buttons/Button';
import MuiCircularProgress from '../../../components/ui/CircularProgress';
import {ProductType} from '../../../types/enum';
import FormAutocomplete from '../../../components/ui/FormAutocomplete';
import FormInput from '../../../components/ui/FormInput';
import {BrandStatus, useSendProductsMutation} from '../../../generated/graphql';
import {useUIContext} from '../../../context/UIContext';
import {MESSAGES} from '../../../constants/messages';

type SendProductSection = {
    type: ProductType,
    selectedItem: number,
    dataRefetch: () => void
    options: any[]
    brand: {
        name: string
        status: BrandStatus
    };
}

const SendProductSection: React.FC<SendProductSection> = (props: SendProductSection): JSX.Element => {
    const classes = useStyles();
    const {brand, type, selectedItem, dataRefetch, options} = props;
    const {showSnackBar, setUnconfirmedWarning} = useUIContext();
    const [sendProduct] = useSendProductsMutation();
    const changeAutocomplete = (setFieldValue) => (event, value) => {
        setFieldValue('receiverAddress', value?.target?.blockchainAddress);
    }
    return (
        <MuiAccordion
            square
            classes={{
                expanded: classes.AccordionExpanded
            }}
        >
            <MuiAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{
                    root: classes.AccordionSummary,
                    expanded: classes.AccordionTopMargin
                }}
            >
                <Typography>
                    SEND {type === ProductType.product ? 'PRODUCT' : 'CONSIGNMENT'}{' '}
                </Typography>
            </MuiAccordionSummary>
            <MuiAccordionDetails>
                <Formik
                    initialValues={{
                        receiverAddress: '',
                        quantity: '',
                    }}
                    validationSchema={SEND_SINGLE_PRODUCT_SCHEMA}
                    onSubmit={async (values) => {
                        try {
                            if (brand?.status !== BrandStatus.AutoConfirmed || type === ProductType.consignment) {
                                const {data} = await sendProduct({
                                    variables: {
                                        receiverAddress: values?.receiverAddress,
                                        input: [{
                                            productId: selectedItem,
                                            quantity: Number(values?.quantity),
                                        }]
                                    }
                                })
                                if (data?.productsSend) {
                                    showSnackBar(type === ProductType.product ?MESSAGES.PRODUCT_SEND : MESSAGES.CONSIGNMENT_SEND)
                                }
                            } else {
                                setUnconfirmedWarning({
                                    open: true,
                                    brandNames: [brand?.name]
                                })
                            }
                        } catch (e) {
                            showSnackBar(e?.message)
                        } finally {
                            await dataRefetch()
                        }
                    }}
                >
                    {({
                        handleSubmit,
                        values,
                        isSubmitting,
                        setFieldValue
                    }) => {
                        return (
                            <Form className={classes.form} onSubmit={handleSubmit}>
                                <FormAutocomplete
                                    freeSolo
                                    name={'receiverAddress'}
                                    label={'Send to'}
                                    disableClearable
                                    options={options}
                                    getOptionLabel={(options) => options?.name}
                                    wrapperStyle={classes.address}
                                    onChange={changeAutocomplete(setFieldValue)}
                                />
                                <Box className={classes.wrapper}>
                                    <FormInput
                                        fullWidth
                                        name={'quantity'}
                                        label={'Quantity'}
                                        labelWidth={60}
                                        formControlProps={{
                                            className: classes.quantity
                                        }}
                                        inputProps={{
                                            type: 'number'
                                        }}
                                    />
                                    <CustomButton
                                        variant={'outlined'}
                                        type={'submit'}
                                        disabled={isSubmitting}
                                        style={{alignSelf: 'flex-start', height: 56}}
                                    >
                                        {isSubmitting ? <MuiCircularProgress /> : 'Send'}
                                    </CustomButton>
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>
            </MuiAccordionDetails>
        </MuiAccordion>
    )
};

const useStyles = makeStyles({
    form: {
        display: 'flex',
        flexFlow: 'row wrap',
        padding: '0 5% 5% 5%',
        justifyContent: 'space-between',
    },
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    AccordionSummary: {
        padding: '12px 20px',
        margin: '0 !important',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    AccordionDetailsFlex: {
        padding: '0 0 10px 0',
        display: 'flex',
        flexFlow: 'column wrap',
        borderBottom: 'none'
    },
    AccordionExpanded: {
        margin: '0 !important'
    },
    AccordionTopMargin: {
        marginTop: '0 !important',
        marginBottom: '0 !important'
    },
    secondaryHeading: {
        alignSelf: 'flex-end'
    },
    quantity: {
        flex: 1,
        marginRight: 16
    },
    address: {
        marginBottom: 8
    }
});

export default SendProductSection;

export const MuiAccordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        borderRight: 'none',
        borderLeft: 'none',
        boxShadow: 'none',
        padding: '0 10px',
        borderBottom: 0,
        '&:last-child': {
            borderBottom: '1px solid rgba(0, 0, 0, .125)'
        },
        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            margin: 'auto'
        }
    },
    expanded: {}
})(Accordion);
export const MuiAccordionSummary = withStyles({
    root: {
        backgroundColor: 'white',
        borderBottom: 'none',
        marginBottom: 'none',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56
        }
    },
    content: {
        '&$expanded': {
            margin: '12px 0'
        }
    },
    expanded: {}
})(AccordionSummary);
export const MuiAccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1)
    }
}))(AccordionDetails);

