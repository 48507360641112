import React, {useEffect, useState} from 'react';
import {Box, IconButton, makeStyles, Table, TableBody, TableContainer, Typography} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import CustomDrawer from './Drawer';
import CustomButton from "../../../../components/ui/Buttons/Button";
import {useUIContext} from "../../../../context/UIContext";
import {ReactSVG} from 'react-svg'
import GeoIcon from '../../../../assets/icons/geo.svg';
import PhoneIcon from '../../../../assets/icons/local_phone.svg';
import EmailIcon from '../../../../assets/icons/email.svg';
import CustomTableHead from "../../../../components/ui/Tables/TableHead";
import {INVOICES_PREVIEW_HEAD} from "../../../../constants/tableColumns";
import CustomTableRow from "../../../../components/ui/Tables/TableRow";
import TooltipCell from "../../../../components/ui/Tables/TooltipCell";
import clsx from "clsx";
import CustomPaper from "../../../../components/ui/Paper";
import {formattedDate} from "../../../../helpers";
import {useColoringValue} from "../../../../helpers/hooks";
import {useGlobalContext} from "../../../../context/globalContext";

type CustomNewInvoicePreviewProps = {
    openNewInvoicePreview: boolean;
    setOpenNewInvoicePreview: React.Dispatch<React.SetStateAction<boolean>>;
    dataNewInvoice: any;
    onSubmitInvoice: any;
}

const NewInvoicePreview: React.FC<CustomNewInvoicePreviewProps> = (props: CustomNewInvoicePreviewProps): JSX.Element => {
    const {openNewInvoicePreview, setOpenNewInvoicePreview, dataNewInvoice, onSubmitInvoice} = props;
    const classes = useStyles();
    const {userData} = useGlobalContext();
    const {setOpenCreateInvoice} = useUIContext();
    const today = new Date();


    const handleBackInvoiceEdit = () => {
        setOpenNewInvoicePreview(false);
        setOpenCreateInvoice(true);
    };

    return (
        <CustomDrawer open={openNewInvoicePreview} onClose={() => setOpenNewInvoicePreview(false)}>
            <div className={classes.newInvoicePreview}>
                <div className={classes.contactInfo}>
                    <Typography
                        variant={'h4'}
                        component={'h4'}
                        className={classes.companyName}
                    >{userData?.fullName}</Typography>
                    <div className={classes.iconContainer}>
                        <ReactSVG src={EmailIcon} className={classes.infoIcon}/>
                        <Typography
                            variant={'body1'}
                            component={'p'}
                            className={classes.infoTitle}
                        >info@shopname.com</Typography>
                    </div>
                    <div className={classes.iconContainer}>
                        <ReactSVG src={GeoIcon} className={classes.infoIcon}/>
                        <Typography variant={'body1'} component={'p'} className={classes.infoTitle}>
                            {`${userData?.zipCode} ${userData?.streetAddress}, ${userData?.cityState}`}
                        </Typography>
                    </div>
                    <div className={classes.iconContainer}>
                        <ReactSVG src={PhoneIcon} className={classes.infoIcon}/>
                        <Typography
                            variant={'body1'}
                            component={'p'}
                            className={classes.infoTitle}
                        >+ 1 (917) - 675 - 52 - 71</Typography>
                    </div>
                </div>
                <div className={classes.totalAmountContainer}>
                    <Typography variant={'h4'} component={'p'} className={classes.totalAmountTitle}>Total
                        amount</Typography>
                    <Typography
                        variant={'h2'}
                        component={'span'}
                        className={classes.totalAmountPrice}
                    >${useColoringValue('#FFFFFF','rgba(255, 255, 255, 0.74)',dataNewInvoice?.totalAmount)}</Typography>
                </div>
                <div className={classes.invoiceBody}>
                    <Typography variant={'h2'} component={'h2'} className={classes.titleInvoice}>Invoice
                        #548-66AC</Typography>
                    {dataNewInvoice?.description &&
                    <div className={classes.containerInfoBlock}>
                        <Typography
                            variant={'caption'}
                            component={'p'}
                            className={classes.titleBlock}
                        >description</Typography>
                        <Typography
                            variant={'body1'}
                            component={'p'}
                            className={classes.textBlock}
                        >{dataNewInvoice?.description}</Typography>
                    </div>}
                    <div className={classes.containerDateEmail}>
                        <div className={classes.containerInfoBlock} style={{minWidth: 190}}>
                            <Typography variant={'caption'} component={'p'} className={classes.titleBlock}>ISSUE
                                DATE</Typography>
                            <Typography variant={'body1'} component={'p'} className={classes.textBlock}>{formattedDate(today)}</Typography>
                        </div>
                        <div className={classes.containerInfoBlock} style={{minWidth: 190}}>
                            <Typography variant={'caption'} component={'p'} className={classes.titleBlock}>due
                                DATE</Typography>
                            <Typography variant={'body1'} component={'p'} className={classes.textBlock}>{formattedDate(dataNewInvoice.dueOnDate)}</Typography>
                        </div>
                        {dataNewInvoice?.recipientEmail &&
                        <div className={classes.containerInfoBlock}>
                            <Typography variant={'caption'} component={'p'} className={classes.titleBlock}>Recepient
                                email</Typography>
                            <Typography
                                variant={'body1'}
                                component={'p'}
                                className={classes.textBlock}
                            >{dataNewInvoice?.recipientEmail}</Typography>
                        </div>}
                    </div>
                    {dataNewInvoice?.itemsInvoice?.length <= 0
                        ? null
                        : <CustomPaper className={
                            clsx(
                                classes.paperContainer
                            )
                        }>
                            <TableContainer
                                classes={{root: classes.container}}
                            >
                                <Table
                                    classes={{root: classes.table}}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                    aria-label="enhanced table"
                                    stickyHeader
                                >
                                    <CustomTableHead
                                        isInvoicesTable
                                        headCells={INVOICES_PREVIEW_HEAD}
                                    />
                                    <TableBody classes={{root: classes.tableBody}}>
                                        {dataNewInvoice?.itemsInvoice?.map((row, index) => {
                                            return (
                                                <CustomTableRow
                                                    tabIndex={-1}
                                                    key={row.id}
                                                >
                                                    <TooltipCell
                                                        component="th"
                                                        id={index}
                                                        scope="row"
                                                        label={row.name}
                                                        value={row.name}
                                                    />
                                                    <TooltipCell
                                                        label={row.quantity}
                                                        value={row.quantity}
                                                        align={'right'}
                                                    />
                                                </CustomTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CustomPaper>
                    }
                    {dataNewInvoice?.note &&
                    <div className={classes.containerInfoBlock}>
                        <Typography
                            variant={'caption'}
                            component={'p'}
                            className={classes.titleBlock}
                        >Note</Typography>
                        <Typography
                            variant={'body1'}
                            component={'p'}
                            className={classes.textBlock}
                        >{dataNewInvoice?.note}</Typography>
                    </div>}
                </div>
                <div className={classes.footer}>
                    <div className={classes.btnContainer}>
                        <CustomButton
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleBackInvoiceEdit}
                            className={classes.btnBackToEdit}
                        >Back to edit</CustomButton>
                        <CustomButton
                            type={'submit'}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => onSubmitInvoice()}
                            className={classes.btnCreate}
                        >Create</CustomButton>
                    </div>
                </div>
            </div>
        </CustomDrawer>
    );
};

export default NewInvoicePreview;

const useStyles = makeStyles<Theme>(() => ({
    newInvoicePreview: {
        minWidth: 807,
        maxWidth: 807,
        padding: '30px 16px 0',
        minHeight: '100vh',
        position: 'relative',
    },
    footer: {
        display: 'flex',
        justifyContent: 'end',
        padding: '32px 40px',
        position: 'fixed',
        width: 807,
        bottom: 0,
        right: 0,
        zIndex: 99999,
        background: '#FFFFFF',
        boxShadow: '0px -8px 11px -6px rgba(132, 111, 216, 0.14)'
    },
    btnCreate: {
        height: 56,
        padding: '0 32px',
        marginLeft: 16
    },
    btnBackToEdit: {
        color: '#276EF9',
        background: '#EEF4FF',
        boxShadow: 'none',
        height: 56,
        padding: '0 32px',
        '&:hover': {
            background: 'rgba(39, 110, 249, 0.1)',
            boxShadow: 'none'
        },
        '&:active': {
            background: 'rgba(39, 110, 249, 0.18)',
            boxShadow: 'none'
        },
    },
    contactInfo: {
        maxWidth: 300,
        margin: '0 60px 30px auto',
        '& > div + div': {
            marginTop: 16
        }
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        '& > div > div > svg': {
            display: 'block'
        }
    },
    infoTitle: {
        marginLeft: 16,
        fontSize: 16,
        lineHeight: '24px',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    infoIcon: {
        marginBottom: 'auto'
    },
    totalAmountContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px 24px',
        background: '#276EF9',
        borderRadius: 16
    },
    totalAmountTitle: {
        color: '#FFFFFF'
    },
    totalAmountPrice: {
        color: '#FFFFFF',
        lineHeight: '60px'
    },
    invoiceBody: {
        padding: '0 24px 150px'
    },
    titleInvoice: {
        lineHeight: '60px',
        margin: '32px 0 16px'
    },
    containerInfoBlock: {},
    titleBlock: {
        color: 'rgba(0, 0, 0, 0.6)',
        marginBottom: 8,
        lineHeight: '18px'
    },
    containerDateEmail: {
        display: 'flex',
        '& > div + div': {
            marginLeft: 16
        },
        margin: '30px 0 34px'
    },
    paperContainer: {
        filter: 'drop-shadow(0px 6px 17px rgba(73, 105, 219, 0.08))',
        borderRadius: 16,
        marginBottom: 34
    },
    tableBody: {
        '& > tr:last-of-type > th, & > tr:last-of-type > td': {
            borderBottom: 'none'
        },
        '& > tr > th:first-child': {
            paddingLeft: '24px !important'
        },
        '& > tr > td:last-child': {
            paddingRight: '24px !important'
        }
    },
    table: {
        paddingBottom: 8
    },
    companyName: {
        marginBottom: 12,
        lineHeight: '48px'
    }
}));
