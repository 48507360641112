import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {AppBar, Box, makeStyles, Tab, Tabs, Typography} from '@material-ui/core';
import CustomPaper from '../../components/ui/Paper';
import Balance from './components/Balance';
import TransactionHistory from './components/TransactionHistory';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom';
import {Elements} from '@stripe/react-stripe-js';
import {STRIPE_KEY} from './helpers/constants';
import InvoiceForm from '../../components/forms/InvoiceForm';
import CreditCardForm from '../../components/forms/CreditCardForm';
import {loadStripe} from '@stripe/stripe-js';
import OnloadInvoice from './components/OnloadInvoice';
import FinanceProvider from '../../context/financeContext';

const stripe = loadStripe(STRIPE_KEY);

const FinancesPage: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const [value, setValue] = React.useState<number>(0);
    const history = useHistory();
    const {pathname} = useLocation();
    const handleChange = (event, newValue) => {
        if (pathname === '/finances/invoice') {
            history.push('/finances');
        }
        setValue(newValue);
    };

    return (
        <FinanceProvider>
            <CustomPaper className={classes.container}>
                <Box className={classes.balance}>
                    <Balance />
                    <TransactionHistory />
                </Box>
                <Box className={classes.rechargeBalance}>
                    <Typography variant={'h4'} className={classes.RechargeTitle}>Recharge the balance</Typography>
                    <Box className={classes.root}>
                        <AppBar position="static" className={classes.AppBar}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="simple tabs example"
                                className={classes.Tabs}
                                indicatorColor={'primary'}
                                textColor={'primary'}
                            >
                                <Tab label="Credit card" {...a11yProps(0)} />
                                <Tab label="Invoice" {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0} className={classes.TabPanel}>
                            <Elements stripe={stripe}>
                                <CreditCardForm />
                            </Elements>
                        </TabPanel>
                        <TabPanel value={value} index={1} className={classes.TabPanel}>
                            <Switch>
                                <Route exact path={'/finances'} component={InvoiceForm}/>
                                <Route exact path={'/finances/invoice'}  component={OnloadInvoice}/>
                            </Switch>
                        </TabPanel>
                    </Box>
                </Box>
            </CustomPaper>
        </FinanceProvider>
    );
};

export default FinancesPage;

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}
function TabPanel(props) {
    const {children, value, index, ...other} = props;
    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box height={'100%'} width={'100%'}>
                    {children}
                </Box>
            )}
        </Box>
    );
}

const useStyles = makeStyles<Theme>(() => ({
    container: {
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '450px 1fr'
    },
    balance: {
        position: 'relative',
        display: 'grid',
        gridTemplateRows: '315px 100%',
        width: '100%',
        height: '100%',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)'
    },
    rechargeBalance: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    },
    root: {
        flexGrow: 1,
        padding: 0,
        display: 'flex',
        flexDirection: 'column'
    },
    Tabs: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.6)',
        marginLeft: 50
    },
    AppBar: {
        boxShadow: 'none',
        borderBottom: '1px solid silver',
        backgroundColor: 'white',
        width: '100%'
    },
    TabPanel: {
        flex: 1,
        position: 'relative',
    },
    Finances: {
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        background: 'white',
        borderRadius: 8,
        display: 'grid',
        gridTemplateColumns: '1.1fr 2fr'
    },
    RechargeTitle: {
        padding: '40px 40px 20px 40px'
    },

}));
