import {CREATE_STORE} from './type';

const CREATE_STORE_TYPE: CREATE_STORE = {
    ADD: 'ADD',
    CREATE: 'CREATE'
}


const OPTIONS = [
    {id: 1, value: CREATE_STORE_TYPE.ADD, label: 'Add account to contact'},
    {id: 2, value: CREATE_STORE_TYPE.CREATE, label: 'Create personal store'}
];

const STORE_TAKE = 10

export {
    CREATE_STORE_TYPE,
    OPTIONS,
    STORE_TAKE
};
