import React from 'react';
import {TableRow} from '@material-ui/core';
import MuiTableCell from './TableCell';
import {Skeleton} from '@material-ui/lab';

const rowsConstructor = (rows: number) => {
    return new Array(rows).fill('row');
};
const cellConstructor = (cell: number) => {
    return new Array(cell).fill('cell');
};

type TableSkeletonProps = {
    rows?: number,
    cell: number
}

// eslint-disable-next-line react/display-name
const TableSkeleton: ({rows, cell}: TableSkeletonProps) => React.ReactElement = React.memo(({rows = 10,cell = 5}: TableSkeletonProps): React.ReactElement => {
    return <>
        {rowsConstructor(rows).map((_, index) => (
            <TableRow key={index}>
                {cellConstructor(cell).map((_item, i) => (
                    <MuiTableCell key={i}><Skeleton /></MuiTableCell>
                ))}
            </TableRow>
        ))}
    </>;
});

export default TableSkeleton;

