import React, {useEffect, useMemo, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, Checkbox, InputAdornment, makeStyles, TextField, Typography} from '@material-ui/core';
import DialogLayout, {DialogLayoutProps} from '../../../layouts/DialogLayout';
import {ProductType} from '../../../types/enum';
import {ProductsSearchType, useGetProductsForSelectQuery} from '../../../generated/graphql';
import {TABLE_DEFAULT_TAKE} from '../../../constants';
import {useGlobalContext} from '../../../context/globalContext';
import {useDebounce} from 'use-debounce';
import SearchIcon from '@material-ui/icons/Search';
import CheckBoxTable from '../Tables/CheckBoxTable';
import {useSelectRow} from '../../../helpers/hooks';
import {CONSIGNMENT_SELECT_HEAD, PRODUCT_SELECT_HEAD} from '../../../constants/tableColumns';
import TableSkeleton from '../Tables/TableSkeleton';
import {isSelected} from '../../../helpers';
import CustomTableRow from '../Tables/TableRow';
import MuiTableCell from '../Tables/TableCell';
import CustomButton from '../Buttons/Button';
import {useFormikContext} from 'formik';
import TooltipCell from '../Tables/TooltipCell';
import clsx from 'clsx';
import {NavLink} from 'react-router-dom';
import NotFoundSection from '../../../pages/products/components/NotFoundSection';

type DialogSelectProductProps = DialogLayoutProps & {
    type: ProductType,
    isInvoice?: boolean
}

// eslint-disable-next-line complexity
const DialogSelectProduct: React.FC<DialogSelectProductProps> = (props: DialogSelectProductProps): JSX.Element => {
    const classes = useStyles();
    const {open, isInvoice, type, onClose, ...rest} = props;
    // @ts-ignore
    const {values: {items, itemsInvoice}, setFieldValue} = useFormikContext();
    const itemsSelectedFormik = isInvoice ? itemsInvoice : items;

    const [skip, setSkip] = useState(0);
    const {userData} = useGlobalContext();
    const [value, setValue] = useState<string>('');
    const [debounceValue] = useDebounce(value, 500);
    const [selected, handleClick, selectAllClick, setSelected] = useSelectRow([]);
    const {data: queryData, loading, error, previousData} = useGetProductsForSelectQuery({
        variables: {
            ownerId: userData?.id,
            take: TABLE_DEFAULT_TAKE,
            skip: skip,
            searchType: type === ProductType.product ? ProductsSearchType.ProductsOnly : ProductsSearchType.ConsignmentsOnly,
            blockchainAddress: userData?.blockchainAddress,
            querySearch: debounceValue,
            // @ts-ignore
            excludeIds: itemsSelectedFormik?.map(item => item?.id)
        }
    })
    const data = useMemo(() => {
        if (queryData?.products?.data) {
            return {
                products: queryData?.products?.data
            }
        } else {
            return null
        }
    }, [queryData]);
    useEffect(() => {
        // @ts-ignore
        setSelected([])
    }, [itemsSelectedFormik]);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => setValue(event?.target?.value)
    const handleAddProduct = () => {
        if (selected?.length > 0) {
            // @ts-ignore
            setFieldValue(isInvoice ? 'itemsInvoice' : 'items', [...selected?.map(item => ({
                ...item,
                quantity: ''
            })), ...itemsSelectedFormik])
        }
        onClose('', 'escapeKeyDown')
    };
    return (
        <DialogLayout
            open={open}
            classes={{
                root: classes.dialog,
                paper: classes.dialogPaper
            }}
            maxWidth={false}
            onClose={onClose}
            {...rest}
        >
            <Box className={classes.searchWrapper}>
                <Typography
                    variant={'h4'}
                    className={classes.title}
                >{type === ProductType.product ? 'Products' : 'Consignments'}</Typography>
                <TextField
                    autoFocus
                    fullWidth
                    variant={'outlined'}
                    value={value}
                    onChange={handleChange}
                    placeholder={'Search'}
                    label={'Search'}
                    type={'search'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon/>
                            </InputAdornment>
                        )
                    }}
                />
            </Box>
            {loading && !previousData || data?.products && data?.products?.length > 0 ?
                <CheckBoxTable
                    headCells={type === ProductType.product ? PRODUCT_SELECT_HEAD : CONSIGNMENT_SELECT_HEAD}
                    data={data?.products}
                    selected={selected}
                    totalCount={queryData?.products?.total}
                    selectAllClick={selectAllClick}
                    setSkip={setSkip}
                    wrapperContainer={classes.table}
                    defaultTakeValue={TABLE_DEFAULT_TAKE}
                >
                    {loading && !previousData ? <TableSkeleton cell={type === ProductType.product ? 5 : 3}/> :
                        data?.products && data?.products?.length > 0 &&
                        data?.products?.map((item, index) => {
                            const isItemSelected = isSelected(item.id, selected);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <CustomTableRow
                                    key={item?.id}
                                    hover
                                    // @ts-ignore
                                    onClick={(event) => handleClick(event, item)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    selected={isItemSelected}
                                >
                                    <MuiTableCell padding="checkbox">
                                        <Checkbox
                                            checked={isItemSelected}
                                            inputProps={{'aria-labelledby': labelId}}
                                            color={'primary'}
                                        />
                                    </MuiTableCell>
                                    <TooltipCell
                                        label={item?.name}
                                        value={item?.name}
                                    />
                                    {type === ProductType.product &&
                                    <TooltipCell
                                        label={item?.brand?.name}
                                        value={item?.brand?.name}
                                    />
                                    }
                                    <TooltipCell
                                        label={item?.balance}
                                        value={item?.balance}
                                        align={'right'}
                                    />
                                </CustomTableRow>
                            )
                        })
                    }
                </CheckBoxTable>
                : <NotFoundSection/>
            }
            <Box className={classes.buttonWrapper}>
                <CustomButton
                    disabled={!selected.length}
                    variant={'contained'}
                    onClick={handleAddProduct}
                    className={classes.submitButton}
                >Add {(selected.length) ? selected.length : null} {type === ProductType.product ? 'products' : 'consignments'}</CustomButton>
            </Box>
        </DialogLayout>
    );
};

export default DialogSelectProduct;

const useStyles = makeStyles<Theme>(() => ({
    dialog: {
        width: '100%'
    },
    dialogPaper: {
        width: 700,
        height: '85%',
        paddingBottom: 16
    },
    searchWrapper: {
        padding: '32px 56px'
    },
    title: {
        marginBottom: 32
    },
    table: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 0,
        marginBottom: 16
    },
    submitButton: {
        marginRight: 30,
    },
    buttonWrapper: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    selectedItem: {
        opacity: 0.6
    }
}));
