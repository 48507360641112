import React from 'react';
import './gloabal-loader.scss';
import TopPattern from '../../../assets/background/authLayoutPattern.svg';
import {useAnimatedText} from '../../../helpers/hooks';

const GlobalLoader = ():JSX.Element => {

    return (
        <div className="loader-wrapper">
            <img src={TopPattern} alt="background" className={'bg-img-preloader'}/>
            <div className="loader"></div>
            <div className={'loading'}>Loading...</div>
        </div>
    );
};

export default GlobalLoader;
