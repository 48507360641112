import React from 'react';
import {withStyles, Snackbar, SnackbarProps} from '@material-ui/core';

const CustomSnackbar = withStyles({
    root: {
        marginLeft: '250px'
    }
})(Snackbar);

export default function MuiSnackbar(props: SnackbarProps) {

    return (
        <CustomSnackbar
            {...props}
        />
    );
}
