import React, {CSSProperties, ReactNode} from 'react';
import {Theme} from '@material-ui/core/styles';
import {
    Checkbox,
    CheckboxProps,
    FormControl,
    FormControlLabel,
    makeStyles,
} from '@material-ui/core';
import {Field, FieldProps} from 'formik';
import CustomHelperText from './FormHelperText';
import clsx from 'clsx';
import {Styles} from '@material-ui/styles';

type FormCheckBoxProps = CheckboxProps & {
    name: string;
    label?: ReactNode;
    formControlWrapper?: any
}

const FormCheckBox: React.FC<FormCheckBoxProps> = (props: FormCheckBoxProps): JSX.Element => {
    const classes = useStyles();
    const {name, label, formControlWrapper, ...rest} = props;
    return (
        <Field name={name}>
            {({meta, field, form}: FieldProps) => (
                <>
                    <FormControl
                        required
                        error={!!(meta.error && meta.touched)}
                        component="fieldset"
                        className={clsx(classes.formControl, {[formControlWrapper]: formControlWrapper})}
                    >
                        <FormControlLabel
                            control={<Checkbox {...field} {...rest}/>}
                            label={label}
                        />
                        <CustomHelperText meta={meta} />
                    </FormControl>
                </>
            )}
        </Field>
    );
};

export default FormCheckBox;

const useStyles = makeStyles<Theme>(theme => ({
    formControl: {
        width: '100%',
        height: '3rem'
    }
}));
