import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import SelectLayout from '../../layouts/SelectLayout';
import {LINKS, QUERY_PARAMS} from '../../constants';
import {useHistory, Link} from 'react-router-dom';
import Logo from './images/logo.svg'
import Manufacturer from './images/manufacturer.svg';
import Customer from './images/customer.svg';
import Distributor from './images/distributor.svg';
import GooglePlay from './images/google-play-button.svg';
import AppStore from './images/app-store-button.svg';
import CustomButton from '../../components/ui/Buttons/Button';
import {UserType} from '../../generated/graphql';

const SelectUserTypePage: React.FC = (props): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const redirectSignUp = (type) => {
        const url = new URL(window.location.href);
        const arr = QUERY_PARAMS.filter((item, _) => url.searchParams.has(item));
        let HREF = `/sign-up/${type}/?`;
        if (arr.length > 0) {
            arr.forEach((item, index) => {
                const value = url.searchParams.get(item);
                if (index === arr.length - 1) {
                    HREF += `${item}=${value}`;
                } else {
                    HREF += `${item}=${value}&`;
                }
            });
            history.push(HREF);
        } else {
            history.push(`/sign-up/${type}`);
        }
    };
    const openPlayMarket = () => {
        window.open(LINKS.PLAY_MARKET_APP);
    }
    const openAppStore = () => {
        window.open(LINKS.APP_STORE_APP);
    }
    return (
        <SelectLayout>
            <img src={Logo} alt="LogoWhite" className={classes.logo}/>

            <Box className={classes.accountTypes}>
                <Typography variant="h2" align={'center'} className={classes.title}>
                    Select account type
                </Typography>
                <Box className={classes.item}>
                    <img src={Manufacturer} alt="Manufacturer" className={classes.itemImg}/>
                    <Typography className={classes.itemTitle}>Manufacturer</Typography>
                    <Typography className={classes.itemDescription}>Creates e-certificates of your products and shares
                        them with distributors or clients so that they can make sure that the product is not a fake.
                        Or use the base of your customers to make special offers using our mobile app.
                    </Typography>
                    <CustomButton
                        name={'chooseManufacturer'}
                        variant={'outlined'}
                        color={'primary'}
                        className={classes.button}
                        onClick={() => redirectSignUp(UserType.Manufacturer)}
                    >Choose</CustomButton>
                </Box>
                <Box className={classes.item}>
                    <img src={Distributor} alt="Distributor" className={classes.itemImg}/>
                    <Typography className={classes.itemTitle}>Distributor / Online Store</Typography>
                    <Typography className={classes.itemDescription}>Receive e-certificates from the Manufacturer and
                        share them with
                        your clients so that they can make sure that the product is not a fake.
                        Or use the base of your customers to make special offers using our mobile app.
                    </Typography>
                    <CustomButton
                        name={'chooseDistributor'}
                        variant={'outlined'}
                        color={'primary'}
                        className={classes.button}
                        onClick={() => redirectSignUp(UserType.Distributor)}
                    >Choose</CustomButton>
                </Box>
                <Box className={classes.item}>
                    <img src={Customer} alt="Customer" className={classes.itemImg}/>
                    <Typography className={classes.itemTitle}>Customer</Typography>
                    <Typography className={classes.itemDescription}>Makes sure that the product you buy is not a fake.
                        Or receive special offers, discounts, coupons from brands and shops.
                    </Typography>
                    <Box className={classes.selectLinks}>
                        <Box className={classes.selectMarketLink} onClick={openPlayMarket}><img src={GooglePlay} alt="GooglePlay"/></Box>
                        <Box className={classes.selectMarketLink} onClick={openAppStore}><img src={AppStore} alt="AppStore"/></Box>
                    </Box>
                </Box>
            </Box>
        </SelectLayout>
    );
};

export default SelectUserTypePage;

const useStyles = makeStyles<Theme>(theme => ({
    logo: {
        position: 'absolute',
        left: 26,
        top: 17
    },
    title: {
        gridColumn: '1/4'
    },
    accountTypes: {
        width: 1300,
        margin: 'auto',
        padding: '7% 16px 7% 16px',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridTemplateRows: '130px 1fr',
        gridColumnGap: 40,
    },
    item: {
        border: '1px dashed #276EF9',
        borderRadius: 16,
        display: 'flex',
        flexFlow: 'column',
        textAlign: 'center',
        position: 'relative',
        padding: '24pt 7% 25px 7%'
    },
    itemImg: {
        alignSelf: 'center'
    },
    itemTitle: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 24,
        textAlign: 'center',
        color: '#276EF9',
        marginBottom: 5,
    },
    itemDescription: {
        textAlign: 'start',
        marginBottom: 32,
        fontSize: 16,
        color: '#21264C',
        lineHeight: 1.8
    },
    button: {
        alignSelf: 'center'
    },
    selectMarketLink: {
        '& img': {
            cursor: 'pointer',
            height: 48,
            width: 'auto'
        }
    },
    selectLinks: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        flex: 1,
    }


}))
// .SelectType {
//     position: relative;
//     width: 100vw;
//     height: 100vh;
// .bg-top-img {
//         position: absolute;
//         top: 0;
//         left: 18%;
//         z-index: 1;
//     }
// .bg-bottom-img {
//         position: absolute;
//         right: 10px;
//         bottom: 20px;
//         z-index: 1;
//     }
// .logo {
//         display: flex;
//         padding: 20px 0 0 30px;
//         img {
//             height: 50px;
//             width: 50px;
//         }
//         span {
//             align-self: center;
//             font-weight: 600;
//             font-size: 18px;
//             line-height: 24px;
//             text-align: center;
//             color: rgba(0, 0, 0, 0.87);
//             margin-left: 10px;
//         }
//     }
// .close {
//         position: absolute;
//         right: 32px;top: 32px;
//         z-index: 99999;
//         color: black;
//     }
// .Account-types {
//         padding: 40px 10%;
//         display: grid;
//         grid-template-columns: repeat(3,1fr);
//         grid-column-gap: 40px;
//     &>* {
//             border: 2px dashed #276EF9;
//             border-radius: 16px;
//             display: flex;
//             flex-flow: column;
//             text-align: center;
//             position: relative;
//             padding: 0px 7% 25px 7%;
//         }
//         img {
//             align-self: center;
//
//         }
//     .item-title {
//             font-style: normal;
//             font-weight: normal;
//             font-size: 24px;
//             line-height: 48px;
//             text-align: center;
//             color: #276EF9;
//             margin-bottom: 5px;
//         }
//     .item-description {
//             text-align: start;
//             margin-bottom: 32px;
//             font-size: 16px;
//             line-height: 32px;
//         }
//     .apple-store,
//     .choose-btn,
//     .google-play {
//             border: 2px solid #9ECFFF;
//             background: none;
//             outline: none;
//             cursor: pointer;
//             color: #276EF9;
//         }
//     .choose-btn {
//             align-self: center;
//
//             border-radius: 12px;
//             background: none;
//             color: #276EF9;
//             padding: 16px 32px;
//             font-weight: 600;
//             font-size: 16px;
//             line-height: 24px;
//
//
//
//         }
//     .select-market {
//             //display: flex;
//             justify-content: center;
//             img {
//                 margin: 0px 25px;
//                 cursor: pointer;
//                 width: 200px;
//                 height: auto;
//             }
//         }
//     .select-market-link-inactive {
//             opacity: 0.5;
//         }
//
//     @media screen and (max-width: 900px) {
//         .select-market-link {
//                 display: flex;
//             }
//         }
//
//     }
// }


