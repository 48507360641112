import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import {IconButton, Typography} from '@material-ui/core';
import {Form, Formik} from 'formik';
import {CONTACT_US_SCHEMA} from '../../../constants/validation_schema';
import FormInput from '../../../components/ui/FormInput';
import CustomButton from '../../../components/ui/Buttons/Button';
import ContactUs from '../images/contact-us-for-plan.svg'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';

export default function ContactUsSection({showForm, setShowForm}) {
    const classes = useStyles();
    return (
        <>
            <div className={showForm ? classes.Opacity : classes.OpacityDisabled} onClick={() => setShowForm(false)}></div>
            <div className={[classes.ContactUsForm, showForm ? classes.ShowForm : classes.HideForm].join(' ')}>
                <IconButton
                    size={'medium'}
                    className={classes.CloseImg}
                    onClick={() => setShowForm(false)}
                >
                    <CloseRoundedIcon />
                </IconButton>
                <Typography variant={'h3'}>Contact us</Typography>
                <Typography variant={'h5'} className={classes.Text}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
                    purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor
                    rhoncus dolor purus non enim
                </Typography>
                <Formik
                    initialValues={{
                        fullName: '',
                        company: '',
                        position: '',
                        email: ''
                    }}
                    validationSchema={CONTACT_US_SCHEMA}
                    onSubmit={async (values, resetForm) => {
                        // resetForm();
                    }}
                >
                    {({handleSubmit, isValid, isSubmitting}) => (
                        <Form onSubmit={handleSubmit} className={classes.Form}>
                            <FormInput
                                name={'fullName'}
                                label={'Full Name'}
                                placeholder={'Full Name'}
                                labelWidth={75}
                                formControlProps={{
                                    className: classes.TextField
                                }}
                            />
                            <FormInput
                                name={'company'}
                                label={'Company'}
                                labelWidth={60}
                                placeholder={'Company'}
                                formControlProps={{
                                    className: classes.TextField
                                }}
                            />
                            <FormInput
                                name={'position'}
                                label={'Position'}
                                labelWidth={60}
                                placeholder={'Position'}
                                formControlProps={{
                                    className: classes.TextField
                                }}
                            />
                            <FormInput
                                name={'email'}
                                label={'Email'}
                                labelWidth={60}
                                placeholder={'Email'}
                                formControlProps={{
                                    className: classes.TextField
                                }}
                            />
                            <CustomButton
                                variant={'contained'}
                                color={'primary'}
                                type={'submit'}
                                className={classes.TextField}
                                disabled={!isValid || isSubmitting}
                            >Send Message</CustomButton>
                        </Form>
                    )}
                </Formik>
                <img src={ContactUs} alt={'contact-us-img'} className={classes.BottomImg}/>
            </div>
        </>

    );
}
const useStyles = makeStyles<Theme>(theme => (
    {
        ContactUsForm: {
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            right: 0,
            top: 0,
            width: '36vw',
            height: '100vh',
            background: 'white',
            boxShadow: '0px 18px 67px rgba(8, 42, 92, 0.29)',
            zIndex: 9,
            padding: '4% 4% 0 4%'

        },
        Opacity: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '64vw',
            height: '100vh',
            opacity: 0.0,
            zIndex: 99
        },
        OpacityDisabled: {
            position: 'absolute',
            left: 0,
            top: 0,
            width: '64vw',
            height: '100vh',
            opacity: 0.0,
            zIndex: -1
        },
        ShowForm: {
            transition: '.2s ease-out',
            transform: 'translateX(0)'
        },
        HideForm: {
            transition: '.2s ease-out',
            transform: 'translateX(100%)'
        },
        CloseImg: {
            position: 'absolute',
            right: 32,
            top: 32,
            zIndex: 9,
            color: 'black'
        },
        Text: {
            marginTop: 32
        },
        BottomImg: {
            position: 'relative',
            bottom: 0,
            left: 0
        },
        Form: {
            flex: '1 1 100%',
            display: 'flex',
            flexDirection: 'column',
        },
        TextField: {
            marginTop: theme.spacing(3)
        },
        HelperText: {
            textTransform: 'none'

        }
    }
));
