import React, {useMemo} from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/core';
import Pagination, {PaginationProps} from '@material-ui/lab/Pagination';
import {PaginationItem, PaginationRenderItemParams} from '@material-ui/lab';
import {COLORS} from '../../constants/colors';

const CustomPaginationItem = (item: PaginationRenderItemParams) => {
    const classes = useStyles();
    return (
        <PaginationItem
            classes={{
                root: classes.root,
                selected: classes.selected
            }}
            {...item}
        >
        </PaginationItem>
    );
};

const CustomPagination: React.FC<PaginationProps> = (props: PaginationProps): JSX.Element => {
    const {...rest} = props;
    const classes = useStyles();
    return (
        <Pagination
            shape={'rounded'}
            renderItem={(item) => <CustomPaginationItem {...item} />}
            classes={{
                root: classes.rootPagination,
                ul: classes.list
            }}
            {...rest}
        />
    );
};

export default CustomPagination;

const useStyles = makeStyles<Theme>(theme => ({
    root: {
        height: 28,
        minWidth: 28,
        display: 'grid',
        placeItems: 'center',
        padding: '0 4px',
        color: COLORS.BLACK,
        margin: '0 2px',
        '&:hover': {
            background: COLORS.PRIMARY_BACKGROUND,
        }
    },
    selected: {
        background: `${COLORS.PRIMARY_BACKGROUND} !important`,
    },
    list: {
        flexWrap: 'nowrap'
    },
    rootPagination: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        width: '100%',
        flexGrow: 1,
        '&>*': {
            alignSelf: 'center'
        },
        marginLeft: 'auto'
    }
}));
