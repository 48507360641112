import React, {FC, useState} from 'react';
import './styles/support-page.scss';
import {useHistory} from 'react-router-dom';
import {IconButton, Paper} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
// import ContactWithUsSection from './component/ContactWithUsSection';
import {SUPPORT_LINKS} from './helpers/constants';
import ContactWithUsSection from './components/ContactWithUsSection';
import {COLORS} from '../../constants/colors';
import {useGlobalContext} from '../../context/globalContext';
import {UserType} from '../../generated/graphql';

const SupportPage:FC = (): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const {userData} = useGlobalContext();
    const [search, setSearch] = useState('');
    return (
        <div className={'Support'}>
            {/*<div className={'Support__search'}>*/}
            {/*    <img src={require('../../img/SupportBackground.svg')} className={'SupportBackground'} alt="SupportBackground"/>*/}
            {/*    <h1>Can we help you?</h1>*/}
            {/*    <div className={'SearchInput'}>*/}
            {/*        <input*/}
            {/*            value={search}*/}
            {/*            onChange={e => setSearch(e.target.value)}*/}
            {/*            placeholder={'Search'}*/}
            {/*        />*/}
            {/*        <Button*/}
            {/*            variant={"primary"}*/}
            {/*            onClick={() => alert('Coming soon...')}*/}
            {/*        ><i className={`material-icons md-48`}>search</i></Button>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="topQuestions">*/}
            {/*    <h1>Top questions</h1>*/}
            {/*    <div className={'paper__wrapper'}>*/}
            {/*        {*/}
            {/*            topQuestions.map((item,index) => (*/}
            {/*                <Paper*/}
            {/*                    elevation={3}*/}
            {/*                    key={index}*/}
            {/*                    classes={{root: classes.topQuestionsPaper}}*/}
            {/*                >*/}
            {/*                    {item}*/}
            {/*                </Paper>*/}
            {/*            ))*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="knowledge-base">*/}
            {/*    <h1 style={{marginBottom: 10}}>Knowledge Base</h1>*/}
            {/*    <div className="knowledge-base__wrapper">*/}
            {/*        {knowledgeBase.map((item,index) => (*/}
            {/*                <Paper*/}
            {/*                    elevation={3}*/}
            {/*                    key={index}*/}
            {/*                    classes={{root: classes.knowledgeBasePaper}}*/}
            {/*                >*/}
            {/*                    <Typography*/}
            {/*                        variant="h5"*/}
            {/*                        classes={{root: classes.titleTypography}}*/}
            {/*                    >{item}</Typography>*/}
            {/*                    {[1, 2, 3, 4].map((item) => (*/}
            {/*                        <Typography*/}
            {/*                            variant="body1"*/}
            {/*                            classes={{root: classes.questionTypography}}*/}
            {/*                        >How to download the product?</Typography>*/}
            {/*                        ))*/}
            {/*                    }*/}
            {/*                    <Button*/}
            {/*                        style={{marginTop: 10}}*/}
            {/*                        onClick={() => history.push(`/support/${item.toLowerCase()}`)}*/}
            {/*                    >*/}
            {/*                       + Expand all articles (30)*/}
            {/*                    </Button>*/}
            {/*                </Paper>*/}
            {/*            ))*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className="Links">
                <h1>Links</h1>
                <div className="Links__wrapper">
                    {
                        SUPPORT_LINKS.map((item, index) => (
                            <Paper
                                elevation={3}
                                key={index}
                                classes={{root: classes.LinkPaper}}
                                onClick={() => history.push('/support/question', {title: item?.title})}
                            >
                                {item.title}
                                <IconButton classes={{root: classes.RoundBtn}}>
                                    <ArrowForwardIosIcon classes={{root: classes.icon}}/>
                                </IconButton>
                            </Paper>
                        ))
                    }
                    {userData?.type !== UserType.Store &&
                    <Paper
                        elevation={3}
                        classes={{root: classes.LinkPaper}}
                        onClick={() => history.push('/support/question', {title: 'Update invoice info'})}
                    >
                        Update invoice info
                        <IconButton classes={{root: classes.RoundBtn}}>
                            <ArrowForwardIosIcon classes={{root: classes.icon}}/>
                        </IconButton>
                    </Paper>
                    }
                    {userData?.type !== UserType.Store &&
                    <Paper
                        elevation={3}
                        classes={{root: classes.LinkPaper}}
                        onClick={() => history.push('/support/question', {title: 'Recharge the balance'})}
                    >
                        Recharge the balance
                        <IconButton classes={{root: classes.RoundBtn}}>
                            <ArrowForwardIosIcon classes={{root: classes.icon}}/>
                        </IconButton>
                    </Paper>
                    }
                </div>
            </div>
            <div className="cantFind">
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                <h1>Can't find what you are looking for?</h1>
                <div className="cantFind__wrapper">
                    {/*<Paper*/}
                    {/*    elevation={3}*/}
                    {/*    classes={{root: classes.LinkPaper}}*/}
                    {/*>*/}
                    {/*    <List className={classes.root}>*/}
                    {/*        <ListItem>*/}
                    {/*            <ListItemAvatar>*/}
                    {/*                <ChatBubbleOutlineIcon />*/}
                    {/*            </ListItemAvatar>*/}
                    {/*            <ListItemText primary="Chat with us" secondary="Status: Not available" />*/}
                    {/*        </ListItem>*/}
                    {/*        <ListItemSecondaryAction>*/}
                    {/*            <Button variant={'outlined'} disabled>*/}
                    {/*                Choose*/}
                    {/*            </Button>*/}
                    {/*        </ListItemSecondaryAction>*/}
                    {/*    </List>*/}
                    {/*</Paper>*/}
                    <ContactWithUsSection
                        icon={<MailOutlineIcon />}
                        title={'Email us'}
                        subTitle={'We\'ll get back to you within 24 hrs.'}
                        push={'/support/send-request'}
                    />
                </div>
            </div>
        </div>
    );
}
export default SupportPage

const useStyles = makeStyles({
    topQuestionsPaper: {
        padding: '24px 32px'
    },
    knowledgeBasePaper: {
        padding: '24px 32px'
    },
    LinkPaper: {
        padding: '24px 32px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            transition: 'all 0.5s 0s ease',
            backgroundColor: COLORS.ROW_BACKGROUND
        }
    },

    titleTypography: {
        marginBottom: 8
    },
    questionTypography: {
        marginBottom: 7
    },
    RoundBtn: {
        borderRadius: '50%',
        width: 30,
        height: 30,
        padding: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#276EF9',
        '&:hover': {
            backgroundColor: '#276EF9'
        }
    },
    icon: {
        color: 'white',
        width: 12,
        height: 12

    },
    captionTypography: {
        color: 'rgba(0, 0, 0, 0.6)'
    },
    root: {
        width: '100%'
    }

});
