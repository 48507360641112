import React, {useState, useEffect} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import DialogLayout, {DialogLayoutProps} from '../../../layouts/DialogLayout';
import {COLORS} from '../../../constants/colors';
import CustomButton from '../Buttons/Button';
import FormInput from '../FormInput';
import {Formik, Form, FormikProps} from 'formik';
import {RECIPIENT_EMAIL_SCHEMA} from '../../../constants/validation_schema';
import {useUIContext} from "../../../context/UIContext";
import {MESSAGES} from "../../../constants/messages";

type DialogSendToEmailProps = DialogLayoutProps & {
    title?: string,
    action: () => void
    name?: any;
}
const DialogSendToEmail: React.FC<DialogSendToEmailProps> = (props: DialogSendToEmailProps): JSX.Element => {
    const classes = useStyles();
    const {title, action, name, ...rest} = props;
    const {showSnackBar} = useUIContext();

    return (
        <DialogLayout
            width={552}
            {...rest}
            onClick={(event) => event.stopPropagation()}
        >
            <Box className={classes.wrapper}>
                <Typography variant={'h4'} style={{lineHeight: '48px'}}>{title}</Typography>
                <Typography
                    style={{color: 'rgba(0, 0, 0, 0.87)'}}
                    variant={'subtitle1'}
                    className={classes.subtitle}
                >Write the address to which the Invoice will be sent Invoice <span>#{name}</span>.
                </Typography>
                <Formik
                    initialValues={{
                        email: ''
                    }}
                    validationSchema={RECIPIENT_EMAIL_SCHEMA}
                    onSubmit={(values, actions) => {
                        console.log({values, actions});
                    }}
                >
                    {(propsFormik: FormikProps<any>) => (
                        <Form>
                            <FormInput
                                name={'email'}
                                label={'Email address'}
                                labelWidth={108}
                                formControlProps={{
                                    className: classes.email
                                }}
                            />
                            <Box className={classes.wrapperButton}>
                                <CustomButton
                                    className={classes.cancel}
                                    onClick={(event) => props.onClose(event, 'escapeKeyDown')}>Cancel</CustomButton>
                                <CustomButton
                                    disabled={(!propsFormik.isValid || (Object.keys(propsFormik.touched).length === 0 && propsFormik.touched.constructor === Object))}
                                    onClick={(event) => {
                                        showSnackBar(MESSAGES.INVOICE_SEND_TO_EMAIL);
                                        action()
                                        props.onClose(event, 'escapeKeyDown')
                                    }}>Send Invoice</CustomButton>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </DialogLayout>
    );
};

export default DialogSendToEmail;

const useStyles = makeStyles<Theme>(() => ({
    wrapper: {
        padding: '40px 40px 32px 56px'
    },
    subtitle: {
        color: COLORS.TEXT_SILVER,
        margin: '8px 0 24px 0',
        '& > span': {
            whiteSpace: 'nowrap',
            color: '#000000',
            fontWeight: 500
        }
    },
    wrapperButton: {
        marginTop: 6,
        display: 'flex',
        justifyContent: 'end',
        '& > button + button': {
            marginLeft: 24
        },
        '& > button:last-of-type': {
            maxHeight: 40,
            marginRight: -16,
            padding: '8px 16px'
        }
    },
    cancel: {
        maxHeight: 40,
        padding: '8px 16px',
        marginLeft: 'auto'
    },
    email: {
        width: '100%',
        minHeight: 73
    }
}));
