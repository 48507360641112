import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, TableRow, TableRowProps, withStyles} from '@material-ui/core';
import {COLORS} from '../../../constants/colors';

const CustomTableRow: React.FC<TableRowProps> = (props: TableRowProps): JSX.Element => {
    return (
        <MuiTableRow {...props}/>
    );
};

const MuiTableRow = withStyles(() => ({
    root: {
    },
    hover: {
        cursor: 'pointer',
        '&:hover': {
            background: `${COLORS.ROW_BACKGROUND} !important`
        }
    },
    selected: {
        background: `${COLORS.ROW_BACKGROUND} !important`
    }
}))(TableRow)

export default CustomTableRow;

