import React, {useState, useMemo, useCallback, useEffect} from 'react';
import {Theme} from '@material-ui/core/styles';
import {IconButton, makeStyles, Tooltip, Typography} from '@material-ui/core';
import CustomPaper from '../../components/ui/Paper';
import TwoSectionLayout from '../../layouts/TwoSectionLayout';
import {
    useDeleteBrandMutation,
    useGetBrandsQuery
} from '../../generated/graphql';
import {useGlobalContext} from '../../context/globalContext';
import FailedToLoad from '../../components/FailedToLoad';
import MainTable from '../../components/ui/Tables/MainTable';
import {BRAND_HEAD} from '../../constants/tableColumns';
import CustomAvatar from '../../components/ui/Avatar';
import MuiTableCell from '../../components/ui/Tables/TableCell';
import DefaultAvatar from '../../assets/icons/default-avatar.svg';
import {changeFormatDate, dateWithHours} from './helpers';
import StatusChip from '../../components/ui/Chips/StatusChip';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteDialog from '../../components/ui/Dialogs/DialogDelete';
import {useUIContext} from '../../context/UIContext';
import TableSkeleton from '../../components/ui/Tables/TableSkeleton';
import EmptyPage, {EmptyPageType} from '../../components/EmptyPage';
import BrandDetails from './components/BrandDetails';
import CustomTableRow from '../../components/ui/Tables/TableRow';
import clsx from 'clsx';
import {COLORS} from '../../constants/colors';
import {MESSAGES} from '../../constants/messages';
import TooltipCell from '../../components/ui/Tables/TooltipCell';

const BrandsPage: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const {userData, rowsPerPage} = useGlobalContext();
    const {showSnackBar} = useUIContext();
    const [skip, setSkip] = useState<number>(0);
    const [deleteId, setDeleteId] = useState<null | number>(null);
    const {data: queryData, loading, error, refetch, fetchMore, previousData} = useGetBrandsQuery({
        variables: {
            ownerId: userData?.id,
            take: +rowsPerPage,
            skip
        }
    })
    const data = useMemo(() => {
        return {
            brands: queryData?.brands?.data,
            total: queryData?.brands?.total
        }
    }, [queryData]);
    const [selectedItem, setSelectedItem] = useState<number | null>(null);
    useEffect(() => {
        if (data?.brands && data?.brands.length > 0 && !selectedItem) {
            setSelectedItem(data?.brands[0]?.id)
        }
    }, [data]);
    const deleteBrandHandler = async () => {
        try {
            const {data} = await deleteBrand({
                variables: {
                    id: deleteId
                }
            })
            if (data?.brandDelete) {
                showSnackBar(MESSAGES.BRAND_DELETE)
            }
        } catch (e) {
            showSnackBar(e)
        } finally {
            setDeleteId(null);
            await refetch()
        }
    }
    const [deleteBrand] = useDeleteBrandMutation();
    const showDeleteDialog = useCallback((event, id) => {
        event.stopPropagation();
        setDeleteId(id);
    }, [deleteId]);
    const selectBrand = useCallback((id: number) => {
        setSelectedItem(id);
    }, [selectedItem]);
    if (error) {
        return <FailedToLoad refetch={refetch}/>
    }
    if (data?.brands?.length === 0 && !loading) {
        return <EmptyPage type={EmptyPageType.Brands} />
    }
    return (
        <TwoSectionLayout
            first={2}
            second={1}
        >
            <CustomPaper className={classes.container}>
                <MainTable
                    fetchMore={fetchMore}
                    skip={skip}
                    setSkip={setSkip}
                    className={classes.table}
                    totalCount={data?.total}
                    tableHeadProps={{
                        headCells: BRAND_HEAD
                    }}
                    tablePaginationProps={{
                        labelDisplayedRows: ({ from, to, count, page }) => {
                            console.log('from, to, count, page', from, to, count, page);
                            return `${skip}-${(data?.brands?.length || 0) + skip} of ${data?.total || 0}`;
                        }
                    }}
                >
                    {loading && !previousData ? <TableSkeleton cell={5}/>
                        : data?.brands?.map(item => (
                            <CustomTableRow
                                key={item?.id}
                                hover
                                className={clsx(
                                    {[classes.tableRow]: item?.id === selectedItem}
                                )}
                                onClick={() => selectBrand(item?.id)}
                            >
                                <MuiTableCell width={'auto'}>
                                    <CustomAvatar
                                        src={item?.logoPath ? `${process.env.REACT_APP_BASE_URL}${item.logoPath}` : DefaultAvatar}
                                    />
                                </MuiTableCell>
                                <TooltipCell
                                    label={item?.name}
                                    value={item?.name}
                                />
                                <TooltipCell
                                    align={'right'}
                                    label={dateWithHours(item?.createdAt)}
                                    value={changeFormatDate(item?.createdAt)}
                                />
                                <MuiTableCell align={'left'} width={'auto'}>
                                    <StatusChip status={item?.status}/>
                                </MuiTableCell>
                                <MuiTableCell align={'center'} width={'auto'}>
                                    <IconButton
                                        onClick={(event) => showDeleteDialog(event, item?.id)}
                                    >
                                        <DeleteOutlineIcon fontSize={'small'}/>
                                    </IconButton>
                                </MuiTableCell>
                            </CustomTableRow>
                        ))
                    }
                </MainTable>
            </CustomPaper>
            <BrandDetails selectedItem={selectedItem} />
            {deleteId &&
                <DeleteDialog
                    open={Boolean(deleteId)}
                    title={'Delete?'}
                    subtitle={'This record will be permanently deleted. You can’t undo this action'}
                    onClose={() => setDeleteId(null)}
                    action={deleteBrandHandler}
                />
            }
        </TwoSectionLayout>
    );
};

export default BrandsPage;

const useStyles = makeStyles<Theme>(() => ({
    BrandsPage: {
        background: 'red',
        width: '100%',
        height: '100%',
        overflowY: 'scroll'
    },
    container: {
        height: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'column',
        position: 'relative'
    },
    table: {
        height: '100%',
        maxHeight: '53px',
        width: '100%',
        paddingBottom: 52,
        borderRadius: 8
    },
    tableRow: {
        background: COLORS.ROW_BACKGROUND
    }
}));
