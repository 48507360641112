import React, {useCallback, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Paper, Typography} from '@material-ui/core';
import {COLORS} from '../../../constants/colors';
import SessionItem from './SessionItem';
import CustomButton from '../../../components/ui/Buttons/Button';
import {useGlobalContext} from '../../../context/globalContext';
import {AccountSession, useLogOutMutation} from '../../../generated/graphql';
import DialogNegativeAction from '../../../components/ui/Dialogs/DialogNegativeAction';
import {useUIContext} from '../../../context/UIContext';
import {MESSAGES} from '../../../constants/messages';

const SessionList: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const {userData: {currentSession, sessions}, setUserData} = useGlobalContext();
    const {showSnackBar} = useUIContext();
    const [open, setOpen] = useState(false);
    const handleOpenDialog = () => setOpen(true)
    const handleCloseDialog = () => setOpen(false)
    console.log('currentSession, sessions', currentSession, sessions);
    const [deleteSession] = useLogOutMutation();
    const determinateSingleSession = async (id: number) => {
        try {
            const {data} = await deleteSession({
                variables: {
                    sessionIds: [id]
                }
            })
            if (data?.logout) {
                setUserData(prevState => ({...prevState, sessions: prevState.sessions.filter(item => item?.id !== id)}))
                showSnackBar(MESSAGES.SUCCESS_TERMINATED_SESSION)
            }
        } catch (e) {
            showSnackBar(e?.message)
        }
    }
    const determinateMultiSessions = async () => {
        try {
            const {data} = await deleteSession({
                variables: {
                    sessionIds: sessions?.map(item => item?.id)
                }
            })
            if (data?.logout) {
                setUserData(prevState => ({...prevState, sessions: []}))
                showSnackBar(MESSAGES.SUCCESS_TERMINATED_SESSIONS)
            }
        } catch (e) {
            showSnackBar(e?.message)
        } finally {
            setOpen(false)
        }
    }
    return (
        <>
            <Paper
                elevation={1}
                classes={{root: classes.paper}}
            >
                <Typography variant={'subtitle1'} className={classes.title}>CURRENT SESSION</Typography>
                <SessionItem
                    currentSection
                    session={currentSession as AccountSession}
                />
                {sessions?.length > 0 &&
                    <>
                        <CustomButton
                            color={'secondary'}
                            onClick={handleOpenDialog}
                        >Terminate all other sessions</CustomButton>
                        <Box className={classes.currentSession}>
                            {sessions?.length > 0 && sessions?.map((item, _) => (
                                <SessionItem
                                    key={_}
                                    session={item as AccountSession}
                                    deleteSession={determinateSingleSession}
                                />
                            ))}
                        </Box>
                    </>
                }
            </Paper>
            {open &&
            <DialogNegativeAction
                open={open}
                onClose={handleCloseDialog}
                mainAction={determinateMultiSessions}
                title={'Terminate all'}
                subtitle={'Are you sure you want to terminate all other sessions?'}
                buttonText={'Terminate'}
            />
            }
        </>
    );
};

export default SessionList;

const useStyles = makeStyles<Theme>(() => ({
    paper: {
        padding: '24px 24px 32px 24px',
        width: 650,
        maxWidth: 800,
        marginTop: 27,
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)'
    },
    currentSession: {
        paddingTop: 16,
        marginTop: 16,
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    title: {
        textTransform: 'uppercase',
        color: COLORS.TEXT_SILVER
    }
}));
