import React, {useMemo, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, InputAdornment, makeStyles, TextField, Typography} from '@material-ui/core';
import DialogLayout, {DialogLayoutProps} from '../../../layouts/DialogLayout';
import MuiCircularProgress from '../CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import LoadingLayout from '../../../layouts/LoadingLayout';
import clsx from 'clsx';
import {NavLink} from 'react-router-dom';
import {useDebounce} from 'use-debounce';
import {ProductAttributeTemplateType, useGetProductAttributeTemplatesQuery} from '../../../generated/graphql';
import _ from 'lodash';
import {useFormikContext} from 'formik';
import {dialogSelectOptionalStyles} from './DialogSelectOptionalField';

type DialogSelectOption = DialogLayoutProps & {
    name: string
}

const DialogSelectOption: React.FC<DialogSelectOption> = (props: DialogSelectOption): JSX.Element => {
    const {name, ...rest} = props
    const classes = dialogSelectOptionalStyles();
    const [value, setValue] = useState('');
    const [debounceValue] = useDebounce(value, 500)
    const {data: dataQuery, loading, error, previousData} = useGetProductAttributeTemplatesQuery({
        variables: {
            searchQuery: debounceValue
        }
    })
    const data = useMemo(() => {
        if (dataQuery?.productAttributeTemplates?.length > 0) {
            return _.groupBy(dataQuery?.productAttributeTemplates, item => {
                return item?.title[0].toUpperCase();
            })
        } else {
            return []
        }
    }, [dataQuery]);
    const {setFieldValue, touched, setTouched, values} = useFormikContext();
    const handlePushOption = (item): void => {
        console.log('item', item);
    }
    return (
        <DialogLayout
            {...rest}
        >
            <Box className={classes.wrapper}>
                <Typography variant={'h4'}>Fields</Typography>
                <TextField
                    fullWidth
                    label={'Search'}
                    placeholder={'Search'}
                    variant={'outlined'}
                    className={classes.input}
                    value={value}
                    onChange={event => setValue(event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {loading && previousData ? <MuiCircularProgress/> : <SearchIcon/>}
                            </InputAdornment>
                        )
                    }}
                />
                {loading && previousData ? <LoadingLayout size={40}/> :
                    data && Object.keys(data)?.length > 0 ?
                        <Box className={classes.gridWrapper}>
                            {
                                Object.keys(data)?.map((key, _) => (
                                    <Box className={classes.list} key={_}>
                                        <Typography className={classes.listKey}>{key}</Typography>
                                        <ul>
                                            {
                                                data[key]?.map((item, index) => {
                                                    return (
                                                        <li
                                                            key={item?.id}
                                                            className={clsx(classes.listItem)}
                                                            onClick={() => handlePushOption(item)}
                                                        >
                                                            {item?.title}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </Box>
                                ))
                            }
                        </Box>
                        :
                        <Box className={classes.notFoundWrapper}>
                            <Typography variant={'h4'} className={classes.notFoundTitle}>Nothing not found</Typography>
                            <Typography variant={'subtitle1'} className={classes.notFoundInfo}>
                                Check the spelling of the request if there are
                                any typos, such as &quot;color&quot; instead of &quot;color&quot;
                            </Typography>
                            <Typography variant={'subtitle1'} className={classes.notFoundInfo}>
                                Try using a more general query like &quot;Material&quot;
                                instead of &quot;Fabric&quot;
                            </Typography>
                            <Typography variant={'subtitle1'} className={classes.notFoundInfo}>
                                If your problem has not been resolved, please{' '}
                                <NavLink to={'/contact/support'}>
                                    contact support
                                </NavLink>
                            </Typography>
                        </Box>
                }
            </Box>
        </DialogLayout>
    );
};

export default DialogSelectOption;

