import React, {useCallback, useState} from 'react';
import {Box, Checkbox, IconButton, makeStyles, Table, TableBody, TableContainer, Typography} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import clsx from "clsx";
import CustomTableHead from "../../../components/ui/Tables/TableHead";
import MuiTableCell from "../../../components/ui/Tables/TableCell";
import CustomPaper from "../../../components/ui/Paper";
import {INVOICES_PENDING_HEAD} from "../../../constants/tableColumns";
import {useSelectRow} from "../../../helpers/hooks";
import TableSkeleton from "../../../components/ui/Tables/TableSkeleton";
import {isSelected} from "../../../helpers";
import CustomTableRow from "../../../components/ui/Tables/TableRow";
import TooltipCell from "../../../components/ui/Tables/TooltipCell";
import {DeleteOutline, LocationOnOutlined} from "@material-ui/icons";
import CustomButton from "../../../components/ui/Buttons/Button";
import CustomTableRowInvoices from "./CustomTableRowInvoices";
import CancelInvoice from '../../../assets/icons/cancelInvoice.svg';
import ConfirmInvoice from '../../../assets/icons/confirmInvoice.svg';
import PdfInvoice from '../../../assets/icons/pdfInvoice.svg';
import QrInvoice from '../../../assets/icons/qrInvoice.svg';
import CancelInvoiceDark from '../../../assets/icons/cancelInvoiceDark.svg';
import {ReactSVG} from 'react-svg'
import {INVOICE_PENDING} from "../helpers/enum";
import DialogCancel from '../../../components/ui/Dialogs/DialogCancel';
import DialogConfirm from '../../../components/ui/Dialogs/DialogConfirm';
import QRInvoicePreview from "./QRInvoicePreview";
import InvoicePreview from "./InvoicePreview";


const PendingTable: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const [selected, handleClick, selectAllClick, setSelected] = useSelectRow([]);
    // const includesSelected = selected.some(r => data?.map(item => item.id).includes(r?.id))
    const [showBtnRowId, setShowBtnRowId] = useState(null);
    const [cancelId, setCancelId] = useState<number | null>(null);
    const [confirmId, setConfirmId] = useState<number | null>(null);
    const [qrPreviewId, setQrPreviewId] = useState<number | null>(null);
    const [invoicePreviewId, setInvoicePreviewId] = useState(null);

    const loading = false;


    const showQrPreview = useCallback((event, id) => {
        event.stopPropagation();
        setQrPreviewId(id);
    }, [qrPreviewId]);

    const closeQrPreview = (value) => {
        setQrPreviewId(value);
    }

    const closeInvoicePreview = (value) => {
        setInvoicePreviewId(value);
    }

    const showInvoicePreview = useCallback((event, id) => {
        event.stopPropagation();
        setInvoicePreviewId(id);
    }, [invoicePreviewId]);


    const showCancelDialog = useCallback((event, id) => {
        event.stopPropagation();
        setCancelId(id);
    }, [cancelId]);


    const showConfirmDialog = useCallback((event, id) => {
        event.stopPropagation();
        setConfirmId(id);
    }, [confirmId]);


    return (
        <div className={classes.pendingTableWrapper}>
            <div className={classes.tableHeader}>
                <Typography variant={'h5'} className={classes.tableTitle}>Pending</Typography>
            </div>
            <div className={classes.pendingTable}>
                <CustomPaper className={
                    clsx(
                        classes.paperContainer
                    )
                }>
                    <TableContainer className={clsx(
                        classes.tableContainer
                    )}>
                        <Table
                            className={classes.table}
                            aria-labelledby="tableTitle"
                            stickyHeader
                        >
                            <CustomTableHead
                                className={classes.tableHead}
                                isInvoicesTable
                                headCells={INVOICES_PENDING_HEAD}
                            >
                                {/*<MuiTableCell width={'auto'} padding="checkbox">*/}
                                {/*    <Checkbox*/}
                                {/*        color={'primary'}*/}
                                {/*        onChange={(event) => selectAllClick(event, data)}*/}
                                {/*        indeterminate={includesSelected}*/}
                                {/*        checked={data.length === selected.length}*/}
                                {/*    />*/}
                                {/*</MuiTableCell>*/}
                            </CustomTableHead>
                            <TableBody classes={{root: classes.tableBody}}>
                                {loading ? <TableSkeleton cell={6} rows={5}/>
                                    : data.map((item, index) => {
                                        const isItemSelected = isSelected(item.id, selected);
                                        const labelId = `enhanced-table-checkbox-${index}`;
                                        // @ts-ignore
                                        return (
                                            <CustomTableRowInvoices
                                                hover
                                                typeInvoice={item?.type}
                                                key={item?.id}
                                                // @ts-ignore
                                                // onClick={(event) => handleClick(event, item)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                selected={isItemSelected}
                                                // @ts-ignore
                                                onMouseEnter={() => setShowBtnRowId(item.id)}
                                                // @ts-ignore
                                                onMouseLeave={() => setShowBtnRowId(null)}
                                                onClick={event => showInvoicePreview(event, item?.id)}
                                            >
                                                {/*<MuiTableCell padding="checkbox">*/}
                                                {/*    <Checkbox*/}
                                                {/*        checked={isItemSelected}*/}
                                                {/*        inputProps={{'aria-labelledby': labelId}}*/}
                                                {/*        color={'primary'}*/}
                                                {/*    />*/}
                                                {/*</MuiTableCell>*/}
                                                <TooltipCell
                                                    label={item?.name}
                                                    value={item?.name}
                                                    type={item?.type}
                                                    icon
                                                />
                                                <TooltipCell
                                                    label={item?.clientAddress === '' ? 'Waiting address...' : item?.clientAddress}
                                                    value={item?.clientAddress === '' ? 'Waiting address...' : item?.clientAddress}
                                                    type={item?.type}
                                                />
                                                <TooltipCell
                                                    label={item?.amount}
                                                    value={item?.amount}
                                                    align={'right'}
                                                    type={item?.type}
                                                />
                                                <TooltipCell
                                                    label={item?.productsPcs}
                                                    value={item?.productsPcs}
                                                    align={'right'}
                                                    type={item?.type}
                                                />
                                                <TooltipCell
                                                    label={item?.issueDate}
                                                    value={item?.issueDate}
                                                    type={item?.type}
                                                />
                                                <MuiTableCell
                                                    style={{display: showBtnRowId === item?.id ? 'table-cell' : 'none'}}
                                                    className={classes.btnContainer}
                                                >
                                                    <IconButton
                                                        disableFocusRipple
                                                        disableRipple
                                                        disableTouchRipple
                                                        className={classes.btnHover}
                                                        onClick={event => showQrPreview(event, item?.id)}
                                                    >
                                                        <ReactSVG src={QrInvoice}/>
                                                    </IconButton>
                                                    <a
                                                        href={`${process.env.REACT_APP_BASE_URL}${'/uploads/1643020525584_7loe5N.pdf'}`}
                                                        target="_blank"
                                                        download
                                                        rel="noreferrer"
                                                    >
                                                        <IconButton
                                                            disableFocusRipple
                                                            disableRipple
                                                            disableTouchRipple
                                                            className={classes.btnHover}
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                            }}
                                                        >
                                                            <ReactSVG src={PdfInvoice}/>
                                                        </IconButton>
                                                    </a>
                                                    <IconButton
                                                        disableFocusRipple
                                                        disableRipple
                                                        disableTouchRipple
                                                        className={classes.btnHover}
                                                        onClick={event => showConfirmDialog(event, item?.id)}
                                                    >
                                                        <ReactSVG src={ConfirmInvoice}/>
                                                    </IconButton>
                                                    <IconButton
                                                        disableFocusRipple
                                                        disableRipple
                                                        disableTouchRipple
                                                        className={classes.btnHover}
                                                        onClick={event => showCancelDialog(event, item?.id)}
                                                    >
                                                        <ReactSVG
                                                            src={item?.type === INVOICE_PENDING.PAID_AND_NOT_SENT ? CancelInvoiceDark : CancelInvoice}/>
                                                    </IconButton>
                                                </MuiTableCell>
                                                <TooltipCell
                                                    style={{display: showBtnRowId === item?.id ? 'none' : 'table-cell'}}
                                                    className={classes.validity}
                                                    label={item?.validity}
                                                    value={item?.validity}
                                                    type={item?.type}
                                                />
                                                {cancelId === item?.id &&
                                                <DialogCancel
                                                    open={Boolean(cancelId)}
                                                    title={'Cancel invoice?'}
                                                    name={item?.name}
                                                    onClose={() => setCancelId(null)}
                                                    action={() => {
                                                        console.log('d')
                                                    }}
                                                />
                                                }
                                                {confirmId === item?.id &&
                                                <DialogConfirm
                                                    open={Boolean(confirmId)}
                                                    title={'Check as confirm?'}
                                                    name={item?.name}
                                                    onClose={() => setConfirmId(null)}
                                                    action={() => {
                                                        console.log('d')
                                                    }}
                                                />
                                                }
                                                <QRInvoicePreview
                                                    openQRInvoicePreview={qrPreviewId === item?.id ? Boolean(qrPreviewId) : false}
                                                    setOpenQRInvoicePreview={closeQrPreview}
                                                    dataNewInvoice={{totalAmount: item?.amount}}
                                                    isBtnPreview
                                                />
                                                <InvoicePreview
                                                    openInvoicePreview={invoicePreviewId === item?.id ? Boolean(invoicePreviewId) : false}
                                                    setOpenInvoicePreview={closeInvoicePreview}
                                                    dataInvoice={{
                                                        name: item?.name,
                                                        description: "Order #4856232",
                                                        dueOnDate: item?.issueDate,
                                                        itemsInvoice: [
                                                            {
                                                                name: 'Oversized long sleeve t-shirt with well long sleeve t-shirt with well',
                                                                quantity: 25
                                                            },
                                                            {
                                                                name: 'Horizon Box long sleeve t-shirt in green top nice horizon Box',
                                                                quantity: 50
                                                            },
                                                            {name: 'Super skinny jeans in light blue', quantity: 10},
                                                            {
                                                                name: 'Jeans gradient box crew neck t-shirt',
                                                                quantity: 73
                                                            },
                                                        ],
                                                        note: "This area for notes. Please, check invoice number and total sum before scan QR-code. Additional details you can see scan QR code or download PDF with full information",
                                                        recipientEmail: "prikol@gmail.com",
                                                        totalAmount: item?.amount
                                                    }}
                                                />
                                            </CustomTableRowInvoices>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <div className={classes.btnMoreWrapper}>
                        <CustomButton onClick={() => {
                            console.log('See more')
                        }}>
                            See more
                        </CustomButton>
                    </div>
                </CustomPaper>
            </div>
        </div>
    );
};

export default PendingTable;


const useStyles = makeStyles<Theme>(() => ({
    pendingTableWrapper: {},
    tableHeader: {
        marginBottom: 16
    },
    tableTitle: {
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '24px'
    },
    pendingTable: {},
    table: {
        // '& td > h6': {
        //     fontSize: '16px'
        // }
    },
    btnMoreWrapper: {
        marginTop: 8,
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    paperContainer: {
        filter: 'drop-shadow(0px 6px 17px rgba(73, 105, 219, 0.08))',
        borderRadius: 16,
        paddingBottom: 20
    },
    btnHover: {
        padding: 0,
        '&:active': {
            transform: 'scale(0.9)',
            transition: 'all .2s ease-out 0s'
        },
        '& > span > div > div > svg': {
            display: 'block'
        },
        '&:hover': {
            background: 'none'
        }
    },
    btnContainer: {
        minWidth: 224,
        overflow: 'visible',
        maxWidth: 'inherit !important',
        '& > a': {
            margin: '0 8px'
        },
        '& > button:first-of-type': {
            marginLeft: '-42px'
        },
        '& > button + button': {
            marginLeft: 8,
        }
    },
    validity: {
        minWidth: 230
    },
    tableBody: {
        '& > tr > td:nth-child(4)': {
            paddingRight: 32
        }
    },
    tableHead: {
        '& > tr > th:nth-child(4)': {
            paddingRight: '32px !important'
        }
    }
}));

const data = [
    {
        id: 1,
        name: '548-66AB',
        clientAddress: '0xc54sa5d458654556sd48654556sd48654556sd4',
        amount: '5 408.22',
        productsPcs: 5,
        issueDate: '08 Jan 2022',
        validity: 'leave 5 minutes',
        type: INVOICE_PENDING.PAID_NOT_FULL
    },
    {
        id: 2,
        name: '548-66Z',
        clientAddress: '',
        amount: '421 545 985.85',
        productsPcs: 120,
        issueDate: '06 Jan 2022',
        validity: 'leave 23 hours',
        type: INVOICE_PENDING.WAITING_ADDRESS
    },
    {
        id: 3,
        name: '548-66AA',
        clientAddress: '0xc54sa5d458654556sd48654556sd48654556sd4',
        amount: '50 985.96',
        productsPcs: 40,
        issueDate: '07 Jan 2022',
        validity: 'leave 1 day 10 hours',
        type: INVOICE_PENDING.PAID_AND_NOT_SENT
    },
    {
        id: 4,
        name: '548-66X',
        clientAddress: '0xc54sa5d458654556sd48654556sd48654556sd4',
        amount: '120.00',
        productsPcs: 15,
        issueDate: '06 Jan 2022',
        validity: 'leave 7 day 23 hours',
        type: INVOICE_PENDING.PAID_NOT_FULL
    },
    {
        id: 5,
        name: '548-66XS',
        clientAddress: '0xc54sa5d458654556sd48654556sd48654556sd4',
        amount: '235.14',
        productsPcs: 35,
        issueDate: '09 Jan 2022',
        validity: 'leave 7 day 23 hours',
        type: INVOICE_PENDING.EXPIRES
    }
]
