import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {Form, Formik} from 'formik';
import {SEND_SINGLE_PRODUCT_SCHEMA, UPDATE_INVOICE_INFO_SCHEMA} from '../../constants/validation_schema';
import {useUIContext} from '../../context/UIContext';
import FormBoxInput from '../ui/FormBoxInput';
import {useGlobalContext} from '../../context/globalContext';
import CustomButton from '../ui/Buttons/Button';
import MuiCircularProgress from '../ui/CircularProgress';
import {useUpdateAccountMutation} from '../../generated/graphql';
import {useLocalStorage} from '../../helpers/hooks';
import {LOCAL_STORAGE_KEYS} from '../../constants';
import {MESSAGES} from '../../constants/messages';

const InvoiceSettingsForm: React.FC = (props): JSX.Element => {
    const classes = useStyles();
    const {showSnackBar} = useUIContext();
    const {userData, setUserData} = useGlobalContext();
    const [updateAccount] = useUpdateAccountMutation();
    const [, setToken] = useLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, null);
    return (
        <Formik
            enableReinitialize
            initialValues={{
                fullName: userData?.fullName || '',
                streetAddress: userData?.streetAddress || '',
                cityState: userData?.cityState || '',
                zipCode: userData?.zipCode || '',
                // phoneNumber: userData?.phoneNumber || '',
                // email: userData?.email || '',
            }}
            validationSchema={UPDATE_INVOICE_INFO_SCHEMA}
            onSubmit={async (values) => {
                try {
                    const {data} = await updateAccount({
                        variables: {
                            data: values
                        }
                    })
                    if (data?.updateAccount?.token) {
                        setToken(data?.updateAccount?.token)
                        setUserData(prevState => ({
                            ...prevState,
                            ...data?.updateAccount?.account
                        }))
                        showSnackBar(MESSAGES.CHANGE_INVOICE_INFO)
                    }
                } catch (e) {
                    showSnackBar(e?.message)
                }
            }}
        >
            {({
                handleSubmit,
                values,
                isSubmitting
            }) => {
                return (
                    <Form className={classes.container} onSubmit={handleSubmit}>
                        <Typography variant={'h3'}>Invoice Settings</Typography>
                        <Box className={classes.rowBox}>
                            <FormBoxInput
                                fullWidth
                                name={'fullName'}
                                icon={<i className={'material-icons-outlined'}>perm_identity</i>}
                                label={'Full name'}
                                labelWidth={75}
                                wrapperContainer={classes.input}
                            />
                            <FormBoxInput
                                fullWidth
                                name={'streetAddress'}
                                icon={<i className={'material-icons-outlined'}>place</i>}
                                labelWidth={110}
                                label={'Street Address'}
                                wrapperContainer={classes.input}
                            />
                            <Box className={classes.cardWrapper}>
                                <FormBoxInput
                                    fullWidth
                                    name={'cityState'}
                                    icon={<i className={'material-icons-outlined'}>home</i>}
                                    label={'City, State'}
                                    labelWidth={80}
                                    wrapperContainer={classes.input}
                                />
                                <FormBoxInput
                                    fullWidth
                                    name={'zipCode'}
                                    labelWidth={80}
                                    icon={<i className={'material-icons-outlined'}>mail</i>}
                                    label={'ZIP Code'}
                                    wrapperContainer={classes.input}
                                />
                            </Box>
                            {/*<Box className={classes.cardWrapper}>*/}
                            {/*    <FormBoxInput*/}
                            {/*        fullWidth*/}
                            {/*        name={'phoneNumber'}*/}
                            {/*        labelWidth={110}*/}
                            {/*        icon={<i className={'material-icons-outlined'}>phone</i>}*/}
                            {/*        label={'Phone number'}*/}
                            {/*        style={{width: 246}}*/}
                            {/*        wrapperContainer={classes.input}*/}
                            {/*    />*/}
                            {/*    <FormBoxInput*/}
                            {/*        fullWidth*/}
                            {/*        name={'email'}*/}
                            {/*        icon={<i className={'material-icons-outlined'}>alternate_email</i>}*/}
                            {/*        label={'Email'}*/}
                            {/*        labelWidth={40}*/}
                            {/*        style={{width: 245}}*/}
                            {/*        wrapperContainer={classes.input}*/}
                            {/*    />*/}
                            {/*</Box>*/}
                        </Box>
                        <CustomButton
                            type={'submit'}
                            disabled={isSubmitting}
                            variant={'outlined'}
                        >
                            {isSubmitting ? <MuiCircularProgress /> : 'Apply'}
                        </CustomButton>
                    </Form>
                )
            }}

        </Formik>
    );
};

export default InvoiceSettingsForm;

const useStyles = makeStyles<Theme>(() => ({
    container: {
        padding: '48px 0',
        width: '100%',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    rowBox: {
        display: 'flex',
        flexFlow: 'column',
        padding: '48px 0 0 0',
        width: 650
    },
    cardWrapper: {
        display: 'grid',
        gridGap: 15,
        gridTemplateColumns: '.9fr .6fr',
    },
    input: {
        marginBottom: 16
    }
}));
