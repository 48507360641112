import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Avatar, AvatarProps, makeStyles, withStyles} from '@material-ui/core';

type CustomAvatarProps = AvatarProps & {
    size?: number
}

const CustomAvatar: React.FC<CustomAvatarProps> = (props: CustomAvatarProps): JSX.Element => {
    const {size = 32, ...rest} = props;
    const classes = useStyles({size});

    return (
        <Avatar
            classes={{
                root: classes.root,
                colorDefault: classes.colorDefault,
                circle: classes.circle,
                circular: classes.circular,
                rounded: classes.rounded,
                square: classes.square,
                img: classes.img,
                fallback: classes.fallback
            }}
            {...rest}
        />
    );
};

export default CustomAvatar;

const useStyles = makeStyles<Theme, {size: number}>(() => ({
    root: {
        width: props => props.size,
        height: props => props.size,
    },
    colorDefault: {},
    circle: {},
    circular: {},
    rounded: {},
    square: {},
    img: {},
    fallback: {},
}));
