import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import MyThemeProvider from './styles/MuiTheme';
import {BrowserRouter} from 'react-router-dom';
import CombineContext from './context';
import {client} from './graphql';
import {ApolloProvider} from '@apollo/client';

ReactDOM.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <BrowserRouter>
                <MyThemeProvider>
                    <CombineContext>
                        <App/>
                    </CombineContext>
                </MyThemeProvider>
            </BrowserRouter>
        </ApolloProvider>
    </React.StrictMode>,
    document.getElementById('root')
)
;

