
const DROPZONE_UPLOAD_FEED = {
    display: 'flex',
    flexDirection: 'column',
    border: '2px dashed rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    width: '100%',
    marginTop: 20,
    padding: '26px 16px',
    textAlign: 'center'
}
export {DROPZONE_UPLOAD_FEED}
