import {Alert, AlertProps, AlertTitle} from '@material-ui/lab';
import React, {FC} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';

type AlertErrorsProps = AlertProps & {
    title: string,
    errors?: string[],
    subTitle: string
}

const AlertErrors: FC<AlertErrorsProps> =  (props: AlertErrorsProps) => {
    const classes = useStyles();
    const {title, errors, subTitle, ...rest} = props;
    return (
        <div className={classes.alert_wrapper}>
            <Alert
                classes={{
                    root: classes.alert,
                    icon: classes.alertIcon,
                    standardError: classes.standardError
                }}
                {...rest}
            >
                <AlertTitle
                    classes={{root: classes.alertTitle}}
                >{title}</AlertTitle>
                {errors ? <ul>{errors.map((error, index) => <li key={index}>{error}</li>)}</ul>
                    : `Unable to ${subTitle} with provided credentials.`
                }
            </Alert>
        </div>
    );
};

export default AlertErrors;

const useStyles = makeStyles<Theme>((theme) => ({
    alert_wrapper: {
        border: '2px solid #DC3030',
        borderRadius: '8px',
        background: '#FAE6E6',
        width: '100%',
        zIndex: 2,
        '&>img': {
            fontSize: '2rem'
        },
        transition: '1s ease'
    },
    alert: {
        width: '100%'
    },
    alertTitle: {
        textAlign: 'start',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#DC3030'
    },
    standardError: {
        backgroundColor: '#FAE6E6'
    },
    alertIcon: {
        height: 42,
        width: 42,
        fontSize: '2.5rem',
        alignSelf: 'start',
        marginTop: 4
    },
}));
