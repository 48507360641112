import React, {useState, useEffect} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import DialogLayout, {DialogLayoutProps} from '../../../layouts/DialogLayout';
import CustomButton from '../Buttons/Button';
import {Formik, Form, FormikProps} from 'formik';
import {UPDATE_INVOICE_INFO_SCHEMA} from '../../../constants/validation_schema';
import {MESSAGES} from "../../../constants/messages";
import {useUIContext} from "../../../context/UIContext";
import FormBoxInput from "../FormBoxInput";
import MuiCircularProgress from "../CircularProgress";
import {useGlobalContext} from "../../../context/globalContext";
import {useUpdateAccountMutation} from "../../../generated/graphql";
import {useLocalStorage} from "../../../helpers/hooks";
import {LOCAL_STORAGE_KEYS} from "../../../constants";

type DialogInvoiceDataProps = DialogLayoutProps & {
    title?: string,
    name?: any;
    setOpenDialogInvoiceData: any;
}
const DialogInvoiceData: React.FC<DialogInvoiceDataProps> = (props: DialogInvoiceDataProps): JSX.Element => {
    const classes = useStyles();
    const {title, name, setOpenDialogInvoiceData, ...rest} = props;
    const {showSnackBar, setOpenCreateInvoice} = useUIContext();
    const {userData, setUserData} = useGlobalContext();
    const [updateAccount] = useUpdateAccountMutation();
    const [, setToken] = useLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, null);

    return (
        <DialogLayout
            width={840}
            {...rest}
            onClick={(event) => event.stopPropagation()}
        >
            <Box className={classes.wrapper}>
                <Typography variant={'h4'} style={{lineHeight: '48px'}}>{title}</Typography>
                <Typography
                    variant={'subtitle1'}
                    className={classes.subtitle}
                >For create invoice we need a data about your company. This information will be displayed on the
                    invoice. You can always edit this data in the settings
                </Typography>
                <Formik
                    enableReinitialize
                    initialValues={{
                        fullName: userData?.fullName || '',
                        streetAddress: userData?.streetAddress || '',
                        cityState: userData?.cityState || '',
                        zipCode: userData?.zipCode || '',
                        // phoneNumber: userData?.phoneNumber || '',
                        // email: userData?.email || '',
                    }}
                    validationSchema={UPDATE_INVOICE_INFO_SCHEMA}
                    onSubmit={async (values) => {
                        try {
                            const {data} = await updateAccount({
                                variables: {
                                    data: values
                                }
                            })
                            if (data?.updateAccount?.token) {
                                setToken(data?.updateAccount?.token)
                                setUserData(prevState => ({
                                    ...prevState,
                                    ...data?.updateAccount?.account
                                }))
                                showSnackBar(MESSAGES.CHANGE_INVOICE_INFO)
                            }
                            setOpenDialogInvoiceData(false);
                            setOpenCreateInvoice(true);
                        } catch (e) {
                            showSnackBar(e?.message)
                        }
                    }}
                >
                    {({
                        handleSubmit,
                        values,
                        isValid,
                        touched,
                        isSubmitting
                    }) => {
                        return (
                            <Form className={classes.container} onSubmit={handleSubmit}>
                                <Box className={classes.rowBox}>
                                    <FormBoxInput
                                        fullWidth
                                        name={'fullName'}
                                        icon={<i className={'material-icons-outlined'}>perm_identity</i>}
                                        label={'Full name'}
                                        labelWidth={75}
                                        wrapperContainer={classes.input}
                                    />
                                    <FormBoxInput
                                        fullWidth
                                        name={'streetAddress'}
                                        icon={<i className={'material-icons-outlined'}>place</i>}
                                        labelWidth={110}
                                        label={'Street Address'}
                                        wrapperContainer={classes.input}
                                    />
                                    <Box className={classes.cardWrapper}>
                                        <FormBoxInput
                                            fullWidth
                                            name={'cityState'}
                                            icon={<i className={'material-icons-outlined'}>home</i>}
                                            label={'City, State'}
                                            labelWidth={80}
                                            style={{minWidth: 387}}

                                            wrapperContainer={classes.input}
                                        />
                                        <FormBoxInput
                                            fullWidth
                                            name={'zipCode'}
                                            labelWidth={80}
                                            icon={<i className={'material-icons-outlined'}>mail</i>}
                                            label={'ZIP Code'}
                                            wrapperContainer={classes.input}
                                        />
                                    </Box>
                                    {/*<Box className={classes.cardWrapper}>*/}
                                    {/*    <FormBoxInput*/}
                                    {/*        fullWidth*/}
                                    {/*        name={'phoneNumber'}*/}
                                    {/*        labelWidth={110}*/}
                                    {/*        icon={<i className={'material-icons-outlined'}>phone</i>}*/}
                                    {/*        label={'Phone number'}*/}
                                    {/*        wrapperContainer={classes.input}*/}
                                    {/*    />*/}
                                    {/*    <FormBoxInput*/}
                                    {/*        fullWidth*/}
                                    {/*        name={'email'}*/}
                                    {/*        icon={<i className={'material-icons-outlined'}>alternate_email</i>}*/}
                                    {/*        label={'Email'}*/}
                                    {/*        labelWidth={40}*/}
                                    {/*        style={{width: 302}}*/}
                                    {/*        wrapperContainer={classes.input}*/}
                                    {/*    />*/}
                                    {/*</Box>*/}
                                </Box>
                                <Box className={classes.wrapperButton}>
                                    <CustomButton
                                        className={classes.cancel}
                                        onClick={(event) => props.onClose(event, 'escapeKeyDown')}>Cancel</CustomButton>
                                    <CustomButton
                                        style={{minWidth: 109}}
                                        disabled={(!isValid || (Object.keys(touched).length === 0 && touched.constructor === Object))}
                                        type={'submit'}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            console.log(values)
                                        }}
                                    >{isSubmitting ? <MuiCircularProgress/> : 'Save data'}</CustomButton>
                                </Box>
                            </Form>
                        )
                    }}

                </Formik>
            </Box>
        </DialogLayout>
    );
};

export default DialogInvoiceData;

const useStyles = makeStyles<Theme>(() => ({
    wrapper: {
        padding: '40px 56px 32px 56px'
    },
    subtitle: {
        color: 'rgba(0, 0, 0, 0.87)',
        margin: '8px 0 24px 0',
        '& > span': {
            color: '#000000',
            fontWeight: 500,
            whiteSpace: 'nowrap'
        }
    },
    rowBox: {
        display: 'flex',
        flexFlow: 'column'
    },
    cardWrapper: {
        display: 'flex',
        '& > div + div': {
            marginLeft: 24
        }
    },
    input: {
        marginBottom: 16
    },
    wrapperButton: {
        display: 'flex',
        justifyContent: 'end',
        '& > button + button': {
            marginLeft: 24
        },
        '& > button:last-of-type': {
            maxHeight: 40,
            marginRight: -16,
            padding: '8px 16px'
        }
    },
    cancel: {
        maxHeight: 40,
        padding: '8px 16px',
        marginLeft: 'auto'
    }
}));
