import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import SelectPeriod from './components/SelectPeriod';
import PlanCard, {PlanItem} from './components/PlanCard';
import ContactUsSection from './components/ContactUsSection';
import SelectLayout from '../../layouts/SelectLayout';

export default function SelectPlanPage(props) {
    const classes = useStyles();
    const [showForm, setShowForm] = useState(false);
    const [plans, setPlans] = useState<PlanItem[]>([
        {
            type: 'Small',
            price: 100,
            period: 'Month',
            description: 'Optimized for small (home/family) business.',
            offer: ['1 brand', '100 products', 'custom seller mobile app'],
            isPopular: false
        },
        {
            type: 'Popular',
            price: 300,
            period: 'Month',
            description: 'Optimized for small (home/family) business.',
            offer: ['2 brand + 1 sub-brand', '1000 products', 'custom seller mobile app'],
            isPopular: true
        },
        {
            type: 'Pro',
            price: 500,
            period: 'Month',
            description: 'Optimized for small (home/family) business.',
            offer: ['5 brand + 3 sub-brand', '5000 products', 'custom seller mobile app'],
            isPopular: false
        },
        {
            type: 'Essential',
            price: 'Individual'
        }
    ]);
    return (
        <SelectLayout>
            <div className={classes.SelectPlanPage}>
                <Typography
                    variant={'h2'}
                    className={classes.Title}
                >Select Plan for Your Business </Typography>
                <SelectPeriod
                    style={{gridColumn: '3/5', justifySelf: 'end'}}
                    plans={plans}
                    setPlans={(newState) => setPlans(newState)}
                />
                {plans.map((item, index) => (
                    <PlanCard
                        key={index}
                        item={item}
                        setShowForm={(newState) => setShowForm(newState)}
                    />
                ))}
                <ContactUsSection
                    showForm={showForm}
                    setShowForm={(newState) => setShowForm(newState)}
                />
            </div>
        </SelectLayout>
    );
}

const useStyles = makeStyles({
    SelectPlanPage: {
        width: 1300,
        height: '100%',
        margin: 'auto',
        padding: '10% 16px 7% 16px',
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridTemplateRows: '130px 1fr',
        gridColumnGap: 10

    },
    Title: {
        gridColumn: '1/3'
    }


});
