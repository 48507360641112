import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/core';
import {Redirect, Route} from 'react-router-dom';
import {useGlobalContext} from '../context/globalContext';

const AuthRoute = props => {
    const {userData} = useGlobalContext();
    // if (type === "guest" && isAuthUser) return <Redirect to="/home" />;
    if (userData) return <Route {...props} /> ;
    return <Redirect to="/" />;
};

export default AuthRoute;

const useStyles = makeStyles<Theme>(() => ({}));
