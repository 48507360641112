import React from 'react';
import GloBalContextProvider from './globalContext';
import AuthContextProvider from './authContext';
import {UIContextProvider} from './UIContext';
import ProductContextProvider from './productContext';

const CombineContext: React.FC = ({children}): JSX.Element => {

    return (
        <GloBalContextProvider>
            <UIContextProvider>
                <ProductContextProvider>
                    {children}
                </ProductContextProvider>
            </UIContextProvider>
        </GloBalContextProvider>
    );
};

export default CombineContext;
