import React, {FC, useState} from 'react';
import {
    FormControlLabel,
    IconButton,
    InputAdornment,
    Radio,
    RadioGroup,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {Form, Formik, FormikProps} from 'formik';
import FormLayout from '../../layouts/FormLayout';
import {CREATE_STORE_TYPE, OPTIONS} from './helpers/constants';
import {ADD_STORE_SCHEMA, CREATE_STORE_SCHEMA} from '../../constants/validation_schema';
import FormInput from '../../components/ui/FormInput';
import {AddStoreInitData, CreateStoreInitData} from './helpers/type';
import {useUIContext} from '../../context/UIContext';
import Random from '../../assets/icons/random.svg';
import SubmitButtonWithErrors from '../../components/ui/Buttons/SubmitButtonWithErrors';
import {useAddStoreMutation, useCreateStoreMutation} from '../../generated/graphql';
import {useHistory} from 'react-router-dom';
import {MESSAGES} from '../../constants/messages';
import {useMergeState} from '../../helpers/hooks';
import DialogCreateStore from '../../components/ui/Dialogs/DialogCreateStore';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

const StoreCreate: FC = (): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<AddStoreInitData | CreateStoreInitData>({
        name: '',
        blockchainAddress: '',
        comment: ''
    });
    const [type, setType] = useState<'ADD' | 'CREATE'>(CREATE_STORE_TYPE.ADD);
    const [dialogData, mergeDialogData] = useMergeState({open: false, login: '', password: ''});
    const {showEndAction, showSnackBar} = useUIContext();
    const [addStore] = useAddStoreMutation();
    const [createStore] = useCreateStoreMutation();
    const toggleShowPassword = () => setShowPassword(prevState => !prevState);
    const changeType = (e) => {
        if (e.target.value === CREATE_STORE_TYPE.ADD) {
            setInitialValues({
                name: '',
                blockchainAddress: '',
                comment: ''
            });
        } else {
            setInitialValues({
                email: '',
                password: '',
                name: '',
                comment: ''
            });
        }
        setType(e.target.value);
    };
    return (
        <FormLayout
            crumbsDetails={[
                {message: 'Stores', link: ''},
                {message: 'Create', link: ''}
            ]}
            title={'Create a store or distributor'}
        >
            <RadioGroup value={type} onChange={(e) => changeType(e)}>
                {OPTIONS.map((option) => (
                    <FormControlLabel
                        key={option.id}
                        value={option.value}
                        control={<Radio color="primary"/>}
                        label={option.label}
                    />
                ))}
            </RadioGroup>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={
                    (type === CREATE_STORE_TYPE.ADD) ? ADD_STORE_SCHEMA : CREATE_STORE_SCHEMA}
                onSubmit={async (values) => {
                    if (type === CREATE_STORE_TYPE.ADD) {
                        try {
                            const {data} = await addStore({variables: values})
                            if (data?.contactAdd?.id) {
                                showSnackBar(MESSAGES.ADD_CONTACT)
                                history.push('/stores');
                            }
                        } catch (e) {
                            showEndAction(e?.message)
                        }
                    } else {
                        try {
                            const {password, ...rest} = values;
                            const {data} = await createStore({
                                variables: {
                                    password: password,
                                    ...rest
                                }})
                            if (data?.storeCreate?.id) {
                                mergeDialogData({open: true, login: values.email, password: values.password});
                            }
                        } catch (e) {
                            showEndAction(e?.message)
                        }
                    }
                }}
            >
                {({setFieldValue}: FormikProps<any>) => {
                    return (type === CREATE_STORE_TYPE.ADD)
                        ? <Form>
                            <div className={classes.storeForm}>
                                <FormInput
                                    name={'name'}
                                    label={'Name'}
                                    labelWidth={43}
                                    formControlProps={{
                                        style: {gridColumn: '1/2'}
                                    }}
                                />
                                <FormInput
                                    name={'blockchainAddress'}
                                    label={'Wallet'}
                                    labelWidth={45}
                                    formControlProps={{
                                        style: {gridColumn: '2/3'}
                                    }}
                                />
                                <FormInput
                                    fullWidth
                                    name={'comment'}
                                    label={'Comment'}
                                    labelWidth={73}
                                    multiline
                                    rows={8}
                                    formControlProps={{
                                        style: {gridColumn: '1/3'}
                                    }}
                                />
                            </div>
                            <SubmitButtonWithErrors/>
                        </Form>
                        : <Form>
                            <div className={classes.storeForm}>
                                <FormInput
                                    name={'email'}
                                    label={'Email'}
                                    labelWidth={40}
                                    formControlProps={{
                                        style: {gridColumn: '1/3'}
                                    }}
                                />
                                <FormInput
                                    name={'name'}
                                    label={'Name'}
                                    labelWidth={45}
                                    formControlProps={{
                                        style: {gridColumn: '1/2'}
                                    }}
                                />
                                <FormInput
                                    name={'password'}
                                    label={'Password'}
                                    labelWidth={75}
                                    formControlProps={{
                                        style: {gridColumn: '2/3'}
                                    }}
                                    type={!showPassword ? 'password' : 'text'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={toggleShowPassword}
                                            >
                                                {!showPassword ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                <FormInput
                                    fullWidth
                                    name={'comment'}
                                    label={'Comment'}
                                    labelWidth={70}
                                    multiline
                                    rows={8}
                                    formControlProps={{
                                        style: {gridColumn: '1/3'}
                                    }}
                                />
                            </div>
                            <SubmitButtonWithErrors/>
                        </Form>;
                }}
            </Formik>
            {dialogData?.open &&
            <DialogCreateStore
                open={dialogData?.open}
                type={type}
                dialogData={dialogData}
                mergeDialogData={mergeDialogData}
            />
            }
        </FormLayout>
    );
};
export default StoreCreate

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2)
        }
    },
    typography: {
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '24px',
        color: 'rgba(0, 0, 0, 0.6)'
    },
    Breadcrumbs: {
        marginBottom: '25px'
    },
    formControl: {},
    cursor: {
        cursor: 'pointer'
    },
    confirmBtn: {
        marginTop: '20px'
    },
    dialogTitle: {
        fontSize: 24,
        color: 'rgba(0, 0, 0, 0.87)',
        textAlign: 'center',
        marginBottom: 25
    },
    dialogText: {
        fontSize: 14,
        lineHeight: 2,
        color: 'rgba(0, 0, 0, 0.6)',
        marginBottom: 15
    },
    DialogLoginItem: {
        display: 'flex',
        width: '100%'

    },
    dialogListBox: {
        width: 56,
        height: 56,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        borderRadius: '15px 5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    ListItemText: {
        paddingLeft: 26
    },
    primaryItemText: {
        fontWeight: 500,
        fontSize: 16,
        color: '#276EF9'
    },
    storeForm: {
        margin: '40px 0',
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)',
        gridColumnGap: 25,
        gridRowGap: 12,
        height: 'auto'
    },
    input: {
        gridRow: '1/2',
        gridColumn: '1/3'
    },
    textArea: {
        gridColumn: '1/3'
    }
}));
