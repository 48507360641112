import React, {useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import {useUIContext} from '../context/UIContext';
import {Redirect, Route, Switch} from 'react-router-dom';
import BrandsPage from '../pages/brands/BrandsPage';
import clsx from 'clsx';
import BrandsCreate from '../pages/brands/BrandsCreate';
import BrandSuccessCreate from '../pages/brands/BrandSuccessCreate';
import HomePage from '../pages/home/HomePage';
import ProductCreate from '../pages/products/ProductCreate';
import ConsignmentsCreate from '../pages/consignments/ConsignmentsCreate';
import StoreCreate from '../pages/stores/StoreCreate';
import SupportPage from '../pages/suport/SupportPage';
import QuestionPage from '../pages/suport/QuestionPage';
import SubmitRequestPage from '../pages/suport/SubmitRequestPage';
import SettingsPage from '../pages/settings/SettingsPage';
import ProductSuccessCreate from '../pages/products/ProductSuccessCreate';
import ProductPage from '../pages/products/ProductPage';
import ConsignmentPage from '../pages/consignments/ConsignmentPage';
import AuthRouter from './AuthRouter';
import ProductSendPage from '../pages/products/ProductSendPage';
import StorePage from '../pages/stores/StorePage';
import ProductDetailsPage from '../pages/products/ProductDetailsPage';
import FinancesPage from '../pages/finances/FinancesPage';
import StoreInfoPage from '../pages/stores/StoreInfoPage';
import {useGlobalContext} from '../context/globalContext';
import {UserType} from '../generated/graphql';
import UploadFeedPage from '../pages/products/UploadFeedPage';
import ActionsPage from '../pages/actions/ActionsPage';
import TransactionPage from '../pages/finances/TransactionPage';
import InvoicePage from '../pages/invoices/InvoicePage';

const AuthorizedRoutes: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const {showHeader} = useUIContext();
    const scrollRef = useRef(null);
    const {userData} = useGlobalContext();
    useEffect(() => {
        if (scrollRef?.current) {

            scrollRef.current.scrollTo(0, 0);
        }
    }, [scrollRef, location.pathname]);
    return (
        <div id={"widthHeader"} ref={scrollRef} className={clsx(
            {[classes.widthHeader]: showHeader},
            {[classes.withoutHeader]: !showHeader},
        )}>
            {userData?.type !== UserType.Store ?
                <Switch>
                    <AuthRouter exact path={'/brands/success-create'} component={BrandSuccessCreate}/>
                    <AuthRouter exact path={'/brands/create'} component={BrandsCreate}/>
                    <AuthRouter exact path={'/brands'} component={BrandsPage}/>
                    <AuthRouter exact path={'/products/send-products'} component={ProductSendPage}/>
                    <AuthRouter exact path={'/products/success-create'}  component={ProductSuccessCreate}/>
                    <AuthRouter exact path={'/products/create'} component={ProductCreate}/>
                    <AuthRouter exact path={'/products/info'} component={ProductDetailsPage}/>
                    <AuthRouter exact path={'/products'} component={ProductPage}/>
                    <AuthRouter exact path={'/consignments/send-consignments'} component={ProductSendPage}/>
                    <AuthRouter exact path={'/consignments/create'} component={ConsignmentsCreate}/>
                    <AuthRouter exact path={'/consignments/info'} component={ProductDetailsPage}/>
                    <AuthRouter exact path={'/consignments'} component={ConsignmentPage}/>
                    <AuthRouter exact path={'/support/send-request'} component={SubmitRequestPage}/>
                    <AuthRouter exact path={'/support/:type'} component={QuestionPage}/>
                    <AuthRouter exact path={'/support'} component={SupportPage}/>
                    <AuthRouter exact path={'/settings'} component={SettingsPage}/>
                    <AuthRouter exact path={'/stores/create'} component={StoreCreate}/>
                    <AuthRouter exact path={'/stores/info/:id'} component={StoreInfoPage}/>
                    <AuthRouter exact path={'/stores'} component={StorePage}/>
                    <AuthRouter exact path={'/invoices'} component={InvoicePage}/>
                    <AuthRouter exact path={'/finances'} component={FinancesPage}/>
                    <AuthRouter exact path={'/finances/invoice'} component={FinancesPage}/>
                    {/*<AuthRouter exact path={'/finances/transaction'} component={TransactionPage}/>*/}
                    <AuthRouter exact path={'/upload-feed'} component={UploadFeedPage}/>
                    <AuthRouter exact path={'/actions'} component={ActionsPage}/>
                    <AuthRouter exact path={'/'} component={HomePage}/>
                    <Route><Redirect to="/" /></Route>
                </Switch>
                : <Switch>
                    <AuthRouter exact path={'/products'} component={ProductPage}/>
                    <AuthRouter exact path={'/products/info'} component={ProductDetailsPage}/>
                    <AuthRouter exact path={'/products/send-products'} component={ProductSendPage}/>
                    <AuthRouter exact path={'/consignments'} component={ConsignmentPage}/>
                    <AuthRouter exact path={'/consignments/info'} component={ProductDetailsPage}/>
                    <AuthRouter exact path={'/consignments/send-consignments'} component={ProductSendPage}/>
                    <AuthRouter exact path={'/support'} component={SupportPage}/>
                    <AuthRouter exact path={'/support/question'} component={QuestionPage}/>
                    <AuthRouter exact path={'/support/send-request'} component={SubmitRequestPage}/>
                    <AuthRouter exact path={'/settings'} component={SettingsPage}/>
                    <AuthRouter exact path={'/actions'} component={ActionsPage}/>
                    <AuthRouter exact path={'/'} component={HomePage}/>
                    <Route><Redirect exact to="/"/></Route>
                </Switch>
            }
        </div>
    );
};

export default AuthorizedRoutes;

const useStyles = makeStyles<Theme>(theme => ({
    widthHeader: {
        background: '#F1F3F9',
        padding: 30,
        gridArea: 'content',
        overflowY: 'scroll',
        borderTopLeftRadius: 8
    },
    withoutHeader: {
        background: '#F1F3F9',
        padding: 30,
        gridColumn: '2/3',
        gridRow: '1/3',
        overflowY: 'scroll'
    }
}));

