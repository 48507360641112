import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, TextField, TextFieldProps} from '@material-ui/core';
import {Autocomplete, AutocompleteProps} from '@material-ui/lab';
import {useFormikContext, Field} from 'formik';
import CustomHelperText, {CustomHelperTextProps} from './FormHelperText';
import clsx from 'clsx';

type FormAutocomplete = Omit<AutocompleteProps<any, any, any, any>, 'renderInput'> & {
    name: string,
    helperText?: Node,
    helperTextProps?: CustomHelperTextProps,
    textInput?: TextFieldProps,
    label?: string,
    wrapperStyle?: any
    property?: string
}

const FormAutocomplete: React.FC<FormAutocomplete> = (props: FormAutocomplete): JSX.Element => {
    const classes = useStyles();
    const {setFieldValue, setTouched} = useFormikContext();
    const {name, helperTextProps, textInput, label, wrapperStyle, property, ...rest} = props;
    const handleChange = (event, value) => {
        setFieldValue(name, value[property])
    }
    const handleBlur = () => setTouched({[name]: true})

    return (
        <Field name={name}>
            {({meta, form, field}) => (
                <Box
                    className={clsx(
                        classes.wrapper,
                        wrapperStyle
                    )}
                >
                    <Autocomplete
                        onBlur={handleBlur}
                        onChange={handleChange}
                        blurOnSelect={'touch'}
                        renderInput={(params) => (
                            <TextField
                                error={meta.error && meta.touched}
                                label={label}
                                variant={'outlined'}
                                {...params}
                                {...textInput}
                                {...field}
                            />
                        )}
                        {...rest}
                    />
                    <CustomHelperText
                        meta={meta}
                        {...helperTextProps}
                    />
                </Box>
            )}
        </Field>

    );
};

export default FormAutocomplete;

const useStyles = makeStyles<Theme>(() => ({
    wrapper: {
        minHeight: 72,
        flex: 1
    }
}));
