import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Dialog, DialogProps, IconButton, makeStyles} from '@material-ui/core';
import {COLORS} from '../constants/colors';
import {Close} from '@material-ui/icons';

export type DialogLayoutProps = DialogProps & {
    width?: number
    hideIcon?: boolean
}

const DialogLayout: React.FC<DialogLayoutProps> = (props: DialogLayoutProps): JSX.Element => {
    const {width = 420, onClose, hideIcon = false, ...rest} = props;
    const classes = useStyles({width});

    return (
        <Dialog
            onClose={onClose}
            classes={{
                container: classes.container,
                paper: classes.paper,
                paperScrollPaper: classes.paperScrollPaper
            }}
            {...rest}
        >
            {hideIcon ||
            <IconButton
                size={'medium'}
                className={classes.closeButton}
                onClick={(event) => onClose(event ,'escapeKeyDown')}
            ><Close /></IconButton>
            }
            {props.children}
        </Dialog>

    );
};

export default DialogLayout;

const useStyles = makeStyles<Theme, {width: number | string}>(() => ({
    container: {
        margin: '0 auto',
        width: '100%'
    },
    paper: {
        maxWidth: 840,
        margin: 0,
        borderRadius: 8,
        backgroundColor: COLORS.WHITE,
        overflowY: 'hidden',
        width: props => props.width,
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2)'
    },
    paperScrollPaper: {
    },
    closeButton: {
        position: 'absolute',
        top: 12,
        right: 12
    }
}));
