import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

import {Box, Typography} from '@material-ui/core';
import {COLORS} from '../../../constants/colors';
const Question = ({questions}):JSX.Element => {
    const classes = useStyles();
    return questions.map((item, _) => (
        <Box className={classes.QuestionSection} key={_}>
            <Typography variant={'h4'} className={classes.TitleItem}>{item.title}</Typography>
            <Typography className={classes.SubTitle}>{item.subTitle}</Typography>
        </Box>
    ));
}

export default Question;

const useStyles = makeStyles({
    QuestionSection: {
        marginBottom: 32
    },
    TitleItem: {
        marginBottom: 8
    },
    SubTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        color: COLORS.TEXT_SILVER
    }
});
