import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import {COLORS} from '../../../constants/colors';

const NotFoundSection: React.FC = (): JSX.Element => {
    const classes = useStyles();

    return (
        <Box className={classes.notFoundWrapper}>
            <Typography variant={'h4'} className={classes.notFoundTitle}>Nothing not found</Typography>
            <Typography variant={'subtitle1'} className={classes.notFoundInfo}>
                Check the spelling of the request if there are
                any typos, such as &quot;color&quot; instead of &quot;color&quot;
            </Typography>
            <Typography variant={'subtitle1'} className={classes.notFoundInfo}>
                Try using a more general query like &quot;Material&quot;
                instead of &quot;Fabric&quot;
            </Typography>
            <Typography variant={'subtitle1'} className={classes.notFoundInfo}>
                If your problem has not been resolved, please{' '}
                <NavLink to={'/contact/support'}>
                    contact support
                </NavLink>
            </Typography>
        </Box>
    );
};

export default NotFoundSection;

const useStyles = makeStyles<Theme>(() => ({
    notFoundWrapper: {
        marginBottom: 20,
        padding: '0 56px 0 56px'
    },
    notFoundTitle: {
        color: COLORS.TEXT_SILVER,
        marginTop: 40
    },
    notFoundInfo: {
        color: COLORS.TEXT_LIGHT_SILVER,
        lineHeight: 2.4
    },
}));
