import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import DialogLayout, {DialogLayoutProps} from '../../../layouts/DialogLayout';
import {COLORS} from '../../../constants/colors';
import CustomButton from '../Buttons/Button';
import {MESSAGES} from "../../../constants/messages";
import {useUIContext} from "../../../context/UIContext";

type DialogCancelProps = DialogLayoutProps & {
    title?: string,
    action: () => void
    name?: any;
}
const DialogCancel: React.FC<DialogCancelProps> = (props: DialogCancelProps): JSX.Element => {
    const classes = useStyles();
    const {title, action, name, ...rest} = props;
    const {showSnackBar} = useUIContext();
    return (
        <DialogLayout
            width={552}
            {...rest}
            onClick={(event) => event.stopPropagation()}
        >
            <Box className={classes.wrapper}>
                <Typography variant={'h4'} style={{lineHeight: '48px'}}>{title}</Typography>
                <Typography
                    variant={'subtitle1'}
                    className={classes.subtitle}
                ><span>Invoice #{name}</span> will be canceled. You can’t undo this action.</Typography>
                <Box className={classes.wrapperButton}>
                    <CustomButton onClick={(event) => props.onClose(event, 'escapeKeyDown')}>Close</CustomButton>
                    <CustomButton className={classes.cancelInvoice} onClick={() => {
                        showSnackBar(MESSAGES.INVOICE_CANCEL);
                        action();
                        props.onClose(event, 'escapeKeyDown');
                    }}>Cancel invoice</CustomButton>
                </Box>
            </Box>
        </DialogLayout>
    );
};

export default DialogCancel;

const useStyles = makeStyles<Theme>(() => ({
    wrapper: {
        padding: '40px 40px 32px 56px'
    },
    subtitle: {
        color: COLORS.TEXT_SILVER,
        margin: '8px 0 24px 0',
        '& > span': {
            color: '#000000',
            fontWeight: 500
        }
    },
    wrapperButton: {
        marginLeft: 'auto',
        width: 'min-content',
        display: 'flex',
        '& > button + button': {
            marginLeft: 24
        }
    },
    cancelInvoice: {
        whiteSpace: 'nowrap',
        color: 'rgba(220, 48, 48, 1)',
        '&:hover': {
            backgroundColor: 'rgba(220, 48, 48, 0.04)'
        }
    }
}));
