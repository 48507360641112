import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import DialogLayout, {DialogLayoutProps} from '../../../layouts/DialogLayout';
import {COLORS} from '../../../constants/colors';
import CustomButton from '../Buttons/Button';

type DialogDeleteProps = DialogLayoutProps & {
    title?: string,
    subtitle?: string,
    action: () => void
}
const DeleteDialog: React.FC<DialogDeleteProps> = (props: DialogDeleteProps): JSX.Element => {
    const classes = useStyles();
    const {title, subtitle, action, ...rest} = props;
    return (
        <DialogLayout
            hideIcon
            width={552}
            {...rest}
        >
            <Box className={classes.wrapper}>
                <Typography variant={'h4'}>{title}</Typography>
                <Typography variant={'subtitle1'} className={classes.subtitle}>{subtitle}</Typography>
                <Box className={classes.wrapperButton}>
                    <CustomButton onClick={(event) => props.onClose(event, 'escapeKeyDown')}>Cancel</CustomButton>
                    <CustomButton onClick={() => {
                        action()
                        props.onClose(event, 'escapeKeyDown')
                    }}>Delete</CustomButton>
                </Box>
            </Box>
        </DialogLayout>
    );
};

export default DeleteDialog;

const useStyles = makeStyles<Theme>(() => ({
    wrapper: {
        padding: '40px 56px'
    },
    subtitle: {
        color: COLORS.TEXT_SILVER,
        margin: '8px 0 24px 0'
    },
    wrapperButton: {
        width: '50%',
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'space-around'
    }
}));
