import React, {useState, useEffect} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, CircularProgress, IconButton, InputAdornment, makeStyles, Typography} from '@material-ui/core';
import {Form, Formik, FormikValues} from 'formik';
import {LOGIN_SCHEMA} from '../../constants/validation_schema';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import FormBoxInput from '../ui/FormBoxInput';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import FormCheckBox from '../ui/FormCheckBox';
import {useLocalStorage} from '../../helpers/hooks';
import {NavLink, useHistory} from 'react-router-dom';
import SubmitButton from '../ui/Buttons/SubmitButton';
import {useLoginMutation} from '../../generated/graphql';
import {LOCAL_STORAGE_KEYS} from '../../constants';
import {useUIContext} from '../../context/UIContext';

type LogInFormProps = {
    serverError: null | string,
    setServerError: (newState: null | string) => void
}

const LogInForm: React.FC<LogInFormProps> = (props: LogInFormProps): JSX.Element => {
    const classes = useStyles();
    const {showSnackBar} = useUIContext();
    const {setServerError} = props;
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const toggleShowPassword = (): void => setShowPassword(prevState => !prevState);
    const [login, {error}] = useLoginMutation();
    const [, setToken] = useLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, false);
    useEffect(() => {
        setServerError(error?.message);
    }, [error]);
    return (
        <Formik
            initialValues={{
                rememberMe: false,
                email: '',
                password: ''
            }}
            validationSchema={LOGIN_SCHEMA}
            onSubmit={async (values: FormikValues, {resetForm}) => {
                try {
                    const {data} = await login({
                        variables: {
                            email: values.email,
                            password: values.password
                        }
                    });
                    if (data?.login?.token) {
                        setToken(data?.login?.token);
                        window.location.reload()
                    }
                } catch (e) {
                    showSnackBar(e?.message);
                }
            }}
        >
            {({isValid, isSubmitting, errors, touched, handleSubmit}) => {
                return (
                    <Form className={classes.formContainer}>
                        <FormBoxInput
                            fullWidth
                            autoFocus
                            name={'email'}
                            icon={<PersonOutlineOutlinedIcon/>}
                            label={'Email'}
                            labelWidth={42}
                        />
                        <FormBoxInput
                            fullWidth
                            name={'password'}
                            icon={<VpnKeyOutlinedIcon/>}
                            label={'Password'}
                            labelWidth={73}
                            type={!showPassword ? 'password' : 'text'}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={toggleShowPassword}
                                    >
                                        {!showPassword ? <VisibilityOutlinedIcon/> : <VisibilityOffOutlinedIcon/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        <Box className={classes.rememberWrapper}>
                            <FormCheckBox
                                color={'primary'}
                                name={'rememberMe'}
                                label={<Typography variant={'h5'}>Remember me</Typography>}
                                formControlWrapper={classes.formControlWrapper}
                            />
                            <NavLink to={'/forgot-password/send-email'}>Forgot password?</NavLink>
                        </Box>
                        <SubmitButton
                            type={'submit'}
                            disabled={!isValid || isSubmitting}
                        >
                            {isSubmitting ? <CircularProgress color={'primary'} size={20}/> : 'Log In'}
                        </SubmitButton>
                        <Typography
                            variant={'h5'}
                            className={classes.h5}
                        >Don’t have an account? <NavLink to={'/select-type'}>Sign up!</NavLink></Typography>
                    </Form>
                )
            }}
        </Formik>
    );
};

export default LogInForm;

const useStyles = makeStyles<Theme>(theme => ({
    formContainer: {
        marginTop: 16,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        zIndex: 2,
        '&>.MuiPaper-root': {
            marginTop: 16
        }
    },
    rememberWrapper: {
        margin: '20px 0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    formControlWrapper: {
        width: 'auto'
    },
    h5: {
        colors: 'rgba(0, 0, 0, 0.6)',
        marginTop: '25px',
        textAlign: 'center',
    }
}));
