import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles} from '@material-ui/core';
import {useHistory, useLocation} from 'react-router-dom';
import {ProductType} from '../../types/enum';
import FormLayout from '../../layouts/FormLayout';
import QRCode from 'qrcode.react';
import {LINKS} from '../../constants';
import CustomButton from '../../components/ui/Buttons/Button';

// eslint-disable-next-line complexity
const ProductDetailsPage: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    // @ts-ignore
    const {type, data}: {type: ProductType, data: any} = location.state;
    return (
        <FormLayout
            crumbsDetails={[
                {message: `${type === ProductType.product ? 'Products' : 'Consignments'}`, link: `${type === ProductType.product ? '/products' : '/consignments'}`},
                {message: data?.name, link: ''}
            ]}
            title={data?.name}
        >
            <Box className={classes.imgWrapper}>
                {
                    (data?.photoFilePaths && data.photoFilePaths.length > 0) &&
                    data.photoFilePaths.map((img, index) =>
                        <img key={index}
                            src={`${process.env.REACT_APP_BASE_URL}${img}`}
                            alt={index}
                            className={classes.imgProduct}
                        />)
                }
            </Box>
            <ul className={classes.listOptions}>
                {Boolean(data?.balance) && <li><span>Quantity:</span> {data.balance}</li>}
                {Boolean(data?.brand?.name) && type === ProductType.product && <li><span>Brand:</span> {data.brand.name}</li>}
                {Boolean(data?.serial) && <li><span>Serial number:</span> {data.serial}</li>}
                {data?.attributes && data?.attributes?.length > 0 && data?.attributes?.map((item, key) => (
                    <li key={key}><span>{item?.template?.title}:</span> {item?.value}</li>
                ))}
                {Boolean(data?.description) && <li><span>Description:</span> {data.description}</li>}
            </ul>
            <CustomButton
                variant={'contained'}
                className={classes.button}
                onClick={() => history.goBack()}
            >Close</CustomButton>
        </FormLayout>
    );
};

export default ProductDetailsPage;

const useStyles = makeStyles<Theme>(() => ({
    imgWrapper: {
        margin: '30px 0',
        display: 'flex',
        overflowX: 'auto',
        paddingBottom: 10,
        // '&::-webkit-scrollbar': {
        //     display: 'none',
        //     '-ms-overflow-style': 'none',
        //     scrollbarWidth: 'none',
        // },
        '&>*': {
            margin: '0 15px',
            width: 'auto'
        }
    },
    imgProduct: {
        maxWidth: 272,
        marginRight: 15,
        height: 200,
        objectFit: 'cover',

    },
    listOptions: {
        width: 'auto',
        '& li': {
            paddingBottom: 10
        },
        '& span': {
            color: 'rgba(0, 0, 0, 0.6)'
        }
    },
    button: {
        alignSelf: 'flex-start',
        marginTop: 12
    }
}));
