import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {FormHelperText, FormHelperTextProps, makeStyles, withStyles} from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {FieldMetaProps} from 'formik';

export type CustomHelperTextProps = FormHelperTextProps & {
    meta?: FieldMetaProps<any>;
    message?: Node;
}

const CustomHelperText: React.FC<CustomHelperTextProps> = (props: CustomHelperTextProps): JSX.Element => {
    const classes = useStyles();
    const {meta, message, ...rest} = props;
    return (
        <>
            {meta.error && meta.touched
                ? <MuiFormHelperText
                    error={meta.error && meta.touched}
                    {...rest}
                ><ErrorOutlineIcon className={classes.ErrorOutlineIcon}/>{meta.error}</MuiFormHelperText>
                : !!message &&
                <MuiFormHelperText
                    {...rest}
                >{message}</MuiFormHelperText>
            }
        </>
    );
};

export default CustomHelperText;

export const MuiFormHelperText = withStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 12,
        textTransform: 'none',
        letterSpacing: 0,
        lineHeight: 1.4,
        marginLeft: 16,
        marginTop: 0
    },
})(FormHelperText)

const useStyles = makeStyles<Theme>(theme => ({
    ErrorOutlineIcon: {
        fontSize: 14,
        marginRight: 2
    },
}));
