import React, {useMemo, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';
import {useLocation, RouteComponentProps} from 'react-router-dom';
import clsx from 'clsx';
import CustomButton from '../../../components/ui/Buttons/Button';
import {COLORS} from '../../../constants/colors';
import ModalDownloadInvoice from '../../../components/ui/Dialogs/DialogDownloadInvoice';
import {CONTACTS_DATA} from '../../../constants';
import {useGlobalContext} from '../../../context/globalContext';


const OnloadInvoice: React.FC = () => {
    const classes = useStyles();
    const location = useLocation();
    const {userData} = useGlobalContext();
    // @ts-ignore
    const {data, comment} = location?.state;
    const invoiceDate = useMemo(() => {
        const newArr = data.createdAt.split('T')[0].split('-');
        return `${newArr[1]}.${newArr[2]}.${newArr[0]}`;
    }, [data.createdAt]);
    const [open, setOpen] = useState(false);
    const handleSubmit = () => {
        setOpen(true);
    };
    if (!data) {
        return null;
    }
    return (
        <div className={classes.onloadInvoice}>
            <div className={classes.idDate}>
                <Typography variant={'h4'} className={classes.invoiceID}>Invoice # {data.invoiceId}</Typography>
                <Typography variant={'h5'}>Date: {invoiceDate}</Typography>
            </div>
            <div className={classes.invoiceInfo}>
                <div className={classes.invoiceSection}>
                    <Typography variant={'h4'} className={classes.title}>Company</Typography>
                    <Typography variant={'h5'}>{CONTACTS_DATA.TITLE}</Typography>
                    <Typography className={classes.info}>Street Address: {CONTACTS_DATA.STREET_ADDRESS}</Typography>
                    <Typography className={classes.info}>City, State: {CONTACTS_DATA.CITY_STATE}</Typography>
                    <Typography className={classes.info}>ZIP Code: {CONTACTS_DATA.ZIP_CODE}</Typography>
                    <Typography className={classes.info}>Phone: {CONTACTS_DATA.PHONE}</Typography>
                    <Typography className={classes.info}>E-mail: {CONTACTS_DATA.MAIL}</Typography>
                </div>
                <div className={classes.invoiceSection}>
                    <Typography variant={'h4'} className={classes.title}>Client</Typography>
                    <Typography variant={'h5'}>Brand Protect User</Typography>
                    <Typography className={classes.info}>Email: {data.clientEmail}</Typography>
                    <Typography className={classes.info}>Name: {userData?.fullName || data.clientName}</Typography>
                    {Boolean(userData?.streetAddress) && <Typography className={classes.info}>Street address: {userData?.streetAddress}</Typography>}
                    {Boolean(userData?.cityState) && <Typography className={classes.info}>City: {userData?.cityState}</Typography>}
                    {Boolean(userData?.zipCode) && <Typography className={classes.info}>ZIP Code: {userData?.zipCode}</Typography>}
                </div>
                {comment &&
                <div className={clsx(
                    classes.invoiceSection,
                    classes.bottomSection
                )}>
                    <Typography variant={'h4'} className={classes.title}>Comment</Typography>
                    <Typography className={classes.info}>{comment}</Typography>
                </div>
                }
                <div className={clsx(
                    classes.invoiceSection,
                    classes.amount,
                    classes.bottomSection,
                    {[classes.fullSection]: !comment}
                )}>
                    <Typography variant={'h4'} className={classes.title}>Amount</Typography>
                    <Typography variant={'h4'} color={'primary'}>$ {data.total.split(' ')[0]}</Typography>
                </div>
                <CustomButton
                    onClick={handleSubmit}
                    variant={'contained'}
                    className={classes.submitButton}
                >
                    Confirm
                </CustomButton>
            </div>
            {open &&
            <ModalDownloadInvoice
                invoiceId={data.invoiceId}
                open={open}
                onClose={() => setOpen(false)}
                file={data?.pdfFilePath}
            />
            }
        </div>
    );
}
export default OnloadInvoice

const useStyles = makeStyles({
    onloadInvoice: {
        paddingTop: 20,
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    idDate: {
        height: 65,
        background: 'rgba(39, 110, 249, 0.08)',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 50px',
        '&>*': {
            alignSelf: 'center'
        }
    },
    invoiceID: {
        color: COLORS.PRIMARY
    },
    invoiceInfo: {
        flex: 1,
        display: 'flex',
        flexWrap: 'wrap',
        padding: '20px 25px',
        overflowY: 'scroll',
        '&:nth-child(1,2)': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
        }
    },
    invoiceSection: {
        width: '50%',
        padding: '20px 25px'
    },
    amount: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    title: {
        marginBottom: 8
    },
    info: {
        fontSize: 14,
        color: 'black',
        lineHeight: 2
    },
    fullSection: {
        width: '100%'
    },
    bottomSection: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)'
    },
    submitButton: {
        margin: '0 25px',
        alignSelf: 'flex-start'
    }
});
