import React, {useState} from 'react';
import {Theme, withStyles} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu, {MenuProps} from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {makeStyles} from "@material-ui/core";
import {ReactSVG} from "react-svg";
import CustomButton from "../../../../components/ui/Buttons/Button";
import ShareIcon from '../../../../assets/icons/share.svg';
import QrCodeIcon from '../../../../assets/icons/qr_codeShare.svg';
import DownloadIcon from '../../../../assets/icons/download.svg';
import SendShareIcon from '../../../../assets/icons/sendShare.svg';
import DialogSendToEmail from "../../../../components/ui/Dialogs/DialogSendToEmail";
import clsx from "clsx";

const StyledMenu = withStyles({
    paper: {
        boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.14), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 5px 5px rgba(0, 0, 0, 0.2)',
        borderRadius: 4,
        '& > .MuiList-root': {
            padding: '16px 0'
        }
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        padding: '7px 24px',
        '& > .MuiListItemIcon-root': {
            minWidth: 45
        },
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export default function CustomShare() {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [openDialogSendToEmail, setOpenDialogSendToEmail] = useState<boolean>(false);


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <CustomButton
                variant={'contained'}
                color={'primary'}
                onClick={handleClick}
                className={clsx(classes.btnInvoice, {[classes.btnInvoiceOpen]: anchorEl})}
            ><ReactSVG src={ShareIcon}/><span>Share</span></CustomButton>
            <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <StyledMenuItem onClick={handleClose}>
                    <ListItemIcon>
                        <ReactSVG src={QrCodeIcon} className={classes.svgIcon}/>
                    </ListItemIcon>
                    <ListItemText primary="Download QR-code"/>
                </StyledMenuItem>
                <a
                    href={`${process.env.REACT_APP_BASE_URL}${'/uploads/1643020525584_7loe5N.pdf'}`}
                    target="_blank"
                    download
                    rel="noreferrer"
                >
                    <StyledMenuItem onClick={handleClose}>
                        <ListItemIcon>
                            <ReactSVG src={DownloadIcon} className={classes.svgIcon}/>
                        </ListItemIcon>
                        <ListItemText primary="Download PDF"/>
                    </StyledMenuItem>
                </a>
                <StyledMenuItem onClick={() => {
                    handleClose();
                    setOpenDialogSendToEmail(true);
                }}>
                    <ListItemIcon>
                        <ReactSVG src={SendShareIcon} className={classes.svgIcon}/>
                    </ListItemIcon>
                    <ListItemText primary="Send to email"/>
                </StyledMenuItem>
            </StyledMenu>
            {openDialogSendToEmail && <DialogSendToEmail
                open={openDialogSendToEmail}
                title={'Send to email'}
                name={'548-66AA'}
                onClose={() => setOpenDialogSendToEmail(false)}
                action={() => {
                    console.log('d')
                }}
            />
            }
        </div>
    );
}

const useStyles = makeStyles<Theme>(() => ({
    btnInvoice: {
        color: 'rgba(0, 0, 0, 0.6)',
        background: '#FFFFFF',
        boxShadow: 'none',
        height: 56,
        padding: '0 16px',
        marginLeft: -16,
        '& > span': {
            lineHeight: '24px'
        },
        '& > span > div > div > svg': {
            display: 'block',
            marginRight: 16
        },
        '&:hover': {
            background: 'rgba(39, 110, 249, 0.1)',
            boxShadow: 'none'
        },
        '&:active': {
            background: 'rgba(39, 110, 249, 0.18)',
            boxShadow: 'none'
        },
    },
    btnInvoiceOpen: {
        color: '#276EF9',
        background: '#EEF4FF',
        '& > span > div > div > svg > path': {
            fill: '#276EF9',
            fillOpacity: 1
        },
    },
    svgIcon: {
        '& > div > svg': {
            display: 'block'
        }
    },
}));
