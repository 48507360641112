import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, IconButton, makeStyles, Paper, Typography} from '@material-ui/core';
import {useGlobalContext} from '../../context/globalContext';
import {useUIContext} from '../../context/UIContext';
import {firstLetterUpperCase} from './helpers';
import {UserType} from '../../generated/graphql';
import CustomPaper from '../../components/ui/Paper';
import AccountPhoto from '../../components/ui/AccountPhoto';
import {COLORS} from '../../constants/colors';
import InvoiceSettingsForm from '../../components/forms/InvoiceSettingsForm';
import PrivacySettingsForm from '../../components/forms/PrivacySettingsForm';
import SessionList from './components/SessionList';

const SettingsPage: React.FC = (props): JSX.Element => {
    const classes = useStyles();
    const {userData} = useGlobalContext();
    const {showSnackBar} = useUIContext();
    const [editName, setEditName] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('111');
    const [showPassword, setShowPassword] = useState(false);
    const [enableAuthentication, setEnableAuthentication] = useState(false);
    const [code, setCode] = useState('Z7D2RAINQ2BYVHWW');
    const handleCopyToClipboard = (address) => {
        navigator.clipboard.writeText(address).then(() => {
            showSnackBar('Copy to clipboard')
        });
    };
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleCheckPass = (password) => {
        if (password === oldPassword) {
            setEnableAuthentication(true);
            setDialogOpen(false);
        } else {
            setDialogOpen(false);
        }
    };
    // @ts-ignore
    return (
        <CustomPaper className={classes.container}>
            <Typography variant={'h3'} className={classes.title}>General Settings</Typography>
            <Box className={classes.generalSettings}>
                <AccountPhoto />
                <Box className={classes.generalInfo}>
                    <Typography variant={'subtitle1'} className={classes.type}>
                        {firstLetterUpperCase(userData?.type)}
                    </Typography>
                    <Typography variant={'h5'} className={classes.address}>
                        {userData?.blockchainAddress}
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => handleCopyToClipboard(userData?.blockchainAddress)}
                            edge="end"
                            classes={{root: classes.IconButtonCopy}}
                        >
                            <i className={'material-icons'}>content_copy</i>
                        </IconButton>
                    </Typography>
                </Box>
            </Box>
            {/*// @ts-ignore*/}
            {/*{(userData.type === UserType.Manufacturer) &&*/}
            <InvoiceSettingsForm />
            {/*}*/}
            <PrivacySettingsForm />
            <Typography variant={'h5'} className={classes.sessionTitle}>Active sessions</Typography>
            <Typography variant={'subtitle1'} className={classes.subtitle}>
                Active sessions shows information about which devices and at what time you entered the site.
                If you suspect that someone has accessed your profile, you can end this session at any time.
            </Typography>
            <SessionList />

            {/*<Box className={'factor-authentification'}>*/}
            {/*    <Box>*/}
            {/*        <h4 className={'settings-subtitle'}>2-factor authentification</h4>*/}
            {/*        <p className={'factor-authentication-text'}>Protect your account with 2-factor authentication.<br/> This service is currently disabled. To connect, click the "Enable" button</p>*/}
            {/*    </Box>*/}
            {/*    <MyButton*/}
            {/*        type={'submit'}*/}
            {/*        variant={'outlined'}*/}
            {/*        className={classes.apllyButton}*/}
            {/*        onClick={() => setDialogOpen(true)}*/}
            {/*    >{ enableAuthentication ? 'Disable': 'Enable'}</MyButton>*/}
            {/*</Box>*/}
            {/*{enableAuthentication ?*/}
            {/*    <Box className={'enableAuthentication'}>*/}
            {/*        <img src={require('../../img/QRCode.png')} />*/}
            {/*        <Box className={'enableAuthentication-text'}>*/}
            {/*            <span className={'settings-subtitle'}>Scan the QR-code or copy and paste the key <br/>into the Google Authenticator app to connect <br/>two-factor authentication</span>*/}
            {/*            <Box className={'enableAuthentication-code'}>*/}
            {/*                {`Key:   ${code}`}*/}
            {/*                <IconButton*/}
            {/*                    aria-label="toggle password visibility"*/}
            {/*                    onClick={() => handleCopyToClipboard(code)}*/}
            {/*                    edge="end"*/}
            {/*                    classes={{root: classes.IconButtonCopy}}*/}
            {/*                >*/}
            {/*                    <i className={`material-icons `}>content_copy</i>*/}
            {/*                </IconButton>*/}
            {/*            </Box>*/}
            {/*        </Box>*/}

            {/*    </Box>*/}
            {/*    : null*/}
            {/*}*/}
            {/*<MyButton*/}
            {/*    type={'submit'}*/}
            {/*    variant={'error'}*/}
            {/*    className={classes.deleteBtn}*/}
            {/*>Delete account</MyButton>*/}
            {/*<MyDialog*/}
            {/*    open={dialogOpen}*/}
            {/*    onCloseDialog={() => setDialogOpen(false)}*/}
            {/*    title={'Enter your password'}*/}
            {/*    subTitle={'Confirm the enabling of two-factor authentication by entering the password'}*/}
            {/*    width={'70%'}*/}
            {/*    renderContent={() => (*/}
            {/*        <OutlinedInput*/}
            {/*            value={password}*/}
            {/*            onChange={(e) => setPassword(e.target.value)}*/}
            {/*            autoFocus*/}
            {/*            fullWidth*/}
            {/*            style={{marginTop: '25px'}}*/}
            {/*            type={showPassword ? 'text' : 'password'}*/}
            {/*            endAdornment={*/}
            {/*                <InputAdornment position="end">*/}
            {/*                    <IconButton*/}
            {/*                        aria-label="toggle password visibility"*/}
            {/*                        onClick={handleClickShowPassword}*/}
            {/*                        onMouseDown={handleMouseDownPassword}*/}
            {/*                        edge="end"*/}
            {/*                    >*/}
            {/*                        {showPassword ? <Visibility /> : <VisibilityOff />}*/}
            {/*                    </IconButton>*/}
            {/*                </InputAdornment>*/}
            {/*            }*/}
            {/*        />*/}
            {/*    )}*/}
            {/*    renderActions={() => (*/}
            {/*        <>*/}
            {/*            <MyButton onClick={() => setDialogOpen(false)} variant={'text'} color={'primary'}>*/}
            {/*                Cancel*/}
            {/*            </MyButton>*/}
            {/*            <MyButton onClick={() => handleCheckPass(password)} variant={'contained'} color={'primary'}>*/}
            {/*                Confirm*/}
            {/*            </MyButton>*/}
            {/*        </>*/}
            {/*    )}*/}
            {/*/>*/}
        </CustomPaper>
    );
};

export default SettingsPage;

const useStyles = makeStyles<Theme>(() => ({
    container: {
        width: '100%',
        minHeight: '100%',
        overflowY: 'auto',
        padding: '32px 50px',
    },
    generalSettings: {
        padding:  '45px 0',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        display: 'grid',
        gridTemplateColumns: '141px 1fr',
    },
    generalInfo: {
        alignSelf: 'center',
        marginLeft: 30,
    },
    name: {

    },
    type: {
        color: COLORS.TEXT_LIGHT_SILVER
    },
    address: {

    },
    IconButtonCopy: {
        marginLeft: 16
    },
    subtitle: {
        color: COLORS.TEXT_SILVER,
        width: 650,
    },
    sessionTitle: {

        marginBottom: 8
    }
}));

//     .AvatarWrapper {
//             position: relative;
//             width: 141px;
//             height: 141px;
//             border-radius: 50%;
//             overflow: hidden;
//         .Avatar {
//                 width: 100%;
//                 height: 100%;
//                 position: relative;
//
//             }
//         &:hover .AvatarDropzone {
//                 transform: translate(0, -50px);
//                 -webkit-transition: all .2s ease-out;
//                 -moz-transition: all .2s ease-out;
//                 -o-transition: all .2s ease-out;
//                 transition: all .2s ease-out;
//             }
//         .AvatarDropzone {
//                 height: 50px;
//                 width: 100%;
//                 background-color: rgba(0, 0, 0, 0.38);
//                 position: absolute;
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 -webkit-transition: all .2s ease-out;
//                 -moz-transition: all .2s ease-out;
//                 -o-transition: all .2s ease-out;
//                 transition: all .2s ease-out;
//                 cursor: pointer;
//                 outline: none;
//             .AvatarDropzoneInput {
//                     width: 100%;
//                     height: 100%;
//                 }
//             }
//         }
//     .general-settings-info {
//             height: 100%;
//             width: 100%;
//             align-self: center;
//             margin-left: 30px;
//         .type {
//                 font-size: 14px;
//                 line-height: 24px;
//                 color: rgba(0, 0, 0, 0.38);
//             }
//         .name {
//                 font-weight: normal;
//                 font-size: 24px;
//                 line-height: 48px;
//                 color: rgba(0, 0, 0, 0.87);
//             .input-name {
//                     border: none;
//                     outline: none;
//                     font-weight: normal;
//                     font-size: 24px;
//                     line-height: 48px;
//                     height: 49.66px;
//                     color: rgba(0, 0, 0, 0.87);
//                 }
//             }
//         }
//     .general-settings-address {
//
//         }
//     }
// .invoice-settings {
//         padding: 48px 0;
//         width: 100%;
//         border-bottom: 1px solid rgba(0, 0, 0, 0.12);
//     .invoiceSettingsForm {
//             display: flex;
//             flex-flow: column;
//             padding: 48px 0 0 0;
//         .form-row {
//                 max-width: 50%;
//                 min-width: 40%;
//             &>* {
//                     margin-bottom: 30px;
//                 }
//             .card-wrapper-input {
//                     display: flex;
//                     grid-gap: 15px;
//                     grid-template-columns: .9fr .6fr;
//                 }
//             }
//         }
//     }
// .privacy-settings {
//         padding: 48px 0;
//     .privacySettingsForm {
//             padding: 40px 0 0 0;
//
//         }
//     .form-row {
//             padding: 0 0 32px 0;
//             width: 50%;
//         &>div:nth-child(1) {
//                 margin-top: 32px;
//             }
//         &>div:nth-child(2) {
//                 margin-top: 32px;
//             }
//         &>div:nth-child(4) {
//                 margin-top: 32px;
//             }
//         }
//
//
//
//     }
// .factor-authentification {
//         width: 50%;
//         display: flex;
//         justify-content: space-between;
//     .factor-authentication-text {
//             font-weight: normal;
//             font-size: 14px;
//             line-height: 24px;
//             color: rgba(0, 0, 0, 0.6);
//             padding-top: 15px;
//         }
//     }
// .enableAuthentication {
//         margin-top: 20px;
//         position: relative;
//         display: flex;
//     .enableAuthentication-text {
//             padding-left: 32px;
//             align-self: center;
//         .settings-subtitle {
//                 font-size: 14px;
//                 line-height: 24px;
//                 color: rgba(0, 0, 0, 0.6);
//             }
//         .enableAuthentication-code {
//                 display: flex;
//                 align-items: center;
//             }
//         }
//
//     }
// }
