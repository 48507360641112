import React, {useRef, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, TooltipProps, Typography, TypographyProps} from '@material-ui/core';
import MuiTableCell, {MuiTableCellProps} from './TableCell';
import MuiTooltip from '../Tooltip';

type LinkTooltipCellProps = MuiTableCellProps & {
    label: string | number;
    value: string | number;
    tooltipProps?: TooltipProps;
    typographyProps?: TypographyProps;
    href: string
}

const LinkTooltipCell: React.FC<LinkTooltipCellProps> = (props: LinkTooltipCellProps): JSX.Element => {
    const classes = useStyles();
    const {label, value, tooltipProps, typographyProps, href, ...rest} = props;
    const [ref, setRef] = useState(null);
    return (
        <MuiTableCell
            {...rest}
        >
            <MuiTooltip
                title={ref?.scrollWidth > ref?.offsetWidth ? label : ''}
                {...tooltipProps}
            >
                <Typography
                    ref={el => setRef(el)}
                    className={classes.value}
                    variant={'subtitle1'}
                    {...typographyProps}
                >
                    <a
                        href={href}
                        target={'_blank'}
                        rel="noreferrer"
                    >{value}</a>
                </Typography>
            </MuiTooltip>
        </MuiTableCell>
    );
};

export default LinkTooltipCell;

const useStyles = makeStyles<Theme>(() => ({
    value: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    }
}));
