import React, {useCallback, useMemo, useState, useEffect} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, Chip, ClickAwayListener, Fade, makeStyles, Popper, Typography} from '@material-ui/core';
import CustomButton from '../components/ui/Buttons/Button';
import {COLORS} from '../constants/colors';
import {useHistory, useLocation} from 'react-router-dom';
import {useGlobalContext} from '../context/globalContext';
import {UserType} from '../generated/graphql';
import {FILTERS} from '../constants';
import clsx from 'clsx';
import {useUIContext} from '../context/UIContext';
import UploadFeed from '../assets/icons/upload-feed.svg';
import DialogInvoiceData from "../components/ui/Dialogs/DialogInvoiceData";

const Header: React.FC = (props): JSX.Element => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const {userData, filter, setFilter} = useGlobalContext();
    const {setUploadFeed, setOpenCreateInvoice} = useUIContext();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openDialogInvoiceData, setOpenDialogInvoiceData] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const onCreateBtnHandler = useCallback(
        () => {
            const path = location.pathname.split('/', 2);
            if (path?.[1] === 'invoices') {
                if (
                    userData?.fullName &&
                    userData?.streetAddress &&
                    userData?.cityState &&
                    userData?.zipCode
                    // userData?.phoneNumber &&
                    // userData?.email
                ) {
                    setOpenCreateInvoice(true);
                    return;
                }
                setOpenDialogInvoiceData(true);
                return;
            }
            history.push(`/${path[1]}/create`);
        },
        [location.pathname, userData],
    );
    const headerUI = useMemo(() => {
        const path = location.pathname.split('/', 2);
        return displayHeaderElements(path[1]);
    }, [location.pathname]);
    const clickHandler = () => (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };
    return (
        <Box className={classes.header}>
            <Box className={classes.buttonsWrapper}>
                {headerUI?.showFilter && <CustomButton
                    variant={'outlined'}
                    color={'primary'}
                    startIcon={<i className={'material-icons'} style={{marginRight: 5}}>filter_list</i>}
                    onClick={(e) => clickHandler()(e)}
                >Filter</CustomButton>}
                {open ? <Popper id={'Popper'} open={open} anchorEl={anchorEl} placement={'bottom-start'} transition>
                    {({TransitionProps}) => (
                        <Fade {...TransitionProps} timeout={350}>
                            <ClickAwayListener onClickAway={() => setOpen(false)}>
                                <div className={classes.popper}>
                                    <Typography variant={'subtitle2'} className={classes.popperText}>Store
                                        type:</Typography>
                                    <div className={classes.chipWrapper}>
                                        <Chip
                                            clickable label={'All types'}
                                            onClick={() => setFilter(FILTERS.STORES.ALL)}
                                            className={
                                                clsx(
                                                    classes.chip,
                                                    {[classes.activeChip]: filter === FILTERS.STORES.ALL},
                                                    {[classes.disabledChip]: filter !== FILTERS.STORES.ALL}
                                                )
                                            }
                                        />
                                        <Chip
                                            clickable
                                            label={'My stores'}
                                            onClick={() => setFilter(FILTERS.STORES.MY_STORES)}
                                            className={
                                                clsx(
                                                    classes.chip,
                                                    {[classes.activeChip]: filter === FILTERS.STORES.MY_STORES},
                                                    {[classes.disabledChip]: filter !== FILTERS.STORES.MY_STORES}
                                                )
                                            }
                                        />
                                        <Chip
                                            clickable
                                            label={'Store contacts'}
                                            onClick={() => setFilter(FILTERS.STORES.STORE_CONTACTS)}
                                            className={clsx(
                                                classes.chip,
                                                {[classes.activeChip]: filter === FILTERS.STORES.STORE_CONTACTS},
                                                {[classes.disabledChip]: filter !== FILTERS.STORES.STORE_CONTACTS}
                                            )}
                                        />
                                    </div>
                                </div>
                            </ClickAwayListener>
                        </Fade>
                    )}
                </Popper> : null}
                {
                    userData?.type !== UserType.Store && headerUI?.showUploadFeed ? <CustomButton
                        variant={'outlined'}
                        color={'primary'}
                        startIcon={<img
                            src={UploadFeed}
                            alt="uploadFeed"
                            style={{marginRight: 5}}/>}
                        onClick={() => setUploadFeed(true)}
                    >Upload feed</CustomButton> : null
                }
                {(headerUI?.createButton && userData?.type !== UserType.Store) &&
                <CustomButton
                    variant={'contained'}
                    color={'primary'}
                    onClick={onCreateBtnHandler}
                >{headerUI?.createButton}</CustomButton>}
            </Box>
            {openDialogInvoiceData && <DialogInvoiceData
                open={openDialogInvoiceData}
                setOpenDialogInvoiceData={setOpenDialogInvoiceData}
                title={'Fill invoice data'}
                onClose={() => setOpenDialogInvoiceData(false)}
            />}
            {/*<div ref={ref} className={classes.inView}/>*/}
        </Box>
    );
};

const displayHeaderElements = (pathname: string) => {
    switch (pathname) {
        case 'brands': {
            return {
                showUploadFeed: true,
                showFilter: false,
                createButton: 'Create Brand'
            }
        }
        case 'products': {
            return {
                showUploadFeed: true,
                showFilter: false,
                createButton: 'Create Product'
            }
        }
        case 'stores': {
            return {
                showUploadFeed: true,
                showFilter: true,
                createButton: 'Add store'
            }
        }
        case 'consignments': {
            return {
                showUploadFeed: true,
                showFilter: false,
                createButton: 'Create Consignment'
            }
        }
        case 'invoices': {
            return {
                showUploadFeed: false,
                showFilter: false,
                createButton: 'Create Invoice'
            }
        }
        default: {
            return {
                showUploadFeed: true,
                showFilter: false,
            }
        }
    }
}

export default Header;

const useStyles = makeStyles<Theme>(() => ({
    header: {
        position: 'relative',
        // width: calc(100vw - 250px);
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gridArea: 'header',
        background: COLORS.WHITE,
        padding: '0 30px',
        // boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.1)',
        boxShadow: '30px 4px 14px 0px rgba(0, 0, 0, 0.1)',
        // '-webkit-box-shadow': '30px 4px 14px 0px rgba(0, 0, 0, 0.1)',
    },
    buttonsWrapper: {
        marginLeft: 'auto',
        '&>*': {
            marginLeft: 24
        }
    },
    popper: {
        backgroundColor: 'white',
        padding: '24px 24px 32px 24px',
        zIndex: 9999,
        boxShadow: '0px 18px 67px rgba(8, 42, 92, 0.29)'
    },
    disabledChip: {
        border: '1px solid rgba(0, 0, 0, 0.12)'
    },
    chip: {
        margin: '0 5px 0 5px'
    },
    activeChip: {
        backgroundColor: 'rgba(39, 110, 249, 0.12)',
        border: '1px solid #276EF9',
        '&:focus': {
            backgroundColor: 'rgba(39, 110, 249, 0.12)',
            border: '1px solid #276EF9'
        }
    },
    popperText: {
        marginBottom: 16
    },
    chipWrapper: {
        justifyContent: 'space-between'
    },
    DialogFormatText: {
        color: 'rgba(0, 0, 0, 0.6)',
        fontWeight: 400,
        fontSize: 12,
        margin: '16px 0'
    },
    inView: {
        height: 20,
        width: 100,
        position: 'absolute',
        zIndex: 0,
        backgroundColor: 'red',
        bottom: -20
    }
}));
