import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, Paper, PaperProps, withStyles} from '@material-ui/core';
import {COLORS} from '../../constants/colors';

const CustomPaper: React.FC<PaperProps> = (props: PaperProps): JSX.Element => {
    return (
        <MyiPaper
            elevation={0}
            {...props}
        >
            {props.children}
        </MyiPaper>
    );
};

export default CustomPaper;

const MyiPaper = withStyles(theme => ({
    root: {
        borderRadius: 8,
        backgroundColor: COLORS.WHITE,
    }
}))(Paper)
