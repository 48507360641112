
const COLORS = {
    WHITE: '#FFFFFF',
    TEXT_MAIN: 'rgba(0, 0, 0, 0.87)',
    TEXT_SILVER: 'rgba(0,0,0,0.6)',
    TEXT_LIGHT_SILVER: 'rgba(0, 0, 0, 0.38)',
    TOOLTIP_BACKGROUND: '#21264C',
    BLACK: '#000000',
    ERROR_PRIMARY: '#DC3030',

    ERROR_LIGHT: '#fff8f8',
    ERROR_BACKGROUND: '#FAE6E6',
    PRIMARY: '#276EF9',
    PRIMARY_BACKGROUND: '#E6EEFF',
    PRIMARY_LIGHT: 'rgba(39, 110, 249, 0.04)',
    PRIMARY_ACTIVE_BACK: 'rgba(39, 110, 249, 0.04)',
    SUPPORT_BACKGROUND: '#F1F3F9',
    ROW_BACKGROUND: '#EEF3FF',
    SUCCESS: '#259D58',
    SUCCESS_ACTION: '#38C172',
    SUCCESS_LIGHT: '#E3FCEC',
    WARNING: '#F2994A',
    BORDER_SILVER: 'rgba(0, 0, 0, 0.12)',
}

export {COLORS}
