import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/core';
import SuccessImg from './images/brand-success-create.svg';
import {useGlobalContext} from '../../context/globalContext';
import CustomButton from '../../components/ui/Buttons/Button';
import {useHistory} from 'react-router-dom';
import CustomPaper from '../../components/ui/Paper';


const BrandSuccessCreate: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    return (
        <CustomPaper className={classes.createSuccessPage}>
            <img src={SuccessImg} alt="failed" className={classes.img}/>
            <h1 className={classes.title}>Brand successfully created</h1>
            <h2 className={classes.subtitle}>
                <p>Your documents have been sent for verification. This can take</p>
                <p>from several hours to a day. After the end of the check, you</p>
                <p>will receive a notification by mail.</p>
            </h2>
            <CustomButton
                variant={'contained'}
                color={'primary'}
                onClick={() => history.push('/brands')}
            >Back to brands</CustomButton>
        </CustomPaper>
    );
};

export default BrandSuccessCreate;

const useStyles = makeStyles<Theme>(() => ({
    createSuccessPage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'white',
        height: '100%',
        width: '100%'
    },
    img: {
        alignSelf: 'center',
        marginBottom: 10

    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.87)',
        marginBottom: 8
    },
    subtitle: {
        fontSize: 14,
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.6)',
        fontWeight: 'normal',
        marginBottom: 20,
        '&>p': {
            marginBottom: 5
        }
    }
}));
