import React, {useMemo, useState, useEffect} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, IconButton, makeStyles, Paper, Typography} from '@material-ui/core';

import {useGlobalContext} from '../../context/globalContext';
import CustomButton from '../../components/ui/Buttons/Button';
import {useHistory} from 'react-router-dom';
import {COLORS} from '../../constants/colors';
import DialogLayout from '../../layouts/DialogLayout';
import {DialogType} from './helpers/type';
import {MESSAGES} from '../../constants/messages';
import {useUIContext} from '../../context/UIContext';
import EmptyPage, {EmptyPageType} from '../../components/EmptyPage';
import {
    useDeleteContactMutation,
    useDeleteStoreMutation,
    useGetStoresQuery,
    useLoginAsStoreMutation
} from '../../generated/graphql';
import FailedToLoad from '../../components/FailedToLoad';
import StoreCard from './components/StoreCard';
import {StoreType} from '../../types/enum';
import {FILTERS, LOCAL_STORAGE_KEYS} from '../../constants';
import {useInView} from 'react-intersection-observer';
import LoadingLayout from '../../layouts/LoadingLayout';
import {useLocalStorage} from '../../helpers/hooks';

const StorePage: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const {filter} = useGlobalContext();
    const [storeToken, setStoreToken] = useLocalStorage(LOCAL_STORAGE_KEYS.STORE_TOKEN, null)
    const {showSnackBar} = useUIContext();
    const [skip, setSkip] = useState(0);
    const [data, setData] = useState(null);
    const [dialogData, setDialogData] = useState({
        type: DialogType.deleteStore,
        open: false,
        item: null
    });
    const {data: dataQuery, error, loading, refetch} = useGetStoresQuery({
        variables: {
            // take: STORE_TAKE,
            take: 100,
            skip: skip
        }
    })
    const [loginAsStore] = useLoginAsStoreMutation();
    const [removeContact] = useDeleteContactMutation();
    const [removeStore] = useDeleteStoreMutation();
    useEffect(() => {
        if (dataQuery) {
            //todo fix pagination

            const stores = dataQuery?.whoami?.stores?.data?.map(item => {
                return {
                    ...item,
                    type: StoreType.store
                }
            // }).sort((a, b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime());
            }).sort((a, b) => b?.createdAt?.localeCompare(a?.createdAt));
            const contacts = dataQuery?.whoami?.contacts?.data?.map(item => {
                return {
                    ...item,
                    type: StoreType.contact
                }
            }).sort((a, b) => b?.createdAt?.localeCompare(a?.createdAt));
            if (filter === FILTERS.STORES.STORE_CONTACTS) {
                setData(contacts)
            } else if (filter === FILTERS.STORES.MY_STORES) {
                setData(stores)
            } else {
                setData([...stores, ...contacts].sort((a, b) => b?.createdAt?.localeCompare(a?.createdAt)))
            }
            // setData([...stores, ...contacts])
        }
    }, [dataQuery, filter]);

    const {ref, inView} = useInView({
        trackVisibility: true,
        delay: 1000,
        skip: true
        // skip: checkAllStoreDisplay(filter, data, dataQuery?.whoami?.stores?.total, dataQuery?.whoami?.contacts?.total)
    });
    const fetchMore = () => setSkip(prevState => prevState + 6);
    // useEffect(() => {
    //     if (inView) {
    //         fetchMore();
    //     }
    // }, [inView]);
    const loginStore = async () => {
        try {
            const {data} = await loginAsStore({
                variables: {
                    storeAccountId: dialogData?.item?.target?.id
                }
            })
            if (data?.loginAsStore?.token) {
                setStoreToken(data?.loginAsStore?.token)
                window.location.reload()
            }
        } catch (e) {
            showSnackBar(e?.message)
        }
    }
    const deleteStore = async () => {
        try {
            if (dialogData?.item?.type === StoreType.contact) {
                const {data} = await removeContact({
                    variables: {
                        contactId: dialogData?.item?.id
                    }
                })
                if (data?.contactDelete) {
                    showSnackBar(MESSAGES.CONTACT_DELETE)
                }
            } else {
                const {data} = await removeStore({
                    variables: {
                        storeAccountId: dialogData?.item?.target?.id
                    }
                })
                if (data?.storeDelete) {
                    showSnackBar(MESSAGES.STORE_DELETE)
                }
            }

        } catch (e) {
            showSnackBar(e?.message)
        } finally {
            await refetch()
            onClose()
        }
    }
    const showDialog = (item, type) => {
        console.log('item', item);
        setDialogData(prevState => ({
            ...prevState,
            open: true,
            type,
            item,
        }))
    }
    const onClose = () => {
        setDialogData(prevState => ({
            ...prevState,
            open: false,
        }))
    }
    if (loading) {
        return <LoadingLayout size={30}/>
    }
    if (data && data?.length === 0 && !loading && filter === FILTERS.STORES.ALL) {
        return <EmptyPage type={EmptyPageType?.Store} />
    }
    if (error) {
        return <FailedToLoad refetch={refetch} />
    }
    return (
        <Box className={classes.storePage}>
            {data?.map(item => (
                <StoreCard
                    key={item?.id}
                    item={item}
                    showDialog={showDialog}
                />
            ))}
            {/*<div ref={ref} className={classes.inView}/>*/}
            {dialogData?.open &&
            <DialogLayout
                open={dialogData?.open}
                width={552}
                onClose={onClose}
                hideIcon
            >
                <Box className={classes.wrapper}>
                    <Typography variant={'h4'}>
                        {dialogData?.type === DialogType.deleteStore ? 'Delete?' : 'Go to account?'}
                    </Typography>
                    <Typography variant={'subtitle1'} className={classes.subtitle}>
                        {dialogData?.type === DialogType.deleteStore ? MESSAGES.DELETE_SUBTITLE : MESSAGES.LOGIN_SUBTITLE(dialogData?.item?.name)}
                    </Typography>
                    <Box className={classes.wrapperButton}>
                        <CustomButton onClick={() => onClose()}>Cancel</CustomButton>
                        <CustomButton onClick={dialogData?.type === DialogType.deleteStore ? deleteStore : loginStore}>
                            {dialogData?.type === DialogType.deleteStore ? 'Delete' : 'Confirm'}
                        </CustomButton>
                    </Box>
                </Box>
            </DialogLayout>
            }
        </Box>
    );
};

export default StorePage;

const useStyles = makeStyles<Theme>(() => ({
    storePage: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, minmax(0px, 1fr))',
        gridGap: '25px',

    },
    wrapper: {
        padding: '40px 56px'
    },
    subtitle: {
        color: COLORS.TEXT_SILVER,
        margin: '8px 0 24px 0'
    },
    wrapperButton: {
        width: '50%',
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'space-around'
    },
    inView: {
        gridColumn: '1/4',
        height: 10,
        width: '100%',
        // background: 'red',
        bottom: 0

    }
}));
