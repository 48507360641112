import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import UploadLogo from '../images/upload-logo.svg';
import CustomButton from '../../../components/ui/Buttons/Button';
import {FormikValues, useFormikContext} from 'formik';
import {COLORS} from '../../../constants/colors';

const RenderUploadLogo: React.FC = (open: any): JSX.Element => {
    const classes = useStyles();
    const {values}: FormikValues = useFormikContext();
    return (
        <>
            <Typography variant={'h5'} className={classes.uploadText}>Upload
                brand logo</Typography>
            <Box className={classes.uploadInfo}>
                {values.logo?.length > 0 ?
                    <img
                        src={URL.createObjectURL(values.logo[0])}
                        alt="SelectConfirmFiles"
                        className={classes.previewLogo}/>
                    :
                    <img
                        src={UploadLogo}
                        alt="UploadLogo"
                        className={classes.uploadImg}/>}
                <Typography variant={'h5'} className={classes.uploadLimits}>
                    Format - JPG, PNG, TIFF or BMP. <br/>
                    Size - no more than 5 MB.
                </Typography>
            </Box>
            <CustomButton
                variant={'text'}
                color={'primary'}
                onClick={open}
                className={classes.uploadButton}
            >
                Upload photo
            </CustomButton>
        </>
    );
};

export default RenderUploadLogo;

const useStyles = makeStyles<Theme>(() => ({
    uploadText: {
        color: COLORS.TEXT_SILVER,
        height: '3rem'
    },
    previewLogo: {
        height: 81,
        width: 81,
        borderRadius: 100,
        marginRight: 8,
        objectFit: 'cover'
    },
    uploadInfo: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        marginTop: 12,
    },
    uploadImg: {
        height: 81,
        width: 'auto',
        marginRight: 8

    },
    uploadLimits: {
        fontSize: 12,
        color: COLORS.TEXT_SILVER
    },
    uploadButton: {
        alignSelf: 'flex-start',
        marginTop: 12
    },
}));
