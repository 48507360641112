import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {ButtonProps, CircularProgress, makeStyles, Typography} from '@material-ui/core';
import CustomButton from './Button';
import {useFormikContext} from 'formik';
import MuiCircularProgress from '../CircularProgress';
import {findFieldError} from '../../../helpers';

type SubmitButtonWithErrorsProps = ButtonProps & {

}

const SubmitButtonWithErrors: React.FC<SubmitButtonWithErrorsProps> = (props: SubmitButtonWithErrorsProps): JSX.Element => {
    const classes = useStyles();
    const {...rest} = props;
    const {isSubmitting, touched, errors} = useFormikContext();
    return (
        <div className={classes.container}>
            <CustomButton
                type="submit"
                color={'primary'}
                variant={'contained'}
                disabled={isSubmitting}
                {...rest}
            >{isSubmitting ? <MuiCircularProgress /> : 'Submit'}</CustomButton>
            <div className={classes.errorWrapper}>
                {findFieldError(touched, errors).incorrect &&
                <div className={classes.errorAroundBtn}>
                    <i className={'material-icons'}>info_outline</i>
                    <Typography variant={'subtitle1'}>
                        Check the correctness of filling in the fields
                    </Typography>
                </div>
                }
                {findFieldError(touched, errors).required &&
                <div className={classes.errorAroundBtn}>
                    <i className={'material-icons'}>info_outline</i>
                    <Typography variant={'subtitle1'}>
                        Fill in all required fields
                    </Typography>
                </div>}
            </div>
        </div>
    );
};

export default SubmitButtonWithErrors;

const useStyles = makeStyles<Theme>(() => ({
    container: {
        display: 'flex',
        flexBasis: 40,
        width: '100%',
        justifyContent: 'center',
        marginTop: 20,
    },
    errorWrapper: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        marginRight: 'auto',
    },
    errorAroundBtn: {
        marginLeft: 20,
        display: 'flex',
        flexDirection: 'row',
        '& i': {
            fontSize: 18,
            color: 'rgba(0,0,0,.6)',
            alignSelf: 'center',
            marginRight: 5,
        }
    }
}));
