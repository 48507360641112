import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, Checkbox, FormControlLabel, makeStyles, TextField, Typography} from '@material-ui/core';
import FormLayout from '../../layouts/FormLayout';
import {Formik, Form, Field, FieldProps, FieldArray} from 'formik';
import {CREATE_PRODUCT_SCHEMA} from '../../constants/validation_schema';
import FormInput from '../../components/ui/FormInput';
import {
    ProductAttributeTemplateType,
    useCreateProductsRangeMutation,
    useGetBrandsForCreateQuery
} from '../../generated/graphql';
import {useGlobalContext} from '../../context/globalContext';
import CustomDropzone from '../../components/ui/CustomDropzone';
import SelectConfirmFiles from '../brands/images/select-confirm-files.svg';
import FileChip from '../../components/ui/Chips/FileChip';
import CustomButton from '../../components/ui/Buttons/Button';
import {CREATE_DROPZONE_STYLE} from './helpers/constants';
import {COLORS} from '../../constants/colors';
import SubmitButtonWithErrors from '../../components/ui/Buttons/SubmitButtonWithErrors';
import {useUIContext} from '../../context/UIContext';
import {Autocomplete} from 'formik-material-ui-lab';
import {uploadedFiles} from '../../helpers';
import SuccessLayout from '../../layouts/SuccessLayout';
import {MAX_UPLOAD_FILES, UPLOAD_IMAGE_SIZE} from '../../constants';
import DialogSelectOptionalField from '../../components/ui/Dialogs/DialogSelectOptionalField';
import OptionInputs from './components/OptionInputs';
import {client} from '../../graphql';
import {gql} from '@apollo/client';

type ProductCreateProps = {
    isConsignment?: boolean
}

const ProductCreate: React.FC<ProductCreateProps> = (props: ProductCreateProps): JSX.Element => {
    const classes = useStyles();
    const {userData} = useGlobalContext();
    const {showEndAction} = useUIContext();
    const {data, loading, error} = useGetBrandsForCreateQuery({
        variables: {
            ownerId: userData?.id,
            confirmedOnly: true
        }
    })

    const [open, setOpen] = useState<boolean>(false);
    const [createProducts, {data: createData}] = useCreateProductsRangeMutation();
    if (createData?.productsCreateRange) {
        return <SuccessLayout
            title={'Product successfully created'}
            push={'/products'}
            buttonText={'Back to product'}
        />
    }
    return (
        <FormLayout
            crumbsDetails={[
                {message: 'Products', link: ''},
                {message: 'Create', link: ''}
            ]}
            title={'Create product'}
        >
            <Formik
                initialValues={{
                    showSerialNumber: false,
                    name: '',
                    brandId: '',
                    serial: {
                        from: 0,
                        to: 0,
                    },
                    quantity: 0,
                    photoFilePaths: [],
                    attributes: []
                }}
                validationSchema={CREATE_PRODUCT_SCHEMA}
                onSubmit={async (values) => {
                    try {
                        const {
                            attributes,
                            brandId,
                            quantity,
                            photoFilePaths,
                            showSerialNumber,
                            serial,
                            name,
                            ...rest
                        } = values
                        const files = await Promise.all(uploadedFiles(photoFilePaths));

                        const {data} = await createProducts({
                            variables: {
                                dataProducts: {
                                    name: name,
                                    serial: showSerialNumber ? name : '' ,
                                    quantity: showSerialNumber ? 1 : quantity,
                                    photoFilePaths: files,
                                    attributes: attributes?.map(item => (
                                        item?.type === ProductAttributeTemplateType.Dropdown
                                            ? {templateId: item?.id, value: `${item?.value} ${item?.dimension}`}
                                            : {templateId: item?.id, value: item?.value}
                                    ))
                                },
                                // @ts-ignore
                                brandId: Number(brandId?.id),
                                rangeFrom: showSerialNumber ? serial.from : 1,
                                rangeTo: showSerialNumber ? serial.to : 1
                            }
                        })
                    } catch (e) {
                        console.log(JSON.stringify(e))
                        showEndAction(e?.message)
                    }
                }}
            >
                {({handleSubmit, errors, touched, values, setFieldError}) => {
                    console.log('errors', errors);
                    return (
                        <Form onSubmit={handleSubmit} className={classes.container}>
                            <Field name={'showSerialNumber'}>
                                {({field}) => {
                                    return (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color={'primary'}
                                                    onChange={() => {

                                                        field.onChange()
                                                        setFieldError('quantity', 'wtf')
                                                    }}
                                                    {...field}
                                                />
                                            }
                                            label={'Has unique serial number'}
                                        />
                                    );
                                }}
                            </Field>
                            <Box className={classes.formContainer}>
                                <FormInput
                                    name={'name'}
                                    label={'Product name'}
                                    labelWidth={105}
                                    formControlProps={{
                                        className: classes.nameInput
                                    }}
                                />
                                <Field
                                    name={'brandId'}
                                    component={Autocomplete}
                                    options={data?.brands?.data || []}
                                    getOptionLabel={(option) => option.name}
                                    className={classes.brandInput}
                                    renderInput={(params) =>
                                        <TextField
                                            error={errors.brandId && touched.brandId}
                                            label={'Brand name'}
                                            variant="outlined"
                                            {...params}
                                        />
                                    }
                                />
                                {values.showSerialNumber ?
                                    <>
                                        <FormInput
                                            name={'serial.from'}
                                            label={'Serial number from'}
                                            labelWidth={140}
                                            formControlProps={{
                                                className: classes.serialFirstInput
                                            }}
                                            inputProps={{
                                                type: 'number',
                                            }}
                                        />
                                        <FormInput
                                            name={'serial.to'}
                                            label={'Serial number to'}
                                            labelWidth={130}
                                            formControlProps={{
                                                className: classes.serialSecondInput
                                            }}
                                            inputProps={{
                                                type: 'number',
                                            }}
                                        />
                                    </>
                                    :
                                    <FormInput
                                        name={'quantity'}
                                        label={'Quantity'}
                                        labelWidth={63}
                                        formControlProps={{
                                            className: classes.quantityInput
                                        }}
                                        inputProps={{
                                            type: 'number',
                                        }}
                                    />
                                }
                            </Box>
                            <Field name={'photoFilePaths'}>
                                {({form, meta, field}: FieldProps) => (
                                    <CustomDropzone
                                        maxSize={UPLOAD_IMAGE_SIZE}
                                        fieldProps={{form, meta, field}}
                                        style={CREATE_DROPZONE_STYLE}
                                        wrapperStyle={classes.dropzoneWrapper}
                                        renderContent={(open: () => void) =>
                                            <>
                                                <Typography variant={'h5'} className={classes.uploadText}>
                                                    Product Pictures
                                                </Typography>
                                                <Box className={classes.uploadInfo}>
                                                    <Box className={classes.imageWrapper}>
                                                        <img
                                                            src={SelectConfirmFiles}
                                                            alt="SelectConfirmFiles"
                                                            className={classes.uploadImg}
                                                        />
                                                    </Box>
                                                    <Box className={classes.chipsWrapper}>
                                                        {values?.photoFilePaths?.length > 0 ?
                                                            values?.photoFilePaths?.map((item, index) => (
                                                                <FileChip
                                                                    key={index}
                                                                    label={item?.name}
                                                                    onDelete={() => {
                                                                        form.setFieldValue('photoFilePaths', values?.photoFilePaths?.filter((clickedItem, i) => index !== i))
                                                                    }}
                                                                />
                                                            ))
                                                            :
                                                            <Typography
                                                                variant={'h5'}
                                                                className={classes.uploadLimits}
                                                            >
                                                                Format - JPG, PNG, TIFF or BMP. <br/>
                                                                Size - no more than 5 MB.
                                                            </Typography>
                                                        }
                                                    </Box>
                                                </Box>
                                                <CustomButton
                                                    variant={'text'}
                                                    color={'primary'}
                                                    onClick={open}
                                                    className={classes.uploadButton}
                                                >
                                                    Select files
                                                </CustomButton>
                                            </>
                                        }
                                        maxFiles={MAX_UPLOAD_FILES}
                                        accept={['image/*']}
                                        onDrop={(acceptedFiles) => {
                                            form.setFieldValue('photoFilePaths', [...acceptedFiles, ...values.photoFilePaths])
                                        }}
                                    />
                                )}
                            </Field>
                            <Typography
                                className={classes.additionalInfo}
                                variant={'h4'}
                            >
                                Additional product information:
                            </Typography>
                            <FieldArray
                                name={'attributes'}
                            >
                                {(fieldArrayProps) => {
                                    const {push, remove, form} = fieldArrayProps;
                                    const {values} = form;
                                    const {attributes} = values;
                                    return attributes?.length > 0 && (
                                        <Box className={classes.attributesInput}>
                                            {attributes?.map((inputItem, index) => (
                                                <OptionInputs
                                                    onDeleteHandler={() => remove(index)}
                                                    key={index}
                                                    item={inputItem}
                                                    name={`attributes[${index}]`}
                                                />
                                            ))}
                                        </Box>
                                    )
                                }}
                            </FieldArray>
                            <CustomButton
                                color={'primary'}
                                variant={'text'}
                                onClick={() => setOpen(true)}
                            >
                                Add field
                            </CustomButton>
                            <SubmitButtonWithErrors/>
                            <DialogSelectOptionalField
                                open={open}
                                onClose={() => setOpen(false)}
                                name={'attributes'}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </FormLayout>
    );
};

export default ProductCreate;

const useStyles = makeStyles<Theme>(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    formContainer: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        marginBottom: 33,
        gridColumnGap: 24,
        gridRowGap: 12,
        // gridColumnGap: 24,
        marginTop: 16,
        // gridRowGap: 24
    },
    nameInput: {
        gridColumn: '1/5'
    },
    brandInput: {
        gridColumn: '1/3'
    },
    quantityInput: {
        gridColumn: '3/5'
    },
    serialFirstInput: {
        gridColumn: '3/4'
    },
    serialSecondInput: {
        gridColumn: '4/5'
    },
    dropzoneWrapper: {
        width: '100%'
    },
    uploadText: {
        color: COLORS.TEXT_SILVER,
        height: '3rem'
    },
    uploadInfo: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        marginTop: 12,
    },
    chipsWrapper: {
        marginLeft: 32,
        height: 81,
        overflowY: 'scroll',
        '-ms-overflow-style': 'n one',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    },
    uploadLimits: {
        fontSize: 12,
        color: COLORS.TEXT_SILVER,
        marginLeft: 36
    },
    uploadButton: {
        marginTop: 16
    },
    additionalInfo: {
        marginTop: 32,
        marginBottom: 16
    },
    ErrorOutlineIcon: {
        fontSize: 14,
        marginRight: 2
    },
    uploadImg: {
        height: 81,
        width: 'auto',
        marginRight: 8,

    },
    attributesInput: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        width: '100%',
        gridColumnGap: 25,
        gridRowGap: 30,
        marginBottom: 20,
    }
}));
