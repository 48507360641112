import React, {useEffect, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, IconButton, makeStyles, Table, TableBody, TableContainer, Typography} from '@material-ui/core';
import {SEND_MULTI_PRODUCT_SCHEMA} from '../../constants/validation_schema';
import {Field, Form, Formik} from 'formik';
import FormLayout from '../../layouts/FormLayout';
import {useHistory, useLocation} from 'react-router-dom';
import {ProductType} from '../../types/enum';
import FormAutocomplete from '../../components/ui/FormAutocomplete';
import {useGetOptionsQuery, useSendProductsMutation} from '../../generated/graphql';
import {checkEmptyArrays} from '../../helpers';
import CustomButton from '../../components/ui/Buttons/Button';
import DialogSelectProduct from '../../components/ui/Dialogs/DialogSelectProduct';
import {MESSAGES} from '../../constants/messages';
import CustomTableHead from '../../components/ui/Tables/TableHead';
import CustomTableRow from '../../components/ui/Tables/TableRow';
import MuiTableCell from '../../components/ui/Tables/TableCell';
import MuiCircularProgress from '../../components/ui/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import {useUIContext} from '../../context/UIContext';
import {END_ACTION_TYPE} from '../../types/type';
import {CONSIGNMENT_MULTI_SEND_STATE, PRODUCT_MULTI_SEND_STATE} from './helpers/constants';
import {COLORS} from '../../constants/colors';
import TooltipCell from '../../components/ui/Tables/TooltipCell';

const ProductSendPage: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    // @ts-ignore
    const {selected, type}: { type: ProductType, selected: any } = location.state;
    const [typeState, setTypeState] = useState(type === ProductType.product ? PRODUCT_MULTI_SEND_STATE : CONSIGNMENT_MULTI_SEND_STATE);
    const {showEndAction} = useUIContext();
    const {data, error, refetch} = useGetOptionsQuery();
    const [open, setOpen] = useState<boolean>(false);
    const [sendProducts] = useSendProductsMutation();
    const handleChangeQuantity = (e, index, setField) => {
        setField(`items[${index}].quantity`, e.target.value);
    };
    const changeAutoComplete = (setFieldValue) => (event, value) => setFieldValue('receiverAddress', value?.target?.blockchainAddress)
    return (
        <FormLayout
            crumbsDetails={[
                {
                    message: typeState?.title,
                    link: typeState?.link
                },
                {message: 'Send', link: ''}
            ]}
            title={`Send ${typeState?.title.toLowerCase()}`}
        >
            <Formik
                initialValues={{
                    receiverAddress: '',
                    purchase: false,
                    items: selected?.map(item => ({...item, quantity: ''}))
                }}
                validationSchema={SEND_MULTI_PRODUCT_SCHEMA}
                onSubmit={async (values) => {
                    try {
                        const {data} = await sendProducts({
                            variables: {
                                receiverAddress: values?.receiverAddress,
                                input: values?.items?.map(item => ({productId: item.id, quantity: Number(item.quantity)}))
                            }
                        })
                        if (data?.productsSend) {
                            history.replace(typeState?.link)
                            showEndAction(typeState?.message, END_ACTION_TYPE.SUCCESS);
                        }
                    } catch (e) {
                        showEndAction(e?.message);
                    }
                }}
            >
                {({
                    handleSubmit,
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    values,
                    submitCount
                }) => {
                    return (
                        <Form onSubmit={handleSubmit}>
                            <FormAutocomplete
                                freeSolo
                                disableClearable
                                property={'id'}
                                name={'receiverAddress'}
                                options={checkEmptyArrays([data?.whoami?.stores?.data, data?.whoami?.contacts?.data])}
                                getOptionLabel={(options) => options?.name}
                                label={'Send to'}
                                wrapperStyle={classes.address}
                                onChange={changeAutoComplete(setFieldValue)}
                            />
                            <Box className={classes.productList}>
                                <Typography variant={'h4'} className={classes.productListTitle}>
                                    {typeState?.listTitle}
                                </Typography>
                                <CustomButton onClick={() => setOpen(true)}>
                                    + Add {typeState?.title?.toLowerCase()}
                                </CustomButton>
                            </Box>
                            {open &&
                            <DialogSelectProduct
                                open={open}
                                type={type}
                                onClose={(() => setOpen(false))}
                            />
                            }
                            {values?.items.length === 0 ? (
                                <Typography
                                    className={classes.anyProductToSend}
                                    variant={'subtitle1'}
                                >{MESSAGES.EMPTY_PRODUCTS_SEND_TITLE(type)}</Typography>
                            ) : (
                                <>
                                    <TableContainer
                                        classes={{root: classes.container}}
                                    >
                                        <Table
                                            classes={{root: classes.table}}
                                            aria-labelledby="tableTitle"
                                            size={'small'}
                                            aria-label="enhanced table"
                                            stickyHeader
                                        >
                                            <CustomTableHead
                                                headCells={typeState?.sendTableHead}
                                            />
                                            <TableBody classes={{root: classes.tableBody}}>
                                                {values?.items?.length > 0 ? (
                                                    values?.items?.map((row, index) => {
                                                        const labelId = `enhanced-table-checkbox-${index}`;
                                                        return (
                                                            <CustomTableRow
                                                                tabIndex={-1}
                                                                key={row.id}
                                                            >
                                                                <TooltipCell
                                                                    component="th"
                                                                    id={labelId}
                                                                    scope="row"
                                                                    label={row.name}
                                                                    value={row.name}
                                                                />
                                                                <TooltipCell
                                                                    label={row.balance}
                                                                    value={row.balance}
                                                                    align={'right'}
                                                                />
                                                                <MuiTableCell align={'right'} width={'auto'}>
                                                                    <Field name={row.id}>
                                                                        {({field, meta, form}) => {
                                                                            return (
                                                                                <input
                                                                                    {...field}
                                                                                    type={'number'}
                                                                                    onChange={(e) =>
                                                                                        handleChangeQuantity(
                                                                                            e,
                                                                                            index,
                                                                                            setFieldValue
                                                                                        )
                                                                                    }
                                                                                    onWheel={event => event.currentTarget.blur()}
                                                                                    inputMode={'numeric'}
                                                                                    max={row.balance}
                                                                                    min={'1'}
                                                                                    className={clsx(
                                                                                        classes.input,
                                                                                        {
                                                                                            [classes.invalidInput]: errors.items &&
                                                                                            errors.items[index] && submitCount
                                                                                        }
                                                                                    )}
                                                                                    placeholder={'0 (press for write)'}
                                                                                />
                                                                            );
                                                                        }}
                                                                    </Field>
                                                                </MuiTableCell>
                                                                <MuiTableCell align="center">
                                                                    <IconButton
                                                                        aria-label="delete"
                                                                        className={classes.margin}
                                                                        onClick={() => setFieldValue('items', values.items.filter((item, i) => {
                                                                            return item?.id !== row?.id;
                                                                        }))}
                                                                    >
                                                                        <CloseIcon fontSize="small"/>
                                                                    </IconButton>
                                                                </MuiTableCell>
                                                            </CustomTableRow>
                                                        );
                                                    })
                                                ) : (
                                                    <></>
                                                )}

                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <CustomButton
                                        variant={'contained'}
                                        disabled={isSubmitting}
                                        type={'submit'}
                                        style={{marginTop: 20}}
                                    >
                                        {isSubmitting ? <MuiCircularProgress /> : 'Send'}
                                    </CustomButton>
                                </>
                            )}

                        </Form>
                    )
                }}
            </Formik>
        </FormLayout>
    );
};

export default ProductSendPage;

const useStyles = makeStyles<Theme>(() => ({
    address: {
        width: 590
    },
    productList: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginBottom: 20,
    },
    productListTitle: {
        marginRight: 32,
        alignSelf: 'center'
    },
    anyProductToSend: {
        color: COLORS.TEXT_SILVER
    },
    container: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 9,
        minWidth: 750,
        width: '70%',
        maxHeight: 300,
        overflowY: 'scroll',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
            '-ms-overflow-style': 'none',
            scrollbarWidth: 'none',
        }
    },
    input: {
        minWidth: '100%',
        height: 40,
        border: 'none',
        textAlign: 'right',
        outline: 'none',
        '&:focus::placeholder': {
            color: 'transparent'
        },
        '&:invalid': {
            borderBottom: '1px solid red',
            marginBottom: -1,
            height: 41
        }
    },
    invalidInput: {
        borderBottom: '1px solid red',
        marginBottom: -1,
        height: 41
    },
}));
