import React, {useContext} from 'react';
// @ts-ignore
import {Exact, GetFinancesQuery, useGetFinancesQuery} from '../generated/graphql';
import {QueryResult} from '@apollo/client';


type initialValuesType = {
    financeQuery: QueryResult<GetFinancesQuery, Exact<{ [key: string]: never; }>>
}

const initialValues: initialValuesType = {
    financeQuery: null
}

const FinanceContext = React.createContext(initialValues);

export const useFinanceContext = () => {
    return useContext(FinanceContext);
};

const FinanceProvider:React.FC = ({children}) => {
    const financeQuery = useGetFinancesQuery();

    return (
        <FinanceContext.Provider
            value={{
                financeQuery
            }}
        >
            {children}
        </FinanceContext.Provider>
    );
}

export default FinanceProvider;
