import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, TableCell, TableRow} from '@material-ui/core';
import {Skeleton} from '@material-ui/lab';

// eslint-disable-next-line react/display-name
const SkeletonBrandProducts = React.memo(():React.ReactElement => {
    return (
        <>
            {
                [1, 2, 3, 4, 5, 6, 7].map((item, _) => (
                    <TableRow key={_} style={{height: '53px'}}>
                        <TableCell component="th" scope="row" align="center" padding="checkbox" style={{padding: '0 0 0 15px'}}>
                            <Skeleton variant="rect" width={'34px'} height={'34px'}/>
                        </TableCell>
                        <TableCell component="th" align="left" scope="row" style={{width: '60%'}}><Skeleton variant="text"/></TableCell>
                        <TableCell
                            align="right"
                            style={{width: '20%', padding: '0 15px 0 0'}}
                        ><Skeleton variant="text"/></TableCell>
                    </TableRow>
                ))
            }
        </>
    )
});

export default SkeletonBrandProducts;

const useStyles = makeStyles<Theme>(() => ({}));
