export enum INVOICE_PENDING {
    WAITING_ADDRESS = 'WAITING_ADDRESS',
    EXPIRES = 'EXPIRES',
    PAID_NOT_FULL = 'PAID_NOT_FULL',
    PAID_AND_NOT_SENT = 'PAID_AND_NOT_SENT'
}

export enum INVOICE_COMPLETED_STATUS {
    PAID = 'PAID',
    CHECKED = 'CHECKED',
    CANCELED = 'CANCELED'
}
