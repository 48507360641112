import React, {useState} from 'react';
import {Box, IconButton, makeStyles, Table, TableBody, TableContainer, Typography} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import CustomDrawer from './Drawer';
import {useUIContext} from '../../../../context/UIContext';
import {Close} from "@material-ui/icons";
import {Field, Form, Formik, FormikProps, useFormikContext} from "formik";
import {CREATE_NEW_INVOICE_SCHEMA} from "../../../../constants/validation_schema";
import FormInput from "../../../../components/ui/FormInput";
import CustomButton from "../../../../components/ui/Buttons/Button";
import DialogSelectProduct from "../../../../components/ui/Dialogs/DialogSelectProduct";
import {ProductType} from "../../../../types/enum";
import AddIcon from '../../../../assets/icons/add.svg';
import {ReactSVG} from 'react-svg'
import 'date-fns';
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import clsx from "clsx";
import {MESSAGES} from "../../../../constants/messages";
import CustomTableHead from "../../../../components/ui/Tables/TableHead";
import CustomTableRow from "../../../../components/ui/Tables/TableRow";
import TooltipCell from "../../../../components/ui/Tables/TooltipCell";
import MuiTableCell from "../../../../components/ui/Tables/TableCell";
import CloseIcon from "@material-ui/icons/Close";
import {INVOICES_PRODUCT_SEND_HEAD} from "../../../../constants/tableColumns";
import CustomPaper from "../../../../components/ui/Paper";


type CustomCreateNewInvoiceProps = {
    setOpenNewInvoicePreview: React.Dispatch<React.SetStateAction<boolean>>;
    setDataNewInvoice: any;
    dataNewInvoice: any;
    onSubmitInvoice: any;
}

const CreateNewInvoice: React.FC<CustomCreateNewInvoiceProps> = (props: CustomCreateNewInvoiceProps): JSX.Element => {
    const {setOpenNewInvoicePreview, setDataNewInvoice, dataNewInvoice, onSubmitInvoice} = props;
    const classes = useStyles();
    const [clickPreview, setClickPreview] = useState<boolean>(false);

    const {openCreateInvoice, setOpenCreateInvoice} = useUIContext();
    const [openAddProduct, setOpenAddProduct] = useState<boolean>(false);
    const handleChangeQuantity = (e, index, setField) => {
        setField(`itemsInvoice[${index}].quantity`, e.target.value);
    };

    const handleNewInvoicePreview = (values: any, validateForm: any, setFieldTouched: any, setSubmitting:any) => {
        validateForm().then((r) => {
            setFieldTouched('totalAmount', true);
            if(r.itemsInvoice) {
                setClickPreview(true);
            }
            setSubmitting(false);
            console.log(r, 'r');
            if (Object.keys(r).length === 0 && !isNaN(values.dueOnDate.getTime())) {
                setDataNewInvoice(values);
                setOpenCreateInvoice(false);
                setOpenNewInvoicePreview(true)
            }
        })
    };


    const today = new Date();

    return (
        <CustomDrawer open={openCreateInvoice} onClose={() => setOpenCreateInvoice(false)}>
            <div className={classes.createNewInvoice}>
                <div className={classes.header}>
                    <Typography variant={'h4'} component={'h4'}>Create new invoice</Typography>
                    <IconButton
                        size={'medium'}
                        className={classes.closeButton}
                        onClick={() => setOpenCreateInvoice(false)}
                    ><Close/></IconButton>
                </div>
                <Typography variant={'h2'} component={'h2'} className={classes.title}>#548-66AC</Typography>
                <Formik
                    initialValues={Object.keys(dataNewInvoice).length !== 0
                        ? {...dataNewInvoice}
                        : {
                            description: '',
                            dueOnDate: new Date(),
                            recipientEmail: '',
                            itemsInvoice: [],
                            note: '',
                            totalAmount: ''
                        }}
                    validationSchema={CREATE_NEW_INVOICE_SCHEMA}
                    onSubmit={(values, actions) => {
                        if (!isNaN(values.dueOnDate.getTime())) {//date is valid
                            setDataNewInvoice(values);
                            onSubmitInvoice(values);
                        }
                    }}
                >
                    {({values, errors, submitCount, setFieldValue, validateForm, setFieldTouched, setSubmitting}) => {
                        return (
                            <Form>
                                <FormInput
                                    name={'description'}
                                    label={'Description (optional)'}
                                    labelWidth={162}
                                    formControlProps={{
                                        className: classes.description
                                    }}
                                />
                                <div className={classes.dateEmailContainer}>
                                    <div className={classes.dateContainer}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                name="dueOnDate"
                                                disableToolbar
                                                inputVariant="outlined"
                                                variant="inline"
                                                format="MM/dd/yyyy"
                                                margin="normal"
                                                minDate={today}
                                                id="date-picker-inline"
                                                label="Due On"
                                                className={clsx(classes.datePicker, {[classes.error]: !values.dueOnDate})}
                                                value={values.dueOnDate}
                                                onChange={value => setFieldValue("dueOnDate", value)}
                                                PopoverProps={{
                                                    anchorOrigin: {horizontal: "left", vertical: "bottom"},
                                                    transformOrigin: {horizontal: "left", vertical: "top"}
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                        <p className={classes.errorMessage}>{errors.dueOnDate}</p>
                                    </div>
                                    <FormInput
                                        name={'recipientEmail'}
                                        label={'Recipient email (optional)'}
                                        labelWidth={190}
                                        formControlProps={{
                                            className: classes.recipientEmail
                                        }}
                                    />
                                </div>
                                <div className={classes.productsContainer}>
                                    <div className={classes.productsTitleBtn}>
                                        <Typography
                                            variant={'h5'}
                                            className={classes.productsTitle}
                                        >Products</Typography>
                                        <Box className={classes.addProductBtn}>
                                            <CustomButton onClick={() => setOpenAddProduct(true)}>
                                                <ReactSVG src={AddIcon} className={classes.addIcon}/>Add product
                                            </CustomButton>
                                        </Box>
                                    </div>
                                    {openAddProduct &&
                                    <DialogSelectProduct
                                        open={openAddProduct}
                                        type={ProductType.product}
                                        onClose={(() => setOpenAddProduct(false))}
                                        isInvoice
                                    />
                                    }
                                    {values?.itemsInvoice.length === 0 ? (
                                        <Typography
                                            className={classes.anyProductToSend}
                                            variant={'subtitle1'}
                                        >{MESSAGES.EMPTY_PRODUCTS_SEND_TITLE(ProductType.product)}</Typography>
                                    ) : (
                                        <>
                                            <CustomPaper className={
                                                clsx(
                                                    classes.paperContainer
                                                )
                                            }>
                                                <TableContainer
                                                    classes={{root: classes.container}}
                                                >
                                                    <Table
                                                        classes={{root: classes.table}}
                                                        aria-labelledby="tableTitle"
                                                        size={'small'}
                                                        aria-label="enhanced table"
                                                        stickyHeader
                                                    >
                                                        <CustomTableHead
                                                            isInvoicesTable
                                                            headCells={INVOICES_PRODUCT_SEND_HEAD}
                                                        />
                                                        <TableBody classes={{root: classes.tableBody}}>
                                                            {values?.itemsInvoice?.length > 0 ? (
                                                                values?.itemsInvoice?.map((row, index) => {
                                                                    const labelId = `enhanced-table-checkbox-${index}`;
                                                                    return (
                                                                        <CustomTableRow
                                                                            tabIndex={-1}
                                                                            key={row.id}
                                                                        >
                                                                            <TooltipCell
                                                                                component="th"
                                                                                id={labelId}
                                                                                scope="row"
                                                                                label={row.name}
                                                                                value={row.name}
                                                                            />
                                                                            <TooltipCell
                                                                                label={row.balance}
                                                                                value={row.balance}
                                                                                align={'right'}
                                                                            />
                                                                            <MuiTableCell
                                                                                align={'right'}
                                                                                width={'auto'}
                                                                            >
                                                                                <Field name={row.id}>
                                                                                    {({field, meta, form}) => {
                                                                                        return (
                                                                                            <input
                                                                                                {...field}
                                                                                                type={'number'}
                                                                                                value={values.itemsInvoice[index].quantity}
                                                                                                onChange={(e) =>
                                                                                                    handleChangeQuantity(
                                                                                                        e,
                                                                                                        index,
                                                                                                        setFieldValue
                                                                                                    )
                                                                                                }
                                                                                                onWheel={event => event.currentTarget.blur()}
                                                                                                inputMode={'numeric'}
                                                                                                max={row.balance}
                                                                                                min={'1'}
                                                                                                className={clsx(
                                                                                                    classes.input,
                                                                                                    {
                                                                                                        [classes.invalidInput]: errors.itemsInvoice &&
                                                                                                        errors.itemsInvoice[index] && submitCount ||
                                                                                                        errors.itemsInvoice &&
                                                                                                        errors.itemsInvoice[index] && clickPreview
                                                                                                    }
                                                                                                )}
                                                                                                placeholder={'Enter quantity'}
                                                                                            />
                                                                                        );
                                                                                    }}
                                                                                </Field>
                                                                            </MuiTableCell>
                                                                            <MuiTableCell align="center">
                                                                                <IconButton
                                                                                    aria-label="delete"
                                                                                    className={classes.margin}
                                                                                    onClick={() => setFieldValue('itemsInvoice', values.itemsInvoice.filter((item, i) => {
                                                                                        return item?.id !== row?.id;
                                                                                    }))}
                                                                                >
                                                                                    <CloseIcon fontSize="small"/>
                                                                                </IconButton>
                                                                            </MuiTableCell>
                                                                        </CustomTableRow>
                                                                    );
                                                                })
                                                            ) : (
                                                                <></>
                                                            )}

                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </CustomPaper>
                                        </>
                                    )}
                                </div>
                                <FormInput
                                    name={'note'}
                                    label={'Note (optional)'}
                                    labelWidth={113}
                                    multiline
                                    rows={2}
                                    formControlProps={{
                                        className: classes.note
                                    }}
                                />
                                <div className={classes.footer}>
                                    <FormInput
                                        name={'totalAmount'}
                                        label={'Total amount'}
                                        labelWidth={100}
                                        formControlProps={{
                                            className: classes.totalAmount
                                        }}
                                    />
                                    <div className={classes.btnContainer}>
                                        <CustomButton
                                            variant={'contained'}
                                            color={'primary'}
                                            onClick={() => handleNewInvoicePreview(values, validateForm, setFieldTouched, setSubmitting)}
                                            className={classes.btnPreview}
                                        >Preview</CustomButton>
                                        <CustomButton
                                            type={'submit'}
                                            variant={'contained'}
                                            color={'primary'}
                                            onClick={() => console.log(values)}
                                            className={classes.btnCreate}
                                        >Create</CustomButton>
                                    </div>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </CustomDrawer>
    );
};

export default CreateNewInvoice;

const useStyles = makeStyles<Theme>(() => ({
    createNewInvoice: {
        minWidth: 807,
        maxWidth: 807,
        padding: '32px 40px 0',
        minHeight: '100vh',
        position: 'relative',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    title: {
        margin: '8px 0 32px'
    },
    description: {
        width: '100%'
    },
    dateEmailContainer: {
        display: 'flex',
        margin: '16px 0 24px'
    },
    recipientEmail: {
        width: '100%',
        marginLeft: 16
    },
    footer: {
        display: 'flex',
        padding: '32px 40px 16px',
        position: 'fixed',
        width: 807,
        bottom: 0,
        right: 0,
        zIndex: 99999,
        background: '#FFFFFF',
        boxShadow: '0px -8px 11px -6px rgba(132, 111, 216, 0.14)'
    },
    totalAmount: {
        maxWidth: 334
    },
    btnCreate: {
        height: 56,
        padding: '0 32px',
        marginLeft: 16
    },
    btnContainer: {
        marginLeft: 'auto'
    },
    productsTitle: {
        fontWeight: 600,
        fontSize: 18,
        lineHeight: '24px'
    },
    productsContainer: {},
    productsTitleBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    note: {
        marginTop: 40,
        width: '100%',
        minHeight: 103,
        paddingBottom: 140
    },
    addIcon: {
        marginRight: 8,
        '& > div > svg': {
            display: 'block'
        }
    },
    closeButton: {
        marginRight: -16
    },
    btnPreview: {
        color: '#276EF9',
        background: '#EEF4FF',
        boxShadow: 'none',
        height: 56,
        padding: '0 32px',
        '&:hover': {
            background: 'rgba(39, 110, 249, 0.1)',
            boxShadow: 'none'
        },
        '&:active': {
            background: 'rgba(39, 110, 249, 0.18)',
            boxShadow: 'none'
        },
    },
    datePicker: {
        margin: 0,
        minHeight: 73,
        '& > p': {
            fontSize: 12,
            textTransform: 'none',
            letterSpacing: 0,
            marginTop: 0,
            lineHeight: '17px'
        }
    },
    dateContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    errorMessage: {
        fontSize: 12,
        marginTop: -17,
        marginLeft: 14,
        lineHeight: '16px',
        color: '#f44336'
    },
    error: {
        '& > label': {
            color: '#f44336 !important',
        },
        '& > div > fieldset': {
            borderColor: '#f44336 !important'
        }
    },
    addProductBtn: {
        marginRight: -22
    },
    invalidInput: {
        borderBottom: '1px solid red !important',
        marginBottom: -1,
        height: 41
    },
    input: {
        minWidth: '100%',
        height: 40,
        border: 'none',
        textAlign: 'right',
        outline: 'none',
        '&:focus::placeholder': {
            color: 'transparent'
        },
        '&:invalid': {
            borderBottom: '1px solid red',
            marginBottom: -1,
            height: 41
        }
    },
    paperContainer: {
        filter: 'drop-shadow(0px 6px 17px rgba(73, 105, 219, 0.08))',
        borderRadius: 16,
        marginTop: 16
    },
    tableBody: {
        '& > tr:last-of-type > th, & > tr:last-of-type > td': {
            borderBottom: 'none'
        },
        '& > tr > th:first-child': {
            paddingLeft: '24px !important'
        },
        '& > tr > td:last-child': {
            paddingRight: '24px !important'
        }
    },
    table: {
        paddingBottom: 8
    }
}));
