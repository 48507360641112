import React, {FC} from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {useHistory} from 'react-router-dom';
import {Breadcrumbs, BreadcrumbsProps, Typography} from '@material-ui/core';
import {COLORS} from '../../constants/colors';

type CustomBreadcrumbsProps =  BreadcrumbsProps & {
    details: {message: string, link: string}[]
}

const CustomBreadcrumbs:FC<CustomBreadcrumbsProps> = ({details}: CustomBreadcrumbsProps) => {
    const history = useHistory();
    const classes = useStyles();
    const historyPush = (index, link) => {
        // eslint-disable-next-line no-empty
        if (details.length === index + 1) {
            if (link) {
                history.push(link);
            }
        } else if (details.length === index + 2) {
            history.goBack();
        } else {
            history.push(link);
        }
    };
    const findActiveLink = (index) => {
        return details.length === index + 2 || details.length < index;
    };
    return (
        <Breadcrumbs classes={{
            root: classes.Breadcrumbs,
            ol: classes.BreadcrumbsOl
        }} separator={<NavigateNextIcon/>}>
            {details.map((item, index) => {
                return (<Typography
                    key={index}
                    variant={'subtitle2'}
                    classes={{root: findActiveLink(index) ? classes.ActiveLink : classes.Typography}}
                    onClick={() => historyPush(index, item.link)}
                >
                    {item.message}
                </Typography>);
            })}
        </Breadcrumbs>
    );
}

export default CustomBreadcrumbs;

const useStyles = makeStyles<Theme>(theme => ({
    Breadcrumbs: {
        marginBottom: 24,
    },
    BreadcrumbsOl: {
        flexWrap: 'nowrap',
        overflow: 'hidden',

    },
    Typography: {
        color: COLORS.TEXT_SILVER
    },
    ActiveLink: {
        color: COLORS.TEXT_SILVER,
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    }
}));
