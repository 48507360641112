
export enum BRAND_STATUS {
    AUTO_CONFIRMED = 'AUTO_CONFIRMED',
    CONFIRMED = 'CONFIRMED',
    REJECTED = 'REJECTED',
    PROCESSING = 'PROCESSING'
}
export enum ProductType {
    consignment = 'consignment',
    product = 'product'
}

export enum StoreType {
    contact = 'contact',
    store = 'store'
}
export enum TransactionType {
    income = 'income',
    outcome = 'outcome'
}
export enum ActionsType {
    deleting = 'deleting',
    sending = 'sending',
    creating = 'creating'
}
