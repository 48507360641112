import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {DialogProps, List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography, Box} from '@material-ui/core';
import DialogLayout from '../../../layouts/DialogLayout';
import {type} from 'os';
import CustomButton from '../Buttons/Button';
import {useGlobalContext} from '../../../context/globalContext';
import {CREATE_STORE_TYPE} from '../../../pages/stores/helpers/constants';
import {useHistory} from 'react-router-dom';
import {COLORS} from '../../../constants/colors';

type DialogCreateStore = DialogProps & {
    type: 'CREATE' | 'ADD',
    dialogData: { open: boolean, login: string, password: string },
    mergeDialogData: (newState: { open: boolean, login: string, password: string }) => void
}

const DialogCreateStore: React.FC<DialogCreateStore> = (props: DialogCreateStore): JSX.Element => {
    const classes = useStyles();
    const {dialogData, mergeDialogData, type, ...rest} = props
    const {userData} = useGlobalContext();
    const history = useHistory();
    const hideDialog = () => {
        mergeDialogData({open: false, login: '', password: ''});
        history.push('/stores');
    }
    return (
        <DialogLayout
            hideIcon
            onClose={(event) => hideDialog()}
            width={552}
            {...rest}
        >
            <Box className={classes.container}>
                <Typography
                    className={classes.dialogTitle}>{(type === CREATE_STORE_TYPE.CREATE) ? 'Store successfully created' : 'Contact successfully added'}</Typography>
                <Typography className={classes.dialogText}>
                    We have successfully created a store account.
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    At any time you can switch to it from the control panel, the "Stores" tab, or enter under the data
                    below.
                    This information has been sent to your mail <span
                        style={{color: '#276EF9'}}>{userData?.email}</span>
                </Typography>
                <div className={classes.DialogLoginItem}>
                    <List>
                        <ListItem>
                            <ListItemAvatar className={classes.dialogListBox}>
                                <span>
                                    <i className={'material-icons-outlined'}>
                                                        perm_identity
                                    </i>
                                </span>
                            </ListItemAvatar>
                            <ListItemText
                                primary={dialogData.login}
                                secondary="Email"
                                classes={{
                                    root: classes.ListItemText,
                                    primary: classes.primaryItemText
                                }}
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar className={classes.dialogListBox}>
                                <span>
                                    <i className={'material-icons-outlined'}>
                                                            vpn_key
                                    </i>
                                </span>
                            </ListItemAvatar>
                            <ListItemText
                                classes={{
                                    root: classes.ListItemText,
                                    primary: classes.primaryItemText
                                }}
                                primary={dialogData.password}
                                secondary="Password"
                            />
                        </ListItem>
                    </List>
                </div>
                <CustomButton
                    style={{alignSelf: 'flex-end'}}
                    variant={'text'}
                    color={'primary'}
                    onClick={hideDialog}
                >
                    GOT IT
                </CustomButton>
            </Box>

        </DialogLayout>
    );
};

export default DialogCreateStore;

const useStyles = makeStyles<Theme>(() => ({
    container: {
        padding: 40,
        display: 'flex',
        flexDirection: 'column'
    },
    dialogTitle: {
        fontSize: 24,
        color: 'rgba(0, 0, 0, 0.87)',
        textAlign: 'center',
        marginBottom: 25
    },
    dialogText: {
        fontSize: 14,
        lineHeight: 2,
        color: 'rgba(0, 0, 0, 0.6)',
        marginBottom: 15
    },
    DialogLoginItem: {
        display: 'flex',
        width: '100%'

    },
    dialogListBox: {
        width: 56,
        height: 56,
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        borderRadius: '15px 5px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& i': {
            color: COLORS.TEXT_SILVER
        }
    },
    ListItemText: {
        paddingLeft: 26
    },
    primaryItemText: {
        fontWeight: 500,
        fontSize: 16,
        color: '#276EF9'
    }

}));
