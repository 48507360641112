import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {CircularProgress, CircularProgressProps, makeStyles} from '@material-ui/core';

const MuiCircularProgress: React.FC<CircularProgressProps> = (props: CircularProgressProps): JSX.Element => {
    const classes = useStyles();

    return (
        <CircularProgress color={'primary'} size={20} {...props}/>
    );
};

export default MuiCircularProgress;

const useStyles = makeStyles<Theme>(() => ({}));
