import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, TableCell, TableCellProps} from '@material-ui/core';

export type MuiTableCellProps = TableCellProps & {
    children?: React.ReactNode,
    width?: number | string
}

const MuiTableCell: React.FC<MuiTableCellProps> = (props: MuiTableCellProps): JSX.Element => {
    const classes = useStyles();
    const {children, width = 0, ...rest} = props;
    return (
        <TableCell
            classes={{
                root: classes.root,
                paddingCheckbox: classes.paddingCheckbox
            }}
            style={{
                maxWidth: width
            }}
            {...rest}
        >
            {children}
        </TableCell>
    );
};

export default MuiTableCell;

const useStyles = makeStyles<Theme>(() => ({
    root: {
        height: 53,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 14,
        padding: '0 8px',
        background: 'transparent'
    }
}));
