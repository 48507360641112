import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, InputAdornment, makeStyles, TextField, Typography} from '@material-ui/core';
import FormLayout from '../../layouts/FormLayout';
import {COLORS} from '../../constants/colors';
import CustomButton from '../../components/ui/Buttons/Button';
import {useUIContext} from '../../context/UIContext';
import Change from '../../assets/icons/change.svg';
import {Field, Form, Formik, useFormikContext} from 'formik';
import {CREATE_PRODUCT_BY_FEED_SCHEMA} from '../../constants/validation_schema';
import {useGetBrandsForCreateQuery} from '../../generated/graphql';
import {useGlobalContext} from '../../context/globalContext';
import {Autocomplete} from 'formik-material-ui-lab';
import DialogSelectOption from '../../components/ui/Dialogs/DialogSelectOption';
import {useLocation} from 'react-router-dom';
import FailedToLoad from '../../components/FailedToLoad';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const name = '123123132131231';

type StateUploadFeedType = {
    filepath: string[]
    fileName: string
}

const UploadFeedPage: React.FC = (): JSX.Element => {
    const classes = useStyles();
    const {userData} = useGlobalContext();
    const {setUploadFeed} = useUIContext();
    const [open, setOpen] = useState<boolean>(false);
    // @ts-ignore
    const {state: {filepath, fileName}} = useLocation();
    const {data, loading, error, refetch} = useGetBrandsForCreateQuery({
        variables: {
            ownerId: userData?.id,
            confirmedOnly: true
        }
    })
    if (error) {
        return <FailedToLoad refetch={refetch} />
    }

    return (
        <FormLayout
            crumbsDetails={[
                {message: 'Product', link: ''},
                {message: 'Create', link: ''}
            ]}
        >
            <Box className={classes.titleWrapper}>
                <Typography variant={'h3'} className={classes.title}>{fileName}</Typography>
                <CustomButton
                    onClick={() => setUploadFeed(true)}
                    startIcon={<img
                        src={Change}
                        alt="uploadFeed"
                        style={{marginRight: 5}}/>}
                >
                    Change File
                </CustomButton>
            </Box>
            <Typography className={classes.subTitle} variant={'subtitle1'}>
                Check the correspondence of your fields to fields in the system
            </Typography>
            <Formik
                initialValues={{
                    brandId: '',
                    options: filepath?.map(item => ({fileOption: item, userOption: ''}))
                }}
                enableReinitialize={true}
                validationSchema={CREATE_PRODUCT_BY_FEED_SCHEMA}
                onSubmit={async (values) => {
                    console.log('values', values);
                }}
            >
                {({handleSubmit, errors, touched, values}) => (
                    <Form>
                        <Box className={classes.formWrapper}>
                            <Box className={classes.inputWrapper}>
                                <Typography variant={'h5'} className={classes.option}>
                                    Brand
                                </Typography>
                                <Field
                                    name={'brandId'}
                                    component={Autocomplete}
                                    options={data?.brands?.data || []}
                                    getOptionLabel={(option) => option.name}
                                    className={classes.input}
                                    renderInput={(params) =>
                                        <TextField
                                            error={errors.brandId && touched.brandId}
                                            label={'Select your brand'}
                                            variant="outlined"
                                            {...params}
                                        />
                                    }
                                />
                            </Box>
                            {values?.options?.map((item, index) => (
                                <Box key={index} className={classes.inputWrapper}>
                                    <Typography variant={'h5'} className={classes.option}>
                                        {item?.fileOption}
                                    </Typography>
                                    <TextField
                                        disabled
                                        placeholder={item.userOption}
                                        variant="outlined"
                                        onClick={() => setOpen(true)}
                                        className={classes.input}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <ArrowDropDownIcon />
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Box>
                            ))}
                        </Box>
                        <DialogSelectOption
                            open={open}
                            onClose={() => setOpen(false)}
                            width={950}
                            maxWidth={false}
                            name={'HEeheh'}
                        />
                        <CustomButton
                            className={classes.submitButton}
                            onClick={() => handleSubmit()}
                            variant={'contained'}
                        >
                            Confirm
                        </CustomButton>
                    </Form>
                )}
            </Formik>
        </FormLayout>
    );
};

export default UploadFeedPage;

const useStyles = makeStyles<Theme>(() => ({
    container: {
        position: 'relative',
        width: '100%',
        height: '100%',
        overflowY: 'scroll'
    },
    titleWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 8
    },
    title: {
        colors: COLORS.BLACK,
        marginRight: 32
    },
    subTitle: {
        color: COLORS.TEXT_SILVER,
    },
    formWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    inputWrapper: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '49%',
        marginTop: 30
    },
    input: {
        width: '70%',
    },
    option: {
        width: '30%',
        marginRight: 30,
        textAlign: 'right',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    submitButton: {
        marginTop: 20
    }
}));
