import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, CircularProgressProps, makeStyles} from '@material-ui/core';
import MuiCircularProgress from '../components/ui/CircularProgress';
import clsx from 'clsx';

type LoadingLayoutProps = CircularProgressProps & {
    wrapperStyle?: any
}

const LoadingLayout: React.FC<LoadingLayoutProps> = (props: LoadingLayoutProps): JSX.Element => {
    const classes = useStyles();
    const {wrapperStyle, ...rest} = props;
    return (
        <Box className={clsx(
            classes.container,
            wrapperStyle
        )}>
            <MuiCircularProgress  {...rest}/>
        </Box>
    );
};

export default LoadingLayout;

const useStyles = makeStyles<Theme>(() => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));
