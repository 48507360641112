import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Checkbox, makeStyles, Table, TableBody, TableContainer, TablePaginationProps} from '@material-ui/core';
import clsx from 'clsx';
import CustomTableHead, {CustomTableHeadProps} from './TableHead';
import {BRAND_PRODUCTS_HEAD} from '../../../constants/tableColumns';
import TableCell from './TableCell';
import CustomPagination from '../Pagination';
import CustomTablePagination, {CustomTablePaginationProps} from './TablePagination';
import {BRAND_PRODUCTS_TAKE} from '../../../pages/brands/helpers/constants';
import MuiTableCell from './TableCell';
import CustomPaper from '../Paper';
import {useGlobalContext} from '../../../context/globalContext';

type CheckBoxTableProps = CustomTableHeadProps &  {
    data: any[];
    selected: any;
    totalCount: number;
    setSkip: React.Dispatch<number>;
    tablePaginationProps?: CustomTablePaginationProps;
    selectAllClick: any;
    wrapperContainer?: any;
    defaultTakeValue?: number
}

const CheckBoxTable: React.FC<CheckBoxTableProps> = (props: CheckBoxTableProps): JSX.Element => {
    const classes = useStyles();
    const {
        headCells,
        data,
        selected,
        selectAllClick,
        totalCount,
        setSkip,
        tablePaginationProps,
        wrapperContainer,
        defaultTakeValue
    } = props;
    const [page, setPage] = useState(1);
    const {rowsPerPage} = useGlobalContext();
    const handleChange = (_event: any, newPage: number): void => {
        if(defaultTakeValue) {
            setSkip((newPage - 1) * defaultTakeValue);
        } else {
            setSkip((newPage - 1) * rowsPerPage);
        }
        setPage(newPage);
    };

    const includesSelected = selected.some(r => data?.map(item => item.id).includes(r?.id))
    return (
        <CustomPaper className={
            clsx(
                classes.paperContainer,
                wrapperContainer
            )
        } >
            <TableContainer className={clsx(
                classes.tableContainer
            )}>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    stickyHeader
                >
                    <CustomTableHead headCells={headCells}>
                        <MuiTableCell width={'auto'} padding="checkbox">
                            <Checkbox
                                color={'primary'}
                                onChange={(event) => selectAllClick(event, data)}
                                indeterminate={includesSelected}
                                checked={totalCount === selected.length}
                            />
                        </MuiTableCell>
                    </CustomTableHead>
                    <TableBody classes={{root: classes.TableBody}}>
                        {props.children}
                    </TableBody>
                </Table>
                <CustomTablePagination
                    // @ts-ignore
                    component={'div'}
                    rowsPerPageOptions={[]}
                    className={classes.tablePagination}
                    classes={{
                        root: classes.classesPagination,
                    }}
                    setSkip={setSkip}
                    ActionsComponent={() =>
                        <CustomPagination
                            page={page}
                            showLastButton
                            showFirstButton
                            count={Math.ceil(totalCount / (defaultTakeValue || rowsPerPage))}
                            onChange={handleChange}
                        />
                    }
                    {...tablePaginationProps}
                />
            </TableContainer>
        </CustomPaper>
    );
};

export default CheckBoxTable;

const useStyles = makeStyles<Theme>(() => ({
    paperContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    tableContainer: {
        flex: '1 1 auto',
        overflowY: 'scroll',
        height: 0
    },
    TableBody: {
        width: '100%',
        position: 'relative'
    },
    table: {
        position: 'relative',
        paddingBottom: 52,
    },
    tablePagination: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8
    },
    classesPagination: {

    }
}));
