import React, {useRef} from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, Table, TableBody, TableContainer} from '@material-ui/core';
import CustomTableHead from '../../../components/ui/Tables/TableHead';
import CustomTableRow from '../../../components/ui/Tables/TableRow';
import MuiTableCell from '../../../components/ui/Tables/TableCell';
import {useHistory, useLocation} from 'react-router-dom';
import {STORE_TRANSACTION_HEAD} from '../../../constants/tableColumns';
import {useGlobalContext} from '../../../context/globalContext';
import {typeIcons} from '../helpers';
import IncomeTrans from '../../../assets/icons/income-transaction.svg'
import OutcomeTrans from '../../../assets/icons/outcome-transaction.svg'
import {changeFormatDate, dateWithHours} from '../../brands/helpers';
import StatusChip from '../../../components/ui/Chips/StatusChip';
import {LINKS} from '../../../constants';
import MuiTooltip from '../../../components/ui/Tooltip';
import TooltipCell from '../../../components/ui/Tables/TooltipCell';
import LinkTooltipCell from '../../../components/ui/Tables/LinkTooltipCell';

type StoreTransactionTableProps = {
    data: any[]
}


const StoreTransactionTable: React.FC<StoreTransactionTableProps> = (props: StoreTransactionTableProps): JSX.Element => {
    const classes = useStyles();
    const {data} = props;
    const {userData} = useGlobalContext();
    return (
        <TableContainer className={classes.tableContainer}>
            <Table
                className={classes.table}
                size={'small'}
                stickyHeader
            >
                <CustomTableHead headCells={STORE_TRANSACTION_HEAD}/>
                <TableBody>
                    {data.map((row, i) => (
                        <CustomTableRow
                            key={row.name}
                            style={{height: '55px', cursor: 'pointer'}}
                            // onClick={goToTransDetails}
                        >
                            <MuiTableCell align={'center'} width={'auto'}>
                                <img src={
                                    typeIcons(userData?.blockchainAddress, row?.addressFrom) ?
                                        OutcomeTrans : IncomeTrans} alt="trans-type"/>
                            </MuiTableCell>
                            <LinkTooltipCell
                                label={row?.blockchainHash}
                                value={row?.blockchainHash}
                                component="th"
                                scope="row"
                                href={`${LINKS.EXPLORER}/transactions/${row?.blockchainHash}`}
                            />
                            <MuiTableCell align={'right'}>{row?.quantity}</MuiTableCell>
                            <TooltipCell
                                label={dateWithHours(row?.createdAt)}
                                value={changeFormatDate(row?.createdAt)}
                                width={'auto'}
                            />
                            <MuiTableCell width={'auto'}><StatusChip status={'SUCCESS'}/></MuiTableCell>
                        </CustomTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default StoreTransactionTable;

const useStyles = makeStyles<Theme>(() => ({
    tableContainer: {
        height: 290,
        borderRadius: 9,
        border: '1px solid rgba(0, 0, 0, 0.12)',
        overflowY: 'scroll',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
            '-ms-overflow-style': 'none',
        }
    },
    table: {

    }

}));
