import React, {useRef, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, TooltipProps, Typography, TypographyProps} from '@material-ui/core';
import MuiTableCell, {MuiTableCellProps} from './TableCell';
import MuiTooltip from '../Tooltip';
import {INVOICE_PENDING} from '../../../pages/invoices/helpers/enum';
import TrigonInvoice from '../../../assets/icons/trigonInvoice.svg';
import WarningInvoice from '../../../assets/icons/warningInvoice.svg';
import {ReactSVG} from 'react-svg';

type TooltipCellProps = MuiTableCellProps & {
    label: string | number;
    value: string | number;
    tooltipProps?: TooltipProps;
    typographyProps?: TypographyProps;
    type?: INVOICE_PENDING;
    icon?: boolean;
}

const TooltipCell: React.FC<TooltipCellProps> = (props: TooltipCellProps): JSX.Element => {
    const classes = useStyles();
    const {label, value, tooltipProps, typographyProps, type = false, icon, ...rest} = props;
    const [ref, setRef] = useState(null);

    let colorInvoice = 'rgba(0, 0, 0, 0.87)'
    if (type === INVOICE_PENDING.PAID_AND_NOT_SENT) {
        colorInvoice = 'rgba(220, 48, 48, 1)'
    }
    let iconWarning = WarningInvoice;
    if (type === INVOICE_PENDING.PAID_NOT_FULL) {
        iconWarning = TrigonInvoice
    }

    return (
        <MuiTableCell
            {...rest}
        >
            <MuiTooltip
                title={ref?.scrollWidth > ref?.offsetWidth ? label : ''}
                {...tooltipProps}
            >
                <Typography
                    ref={el => setRef(el)}
                    className={classes.value}
                    variant={'subtitle1'}
                    style={{color: label === 'Waiting address...' ? 'rgba(0, 0, 0, 0.6)' : colorInvoice}}
                    {...typographyProps}
                >
                    {icon && type !== INVOICE_PENDING.WAITING_ADDRESS
                        ? <p className={classes.valueFirst}>{value}<span style={{marginLeft: 10}}><ReactSVG src={iconWarning}/></span></p>
                        : value
                    }
                </Typography>
            </MuiTooltip>
        </MuiTableCell>
    );
};

export default TooltipCell;

const useStyles = makeStyles<Theme>(() => ({
    value: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    valueFirst: {
        display: 'flex',
        alignItems: 'center',
        '& > span > div > div > svg': {
            display: 'block'
        }
    }
}));
