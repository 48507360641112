import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import SelectConfirmFiles from '../images/select-confirm-files.svg';
import FileChip from '../../../components/ui/Chips/FileChip';
import CustomButton from '../../../components/ui/Buttons/Button';
import {FormikValues, useFormikContext} from 'formik';
import {COLORS} from '../../../constants/colors';

const RenderUploadFiles: React.FC = (open: any): JSX.Element => {
    const classes = useStyles();
    const {values, setFieldValue}: FormikValues = useFormikContext();
    return (
        <>
            <Typography variant={'h5'} className={classes.uploadText}>Attach
                files that confirm that you are a holder of this
                brand</Typography>
            <Box className={classes.uploadInfo}>
                <img
                    src={SelectConfirmFiles}
                    alt="SelectConfirmFiles"
                    className={classes.uploadImg}/>
                <Box className={classes.chipsWrapper}>
                    {values?.brandConfirmFiles?.length > 0 ?
                        values?.brandConfirmFiles?.map((item, index) => (
                            <FileChip
                                key={index}
                                label={item?.name}
                                onDelete={() => {
                                    setFieldValue('brandConfirmFiles', values?.brandConfirmFiles?.filter((clickedItem, i) => i !== index))
                                }}
                            />
                        ))
                        :
                        <Typography
                            variant={'h5'}
                            className={classes.uploadLimits}>
                            {/*Format - JPG, PNG, TIFF or BMP.*/}
                        </Typography>
                    }
                </Box>
            </Box>
            <CustomButton
                variant={'text'}
                color={'primary'}
                onClick={open}
                className={classes.uploadButton}
            >
                Select files
            </CustomButton>
        </>
    );
};

export default RenderUploadFiles;

const useStyles = makeStyles<Theme>(() => ({
    uploadText: {
        color: COLORS.TEXT_SILVER,
        height: '3rem'
    },
    uploadInfo: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        marginTop: 12,
    },
    uploadImg: {
        height: 81,
        width: 'auto',
        marginRight: 8
    },
    uploadLimits: {
        fontSize: 12,
        color: COLORS.TEXT_SILVER
    },
    uploadButton: {
        alignSelf: 'flex-start',
        marginTop: 12
    },
    chipsWrapper: {
        height: 81,
        overflowY: 'scroll',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        '-ms-overflow-style': 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
            display: 'none',
        }
    }
}));
