import React, {FC} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import {Paper, PaperProps} from '@material-ui/core';
import {useHistory} from 'react-router-dom';
import CustomButton from '../../../components/ui/Buttons/Button';

type ContactWithUsSectionProps = PaperProps & {
    title: string
    subTitle: string
    push: string
    icon: any
    disabled?: boolean
}

const ContactWithUsSection:FC<ContactWithUsSectionProps> = (props: ContactWithUsSectionProps):JSX.Element => {
    const classes = useStyles();
    const {title, subTitle, push, icon, disabled, ...rest} = props;
    const history = useHistory();
    return (<Paper
        elevation={3}
        classes={{root: classes.LinkPaper}}
        {...rest}
    >
        <List className={classes.root}>
            <ListItem>
                <ListItemAvatar>
                    {icon}
                </ListItemAvatar>
                <ListItemText primary={title} secondary={subTitle} />
            </ListItem>
            <ListItemSecondaryAction>
                <CustomButton
                    variant={'outlined'}
                    color={'primary'}
                    disabled={disabled}
                    onClick={() => history.push(push)}
                >
                    Choose
                </CustomButton>
            </ListItemSecondaryAction>
        </List>
    </Paper>);
}
export default ContactWithUsSection;

const useStyles = makeStyles({
    LinkPaper: {
        padding: '24px 32px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    root: {
        width: '100%'
    }
});
