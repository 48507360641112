import React, {useEffect, useState} from 'react';
import {Box, IconButton, makeStyles, Table, TableBody, TableContainer, Typography} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import CustomDrawer from './CreateInvoice/Drawer';
import CustomButton from "../../../components/ui/Buttons/Button";
import {useUIContext} from "../../../context/UIContext";
import {ReactSVG} from 'react-svg'
import GeoIcon from '../../../assets/icons/geo.svg';
import PhoneIcon from '../../../assets/icons/local_phone.svg';
import EmailIcon from '../../../assets/icons/email.svg';
import CustomTableHead from "../../../components/ui/Tables/TableHead";
import {INVOICES_PREVIEW_HEAD} from "../../../constants/tableColumns";
import CustomTableRow from "../../../components/ui/Tables/TableRow";
import TooltipCell from "../../../components/ui/Tables/TooltipCell";
import clsx from "clsx";
import CustomPaper from "../../../components/ui/Paper";
import {formattedDate} from "../../../helpers";
import {useColoringValue} from "../../../helpers/hooks";
import QrPreviewInvoice from "../../../assets/qrPreviewInvoice.png";
import {Close} from "@material-ui/icons";
import CheckCircle from "../../../assets/icons/check_circle.svg";
import Failed from "../../../assets/icons/failed.svg";
import CustomShare from "./Share/Share";
import DialogCancel from "../../../components/ui/Dialogs/DialogCancel";
import DialogConfirm from "../../../components/ui/Dialogs/DialogConfirm";
import {useGlobalContext} from "../../../context/globalContext";

type CustomInvoicePreviewProps = {
    openInvoicePreview: boolean;
    setOpenInvoicePreview: any;
    dataInvoice: any;
}

const InvoicePreview: React.FC<CustomInvoicePreviewProps> = (props: CustomInvoicePreviewProps): JSX.Element => {
    const {openInvoicePreview, setOpenInvoicePreview, dataInvoice} = props;
    const classes = useStyles();
    const today = new Date();
    const [openCancel, setOpenCancel] = useState<boolean>(false);
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);

    return (
        <CustomDrawer open={openInvoicePreview} onClose={() => setOpenInvoicePreview(false)}>
            <div className={classes.invoicePreview}>
                <div className={classes.header}>
                    <img src={QrPreviewInvoice} className={classes.imgQr}/>
                    <div className={classes.contactInfo}>
                        <Typography
                            variant={'h4'}
                            component={'h4'}
                            className={classes.companyName}
                        >Company Name</Typography>
                        <div className={classes.iconContainer}>
                            <ReactSVG src={EmailIcon} className={classes.infoIcon}/>
                            <Typography
                                variant={'body1'}
                                component={'p'}
                                className={classes.infoTitle}
                            >info@shopname.com</Typography>
                        </div>
                        <div className={classes.iconContainer}>
                            <ReactSVG src={GeoIcon} className={classes.infoIcon}/>
                            <Typography variant={'body1'} component={'p'} className={classes.infoTitle}>19510 Van Buren
                                Blvd
                                #F3-1028
                                Riverside, CA 92508
                                Riverside County, California</Typography>
                        </div>
                        <div className={classes.iconContainer}>
                            <ReactSVG src={PhoneIcon} className={classes.infoIcon}/>
                            <Typography
                                variant={'body1'}
                                component={'p'}
                                className={classes.infoTitle}
                            >+ 1 (917) - 675 - 52 - 71</Typography>
                        </div>
                    </div>
                    <IconButton
                        size={'medium'}
                        className={classes.closeButton}
                        onClick={() => setOpenInvoicePreview(false)}
                    ><Close/></IconButton>
                </div>
                <div className={classes.totalAmountContainer}>
                    <Typography variant={'h4'} component={'p'} className={classes.totalAmountTitle}>Total
                        amount</Typography>
                    <Typography
                        variant={'h2'}
                        component={'span'}
                        className={classes.totalAmountPrice}
                    >${useColoringValue('#FFFFFF', 'rgba(255, 255, 255, 0.74)', dataInvoice?.totalAmount)}</Typography>
                </div>
                <div className={classes.invoiceBody}>
                    <Typography variant={'h2'} component={'h2'} className={classes.titleInvoice}>Invoice
                        #{dataInvoice?.name}</Typography>
                    {dataInvoice?.description &&
                    <div className={classes.containerInfoBlock}>
                        <Typography
                            variant={'caption'}
                            component={'p'}
                            className={classes.titleBlock}
                        >description</Typography>
                        <Typography
                            variant={'body1'}
                            component={'p'}
                            className={classes.textBlock}
                        >{dataInvoice?.description}</Typography>
                    </div>}
                    <div className={classes.containerDateEmail}>
                        <div className={classes.containerInfoBlock} style={{minWidth: 190}}>
                            <Typography variant={'caption'} component={'p'} className={classes.titleBlock}>ISSUE
                                DATE</Typography>
                            <Typography
                                variant={'body1'}
                                component={'p'}
                                className={classes.textBlock}
                            >{formattedDate(today)}</Typography>
                        </div>
                        <div className={classes.containerInfoBlock} style={{minWidth: 190}}>
                            <Typography
                                variant={'caption'}
                                component={'p'}
                                className={classes.titleBlock}
                            >due DATE</Typography>
                            <Typography
                                variant={'body1'}
                                component={'p'}
                                className={classes.textBlock}
                            >{formattedDate(dataInvoice.dueOnDate)}</Typography>
                        </div>
                        {dataInvoice?.recipientEmail &&
                        <div className={classes.containerInfoBlock}>
                            <Typography variant={'caption'} component={'p'} className={classes.titleBlock}>Recepient
                                email</Typography>
                            <Typography
                                variant={'body1'}
                                component={'p'}
                                className={classes.textBlock}
                            >{dataInvoice?.recipientEmail}</Typography>
                        </div>}
                    </div>
                    {dataInvoice?.itemsInvoice?.length > 0
                        ? <CustomPaper className={
                            clsx(
                                classes.paperContainer
                            )
                        }>
                            <TableContainer
                                classes={{root: classes.container}}
                            >
                                <Table
                                    classes={{root: classes.table}}
                                    aria-labelledby="tableTitle"
                                    size={'small'}
                                    aria-label="enhanced table"
                                    stickyHeader
                                >
                                    <CustomTableHead
                                        isInvoicesTable
                                        headCells={INVOICES_PREVIEW_HEAD}
                                    />
                                    <TableBody classes={{root: classes.tableBody}}>
                                        {dataInvoice?.itemsInvoice?.map((row, index) => {
                                            return (
                                                <CustomTableRow
                                                    tabIndex={-1}
                                                    key={row.id}
                                                >
                                                    <TooltipCell
                                                        component="th"
                                                        id={index}
                                                        scope="row"
                                                        label={row.name}
                                                        value={row.name}
                                                    />
                                                    <TooltipCell
                                                        label={row.quantity}
                                                        value={row.quantity}
                                                        align={'right'}
                                                    />
                                                </CustomTableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CustomPaper>
                        : null
                    }
                    {dataInvoice?.note &&
                    <div className={classes.containerInfoBlock}>
                        <Typography
                            variant={'caption'}
                            component={'p'}
                            className={classes.titleBlock}
                        >Note</Typography>
                        <Typography
                            variant={'body1'}
                            component={'p'}
                            className={classes.textBlock}
                        >{dataInvoice?.note}</Typography>
                    </div>}
                </div>
                <div className={classes.footer}>
                    <CustomShare/>
                    <div className={classes.btnContainer}>
                        <CustomButton
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => setOpenCancel(true)}
                            className={classes.cancelInvoice}
                        ><ReactSVG src={Failed}/><span>Cancel invoice</span></CustomButton>
                        <CustomButton
                            type={'submit'}
                            variant={'contained'}
                            color={'primary'}
                            onClick={() => setOpenConfirm(true)}
                            className={classes.btnCheck}
                        ><ReactSVG src={CheckCircle}/><span>Check as confirm</span></CustomButton>
                    </div>
                </div>
            </div>
            {openCancel && <DialogCancel
                open={openCancel}
                title={'Cancel invoice?'}
                name={dataInvoice?.name}
                onClose={() => setOpenCancel(false)}
                action={() => {
                    console.log('d')
                }}
            />}
            {openConfirm && <DialogConfirm
                open={openConfirm}
                title={'Check as confirm?'}
                name={dataInvoice?.name}
                onClose={() => setOpenConfirm(false)}
                action={() => {
                    console.log('d')
                }}
            />}
        </CustomDrawer>
    );
};

export default InvoicePreview;

const useStyles = makeStyles<Theme>(() => ({
    invoicePreview: {
        minWidth: 807,
        maxWidth: 807,
        padding: '16px 16px 0',
        minHeight: '100vh',
        position: 'relative',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 32
    },
    imgQr: {
        padding: 18
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '32px 40px',
        position: 'fixed',
        width: 807,
        bottom: 0,
        right: 0,
        zIndex: 99999,
        background: '#FFFFFF',
        boxShadow: '0px -8px 11px -6px rgba(132, 111, 216, 0.14)'
    },
    btnCheck: {
        height: 56,
        padding: '0 32px',
        marginLeft: 16,
        '& > span > div > div > svg': {
            display: 'block',
            marginRight: 8
        }
    },
    cancelInvoice: {
        color: '#DC3030',
        background: '#FAE6E6',
        boxShadow: 'none',
        height: 56,
        padding: '0 32px',
        '& > span > div > div > svg': {
            display: 'block',
            marginRight: 8
        },
        '&:hover': {
            background: 'rgba(220, 48, 48, 0.15)',
            boxShadow: 'none'
        },
        '&:active': {
            background: 'rgba(220, 48, 48, 0.1)',
            boxShadow: 'none'
        },
    },
    contactInfo: {
        maxWidth: 300,
        margin: '0 0 0 auto',
        '& > div + div': {
            marginTop: 16
        }
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        '& > div > div > svg': {
            display: 'block'
        }
    },
    infoTitle: {
        marginLeft: 16,
        fontSize: 16,
        lineHeight: '24px',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    infoIcon: {
        marginBottom: 'auto'
    },
    totalAmountContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '12px 24px',
        background: '#276EF9',
        borderRadius: 16
    },
    totalAmountTitle: {
        color: '#FFFFFF'
    },
    totalAmountPrice: {
        color: '#FFFFFF',
        lineHeight: '60px'
    },
    invoiceBody: {
        padding: '0 24px 150px'
    },
    titleInvoice: {
        lineHeight: '60px',
        margin: '32px 0 16px'
    },
    containerInfoBlock: {},
    titleBlock: {
        color: 'rgba(0, 0, 0, 0.6)',
        marginBottom: 8,
        lineHeight: '18px'
    },
    containerDateEmail: {
        display: 'flex',
        '& > div + div': {
            marginLeft: 16
        },
        margin: '30px 0 34px'
    },
    paperContainer: {
        filter: 'drop-shadow(0px 6px 17px rgba(73, 105, 219, 0.08))',
        borderRadius: 16,
        marginBottom: 34
    },
    tableBody: {
        '& > tr:last-of-type > th, & > tr:last-of-type > td': {
            borderBottom: 'none'
        },
        '& > tr > th:first-child': {
            paddingLeft: '24px !important'
        },
        '& > tr > td:last-child': {
            paddingRight: '24px !important'
        }
    },
    table: {
        paddingBottom: 8
    },
    closeButton: {
        margin: '10px 8px auto 23px'
    },
    companyName: {
        marginBottom: 12,
        lineHeight: '48px'
    }
}));
