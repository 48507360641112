import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import AuthLayout from '../../layouts/AuthLayout';
import ForgotPassword from './images/forgot-password-icon.svg';
import {Switch, Route, useHistory} from 'react-router-dom';
import SendEmailForm from '../../components/forms/SendEmailForm';
import SendCodeForm from '../../components/forms/SendCodeForm';
import {ForgotPasswordContextProvider} from '../../context/forgotPasswordContext';

const ForgotPasswordPage: React.FC = (props): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <AuthLayout icon={ForgotPassword}>
            <Box className={classes.formWrapper}>
                <Typography
                    variant={'h5'}
                    className={classes.backBtn}
                    onClick={() => history.push('/log-in')}
                >Back to login</Typography>
                <ForgotPasswordContextProvider>
                    <Switch>
                        <Route exact path={'/forgot-password/send-email'} component={SendEmailForm} />
                        <Route exact path={'/forgot-password/send-code'} component={SendCodeForm} />
                    </Switch>
                </ForgotPasswordContextProvider>
            </Box>
        </AuthLayout>
    );
};

export default ForgotPasswordPage;

const useStyles = makeStyles<Theme>(theme => ({
    formWrapper: {
        height: 'auto',
        display: 'flex',
        flexFlow: 'column wrap',
        position: 'relative',
        width: '100%'
    },
    backBtn: {
        color: theme.palette.primary.main,
        alignSelf: 'flex-start',
        cursor: 'pointer',
        marginBottom: 8,
        zIndex: 2
    }
}));
