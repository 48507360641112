import React, {useState} from 'react';
import {Form, Formik} from 'formik';
import {Box, IconButton, InputAdornment, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {CHANGE_PASSWORD_SCHEMA} from '../../constants/validation_schema';
import CustomButton from '../ui/Buttons/Button';
import FormBoxInput from '../ui/FormBoxInput';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import {useChangePasswordMutation} from '../../generated/graphql';
import {useLocalStorage} from '../../helpers/hooks';
import {LOCAL_STORAGE_KEYS} from '../../constants';
import {useUIContext} from '../../context/UIContext';
import {useGlobalContext} from '../../context/globalContext';
import {MESSAGES} from '../../constants/messages';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

const PrivacySettingsForm = () => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState([false, false, false]);
    const [,setToken] = useLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, null);
    const [storeToken, setStoreToken] = useLocalStorage(LOCAL_STORAGE_KEYS.STORE_TOKEN, null);
    const {showSnackBar} = useUIContext();
    const {setUserData, userData} = useGlobalContext();
    const handleClickShowPassword = (index) => {
        const arr = showPassword;
        const item = showPassword[index];
        const newArr = [...arr.slice(0, index), !item, ...arr.slice(index + 1)];
        setShowPassword(newArr);
    };
    const [changePassword] = useChangePasswordMutation();
    return (
        <Formik
            initialValues={{
                password: '',
                newPassword: '',
                confirmPassword: ''
            }}
            validationSchema={CHANGE_PASSWORD_SCHEMA}
            onSubmit={async (values, resetForm) => {
                try {
                    const {data} = await changePassword({
                        variables: {
                            password: values.password,
                            newPassword: values.newPassword
                        }
                    })
                    if (data?.changePassword?.token) {
                        if (storeToken) {
                            setStoreToken(data?.changePassword?.token)
                        } else {
                            setToken(data?.changePassword?.token);
                        }
                        // @ts-ignore
                        setUserData(prevState => ({
                            ...prevState,
                            ...data?.changePassword?.account
                        }));
                        showSnackBar(MESSAGES.SUCCESS_CHANGE_PASSWORD);
                    }
                } catch (e) {
                    showSnackBar(e?.message);
                } finally {
                    resetForm.resetForm()
                }
            }}
        >
            {({isSubmitting, setFieldValue}) => (
                <Form className={classes.privacySettings}>
                    <Typography variant={'h3'} className={classes.settingsTitle}>Privacy Settings</Typography>
                    <Box className={classes.privacySettingsForm}>
                        <Typography>Change password</Typography>
                        <Box className={classes.formRow}>
                            <FormBoxInput
                                fullWidth
                                name={'password'}
                                icon={<VpnKeyOutlinedIcon />}
                                label={'Old password'}
                                labelWidth={100}
                                type={showPassword[0] ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword(0)}
                                            edge="end"
                                        >
                                            {showPassword[0] ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                wrapperContainer={classes.input}
                            />
                            <FormBoxInput
                                fullWidth
                                name={'newPassword'}
                                icon={<VpnKeyOutlinedIcon />}
                                label={'New password'}
                                labelWidth={110}
                                type={showPassword[1] ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword(1)}
                                            edge="end"
                                        >
                                            {showPassword[1] ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                wrapperContainer={classes.input}
                            />
                            <FormBoxInput
                                fullWidth
                                name={'confirmPassword'}
                                icon={<VpnKeyOutlinedIcon />}
                                label={'Re-enter new password'}
                                labelWidth={180}
                                type={showPassword[2] ? 'text' : 'password'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword(2)}
                                            edge="end"
                                        >
                                            {showPassword[2] ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                wrapperContainer={classes.input}
                            />
                        </Box>
                        <CustomButton
                            type={'submit'}
                            variant={'outlined'}
                            className={classes.applyButton}
                            disabled={isSubmitting}
                        >Save</CustomButton>
                    </Box>
                </Form>
            )}
        </Formik>
    );
};

export default PrivacySettingsForm;

const useStyles = makeStyles(() => ({
    applyButton: {
        alignSelf: 'flex-start'
    },
    privacySettings: {
        padding: '48px 0'
    },
    settingsTitle: {

    },
    privacySettingsForm: {
        padding: '40px 0 0 0'
    },
    settingsSubtitle: {

    },
    formRow: {
        marginTop: 32
    },
    input: {
        marginBottom: 16,
        width: 650
    }
}))
