import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/core';
import SuccessImg from '../assets/background/success-img.svg';
import CustomButton from '../components/ui/Buttons/Button';
import {useHistory} from 'react-router-dom';

type SuccessLayout = {
    title: string;
    push: string;
    buttonText: string;
    subtitle?: string;
}

const SuccessLayout: React.FC<SuccessLayout> = (props: SuccessLayout): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const {subtitle, title, push, buttonText} = props;
    return (
        <div className={classes.CreateSuccessPage}>
            <img src={SuccessImg} alt="failed" className={classes.img}/>
            {title && <h1 className={classes.title}>{title}</h1>}
            {subtitle && <h2 className={classes.subtitle}>{subtitle}</h2>}
            <CustomButton
                variant={'contained'}
                onClick={() => history.push(push)}
            >{buttonText}</CustomButton>
        </div>
    );
};

export default SuccessLayout;

const useStyles = makeStyles<Theme>(() => ({
    CreateSuccessPage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        backgroundColor: 'white',
        height: '100%',
        width: '100%'
    },
    img: {
        alignSelf: 'center',
        marginBottom: 10

    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.87)',
        marginBottom: 8
    },
    subtitle: {
        fontSize: 14,
        textAlign: 'center',
        color: 'rgba(0, 0, 0, 0.6)',
        fontWeight: 'normal',
        marginBottom: 20,
        '&>p': {
            marginBottom: 5
        }
    }
}));
