import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default function CustomSelect() {
    const classes = useStyles();
    const [state, setState] = React.useState<{ age: string | number; name: string }>({
        age: '0',
        name: 'hai',
    });

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
        const name = event.target.name as keyof typeof state;
        setState({
            ...state,
            [name]: event.target.value,
        });
    };

    return (
        <div>
            <FormControl variant="outlined" className={classes.formControl}>
                <Select
                    value={state.age}
                    onChange={handleChange}
                    name="age"
                    classes={{
                        root: classes.root,
                        outlined: classes.outlined,
                        iconOutlined: classes.iconOutlined
                    }}
                    inputProps={{'aria-label': 'age'}}
                    MenuProps={{
                        getContentAnchorEl: null,
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                        },
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                        },
                        classes: {
                            list: classes.list,
                            paper: classes.paper
                        }
                    }}
                >
                    <MenuItem value={0}>Product quantity</MenuItem>
                    <MenuItem value={10}>Product quantity 1</MenuItem>
                    <MenuItem value={20}>Product quantity 2</MenuItem>
                    <MenuItem value={30}>Product quantity 3</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}

const useStyles = makeStyles<Theme>((theme) => ({
    formControl: {
        fontSize: 16,
        lineHeight: '24px',
        width: 325
    },
    root: {
        fontSize: 16,
        padding: '18px 25px 18px 16px'
    },
    paper: {
        boxShadow: '0px 6px 29px rgba(0, 4, 38, 0.16)',
        borderRadius: '16px',
        padding: '8px 0',
        marginTop: '8px'
    },
    outlined: {},
    list: {
        'li:hover, &:focus': {
            borderRadius: '8px',
            backgroundColor: 'rgba(39, 110, 249, 0.04)'
        },
        'li:active': {
            color: 'rgba(39, 110, 249, 0.1)',
            backgroundColor: 'rgba(39, 110, 249, 0.1)'
        },
        '& .Mui-selected': {
            paddingLeft: '16px',
            borderRadius: '8px',
            backgroundColor: 'rgba(39, 110, 249, 0.08)',
            '&:hover, &:focus': {
                backgroundColor: 'rgba(39, 110, 249, 0.08)'
            },
            '&:active': {
                backgroundColor: 'rgba(39, 110, 249, 0.1)'
            }
        },
        '& .MuiTouchRipple-root': {
            color: 'rgba(39, 110, 249, 0.1)'
        }
    },
    iconOutlined: {
        right: 10
    }
}));
