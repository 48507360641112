import React, {useEffect, useState, useRef} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import {useUIContext} from '../context/UIContext';
import Header from '../pages/Header';
import {ELEMENTS_HEIGHT, LOCAL_STORAGE_KEYS} from '../constants';
import AuthorizedRoutes from '../navigation/AuthorizedRoutes';
import MainNavigation from '../navigation/MainNavigation';
import {HEADER_HIDE} from '../navigation/helpers/constants';
import {useLocation} from 'react-router-dom';
import {COLORS} from '../constants/colors';
import {useGlobalContext} from '../context/globalContext';
import GlobalLoader from '../components/ui/Animations/GlobalLoader';
import {useLocalStorage} from '../helpers/hooks';
import DialogGreetings from '../components/ui/Dialogs/DialogGreetings';

const MainLayout: React.FC = (props): JSX.Element => {
    const classes = useStyles();
    const [firstOnPlatform, setFirstOnPlatform] = useLocalStorage(LOCAL_STORAGE_KEYS.FIRST_ON_PLATFORM, null)
    const [firstOnPlatformState, setFirstOnPlatformState] = useState(JSON.parse(firstOnPlatform));
    const location = useLocation();
    const {userData} = useGlobalContext();

    const {showHeader, setShowHeader} = useUIContext();
    useEffect(() => {
        setShowHeader(!HEADER_HIDE.includes(location.pathname.split('/', 2)[1]))
    }, [location.pathname]);
    if (!userData?.type) {
        return <GlobalLoader />
    }
    return (
        <Box className={classes.app}>
            {showHeader && <Header />}
            <MainNavigation />
            <AuthorizedRoutes />
            {process.env.REACT_APP_MODE === 'DEMO' && firstOnPlatformState &&
                <DialogGreetings
                    open={firstOnPlatform}
                    onClose={() => {
                        setFirstOnPlatform(false)
                        setFirstOnPlatformState(false)
                    }}
                />
            }
        </Box>
    );
};

export default MainLayout;

const useStyles = makeStyles<Theme>(theme => ({
    app: {
        position: 'relative',
        width: '100vw',
        height: '100vh',
        background: 'silver',
        display: 'grid',
        backgroundColor: COLORS.WHITE,
        gridTemplateAreas: `
            'side header'
            'side content'
            'side content'
        `,
        gridTemplateColumns: '250px 1fr',
        gridTemplateRows: `${ELEMENTS_HEIGHT.HEADER}px 1fr`
    }
}));

