import {FILTERS_TYPE} from '../../../types/type';

const checkAllStoreDisplay = (filter: FILTERS_TYPE, displayed, storesTotal: number, contactsTotal: number): boolean => {
    if (filter === 'STORE_CONTACTS') {
        return displayed?.length >= contactsTotal
    } else if (filter === 'MY_STORES') {
        return displayed?.length >= storesTotal
    } else {
        return displayed?.length >= contactsTotal + storesTotal
    }
}
const typeIcons = (myWallet: string, fromWallet: string) => {
    return myWallet === fromWallet;
}

export {
    checkAllStoreDisplay,
    typeIcons
}
