import React, {useCallback, useEffect, useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Checkbox, IconButton, makeStyles} from '@material-ui/core';
import {useUIContext} from '../../context/UIContext';
import {useGlobalContext} from '../../context/globalContext';
import {useHistory} from 'react-router-dom';
import {useSelectRow} from '../../helpers/hooks';
import {
    ProductsSearchType,
    useDeleteProductMutation,
    useGetConsignmentsQuery,
} from '../../generated/graphql';
import TwoSectionLayout from '../../layouts/TwoSectionLayout';
import CustomPaper from '../../components/ui/Paper';
import {CONSIGNMENT_HEAD} from '../../constants/tableColumns';
import CheckBoxTable from '../../components/ui/Tables/CheckBoxTable';
import FailedToLoad from '../../components/FailedToLoad';
import EmptyPage, {EmptyPageType} from '../../components/EmptyPage';
import TableSkeleton from '../../components/ui/Tables/TableSkeleton';
import CustomTableRow from '../../components/ui/Tables/TableRow';
import {isSelected} from '../../helpers';
import MuiTableCell from '../../components/ui/Tables/TableCell';
import {DeleteOutline, LocationOnOutlined} from '@material-ui/icons';
import DeleteDialog from '../../components/ui/Dialogs/DialogDelete';
import {MESSAGES} from '../../constants/messages';
import {ProductType} from '../../types/enum';
import ProductDetails from '../products/components/ProductDetails';
import TooltipCell from '../../components/ui/Tables/TooltipCell';


const ConsignmentPage: React.FC = (props): JSX.Element => {
    const classes = useStyles();
    const history = useHistory();
    const {showSnackBar} = useUIContext();
    const {rowsPerPage, userData} = useGlobalContext();
    const [skip, setSkip] = useState<number>(0);
    const [deleteId, setDeleteId] = useState<number | null>(null);
    const [id, setId] = useState(null);
    const [selected, handleClick, selectAllClick, setSelected] = useSelectRow([]);
    const [deleteConsignments] = useDeleteProductMutation();
    const {data, loading, error, refetch, fetchMore, previousData} = useGetConsignmentsQuery({
        variables: {
            ownerId: userData?.id,
            searchType: ProductsSearchType.ConsignmentsOnly,
            take: +rowsPerPage,
            skip: skip,
            blockchainAddress: userData?.blockchainAddress
        }
    })
    const showDeleteDialog = useCallback((event, id) => {
        event.stopPropagation();
        setDeleteId(id);
    }, [deleteId]);
    useEffect(() => {
        if (data?.products?.data?.length > 0) {
            setId(data?.products?.data[0]?.id)
        }
    }, [data]);
    const deleteProductHandler = async () => {
        try {
            const {data} = await deleteConsignments({
                variables: {
                    // @ts-ignore
                    ids: deleteId ? [deleteId] : selected?.map(item => item?.id)
                }
            })
            if (data?.productsDelete) {
                setSelected((prevState) => deleteId ? prevState?.filter(item => item?.id !== deleteId) : [])
                showSnackBar(selected?.length >= 2 ? MESSAGES.CONSIGNMENTS_DELETE : MESSAGES.CONSIGNMENT_DELETE)
            }
        } catch (e) {
            showSnackBar(e?.message)
        } finally {
            setDeleteId(null)
            await refetch()
        }
    }
    const goToLocation = (id: number): void => history.push(`/products/location/${id}`)
    if (error) {
        return <FailedToLoad refetch={refetch}/>
    }
    if (data?.products?.data?.length === 0 && !loading) {
        return <EmptyPage type={EmptyPageType.Consignments}/>
    }
    return (
        <TwoSectionLayout
            first={2}
            second={1}
        >
            <CustomPaper className={classes.tableContainer}>
                <CheckBoxTable
                    headCells={CONSIGNMENT_HEAD}
                    data={data?.products?.data}
                    selected={selected}
                    totalCount={data?.products?.total}
                    setSkip={setSkip}
                    selectAllClick={selectAllClick}
                    tablePaginationProps={{
                        rowsPerPageOptions: [10, 25, 50],
                        fetchMore: fetchMore,
                        labelDisplayedRows: () => {
                            return `${skip}-${(data?.products?.data?.length || 0) + skip} of ${data?.products?.total || 0}`;
                        }
                    }}
                >
                    {loading && !previousData ? <TableSkeleton cell={4}/> :
                        data?.products?.data?.map((item, index) => {
                            const isItemSelected = isSelected(item.id, selected);
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <CustomTableRow
                                    key={item?.id}
                                    hover
                                    // @ts-ignore
                                    onClick={(event) => handleClick(event, item)}
                                    role="checkbox"
                                    aria-checked={isItemSelected}
                                    tabIndex={-1}
                                    selected={isItemSelected}
                                >
                                    <MuiTableCell padding="checkbox">
                                        <Checkbox
                                            checked={isItemSelected}
                                            inputProps={{'aria-labelledby': labelId}}
                                            color={'primary'}
                                        />
                                    </MuiTableCell>
                                    <TooltipCell
                                        label={item?.name}
                                        value={item?.name}
                                    />
                                    <TooltipCell
                                        label={item?.balance}
                                        value={item?.balance}
                                        align={'right'}
                                    />
                                    <MuiTableCell align="center" width={'auto'}>
                                        <IconButton
                                            disabled={true}
                                            onClick={() => goToLocation(item?.id)}
                                        >
                                            <LocationOnOutlined fontSize={'small'}/>
                                        </IconButton>
                                        <IconButton
                                            onClick={event => showDeleteDialog(event, item?.id)}
                                        >
                                            <DeleteOutline fontSize={'small'}/>
                                        </IconButton>
                                    </MuiTableCell>
                                </CustomTableRow>
                            )
                        })
                    }
                </CheckBoxTable>
            </CustomPaper>
            <ProductDetails
                selectedItem={selected?.length > 0 ? selected[0]?.id : id}
                selected={selected}
                setSelected={setSelected}
                type={ProductType.consignment}
                dataRefetch={refetch}
                deleteProducts={deleteProductHandler}
            />
            {deleteId &&
            <DeleteDialog
                open={Boolean(deleteId)}
                title={'Delete?'}
                subtitle={'This record will be permanently deleted. You can’t undo this action'}
                onClose={() => setDeleteId(null)}
                action={deleteProductHandler}
            />
            }
        </TwoSectionLayout>
    );
};

export default ConsignmentPage;

const useStyles = makeStyles<Theme>(() => ({
    tableContainer: {
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
}));
