import React, {FC} from 'react';
import {Box, makeStyles, Typography} from '@material-ui/core';
import CustomButton from './ui/Buttons/Button';
import {Theme} from '@material-ui/core/styles';
import FailedImg from '../assets/background/failed-to-load.svg';
import CustomPaper from './ui/Paper';

type FailedToLoadProps = {
    refetch: any
}

const FailedToLoad:FC<FailedToLoadProps> = ({refetch}: FailedToLoadProps):JSX.Element => {
    const classes = useStyles();
    return (
        <CustomPaper className={classes.container}>
            <img src={FailedImg} alt="failed"/>
            <Typography variant={'subtitle2'} className={classes.title}>Failed to load</Typography>
            <CustomButton variant={'outlined'} onClick={() => refetch()}>Try again</CustomButton>
        </CustomPaper>
    );
}

export default FailedToLoad;

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexFlow: 'column',
        width: '100%',
        height: '100%',
        background: 'white',
        '&>*': {
            marginBottom: 25
        },
    },
    title: {

        color: 'rgba(0, 0, 0, 0.87)'
    }
}))
