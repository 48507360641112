import React, {useEffect, useState} from 'react';
import {Box, Button, IconButton, makeStyles, Table, TableBody, TableContainer, Typography} from '@material-ui/core';
import {Theme} from '@material-ui/core/styles';
import CustomDrawer from './CreateInvoice/Drawer';
import {Close} from '@material-ui/icons';
import QrInvoice from '../../../assets/qrInvoice.png';
import {Field, Form, Formik} from "formik";
import {RECIPIENT_EMAIL_SCHEMA} from "../../../constants/validation_schema";
import FormInput from "../../../components/ui/FormInput";
import SendIcon from '../../../assets/icons/send.svg';
import {ReactSVG} from 'react-svg'
import CustomButton from "../../../components/ui/Buttons/Button";
import AttachmentIcon from '../../../assets/icons/attachment.svg';
import QrCodeIcon from '../../../assets/icons/qr_code.svg';
import MuiTableCell from "../../../components/ui/Tables/TableCell";

type CustomQRInvoicePreviewProps = {
    openQRInvoicePreview: boolean;
    setOpenQRInvoicePreview: any;
    dataNewInvoice: any;
    setDataNewInvoice?: any;
    isBtnPreview?: boolean;
}

const QRInvoicePreview: React.FC<CustomQRInvoicePreviewProps> = (props: CustomQRInvoicePreviewProps): JSX.Element => {
    const {openQRInvoicePreview, setOpenQRInvoicePreview, dataNewInvoice, setDataNewInvoice, isBtnPreview} = props;
    const classes = useStyles();

    const onCloseHandler = () => {
        if (isBtnPreview) {
            setOpenQRInvoicePreview(false);
            return;
        }
        setDataNewInvoice([]);
        setOpenQRInvoicePreview(false);
    }

    return (
        <CustomDrawer open={openQRInvoicePreview} onClose={onCloseHandler}>
            <div className={classes.qrInvoicePreview}>
                <div className={classes.header}>
                    {!isBtnPreview
                        ? <Typography variant={'h4'} component={'h4'}>Invoice has been created</Typography>
                        : null}
                    <IconButton
                        size={'medium'}
                        className={classes.closeButton}
                        onClick={onCloseHandler}
                    ><Close/></IconButton>
                </div>
                <Typography variant={'h2'} component={'h2'} className={classes.title}>Invoice #548-66AC</Typography>
                <Typography
                    variant={'h3'}
                    component={'h3'}
                    style={{lineHeight: '48px'}}
                >Total sum: ${dataNewInvoice.totalAmount}</Typography>
                <img src={QrInvoice} className={classes.imgQr}/>
                <Typography variant={'body1'} style={{color: 'rgba(0, 0, 0, 0.6)'}}>This area for notes. Please, check
                    invoice number and total sum before scan QR-code. Additional details you can see scan QR code or
                    download PDF with full information</Typography>
                <div className={classes.footer}>
                    <Formik
                        initialValues={{
                            email: ''
                        }}
                        validationSchema={RECIPIENT_EMAIL_SCHEMA}
                        onSubmit={(values, actions) => {
                            console.log(values, 'values');
                        }}
                    >
                        {(propsFormik) => {
                            return (
                                <Form>
                                    <div className={classes.emailContainer}>
                                        <FormInput
                                            name={'email'}
                                            label={'Recipient email'}
                                            labelWidth={115}
                                            formControlProps={{
                                                className: classes.email
                                            }}
                                        />
                                        <Button
                                            type={'submit'}
                                            className={classes.btnSend}
                                        ><ReactSVG src={SendIcon}/></Button>
                                    </div>
                                    <div className={classes.btnContainer}>
                                        <a
                                            href={`${process.env.REACT_APP_BASE_URL}${'/uploads/1643020525584_7loe5N.pdf'}`}
                                            target="_blank"
                                            download
                                            rel="noreferrer"
                                        >
                                            <CustomButton
                                                variant={'contained'}
                                                color={'primary'}
                                                onClick={() => console.log('Download PDF')}
                                                className={classes.btnInvoice}
                                            ><ReactSVG src={AttachmentIcon}/><span>Download PDF</span>
                                            </CustomButton>
                                        </a>
                                        <CustomButton
                                            variant={'contained'}
                                            color={'primary'}
                                            onClick={() => console.log('QrCodeIcon')}
                                            className={classes.btnInvoice}
                                        ><ReactSVG src={QrCodeIcon}/><span>Download QR-code</span></CustomButton>
                                    </div>
                                </Form>
                            )
                        }}
                    </Formik>
                </div>
            </div>
        </CustomDrawer>
    );
};

export default QRInvoicePreview;

const useStyles = makeStyles<Theme>((theme) => ({
    qrInvoicePreview: {
        minWidth: 540,
        maxWidth: 540,
        padding: '24px 32px 40px',
        minHeight: '100vh',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    closeButton: {
        marginLeft: 'auto',
        marginRight: -16
    },
    title: {
        lineHeight: '60px',
        margin: 'auto 0 8px',
        paddingTop: '24px',
        // [theme.breakpoints.up(1920)]: {
        //     margin: 'auto 0 8px'
        // }
    },
    imgQr: {
        display: 'block',
        margin: 'auto',
        padding: '33px 0 23px'
    },
    emailContainer: {
        display: 'flex'
    },
    btnSend: {
        minWidth: 56,
        maxHeight: 56,
        padding: 16,
        background: '#EEF4FF',
        borderRadius: 12,
        marginLeft: 8,
        color: 'rgba(39, 110, 249, 0.18)',
        '& > span > div > div > svg': {
            display: 'block'
        },
        '&:hover': {
            background: 'rgba(39, 110, 249, 0.1)',
            boxShadow: 'none'
        },
        '&:active': {
            background: 'rgba(39, 110, 249, 0.18)',
            boxShadow: 'none'
        }
    },
    email: {
        width: '100%'
    },
    footer: {
        paddingTop: 32,
        marginTop: 'auto'
    },
    btnContainer: {
        marginTop: 4,
        '& > a': {
            marginRight: 16
        }
    },
    btnInvoice: {
        width: '222px',
        color: '#276EF9',
        background: '#EEF4FF',
        boxShadow: 'none',
        height: 56,
        padding: '0 20px',
        '& > span': {
            lineHeight: '24px',
            fontWeight: 400
        },
        '& > span > div > div > svg': {
            display: 'block',
            marginRight: 8
        },
        '&:hover': {
            background: 'rgba(39, 110, 249, 0.1)',
            boxShadow: 'none'
        },
        '&:active': {
            background: 'rgba(39, 110, 249, 0.18)',
            boxShadow: 'none'
        },
    },
}));
