import {Cell} from '../components/ui/Tables/TableHead';

const BRAND_HEAD: Cell[] = [
    {label: '', width: '5%', align: 'center'},
    {label: 'Name', width: '35%', align: 'left'},
    // {label: 'Hierarchy', width: '15%', align: 'left'},
    {label: 'Date of creation', width: '20%', align: 'right'},
    {label: 'Status', width: '15%', align: 'left'},
    {label: 'Actions', width: '10%', align: 'center'}
]
const BRAND_PRODUCTS_HEAD: Cell[] = [
    {align: 'left', label: 'Title', width: '80%'},
    {align: 'right', label: 'Quantity', width: '30%'}
]

const PRODUCTS_HEAD: Cell[] = [
    {align: 'left', label: 'Name', width: '37%'},
    {align: 'left', label: 'Brand name' ,width: '25%'},
    {align: 'right', label: 'Quantity', width: '10%'},
    {align: 'center', label: 'Actions', width: '20%'}
]

const INVOICES_PENDING_HEAD: Cell[] = [
    {align: 'left', label: 'Invoice Number', width: '18%'},
    {align: 'left', label: 'Client address' ,width: '18%'},
    {align: 'right', label: 'Amount (USD)', width: '15%'},
    {align: 'right', label: 'Products (pcs.)', width: '15%'},
    {align: 'left', label: 'Issue date', width: '14%'},
    {align: 'left', label: 'Validity', width: '18%'}
]

const INVOICES_COMPLETED_HEAD: Cell[] = [
    {align: 'left', label: 'Invoice Number', width: '18%'},
    {align: 'left', label: 'Client address' ,width: '18%'},
    {align: 'right', label: 'Amount (USD)', width: '15%'},
    {align: 'right', label: 'Products (pcs.)', width: '15%'},
    {align: 'left', label: 'Issue date', width: '14%'},
    {align: 'left', label: 'Status', width: '18%'}
]

const INVOICES_PRODUCT_SEND_HEAD: Cell[] = [
    {align: 'left', label: 'Name', width: '50%'},
    {align: 'right', label: 'Available (pcs.)', width: '19%'},
    {align: 'right', label: 'Quantity', width: '19%'},
    {align: 'center', label: '', width: '12%'},
]

const INVOICES_PREVIEW_HEAD: Cell[] = [
    {align: 'left', label: 'Name', width: '80%'},
    {align: 'right', label: 'Available (pcs.)', width: '20%'},
]

const CONSIGNMENT_HEAD: Cell[] = [
    {align: 'left', label: 'Name', width: '65%'},
    {align: 'right', label: 'Quantity', width: '15%'},
    {align: 'center', label: 'Actions', width: '20%'}
]

const PRODUCT_SELECT_HEAD: Cell[] = [
    {align: 'left', label: 'Name', width: '50%'},
    {align: 'left', label: 'Brand name' ,width: '30%'},
    {align: 'right', label: 'Available', width: '10%'},
]
const CONSIGNMENT_SELECT_HEAD: Cell[] = [
    {align: 'left', label: 'Name', width: '65%'},
    {align: 'right', label: 'Available', width: '35%'},
]

const PRODUCT_SEND_HEAD: Cell[] = [
    {align: 'left', label: 'Name', width: '50%'},
    {align: 'left', label: 'Available', width: '10%'},
    {align: 'right', label: 'Quantity', width: '20%'},
    {align: 'center', label: 'Actions', width: '15%'},
]
const CONSIGNMENT_SEND_HEAD: Cell[] = [
    {align: 'left', label: 'Name', width: '50%'},
    {align: 'right', label: 'Available', width: '10%'},
    {align: 'right', label: 'Quantity', width: '20%'},
    {align: 'center', label: 'Actions', width: '10%'},
]
const STORE_TRANSACTION_HEAD: Cell[] = [
    {width: 'auto', label: '',  align: 'center'},
    {width: '40%', label: 'Hash',  align: 'left'},
    {width: '30%', label: 'Quantity of goods',  align: 'right'},
    {width: '10%', label: 'Date',  align: 'left'},
    {width: '10%', label: 'State',  align: 'left'}
]


export {
    BRAND_HEAD,
    BRAND_PRODUCTS_HEAD,
    PRODUCTS_HEAD,
    CONSIGNMENT_HEAD,
    PRODUCT_SELECT_HEAD,
    CONSIGNMENT_SELECT_HEAD,
    PRODUCT_SEND_HEAD,
    CONSIGNMENT_SEND_HEAD,
    STORE_TRANSACTION_HEAD,
    INVOICES_PENDING_HEAD,
    INVOICES_COMPLETED_HEAD,
    INVOICES_PRODUCT_SEND_HEAD,
    INVOICES_PREVIEW_HEAD
}
