import React from 'react';
import {Typography} from '@material-ui/core';
import AuthLayout from '../../layouts/AuthLayout';
import SignUpForm from '../../components/forms/SignUpForm';
import SignUpLeftImg from '../../assets/background/SignUpLeftImg.svg';

const SignUpPage: React.FC = (): JSX.Element => {
    return (
        <AuthLayout
            icon={SignUpLeftImg}
        >
            <Typography variant={'h2'}>Sign Up to Brand Protect</Typography>
            <SignUpForm />
        </AuthLayout>
    );
};

export default SignUpPage;

