import React, {useState, useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import {uploadFile} from '../../helpers';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {Theme} from '@material-ui/core/styles';
import Avatar from './Avatar';
import DefaultAvatar from '../../assets/icons/default-avatar.svg';
import {useGlobalContext} from '../../context/globalContext';
import {useUpdateAccountMutation} from '../../generated/graphql';
import {useLocalStorage} from '../../helpers/hooks';
import {LOCAL_STORAGE_KEYS} from '../../constants';
import {useUIContext} from '../../context/UIContext';
import {MESSAGES} from '../../constants/messages';

const AccountPhoto = props => {
    const classes = useStyles();
    const {userData, setUserData} = useGlobalContext();
    const {showSnackBar} = useUIContext();
    const [, setToken] = useLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, null);
    const [storeToken, setStoreToken] = useLocalStorage(LOCAL_STORAGE_KEYS.STORE_TOKEN, null);
    const [updateAccount] = useUpdateAccountMutation();
    const onDrop = useCallback((acceptedFiles) => {
        uploadFile(acceptedFiles, (data) => {
            (async () => {
                try {
                    const {data: updateData} = await updateAccount({
                        variables: {
                            data: {
                                avatarFilePath: data.filePath
                            }
                        }
                    })
                    if (updateData?.updateAccount?.token) {
                        if (storeToken) {
                            setStoreToken(updateData?.updateAccount?.token)
                        } else {
                            setToken(updateData?.updateAccount?.token);
                        }
                    }
                    if (updateData?.updateAccount?.account) {
                        setUserData(prevState => ({...prevState, ...updateData?.updateAccount?.account}));
                        showSnackBar(MESSAGES.SUCCESS_CHANGE_AVATAR);
                    }
                } catch (e) {
                    showSnackBar(e?.message)
                }
            })()
        })
    }, []);
    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    return (
        <Box className={classes.container}>
            <Avatar
                alt="MyLogoAccount"
                // @ts-ignore
                src={userData?.avatarFilePath ? `${process.env.REACT_APP_BASE_URL}${userData?.avatarFilePath}` : DefaultAvatar}
                className={classes.avatar}
            />
            <Box className={classes.avatarDropzone} id={'box'} {...getRootProps()} {...props}>
                <input {...getInputProps()} />
                <i className={'material-icons-outlined'} style={{color: 'white'}}>add_a_photo</i>
            </Box>
        </Box>
    );
};
export default AccountPhoto;

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        position: 'relative',
        width: 141,
        height: 141,
        borderRadius: '50%',
        overflow: 'hidden',
        '&:hover #box': {
            transform: 'translate(0, -50px)',
            '-webkit-transition': 'all .2s ease-out',
            '-moz-transition': 'all .2s ease-out',
            '-o-transition': 'all .2s ease-out',
            transition: 'all .2s ease-out',
        }
    },
    avatar: {
        width: '100%',
        height: '100%',
        position: 'relative'
    },
    avatarDropzone: {
        height: '50px',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '-webkit-transition': 'all .2s ease-out',
        '-moz-transition': 'all .2s ease-out',
        '-o-transition': 'all .2s ease-out',
        transition: 'all .2s ease-out',
        cursor: 'pointer',
        outline: 'none',
    },
}))
//     &:hover .AvatarDropzone {
//     transform: translate(0, -50px);
//     -webkit-transition: all .2s ease-out;
//     -moz-transition: all .2s ease-out;
//     -o-transition: all .2s ease-out;
//     transition: all .2s ease-out;
// }
// .AvatarDropzone {
//     height: 50px;
//     width: 100%;
//     background-color: rgba(0, 0, 0, 0.38);
//     position: absolute;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     -webkit-transition: all .2s ease-out;
//     -moz-transition: all .2s ease-out;
//     -o-transition: all .2s ease-out;
//     transition: all .2s ease-out;
//     cursor: pointer;
//     outline: none;
// .AvatarDropzoneInput {
//         width: 100%;
//         height: 100%;
//     }
// }
