import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {makeStyles, TablePagination, TablePaginationProps, withStyles} from '@material-ui/core';
import {useGlobalContext} from '../../../context/globalContext';
import CustomPagination from '../Pagination';

export type CustomTablePaginationProps = Omit<TablePaginationProps, 'count' | 'page' | 'onPageChange'| 'rowsPerPage'> & {
    setSkip?: React.Dispatch<number>
    fetchMore?: any,
    totalCount?: number
}

const CustomTablePagination: React.FC<CustomTablePaginationProps> = (props: CustomTablePaginationProps): JSX.Element => {
    const {changeRowsPerPage, rowsPerPage} = useGlobalContext();
    const {setSkip, fetchMore, totalCount, ...rest} = props;
    const [page, setPage] = useState(1);
    const handleChangePage = (event: any, newPage: number): void => {

        setPage(newPage);
    };
    const handleChange = (_event: any, newPage: number): void => {
        setSkip((newPage - 1) * rowsPerPage);
        setPage(newPage);
    };
    const handleChangeRowsPerPage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        changeRowsPerPage(+event.target.value);
        await fetchMore({
            variables: {
                skip: 0,
                take: +event.target.value
            }
        });
        setPage(1);
    };
    return (
        <MuiTablePagination
            // @ts-ignore
            component={'div'}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage={'Rows per page'}
            rowsPerPageOptions={[10, 25, 50]}
            labelDisplayedRows={({from, to, count, page}) => {
                return ''
            }}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={() =>
                <CustomPagination
                    page={page}
                    showLastButton
                    showFirstButton
                    count={Math.ceil(totalCount / rowsPerPage)}
                    onChange={handleChange}
                />
            }
            {...rest}
        />
    );
};

const MuiTablePagination = withStyles(theme => ({
    root: {
        backgroundColor: 'white',
        zIndex: 2,
        borderTop: '1px solid rgba(0, 0, 0, 0.12)'
    },
    toolbar: {
        padding: '0 32px'
    },
    menuItem: {

    },
    input: {
        border: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: 4,
    },
    spacer: {
        flex: '0 0 auto',
        '&>p': {
            textAlign: 'start'
        }
    },
    caption: {
        textAlign: 'end',
        flex: '1 1 100%'
    },
}))(TablePagination)

export default CustomTablePagination;

const useStyles = makeStyles<Theme>(() => ({}));
