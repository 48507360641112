import { Maybe, Scalars, UserAgent, UserType} from '../generated/graphql';

export type userDataType = {
    id: number;
    email: string;
    type: UserType;
    currentSession: AccountSession;
    sessions?: AccountSession[];
    blockchainAddress?: string;
    billingBalance?: number;
    consignmentsBrand: {id: number};
    contacts?: storeType[];
    stores?: storeType[];
    fullName?: string;
    streetAddress?: string;
    cityState?: string;
    zipCode?: string;
    avatarFilePath?: string;

}

type AccountSession = {
    id: Scalars['Int'];
    createdAt: Scalars['Date'];
    updatedAt: Scalars['Date'];
    ipAddr: Scalars['String'];
    address?: Maybe<Scalars['String']>;
    userAgent?: Maybe<UserAgent>;
    expiresAt: Scalars['Date'];
}

type storeType = {
    id: number,
    name: string,
    comment?: string,
    createdAt: string,
    target: {blockchainAddress: string}
}

export type FILTERS_TYPE = 'ALL' | 'MY_STORES' | 'STORE_CONTACTS'

export type EndActionData = {
    type: END_ACTION_TYPE,
    open: boolean,
    message: string
    action: () => void
}

export enum END_ACTION_TYPE {
    WARNING = 'WARNING',
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS'
}

