import React, {useState} from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, Checkbox, IconButton, makeStyles, TextField, Toolbar, Typography} from '@material-ui/core';
import {BrandStatus, ProductsSearchType, useGetBrandQuery} from '../../../generated/graphql';
import {useDebounce} from 'use-debounce';
import {Skeleton} from '@material-ui/lab';
import {useGlobalContext} from '../../../context/globalContext';
import {COLORS} from '../../../constants/colors';
import EmptyProductsSection from './EmptyProductsSection';
import {BRAND_PRODUCTS_TAKE} from '../helpers/constants';
import CheckBoxTable from '../../../components/ui/Tables/CheckBoxTable';
import {BRAND_PRODUCTS_HEAD} from '../../../constants/tableColumns';
import {isSelected} from '../../../helpers';
import MuiTableCell from '../../../components/ui/Tables/TableCell';
import {useSelectRow} from '../../../helpers/hooks';
import CustomTableRow from '../../../components/ui/Tables/TableRow';
import {useHistory} from 'react-router-dom';
import CustomButton from '../../../components/ui/Buttons/Button';
import SkeletonBrandProducts from '../../../components/ui/Skeletons/SkeletonBrandProducts';
import FailedToLoad from '../../../components/FailedToLoad';
import {ProductType} from '../../../types/enum';
import CustomPaper from '../../../components/ui/Paper';
import {useUIContext} from '../../../context/UIContext';
import TooltipCell from '../../../components/ui/Tables/TooltipCell';

type BrandDetailsProps = {
    selectedItem: number | null,
}

const BrandDetails: React.FC<BrandDetailsProps> = (props: BrandDetailsProps): JSX.Element => {
    const classes = useStyles();
    const {selectedItem} = props;
    const history = useHistory();
    const [value, setValue] = useState<string>('');
    const {userData} = useGlobalContext();
    const {setUnconfirmedWarning} = useUIContext();
    const [debounceValue] = useDebounce(value, 500);
    const [skip, setSkip] = useState<number>(0);
    const [showInput, setShowInput] = useState<boolean>(false);
    const {data, loading, error, refetch, fetchMore, previousData} = useGetBrandQuery({
        variables: {
            id: selectedItem,
            take: BRAND_PRODUCTS_TAKE,
            skip: skip,
            querySearch: debounceValue,
            blockchainAddress: userData?.blockchainAddress,
            ownerId: userData?.id,
        },
        skip: !selectedItem
    });
    const [selected, handleClick, selectAllClick] = useSelectRow([]);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>):void => {
        setValue(event?.target?.value)
    }
    const handleBlur = (event: React.FocusEvent) => {
        if (value?.length === 0) {
            setShowInput(false)
        }
    }
    const sendProducts = () => {
        // @ts-ignore
        const brandNames = selected?.filter(item => item?.brand?.status === BrandStatus.AutoConfirmed).map(item => item?.brand?.name)
        if (brandNames?.length === 0) {
            history.push({
                pathname: '/products/send-products',
                state: {
                    selected,
                    type: ProductType.product
                }
            })
        } else {
            setUnconfirmedWarning({
                open: true,
                // @ts-ignore
                brandNames: [...new Set(brandNames)]
            })
        }

    }
    if (error) {
        return <FailedToLoad refetch={refetch} />
    }
    return (
        <CustomPaper>
            <Box className={classes.address}>
                <Typography variant={'h4'} className={classes.name}>
                    {data?.brand?.name || <Skeleton/>}
                </Typography>
            </Box>
            <Box className={classes.tableContainer}>
                <Toolbar className={classes.toolbar}>
                    {showInput ?
                        <TextField
                            autoFocus
                            fullWidth
                            value={value}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder={'Search'}
                            style={{
                                width: '80%'
                            }}
                        />
                        :
                        <Typography
                            className={classes.title}
                            variant={'h4'}
                        >Your products</Typography>
                    }
                    <IconButton
                        onClick={() => setShowInput(true)}
                        disabled={showInput}
                        className={classes.iconWrapper}
                    >
                        <i className={`material-icons ${showInput && classes.activeButton}`} >search</i>
                    </IconButton>
                    {selected.length > 0 && <CustomButton onClick={sendProducts}>SEND</CustomButton>}
                </Toolbar>
                {
                    data?.brand?.products?.data &&
                    data?.brand?.products?.data?.length === 0 &&
                    !loading &&
                    debounceValue.length === 0 ?
                        <EmptyProductsSection />
                        : <CheckBoxTable
                            headCells={BRAND_PRODUCTS_HEAD}
                            data={data?.brand?.products?.data}
                            selected={selected}
                            totalCount={data?.brand?.products?.total}
                            setSkip={setSkip}
                            selectAllClick={selectAllClick}
                        >
                            {!(loading && !previousData) ? data?.brand?.products?.data?.map((item, index) => {
                                const isItemSelected = isSelected(item.id, selected);
                                return (
                                    <CustomTableRow
                                        key={index}
                                        hover
                                        // @ts-ignore
                                        onClick={(event) => handleClick(event, item)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        classes={{
                                            root: classes.TableRow,
                                        }}
                                        selected={isItemSelected}
                                    >
                                        <MuiTableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                color={'primary'}
                                            />
                                        </MuiTableCell>
                                        <TooltipCell label={item?.name} value={item?.name} />
                                        <MuiTableCell
                                            align="right"
                                        >{item?.balance}</MuiTableCell>
                                    </CustomTableRow>
                                )
                            }) : <SkeletonBrandProducts/>
                            }
                        </CheckBoxTable>
                }
            </Box>
        </CustomPaper>

    );
};

export default BrandDetails;

const useStyles = makeStyles<Theme>(() => ({
    address: {
        padding: 45,
        height: '20%',
        width: '100%',
    },
    name: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
        lineHeight: 1.5,
        wordBreak: 'break-word'
    },
    tableContainer: {
        position: 'relative',
        height: '80%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    toolbar: {
        borderBottom: `1px solid ${COLORS.BORDER_SILVER}`
    },
    title: {
        color: COLORS.TEXT_LIGHT_SILVER,
    },
    iconWrapper: {
        marginRight: 'auto',
        marginLeft: '10px'
    },
    activeButton: {
        color: COLORS.PRIMARY
    }
}));
