import React from 'react';
import {Theme} from '@material-ui/core/styles';
import {Box, makeStyles, Typography} from '@material-ui/core';
import DialogLayout, {DialogLayoutProps} from '../../../layouts/DialogLayout';
import {DialogIconWrapper} from './DialogEndActionType';
import WarningIcon from '../../../assets/icons/warning.svg';
import CustomButton from '../Buttons/Button';

type DialogLogoutProps = DialogLayoutProps & {
    brandNames: string[];
}

const DialogLogout: React.FC<DialogLogoutProps> = (props: DialogLogoutProps): JSX.Element => {
    const classes = useStyles();
    const {brandNames, ...rest} = props;

    return (
        <DialogLayout
            {...rest}
        >
            <Box className={classes.wrapper}>
                <DialogIconWrapper
                    className={'WarningIconWrap'}
                    icon={WarningIcon}
                />
                <Typography variant={'h4'} className={classes.title}>Ooops!</Typography>
                <Typography variant={'subtitle2'} className={classes.info}>
                    At the time of brand verification, {brandNames?.map((item, _) => <strong key={_}>&quot;{item}&quot;, </strong>)} you have limited rights to use the platform.
                    We will notify you as the verification of your brand is completed (usually it takes from 1 to 7 days)
                </Typography>
                <CustomButton
                    variant={'contained'}
                    onClick={() => props.onClose('', 'escapeKeyDown')}
                >
                    Continue
                </CustomButton>
            </Box>
        </DialogLayout>
    );
};

export default DialogLogout;

const useStyles = makeStyles<Theme>(() => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '43px 72px 48px 72px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    title: {
        textAlign: 'center',
        marginBottom: 8
    },
    info: {
        textAlign: 'center',
        marginBottom: 32
    }
}));
