
const REGEX = {
    ONLY_INTEGER: /^[0-9]+$/,
    ONLY_FLOAT: /^(?![0.]+$)\d+(\.\d{1,2})?$/gm,
    PRICE: /^\d*.?\d{0,2}$/,
    ETHEREUM_ADDRESS: /^0x[a-fA-F0-9]{40}$/,
    ZIP_CODE: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
    NOT_AUTHORIZED_URLS: /sign-up|select-type|select-plan|forgot-password|log-in/,
    PHONE_NUMBER: /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
}

export {REGEX}
